<div class="featured-hotel-container">
    <div class="image-container">
        <img loading="lazy" [src]="hotelItem.imageUrl" alt="" />
    </div>
    <div class="overlay">
        <div class="footer-container">
            <div class="title-container">
                <span class="title">{{ hotelItem.name }}</span>
            </div>
            <div class="rating-container">
                <app-rating
                    [rating]="hotelItem.rating"
                    [ratingColor]="'white'"
                ></app-rating>
            </div>
        </div>
    </div>
</div>
