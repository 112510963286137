<!-- SITA FLAVOR -->

<!-- LAX FLAVOR -->

<div class="header-idle-state">
    <div class="left-container">
        @if(showHeaderLeftContainer){
        <div class="time-container">
            <app-time
                [timeValueColor]="'white'"
                [dateValueColor]="'white'"
            ></app-time>
        </div>
        <div class="weather-container">
            <app-weather
                [info]="weatherInfo"
                [tempTextColor]="'white'"
            ></app-weather>
        </div>
        }

        <div class="flex-space"></div>
    </div>
    <div class="middle-container">
        <div class="logo">
            <img
                (dblclick)="triggerDialog()"
                src="assets/branding/images/airportLogo.svg"
                loading="lazy"
            />
        </div>
    </div>
    <div class="right-container"></div>
</div>
