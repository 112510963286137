import { WeatherInfo } from "../../models/ui/weatherInfo.model";
import { Component, OnInit, Input } from "@angular/core";
import { NgIf, NgStyle } from "@angular/common";
@Component({
    selector: "app-weather",
    templateUrl: "./weather.component.html",
    styleUrls: ["./weather.component.scss"],
    standalone: true,
    imports: [NgIf, NgStyle]
})
export class WeatherComponent implements OnInit {
  @Input("tempTextColor") tempTextColor: string;
  @Input("info") info: WeatherInfo;

  switchTempUnit: any;
  tempValue: number;
  tempUnit: string;

  constructor() {}

  ngOnInit() {
    this.tempConverter();
  }

  ngOnChanges(changes) {
    this.tempValue = parseInt(this.info.value);
    this.tempUnit = this.info.unit;
  }

  ngOnDestroy() {
    clearInterval(this.switchTempUnit);
  }

  tempConverter() {
    this.switchTempUnit = setInterval(() => {
      if (this.tempUnit == "F") {
        this.tempValue = (this.tempValue - 32) * (5 / 9);
        this.tempValue = Math.round(this.tempValue);
        this.tempUnit = "C";
      } else {
        this.tempValue = this.tempValue * (9 / 5) + 32;
        this.tempValue = Math.round(this.tempValue);
        this.tempUnit = "F";
      }
    }, 5000);
  }
}
