/* src/app/components/static-content/static-content.component.scss */
.side-content-container {
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 50;
}
.side-content-container app-back-poi-item {
  width: 100%;
}
.side-content-container .left-section {
  background: #e8e8e8;
  display: inline-block;
  min-width: 480px;
  width: 25%;
  height: 100%;
}
.side-content-container .left-section .header-container {
  background-color: black;
  color: white;
  display: flex;
  min-height: 4em;
  height: 5em;
}
.side-content-container .left-section .header-container .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.15;
  cursor: pointer;
}
.side-content-container .left-section .header-container .icon-container .arrow-left-icon {
  font-size: 2.5em;
}
.side-content-container .left-section .header-container .title-container {
  display: flex;
  align-items: center;
  flex: 0.85;
}
.side-content-container .left-section .header-container .title-container span {
  font-size: 1.6em;
  font-weight: 500;
  text-transform: capitalize;
}
.side-content-container .left-section .list-container {
  height: calc(100% - 5em);
  overflow: auto;
  height: 100%;
}
.side-content-container .left-section .list-container .item-container {
  display: flex;
  flex: 1;
  height: 4.5em;
  cursor: pointer;
}
.side-content-container .left-section .list-container .item-container .sub-category-title {
  display: flex;
  align-items: center;
  flex: 1;
}
.side-content-container .left-section .list-container .item-container .sub-category-title span {
  color: black;
  font-size: 1.4em;
  font-weight: 500;
  text-transform: capitalize;
  padding-left: 1em;
}
.side-content-container .right-section {
  height: 100%;
  min-width: 700px;
  width: calc(100% - 4em - 480px - 8em - 1em);
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.side-content-container .right-section .section-info-container {
  flex-direction: column;
  display: flex;
  height: 100%;
  background: white;
  box-sizing: border-box;
  height: 90%;
  width: 95%;
}
.side-content-container .right-section .section-info-container .info-container {
  overflow: scroll;
  width: calc(100% - 4em) !important;
  margin-left: 2em;
  margin-right: 2em;
}
.side-content-container .right-section .section-info-container .header-title-container {
  align-items: center;
  display: flex;
  margin-top: 2em;
}
.side-content-container .right-section .section-info-container .header-title-container .title-text {
  text-transform: capitalize;
  font-size: 2.2em;
  font-weight: 500;
  color: #00a6ce;
  opacity: 0.8;
}
.side-content-container .right-section .section-info-container .subtitle-container {
  padding: 1em 0;
}
.side-content-container .right-section .section-info-container .subtitle-container .subtitle {
  text-transform: capitalize;
  color: #00a6ce;
  font-weight: 500;
  font-size: 2em;
  opacity: 0.8;
}
.send-to-mobile {
  border-bottom-left-radius: 10px;
  background-color: #00a6ce;
  align-self: flex-end;
  position: absolute;
  font-size: 0.95em;
  font-weight: 600;
  color: white;
}
.empty-flex {
  flex: 0.2;
}
.colorBlk span {
  color: black !important;
  flex: 1;
}
.highlightText {
  background: #00a6ce;
}
.highlightText span {
  color: white !important;
}
.item-container-expandable {
  padding-top: 0.5em;
}
.subcategory-body-container {
  font-size: 1.5em;
}
.send-to-client {
  font-size: 0.95em;
  font-weight: 600;
  color: white;
  height: 100%;
  width: 26%;
}
.send-to-client:hover {
  opacity: 0.5;
}
@media screen and (min-width: 1601px) and (min-height: 870px) {
  .side-content-container .left-section .header-container .icon-container .arrow-left-icon {
    font-size: 5em !important;
  }
  .side-content-container .left-section .header-container .title-container span {
    font-size: 3.2em !important;
    font-weight: 500;
  }
  .side-content-container .left-section .list-container {
    height: calc(100% - 5em) !important;
  }
  .side-content-container .left-section .list-container .item-container {
    height: 9em !important;
  }
  .side-content-container .left-section .list-container .item-container .sub-category-title {
    flex: 1;
  }
  .side-content-container .left-section .list-container .item-container .sub-category-title span {
    font-size: 2.8em !important;
    font-weight: 500;
    padding-left: 1em;
  }
  .side-content-container .left-section-lax {
    min-width: 480px !important;
  }
  .side-content-container .left-section-lax .header-container .icon-container .arrow-left-icon {
    font-size: 3em !important;
  }
  .side-content-container .left-section-lax .header-container .title-container span {
    font-size: 2.5em !important;
    font-weight: 500 !important;
  }
  .side-content-container .left-section-lax .list-container {
    height: calc(100% - 5em) !important;
  }
  .side-content-container .left-section-lax .list-container .item-container {
    height: 4.5em !important;
  }
  .side-content-container .left-section-lax .list-container .item-container .sub-category-title {
    flex: 1;
  }
  .side-content-container .left-section-lax .list-container .item-container .sub-category-title span {
    font-size: 1.8em !important;
    font-weight: 500;
    padding-left: 1em;
  }
  .side-content-container .right-section .section-info-container {
    padding: 4em !important;
  }
  .side-content-container .right-section .section-info-container .header-title-container .title-text {
    font-size: 4.4em !important;
    font-weight: 500;
  }
  .side-content-container .right-section .section-info-container .subtitle-container {
    padding: 2em 0 !important;
  }
  .side-content-container .right-section .section-info-container .subtitle-container .subtitle {
    font-size: 4em !important;
    font-weight: 500;
  }
  .side-content-container .right-section-lax {
    height: calc(100% - 4em) !important;
    width: calc(100% - 4em - 480px - 8% - 1em) !important;
  }
  .side-content-container .right-section-lax .section-info-container {
    height: 100% !important;
  }
  .side-content-container .right-section-lax .section-info-container .info-container {
    width: calc(100% - 5em) !important;
    margin-left: 2.5em !important;
    margin-right: 2.5em !important;
  }
  .side-content-container .right-section-lax .section-info-container .header-title-container {
    margin-top: 2.5em !important;
  }
  .side-content-container .right-section-lax .section-info-container .header-title-container .title-text {
    font-size: 3em !important;
    font-weight: 500;
  }
  .side-content-container .right-section-lax .section-info-container .subtitle-container {
    padding: 2em 0 !important;
  }
  .side-content-container .right-section-lax .section-info-container .subtitle-container .subtitle {
    font-size: 4em !important;
    font-weight: 500;
  }
  .item-container-expandable {
    padding-top: 1em !important;
  }
  .send-to-client {
    font-size: 0.95em !important;
  }
  .send-to-mobile {
    font-size: 0.95em !important;
  }
}
@media screen and (min-width: 1920px) and (min-height: 900px) {
  .side-content-container .left-section .header-container .icon-container .arrow-left-icon {
    font-size: 5em !important;
  }
  .side-content-container .left-section .header-container .title-container span {
    font-size: 3.2em !important;
    font-weight: 500;
  }
  .side-content-container .left-section .list-container {
    height: calc(100% - 5em) !important;
  }
  .side-content-container .left-section .list-container .item-container {
    height: 5em !important;
  }
  .side-content-container .left-section .list-container .item-container .sub-category-title {
    flex: 1;
  }
  .side-content-container .left-section .list-container .item-container .sub-category-title span {
    font-size: 1.8em !important;
    font-weight: 500;
    padding-left: 1em;
  }
  .side-content-container .left-section-lax {
    min-width: 480px !important;
  }
  .side-content-container .left-section-lax .header-container .icon-container .arrow-left-icon {
    font-size: 3em !important;
  }
  .side-content-container .left-section-lax .header-container .title-container span {
    font-size: 2.5em !important;
    font-weight: 500 !important;
  }
  .side-content-container .left-section-lax .list-container {
    height: calc(100% - 5em) !important;
  }
  .side-content-container .left-section-lax .list-container .item-container {
    height: 4.5em !important;
  }
  .side-content-container .left-section-lax .list-container .item-container .sub-category-title {
    flex: 1;
  }
  .side-content-container .left-section-lax .list-container .item-container .sub-category-title span {
    font-size: 1.8em !important;
    font-weight: 500;
    padding-left: 1em;
  }
  .side-content-container .right-section .section-info-container {
    padding: 0em !important;
  }
  .side-content-container .right-section .section-info-container .header-title-container .title-text {
    font-size: 4.4em !important;
    font-weight: 500;
  }
  .side-content-container .right-section .section-info-container .subtitle-container {
    padding: 2em 0 !important;
  }
  .side-content-container .right-section .section-info-container .subtitle-container .subtitle {
    font-size: 4em !important;
    font-weight: 500;
  }
  .side-content-container .right-section-lax {
    height: calc(100% - 4em) !important;
    width: calc(67% - 4em - 1em) !important;
  }
  .side-content-container .right-section-lax .section-info-container {
    height: 100% !important;
  }
  .side-content-container .right-section-lax .section-info-container .info-container {
    width: calc(100% - 5em) !important;
    margin-left: 2.5em !important;
    margin-right: 2.5em !important;
  }
  .side-content-container .right-section-lax .section-info-container .header-title-container {
    margin-top: 2.5em !important;
  }
  .side-content-container .right-section-lax .section-info-container .header-title-container .title-text {
    font-size: 3em !important;
    font-weight: 500;
  }
  .side-content-container .right-section-lax .section-info-container .subtitle-container {
    padding: 2em 0 !important;
  }
  .side-content-container .right-section-lax .section-info-container .subtitle-container .subtitle {
    font-size: 4em !important;
    font-weight: 500;
  }
  .item-container-expandable {
    padding-top: 1em !important;
  }
  .send-to-client {
    font-size: 1em !important;
  }
  .send-to-mobile {
    font-size: 1em !important;
  }
}
@media screen and (min-width: 3840px) and (min-height: 2160px) {
  .side-content-container .left-section .header-container {
    height: 10em;
  }
  .side-content-container .left-section .header-container .icon-container .arrow-left-icon {
    font-size: 5em !important;
  }
  .side-content-container .left-section .header-container .title-container span {
    font-size: 3.2em !important;
    font-weight: 500;
  }
  .side-content-container .left-section .list-container {
    height: calc(100% - 10em) !important;
  }
  .side-content-container .left-section .list-container .item-container {
    height: 9em !important;
  }
  .side-content-container .left-section .list-container .item-container .sub-category-title {
    flex: 1;
  }
  .side-content-container .left-section .list-container .item-container .sub-category-title span {
    font-size: 2.8em !important;
    font-weight: 500;
    padding-left: 1em;
  }
  .side-content-container .left-section-lax .header-container {
    min-height: 8em !important;
    height: 10em !important;
  }
  .side-content-container .left-section-lax .header-container .icon-container .arrow-left-icon {
    font-size: 6em !important;
  }
  .side-content-container .left-section-lax .header-container .title-container span {
    font-size: 4.2em !important;
    font-weight: 500;
  }
  .side-content-container .left-section-lax .list-container {
    height: calc(100% - 10em) !important;
  }
  .side-content-container .left-section-lax .list-container .item-container {
    height: 9em !important;
  }
  .side-content-container .left-section-lax .list-container .item-container .sub-category-title {
    flex: 1;
  }
  .side-content-container .left-section-lax .list-container .item-container .sub-category-title span {
    font-size: 3em !important;
    font-weight: 500;
    padding-left: 1em;
  }
  .side-content-container .right-section .section-info-container .header-title-container .title-text {
    font-size: 4.4em !important;
    font-weight: 500;
  }
  .side-content-container .right-section .section-info-container .subtitle-container {
    padding: 2em 0 !important;
  }
  .side-content-container .right-section .section-info-container .subtitle-container .subtitle {
    font-size: 4em !important;
    font-weight: 500;
  }
  .side-content-container .right-section-lax {
    height: calc(100% - 7.2em) !important;
    width: calc(67% - 7.2em - 1em) !important;
  }
  .side-content-container .right-section-lax .section-info-container {
    height: 100% !important;
  }
  .side-content-container .right-section-lax .section-info-container .info-container {
    width: calc(100% - 8em) !important;
    margin-right: 4em !important;
    margin-left: 4em !important;
  }
  .side-content-container .right-section-lax .section-info-container .header-title-container {
    margin-top: 4em !important;
  }
  .side-content-container .right-section-lax .section-info-container .header-title-container .title-text {
    font-size: 6.4em !important;
    font-weight: 500;
  }
  .side-content-container .right-section-lax .section-info-container .subtitle-container {
    padding: 2em 0 !important;
  }
  .side-content-container .right-section-lax .section-info-container .subtitle-container .subtitle {
    font-size: 4em !important;
    font-weight: 500;
  }
  .subcategory-body-container {
    font-size: 3em !important;
  }
  .item-container-expandable {
    padding-top: 1em !important;
  }
  .send-to-client {
    font-size: 1.9em !important;
  }
  .send-to-mobile {
    font-size: 1.9em !important;
  }
}
.lax-tv-section {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
/*# sourceMappingURL=static-content.component-ILDHCJLR.css.map */
