import { EventHubService } from "../../services/event-hub.service";
import { WebRTCService } from "@app/services/web-rtc.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { AppConfig } from "environments/environment";
import { showDialog } from "../../store/actions";
import { selectUI } from "../../store/selectors";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { NgIf, NgClass } from "@angular/common";
@Component({
    selector: "app-alert-modal",
    templateUrl: "./alert-modal.component.html",
    styleUrls: ["./alert-modal.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        TranslateModule,
    ],
})
export class AlertModalComponent implements OnInit, OnDestroy {
  dialog = {
    showCloudFunction: false,
    title: "",
    subTitle: "",
    version: "",
    fromComponent: "",
  };
  uiSubscription;
  dismissTimer: any;

  constructor(
    public webRTCService: WebRTCService,
    private store: Store<any>,
    public eventHubService: EventHubService
  ) {}

  ngOnInit() {
    this.uiSubscribe();
  }

  ngAfterViewInit() {
    if (this.dialog.fromComponent != "sticky-button-list") {
      this.dismissTimer = setTimeout(() => {
        this.store.dispatch(
          showDialog({
            showDialog: false,
            title: "",
            subTitle: ``,
            showCloudFunction: false,
          })
        );
      }, AppConfig.surveyDismissTimeout);
    } else {
      clearTimeout(this.dismissTimer);
    }
  }

  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    clearTimeout(this.dismissTimer);
  }

  uiSubscribe() {
    const uiState$ = this.store.select(selectUI);

    this.uiSubscription = uiState$.subscribe((uiItem) => {
      this.dialog.showCloudFunction = uiItem.dialog.showCloudFunction;
      this.dialog.subTitle = uiItem.dialog.subTitle;
      this.dialog.version = uiItem.dialog.version;
      this.dialog.title = uiItem.dialog.title;
      this.dialog.fromComponent = uiItem.dialog.fromComponent || "";
    });
  }

  closeDialog() {
    this.store.dispatch(
      showDialog({
        showDialog: false,
        title: "",
        subTitle: ``,
        showCloudFunction: false,
      })
    );
  }

  onReactiveAgent() {
    this.eventHubService.reactiveAgent.emit(true);
    this.closeDialog();
  }
}
