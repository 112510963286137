/* src/app/components/video-player-modal/video-player-modal.component.scss */
.video-player-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.video-player-modal-container app-video-player {
  display: contents;
  z-index: 2;
}
.send-to-mobile {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-color: black;
  align-self: flex-end;
  position: absolute;
  margin-top: 11.25%;
  font-size: 0.95em;
  font-weight: 600;
  color: white;
  z-index: 2;
  left: 80%;
  top: 0em;
}
@media screen and (min-width: 1601px) and (min-height: 920px) {
  .send-to-mobile {
    margin-top: 7.7% !important;
  }
}
@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .send-to-mobile {
    margin-top: 11.25% !important;
  }
}
/*# sourceMappingURL=video-player-modal.component-FJJUVRGW.css.map */
