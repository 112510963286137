<div
    (click)="onButtonClick(buttonInfo)"
    class="button-icon-container"
    [ngClass]="{
        'horizontal-button-icon-container': isHorizontal,

        ripple: hasRipple == true
    }"
>
    <!-- 'grey-background': isSelected ? false : true,
    'grey-background.selected': isSelected, -->
    <div *ngIf="buttonInfo.icon && buttonInfo.icon != 'videocam'" class="icon">
        <i
            *ngIf="
                buttonInfo.label != 'TRAFFIC' && buttonInfo.label != 'TOURISM'
            "
            class="material-icons"
            [ngStyle]="{ color: iconColor }"
            [ngClass]="{ 'icon-size-3': iconSize == '3em' }"
        >
            {{ buttonInfo.icon }}
        </i>
        <img
            *ngIf="
                buttonInfo.label == 'TRAFFIC' || buttonInfo.label == 'TOURISM'
            "
            loading="lazy"
            [src]="buttonInfo.icon"
            class="material-icons svg"
            [ngClass]="{ 'icon-size-3': iconSize == '3em' }"
        />
    </div>

    <div *ngIf="buttonInfo.icon && buttonInfo.icon == 'videocam'" class="icon">
        <i
            class="material-icons-outlined"
            [ngStyle]="{ color: iconColor }"
            [ngClass]="{ 'icon-size-3': iconSize == '3em' }"
        >
            {{ buttonInfo.icon }}
        </i>
    </div>

    <div class="text-container">
        <span class="text" [ngClass]="{ white: textColor === 'white' }">
            {{ buttonInfo.label | translate | uppercase }}
        </span>
    </div>
</div>
