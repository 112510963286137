import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import {
    WebcamImage,
    WebcamInitError,
    WebcamUtil,
    WebcamModule,
} from 'ngx-webcam';
import { selectDataBus, selectPOI } from '@app/store/selectors';
import { AppConfig } from '../../../environments/environment';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Subject, Observable } from 'rxjs';
import watermark from 'watermarkjs';
import { Store } from '@ngrx/store';
import {
    toggleSelfieModal,
    toggleSubSideMenu,
    showDialog,
    saveSelfie,
} from '../../store/actions';
import {
    EventEmitter,
    ElementRef,
    Component,
    ViewChild,
    OnInit,
    Output,
} from '@angular/core';
import { MaskCodeComponent } from '../mask-code/mask-code.component';
import { QRCodeModule } from 'angularx-qrcode';
import { HorizontalButtonIconComponent } from '../horizontal-button-icon/horizontal-button-icon.component';
import { NgIf, NgStyle, NgClass, NgFor, SlicePipe } from '@angular/common';
@Component({
    selector: 'app-selfie-modal',
    templateUrl: './selfie-modal.component.html',
    styleUrls: ['./selfie-modal.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgStyle,
        NgClass,
        WebcamModule,
        NgFor,
        HorizontalButtonIconComponent,
        QRCodeModule,
        MaskCodeComponent,
        SlicePipe,
        TranslateModule,
    ],
})
export class SelfieModalComponent implements OnInit {
    @ViewChild('waterMarkedImage') waterMarkImage: ElementRef;
    @Output('onClose') onClose = new EventEmitter();
    private nextWebcam: Subject<boolean | string> = new Subject<
        boolean | string
    >();
    private trigger: Subject<void> = new Subject<void>();
    public multipleWebcamsAvailable = false;
    public webcamImage: WebcamImage = null;
    public errors: WebcamInitError[] = [];
    public allowCameraSwitch = true;
    public showWebcam = true;
    public deviceId: string;
    showShareSelfieLoader: boolean = false;
    showShareSelfiePhase: boolean = false;
    selfieButtonClicked: boolean = false;
    showTakeSelfiePhase: boolean = true;
    captureAnimation: boolean = false;
    showSelfieModal: boolean = false;
    captureMode: boolean = false;
    isMasked: boolean = true;
    dataBusSubscription: any;
    selfieTimer: number = 3;
    qrSelfieCode: string;
    webcamHeight: number;
    webcamWidth: number;
    qrCodeWidth: number;
    blobImage = null;
    qrData: any;
    buttonsInfo: any = [
        {
            id: 0,
            backgroundColor: 'rgb(198, 88, 206)',
            contentColor: 'white',
            title: this.translate.instant('SHARE_THIS_SELFIE'),
            iconName: 'share',
            clickEvent: 'share',
            isFontSizeBigger: false,
            minWidth: '9.5em',
            fontSize: '0.6em',
        },
        {
            id: 1,
            backgroundColor: 'black',
            contentColor: 'white',
            title: this.translate.instant('TAKE_ANOTHER_SELFIE'),
            iconName: 'photo_camera',
            clickEvent: 'take',
            isFontSizeBigger: false,
            minWidth: '9.5em',
            fontSize: '0.6em',
        },
        {
            id: 2,
            backgroundColor: 'transparent',
            contentColor: "'$primary'",
            title: this.translate.instant('TAP_TO_UNMASK_CODE'),
            iconName: 'remove_red_eye',
            iconSize: '1.5em',
            clickEvent: 'unmask',
            isFontSizeBigger: false,
            minWidth: '12em',
            fontSize: '0.7em',
        },
    ];
    waterMarkImage1: any;
    waterMarkSelfieImage: any;
    poiSubscription: any;
    categoryInfo: any;

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private translate: TranslateService,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.webcamImage = null;
        if (window.innerWidth == 3840) {
            this.webcamWidth = 1900;
            this.webcamHeight = 1300;
            this.qrCodeWidth = 525;
        } else {
            this.webcamWidth = 800;
            this.webcamHeight = 650;
            this.qrCodeWidth = 256;
        }

        const dataBusState$ = this.store.select(selectDataBus);

        this.dataBusSubscription = dataBusState$.subscribe((dataBusItem) => {
            this.qrSelfieCode = dataBusItem.selfie.code.toString();
            this.qrData = `${AppConfig.microSiteUrl}selfie/${this.qrSelfieCode}`;
            if (this.qrSelfieCode != '0') {
                this.showShareSelfieLoader = false;
                if (!this.showTakeSelfiePhase) this.showShareSelfiePhase = true;
            } else {
                this.showShareSelfieLoader = false;
            }
        });

        const poiState$ = this.store.select(selectPOI);

        this.poiSubscription = poiState$.subscribe((poiItem) => {
            this.categoryInfo = poiItem.categoryInfo;
        });

        WebcamUtil.getAvailableVideoInputs().then(
            (mediaDevices: MediaDeviceInfo[]) => {
                if (mediaDevices.length == 0) {
                    setTimeout(() => {
                        this.store.dispatch(
                            showDialog({
                                showDialog: true,
                                title: 'NO_WEBCAM',
                                subTitle: ``,
                                showCloudFunction: false,
                            })
                        );
                    }, 400);
                    this.close();
                }

                this.multipleWebcamsAvailable =
                    mediaDevices && mediaDevices.length > 1;
            }
        );
    }

    ngOnDestroy() {
        this.dataBusSubscription.unsubscribe();
        this.poiSubscription.unsubscribe();
        this.showShareSelfieLoader = false;
        this.showShareSelfiePhase = false;
    }

    countDownTimer() {
        this.googleAnalyticService.onSelfieCaptureButtonClick(
            this.categoryInfo.storeName
        );

        this.selfieButtonClicked = true;
        let interval = setInterval(() => {
            this.selfieTimer--;
            if (this.selfieTimer < 1) {
                this.captureAnimation = true;
                this.captureMode = true;
                this.selfieTimer = null;
                this.toggleWebcam();
                clearInterval(interval);
                this.triggerSnapshot();
            }
        }, 1000);
    }

    public triggerSnapshot() {
        this.trigger.next();
    }

    public toggleWebcam() {
        this.showWebcam = !this.showWebcam;
    }

    public switchToCaptureMode() {
        this.captureMode = false;
        this.captureAnimation = false;
        this.webcamImage = null;
        this.selfieTimer = 3;
        this.showWebcam = true;
        this.selfieButtonClicked = false;
    }

    public handleInitError(error: WebcamInitError) {
        this.errors.push(error);
    }

    public showNextWebcam(directionOrDeviceId: boolean | string) {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    }

    public handleImage(webcamImage: WebcamImage) {
        // console.info("received webcam image", webcamImage);
        this.webcamImage = webcamImage;
        this.blobImage = this.webcamImage['_imageAsDataUrl'];
        this.addWatermarkImage();
    }

    public cameraWasSwitched(deviceId: string): void {
        // console.log("active device: " + deviceId);
        this.deviceId = deviceId;
        this.showSelfieModal = true;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }

    addWatermarkImage() {
        watermark([this.blobImage, 'assets/images/lax-watermark-logo.svg'])
            .image(watermark.image.lowerLeft())
            .then((img) => {
                setTimeout(() => {
                    this.waterMarkImage.nativeElement.src = img.src;
                    this.waterMarkSelfieImage = img.src;
                }, 100);
            });
    }

    handleClick(event) {
        switch (event) {
            case 'share':
                this.showTakeSelfiePhase = false;
                this.showShareSelfieLoader = true;
                this.store.dispatch(
                    saveSelfie({ data: this.waterMarkSelfieImage })
                );

                this.googleAnalyticService.onSelfieReShareButtonClick(
                    this.categoryInfo.storeName
                );
                break;
            case 'take':
                this.showShareSelfiePhase = false;
                this.showSelfieModal = false;
                this.switchToCaptureMode();

                this.googleAnalyticService.onSelfieRecaptureButtonClick(
                    this.categoryInfo.storeName
                );
                break;
            case 'unmask':
                this.isMasked = true;

                this.googleAnalyticService.onSelfieUnmaskButtonClick(
                    this.categoryInfo.storeName
                );
                break;

            default:
                break;
        }
    }

    handleEvent() {
        this.isMasked = !this.isMasked;
    }

    close() {
        setTimeout(() => {
            this.store.dispatch(toggleSelfieModal({ shouldOpen: false }));
            this.store.dispatch(
                toggleSubSideMenu({
                    shouldOpen: false,
                    selectedCategory: '',
                    categoryInfo: {},
                })
            );
        }, 400);
        this.onClose.emit(false);
    }
}
