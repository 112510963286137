import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { toggleSurveyModal, setComputerName } from '@app/store/actions';
import { animationConfig } from '../../angular-animation/animation';
import { selectAgent, selectDataBus } from '../../store/selectors';
import { WebRTCService } from '../../services/web-rtc.service';
import { AppConfig } from '../../../environments/environment';
import { MapService } from '@app/services/map.service';
import { Store } from '@ngrx/store';
import {
    HostListener,
    ElementRef,
    Component,
    ViewChild,
    OnInit,
    inject,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatSlider } from '@angular/material/slider';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { NgStyle, NgIf, NgClass } from '@angular/common';
import { TimeConversionService } from '@app/services/time-conversion.service';

@Component({
    selector: 'app-video-chat',
    templateUrl: './video-chat.component.html',
    styleUrls: ['./video-chat.component.scss'],
    animations: [animationConfig.dimensionsTransition],
    standalone: true,
    imports: [
        NgStyle,
        NgIf,
        SnackBarComponent,
        NgClass,
        MatSlider,
        TranslateModule,
    ],
})
export class VideoChatComponent implements OnInit {
    @ViewChild('agentRinging') agentRinging: ElementRef;
    @ViewChild('remoteVideo') remoteVideo: ElementRef;
    @ViewChild('localVideo') localVideo: ElementRef;
    @ViewChild('ringing') ringing: ElementRef;

    timeService = inject(TimeConversionService);

    shouldPostCallSurveyAppear: boolean = false;
    disableAgentAnswerButton: boolean = false;
    turnServers: Array<string> = [];
    didAgentAnswer: boolean = false;
    isMaximized: boolean = false;
    animateCallAgent: boolean;
    agentSubscription: any;
    showIncomingCallState;
    kioskInformation: any;
    uiSubscription: any;
    dataBusSubscription;
    showVolume = false;
    agentTimer: any;
    dialingTimeout;
    remoteVideoId;
    isAgent: any;
    localVideoId;
    title: any;
    uid: any;

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        public webRTCService: WebRTCService,
        private mapService: MapService,
        private store: Store<any>
    ) {
        setTimeout(() => {
            if (this.isAgent) this.webRTCService.addAgentToFirebase();
        }, 5000);

        this.webRTCService.didClientInitiateCall.subscribe((value) => {
            this.dialingTimeout = setTimeout(() => {
                if (!this.webRTCService.isConnected) {
                    this.googleAnalyticService.onCallNotAnswered(
                        AppConfig.callAgentTimer / 1000
                    );
                    this.hangUp();
                }
            }, AppConfig.callAgentTimer);
            this.callAgent();
        });

        this.webRTCService.isConnectionEstablished.subscribe((value) => {
            this.webRTCService.isConnected = true;
            this.showConferenceState();
        });

        this.webRTCService.didStreamEnd.subscribe((value) => {
            setTimeout(() => {
                if (
                    this.shouldPostCallSurveyAppear &&
                    this.webRTCService.isConnected &&
                    !this.isAgent
                )
                    this.store.dispatch(
                        toggleSurveyModal({
                            shouldOpen: true,
                            activeSurvey: 'postCallSurvey',
                        })
                    );
                this.webRTCService.isConnected = false;
            }, 1000);

            this.hangUp();
            clearTimeout(this.dialingTimeout);
            setTimeout(() => {
                if (
                    this.isAgent &&
                    this.webRTCService.clients &&
                    this.webRTCService.clients.length > 0
                )
                    this.triggerAgentIncomingCallState();
            }, 2000);
        });

        this.webRTCService.didClientCall.subscribe((value) => {
            if (
                this.webRTCService.isAgentOnline &&
                this.webRTCService.didAppFinishLoading
            )
                this.triggerAgentIncomingCallState();
        });
    }

    ngOnInit() {
        const agentState$ = this.store.select(selectAgent);

        this.agentSubscription = agentState$.subscribe((agent) => {
            this.uid = agent.uid; //getting the agent uid
            this.webRTCService.agentEmail = agent.email;
            this.webRTCService.agentLanguage = agent.languages;
        });

        const dataBusState$ = this.store.select(selectDataBus);

        this.dataBusSubscription = dataBusState$.subscribe((dataBusItem) => {
            this.turnServers = dataBusItem.turnServers;

            this.shouldPostCallSurveyAppear =
                dataBusItem.surveyConfig.postCallSurvey.enabled &&
                dataBusItem.surveyConfig.postCallSurvey.questions.length > 0;
        });

        if (AppConfig.isAgent) {
            this.isAgent = true;
            this.webRTCService.onClientRinging();
        } else this.webRTCService.onActiveAgent();
    }

    triggerAgentIncomingCallState() {
        this.kioskInformation = this.webRTCService.clients[0].kioskInformation;
        this.webRTCService.startCallTimer();
        this.googleAnalyticService.onCallRing();
        this.webRTCService.showCallingState = true;
        this.showIncomingCallState = true;
        this.agentRinging.nativeElement.play();
        this.agentRinging.nativeElement.loop = true;
    }

    onSliderChange(event) {
        document.getElementById(this.remoteVideoId)['volume'] =
            parseFloat(event.value) / 100;
    }

    // Client function to call the agent :hg
    callAgent() {
        this.webRTCService.showCallingState = true;

        this.webRTCService.callAgent();
        this.onStream();

        this.webRTCService.connection.open(
            this.webRTCService.connection.userid,
            (isRoomOpened, roomid, error) => {
                if (error) {
                    console.log(error);
                }

                if (isRoomOpened === true) {
                    // alert("Successfully created itxi-test-room2.");
                    console.log(
                        'client connection: ',
                        this.webRTCService.connection
                    );
                }
            }
        );

        this.ringing.nativeElement.play();
        this.ringing.nativeElement.loop = true;
    }

    ignoreIncomingCall() {
        this.agentRinging.nativeElement.pause();
    }

    // (Client/Agent) hangs the call and reset the ui state function :hg
    hangUp() {
        if (this.showIncomingCallState) this.webRTCService.onAgentNotAnswer();

        if (this.webRTCService.busyAgentKey)
            this.webRTCService.removeBusyAgentFromFirebase();
        this.webRTCService.showCallingState = false;
        this.webRTCService.showRemoteVideo = false;
        this.agentRinging.nativeElement.pause();
        this.disableAgentAnswerButton = false;
        this.ringing.nativeElement.pause();
        this.showIncomingCallState = false;
        this.isMaximized = false;

        if (!AppConfig.isAgent && this.webRTCService.clientKey) {
            this.webRTCService.removeClient(this.webRTCService.clientKey);
        }

        if (this.webRTCService.connection) {
            // disconnectWith all users
            this.webRTCService.connection
                .getAllParticipants()
                .forEach((pid) => {
                    this.webRTCService.connection.disconnectWith(pid);
                });

            // stop all local cameras
            this.webRTCService.connection.attachStreams.forEach(
                (localStream) => {
                    localStream.stop();
                }
            );

            // close socket.io connection
            this.webRTCService.connection.closeSocket();

            this.webRTCService.initializeWebRTC(this.turnServers);
        }
    }

    showConferenceState() {
        /////////////////////////////////////FOR NOW

        this.webRTCService.showRemoteVideo = true;

        if (this.isAgent) {
            this.webRTCService.addBusyAgentToFirebase();
            this.store.dispatch(
                setComputerName({
                    computerName: this.kioskInformation.computerName,
                })
            );
            this.onStream();

            this.disableAgentAnswerButton = true;
            setTimeout(() => {
                this.googleAnalyticService.onCallPickup(
                    this.timeService.getUnix() -
                        this.webRTCService.callDuration,
                    this.webRTCService.agentEmail
                );
                this.showIncomingCallState = false;
                let roomID = this.webRTCService.clients[0].userId;

                this.webRTCService.connection.extra = {
                    roomId: roomID,
                    role: 'agent',
                    joinedAt: new Date().toISOString(),
                };

                this.webRTCService.connection.checkPresence(
                    roomID,
                    (isRoomExist, roomid, error) => {
                        if (isRoomExist === true) this.joinRoom(roomID);
                        else {
                            this.webRTCService.removeBusyAgentFromFirebase();
                            console.log(error);
                        }
                    }
                );

                this.didAgentAnswer = true;
                this.agentRinging.nativeElement.pause();
                this.ringing.nativeElement.pause();
            }, 2000);
        } else {
            this.ringing.nativeElement.pause();

            let localVideo = document.getElementById(this.localVideoId);

            localVideo.style.position = 'absolute';
            localVideo.style.objectFit = 'cover';
            localVideo.style.height = '25%';
            localVideo.style.width = '25%';
            localVideo.style.bottom = '0';
            localVideo.style.right = '0';
        }
    }

    joinRoom(roomID) {
        this.webRTCService.connection.join(
            roomID,
            (isRoomJoined, roomid, error) => {
                if (error) {
                    console.log(error);
                    this.hangUp();
                }

                if (isRoomJoined === true) {
                    // alert("Successfully joined itxi-test-room2.");

                    this.webRTCService.removeAgentFromFirebase();
                    this.webRTCService.isConnected = true;
                    this.webRTCService.once = false;

                    this.webRTCService.removeClient(
                        this.webRTCService.clients[0].firebaseKey
                    );
                }
            }
        );
    }

    onStream() {
        this.webRTCService.connection.onstream = (event) => {
            if (event.type == 'remote') {
                const video = event.mediaElement;
                this.remoteVideoId = video.id;
                video.classList.add('remoteVideo');
                video.style.objectFit = 'cover';
                video.style.height = '100%';
                video.style.width = '100%';
                video.controls = false;
                this.remoteVideo.nativeElement.replaceWith(video);

                let localVideo = document.getElementById(this.localVideoId);

                localVideo.style.position = 'absolute';
                localVideo.style.objectFit = 'cover';
                localVideo.style.height = '25%';
                localVideo.style.width = '25%';
                localVideo.style.bottom = '0';
                localVideo.style.right = '0';
            }

            if (event.type == 'local') {
                const video = event.mediaElement;
                this.localVideoId = video.id;
                if (this.webRTCService.showRemoteVideo) {
                    video.style.position = 'absolute';
                    video.style.objectFit = 'cover';
                    video.style.height = '25%';
                    video.style.width = '25%';
                    video.style.bottom = '0';
                    video.style.right = '0';
                    video.controls = false;
                } else {
                    video.classList.add('localVideo');
                    video.style.width = '100%';
                    video.style.height = '100%';
                    video.style.objectFit = 'cover';
                    video.controls = false;
                }

                this.localVideo.nativeElement.replaceWith(video);
            }
        };
    }

    @HostListener('window:unload', ['$event'])
    beforeunloadHandler(event: Event): void {
        this.webRTCService.removeAgentFromFirebase();
        this.webRTCService.removeBusyAgentFromFirebase();
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event) {
        if (this.isAgent && this.webRTCService.isAgentOnline)
            this.webRTCService.updateTimeStamp();
    }
}
