export const sideMenuDummyData = {
  takeASelfie: {
    icon: "local_see",
    title: "TAKE A SELFIE",
    active: true,
  },
  categories: [
    {
      icon: "exit_to_app",
      storeName: "Gates",
      label: "GATES",
      dataType: "LL",
      query: "Gates",
      subMenu: [],
      id: 1,
    },
    {
      icon: "flight",
      storeName: "Flights",
      label: "FLIGHTS",
      dataType: "",
      query: "",
      subMenu: [],
      id: 0,
    },
    {
      icon: "wc",
      storeName: "Restrooms",
      label: "RESTROOMS",
      dataType: "LL",
      query: "Restrooms",
      subMenu: [],
      id: 3,
    },
    {
      icon: "airline_seat_recline_extra",
      storeName: "Relax",
      label: "RELAX",
      dataType: "LL",
      query: "Lounges",
      subMenu: [],
      id: 5,
    },
    {
      icon: "shopping_basket",
      storeName: "Shopping",
      label: "SHOPPING",
      dataType: "LL",
      query: "Shopping",
      subMenu: [],
      id: 6,
    },
    {
      icon: "restaurant",
      storeName: "Dining",
      label: "DINING",
      dataType: "LL",
      query: "Dining",
      subMenu: [],
      id: 7,
    },
    {
      icon: "work",
      storeName: "Check-In",
      label: "CHECK_IN",
      dataType: "LL",
      query: "CheckIn",
      subMenu: [],
      id: 9,
    },
    {
      icon: "train",
      storeName: "Transport",
      label: "TRANSPORT",
      dataType: "LL",
      query: "Transport",
      id: 11,
      subMenu: [],
    },
    {
      icon: "hotel",
      storeName: "Hotels",
      label: "HOTELS",
      dataType: "CMS",
      query: "Hotels",
      subMenu: [],
      id: 10,
    },
    {
      icon: "assets/icon/traffic.svg",
      storeName: "traffic",
      label: "TRAFFIC",
      dataType: "",
      query: "",
      subMenu: [],
      id: 0,
    },
    {
      icon: "assets/icon/attraction_icon.svg",
      storeName: "tourism",
      label: "TOURISM",
      dataType: "CMS",
      query: "Attractions",
      subMenu: [],
      id: 15,
    },
    {
      icon: "accessible",
      storeName: "ADA",
      label: "ADA",
      dataType: "CMS",
      query: "ADA",
      subMenu: [],
      id: 12,
    },
    {
      icon: "local_see",
      storeName: "selfie",
      label: "SELFIE",
      dataType: "",
      query: "",
      subMenu: [],
      id: 0,
    },
    {
      icon: "live_tv",
      storeName: "LAX TV",
      label: "LAX_TV",
      dataType: "",
      query: "LAX_TV",
      subMenu: [],
      id: 13,
    },
    {
      icon: "info",
      storeName: "INFO & TIPS",
      label: "INFO_AND_TIPS",
      dataType: "CMS",
      query: "INFO_TIPS",
      subMenu: [],
      id: 14,
    },
  ],
};
