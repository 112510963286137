import { GoogleAnalyticsService } from './google-analytics.service';
import { UtilityService } from './utility.service';
import { Injectable } from '@angular/core';
import { MapService } from './map.service';
import { Store } from '@ngrx/store';
import {
    toggleStaticInfoModal,
    departuresScrolling,
    toggleSideMenuInfo,
    toggleSubSideMenu,
    toggleSelfieModal,
    arrivalScrolling,
    setSelectedPoi,
    flightSearch,
} from '@app/store/actions';

@Injectable({
    providedIn: 'root',
})
export class SideMenuService {
    timeOut: NodeJS.Timeout;
    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private utility: UtilityService,
        private mapService: MapService,
        private store: Store<any>
    ) {}

    sideMenuButtonClicked(categoryData, componentState) {
        this.store.dispatch(setSelectedPoi({ poiContent: {} }));
        if (componentState.isStaticInfoOpen)
            this.store.dispatch(toggleStaticInfoModal({ shouldOpen: false }));

        if (componentState.isSubSideMenuInfoOpen)
            this.store.dispatch(toggleSideMenuInfo({ shouldOpen: false })); // make side menu info close if open:hg

        switch (categoryData.storeName) {
            case 'INFO & TIPS':
            case 'ADA':
                this.store.dispatch(
                    setSelectedPoi({
                        poiContent: {
                            category:
                                categoryData.storeName == 'ADA'
                                    ? 'ADA'
                                    : 'INFO_TIPS',
                        },
                        instructions: [],
                    })
                );
                this.store.dispatch(
                    toggleStaticInfoModal({ shouldOpen: true })
                );
                break;

            case 'LAX TV':
                this.store.dispatch(
                    setSelectedPoi({
                        poiContent: { category: 'LAX_TV' },
                        instructions: [],
                    })
                );
                this.store.dispatch(
                    toggleStaticInfoModal({ shouldOpen: true })
                );

                break;

            case 'selfie':
                setTimeout(() => {
                    this.store.dispatch(
                        toggleSelfieModal({ shouldOpen: true })
                    );
                }, 500);
                break;

            case 'traffic':
                if (componentState.selectedCategory === categoryData.storeName)
                    this.closeCategory();
                else
                    this.store.dispatch(
                        toggleSubSideMenu({
                            shouldOpen: false,
                            selectedCategory: categoryData.storeName,
                        })
                    );

                break;

            case 'Flights':
                this.resetFlightsState();
                break;

            default:
                this.store.dispatch(
                    setSelectedPoi({
                        poiContent: {},
                        instructions: [],
                    })
                );
                break;
        }

        this.googleAnalyticService.onCategoryMenuClick(categoryData.storeName);
        if (categoryData.storeName == 'traffic') {
            this.utility.checkConnection();
            this.closeCategory();
        }

        if (
            categoryData.storeName === 'Hotels' ||
            categoryData.storeName === 'tourism'
        ) {
            this.closeCategory();
        }

        if (!componentState.isSubSideMenuOpen) this.openCategory(categoryData);
        else if (
            componentState.isSubSideMenuOpen &&
            componentState.selectedCategory === categoryData.storeName
        )
            this.closeCategory();
        else {
            this.store.dispatch(toggleSubSideMenu({ shouldOpen: false }));

            this.openCategory(categoryData, true);
        }
    }

    openCategory(data, withDelay?) {
        setTimeout(
            () => {
                let shouldOpen =
                    data.storeName == 'INFO & TIPS' ||
                    data.storeName == 'traffic' ||
                    data.storeName == 'selfie' ||
                    data.storeName == 'ADA' ||
                    data.storeName == 'LAX TV'
                        ? false
                        : true;

                this.store.dispatch(
                    toggleSubSideMenu({
                        shouldOpen: shouldOpen,
                        selectedCategory: data.storeName,
                        categoryInfo: data,
                    })
                );
            },
            withDelay ? 350 : 0
        );
    }

    resetFlightsState() {
        this.store.dispatch(
            flightSearch({
                isFlightSearch: false,
                isDepartureActive: false,
                flights: [],
            })
        );

        this.store.dispatch(
            arrivalScrolling({
                arrivalsScroll: 0,
            })
        );

        this.store.dispatch(
            departuresScrolling({
                departuresScroll: 0,
            })
        );
    }

    closeCategory() {
        this.store.dispatch(
            toggleSubSideMenu({ shouldOpen: false, selectedCategory: '' })
        );
    }
}
