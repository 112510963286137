/* src/app/components/rating/rating.component.scss */
.star-icon {
  color: black;
}
.star-icon {
  font-size: 1em;
  transition: font-size 500ms linear;
  padding-right: 0.25em;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .star-icon {
    font-size: 2em !important;
    transition: font-size 500ms linear;
    padding-right: 0.25em;
  }
}
/*# sourceMappingURL=rating.component-EOOHI4I4.css.map */
