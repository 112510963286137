import { createAction, props } from '@ngrx/store';
import { types } from './types';

export const setAgentToken = createAction(
    types.SET_AGENT_TOKEN,
    props<{
        agentLoginTime: string;
        languages: Array<string>;
        agentToken: string;
        email: string;
        uid: string;
    }>()
);

export const resetAgentToken = createAction(types.RESET_AGENT_TOKEN);

export const setAgentToken_And_LoginTime = createAction(
    types.SET_AGENT_TOKEN_AND_LOGIN_TIME,
    props<{
        agentToken: string;
        agentLoginTime: string;
    }>()
);
