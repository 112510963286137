import { AppConfig } from '../../../environments/environment';
import { Action, createReducer, on } from '@ngrx/store';
import { poiState } from '../state';
import {
    toggleSideMenuInfo,
    toggleSubSideMenu,
    setSelectedPoi,
    resetApp,
    cacheWalkTime,
    flightSearch,
    flightType,
    recentSelected,
} from '../actions';

const initialState: poiState = {
    categoryInfo: {},
    selectedCategory: '',
    poiInstructions: [],
    poiContent: {},
    cachedWalkTimes: {},
    isFlightSearch: false,
    isDepartureActive: true,
    flights: [],
    searchError: false,
    recentSelected: {
        Transport: [],
        Shopping: [],
        CheckIn: [],
        Lounges: [],
        Dining: [],
        Hotels: [],
        Attractions: [],
    },
};

const poiReducer = createReducer(
    initialState,
    on(resetApp, (state, action) => {
        let _p = { ...state };
        _p.categoryInfo = {};
        _p.selectedCategory = '';
        _p.poiInstructions = [];
        _p.poiContent = {};
        _p.isFlightSearch = false;
        _p.isDepartureActive = true;
        _p.flights = [];
        _p.searchError = false;
        return _p;
    }),
    on(toggleSideMenuInfo, (state, action) => {
        let _p = { ...state };

        _p.selectedCategory = action.selectedCategory;

        return _p;
    }),
    on(toggleSubSideMenu, (state, action) => {
        let _p = { ...state };
        _p.selectedCategory = action.selectedCategory;
        _p.categoryInfo = action.categoryInfo;
        _p.isFlightSearch = action.isFlightSearch;
        _p.isDepartureActive = action.isDepartureActive || true;
        _p.flights = action.flights || [];
        _p.searchError = action.searchError || false;
        return _p;
    }),
    on(flightSearch, (state, action) => {
        let _p = { ...state };
        _p.isFlightSearch = action.isFlightSearch;
        _p.isDepartureActive = action.isDepartureActive;
        _p.flights = action.flights;
        _p.searchError = action.searchError;
        return _p;
    }),
    on(flightType, (state, action) => {
        let _p = { ...state };
        _p.isDepartureActive = action.isDepartureActive;
        return _p;
    }),
    on(setSelectedPoi, (state, action) => {
        let _p = { ...state };

        _p.poiInstructions = action.instructions;
        if (action.poiContent) _p.poiContent = action.poiContent;

        if (Object.keys(_p.poiContent).length > 0) {
            if (
                _p.categoryInfo &&
                _p.categoryInfo['query'] &&
                _p.recentSelected[_p.categoryInfo['query']]
            ) {
                let tempListing = [];

                _p.recentSelected[_p.categoryInfo['query']].forEach(
                    (element) => {
                        if (element.id != action.poiContent['id'])
                            tempListing.push(element);
                    }
                );
                if (tempListing.length == 3) tempListing.pop();
                tempListing.unshift(action.poiContent);
                _p.recentSelected[_p.categoryInfo['query']] = tempListing;
            }
        }

        return _p;
    }),
    on(cacheWalkTime, (state, action) => {
        let _p = { ...state };

        _p.cachedWalkTimes = action.walkTimes;
        return _p;
    }),
    on(recentSelected, (state, action) => {
        let _p = { ...state };

        _p.recentSelected.Attractions = action.recentSelected.Attractions;
        _p.recentSelected.CheckIn = action.recentSelected.CheckIn;
        _p.recentSelected.Dining = action.recentSelected.Dining;
        _p.recentSelected.Hotels = action.recentSelected.Hotels;
        _p.recentSelected.Lounges = action.recentSelected.Lounges;
        _p.recentSelected.Shopping = action.recentSelected.Shopping;
        _p.recentSelected.Transport = action.recentSelected.Transport;
        return _p;
    })
);

export default function reducer(state: poiState, action: Action) {
    return poiReducer(state, action);
}
