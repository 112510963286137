import { AppConfig } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { selectDataBus } from '@app/store/selectors';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TimeConversionService } from './time-conversion.service';

@Injectable({
    providedIn: 'root',
})
export class MappingService {
    timeService = inject(TimeConversionService);
    constructor(
        private translate: TranslateService,
        private store: Store<any>
    ) {}

    mapHotelsAndAttractions(hotelRecord, type) {
        let obj = {
            id: null,
            name: '',
            mapElementId: null,
            placeID: '',
            imageUrl: '',
            telephoneNo: '',
            webUrl: '',
            coordinates: '',
            isFeatured: '',
            categoryId: 0,
            rating: {
                ratingNumber: null,
                ratingSize: null,
                color: '',
            },
            details: {
                en: {
                    fullDescription: '',
                    shortLocation: '',
                    fullLocation: '',
                },
                es: {
                    fullDescription: '',
                    shortLocation: '',
                    fullLocation: '',
                },
            },
        };
        let hotelItems = [];

        hotelRecord.forEach((element, index) => {
            obj.isFeatured = element.isFeatured;
            obj.id = element.id;
            obj.imageUrl = element.imageURL;
            obj.mapElementId = null;
            obj.name = element.name;
            obj.telephoneNo = element.phoneNumber;
            obj.webUrl = element.websiteURL;
            obj.details.en.shortLocation = element.address;
            obj.details.en.fullLocation = element.address;
            obj.details.es.shortLocation = element.address;
            obj.details.es.fullLocation = element.address;
            obj.coordinates = element.location;
            obj.rating.color = '$primary';
            obj.rating.ratingSize = 5;
            obj.rating.ratingNumber = element.rating ? element.rating : null;
            obj.categoryId = type == 'hotel' ? 10 : 15; // 10 -> hotel and 15-> attraction
            obj.placeID = element.placeID;
            obj.details.en.fullDescription = element.description.en;
            obj.details.es.fullDescription = element.description.es;

            hotelItems.push(JSON.parse(JSON.stringify(obj)));
        });
        return hotelItems;
    }

    mapVideos(videos) {
        let fearturedYoutubeList = [];
        let youtubeList = [];
        let filteredValues = [];
        videos.forEach((category) => {
            filteredValues = category.videos.filter((v) => {
                return v.featured == true;
            });
            filteredValues.forEach((element) => {
                fearturedYoutubeList.push(element['videoId']);
            });
        });

        if (fearturedYoutubeList.length == 0) {
            filteredValues = [];
            videos.forEach((category) => {
                filteredValues = category.videos.filter((v) => {
                    return v.type == 'YouTube';
                });
                filteredValues.forEach((element) => {
                    youtubeList.push(element['videoId']);
                });
            });
        }

        let obj = {
            videos: videos || [],
            featuredYoutube: fearturedYoutubeList || [],
            youtube: youtubeList || [],
        };

        return obj;
    }

    mapFlights(flightRecord, flightType = '') {
        let _flightRecord = flightRecord;
        let flightStatus: string = '';
        let displayTime: string = '';
        let codeShareFlights: any[];
        let flightNumber: string;
        let statusToShow: string;
        let airlineName: string;
        let statusColor: string;
        let airlineLogo: string;
        let timeToShow: string;
        let scheduled: string;
        let timeColor: string;
        let cityName: string;
        let gate: string;

        for (let i = 0; _flightRecord.length > i; i++) {
            displayTime = this.timeService.formatDate(
                _flightRecord[i].FlightDateTime,
                'h:mm a'
            );

            flightStatus = this.getFlightsStatus(
                _flightRecord[i].FlightStatus,
                _flightRecord[i].FlightRemark,
                _flightRecord[i].Arr_Or_Dep
            );
            let str =
                _flightRecord[i].Arr_Or_Dep == 'Departure'
                    ? _flightRecord[i].FlightArrAirportCD + ') '
                    : _flightRecord[i].FlightDepartAirportCD + ') ';

            cityName = _flightRecord[i].FlightCity + ' (' + str;
            airlineName = _flightRecord[i].FlightCarrier;
            flightNumber = _flightRecord[i].FlightID;
            scheduled =
                _flightRecord[i].Arr_Or_Dep == 'Departure'
                    ? _flightRecord[i].FlightDepartDT
                    : _flightRecord[i].FlightArrDT;

            timeToShow = displayTime;
            timeColor =
                _flightRecord[i].FlightRemark == 'Delayed' ? '#ff0000' : '';
            statusToShow = flightStatus;
            statusColor =
                flightStatus == 'CANCELLED'
                    ? 'red'
                    : flightStatus == 'DELAYED'
                    ? 'orange'
                    : '';

            gate = _flightRecord[i].FlightGate
                ? _flightRecord[i].FlightGate
                : '';

            airlineLogo = `${AppConfig.airlineLogo}/${_flightRecord[i].FlightCarrierCD}.png`;

            if (Array.isArray(_flightRecord[i].CodeShareFlights)) {
                codeShareFlights = _flightRecord[i].CodeShareFlights;
            } else {
                codeShareFlights = [''];
            }

            _flightRecord[i] = {
                cityName: cityName,
                airlineName: airlineName,
                flightNumber: flightNumber,
                scheduled: scheduled,
                timeToShow: timeToShow,
                timeColor: timeColor,
                statusToShow: statusToShow,
                statusColor: statusColor,
                gate: gate,
                codeShareFlights: codeShareFlights,
                airlineLogo: airlineLogo,
                id: i,
                flightType: flightType,
            };
        }
        return _flightRecord;
    }

    getFlightsStatusColor(status: string) {
        let statusColor: string = '';

        if (
            status.toUpperCase() == 'SC' ||
            status.toUpperCase() == 'IA' ||
            status.toUpperCase() == 'EN' ||
            status.toUpperCase() == 'AR' ||
            status.toUpperCase() == 'ER' ||
            status.toUpperCase() == 'LN'
        )
            statusColor = 'green';
        else {
            if (status.toUpperCase() == 'CX') statusColor = 'red';
            else {
                if (status.toUpperCase() == 'DL') statusColor = 'orange';
                else statusColor = '';
            }
        }
        return statusColor;
    }

    getFlightsStatus(
        flightStatus: string,
        flightRemark: string,
        Arr_Or_Dep: string
    ) {
        let statusToShow: string = '';
        switch (flightStatus.toUpperCase()) {
            case 'ARRIVED':
                if (Arr_Or_Dep.toUpperCase() == 'ARRIVAL')
                    statusToShow = 'ARRIVED';
                else statusToShow = '';
                break;

            case 'LANDED':
                if (Arr_Or_Dep.toUpperCase() == 'ARRIVAL')
                    statusToShow = 'ARRIVED';
                else statusToShow = '';
                break;

            case 'DEPARTED':
                if (Arr_Or_Dep.toUpperCase() == 'DEPARTURE')
                    statusToShow = 'DEPARTED';
                else statusToShow = '';
                break;

            case 'SCHEDULED':
                statusToShow = this.checkStatusState(flightRemark);
                break;

            case 'IN AIR':
                statusToShow = this.checkStatusState(flightRemark);
                break;

            case 'PROPOSED':
                statusToShow = this.checkStatusState(flightRemark);
                break;

            case 'CANCELLED':
                statusToShow = 'CANCELLED';
                break;

            case 'DELAYED':
                statusToShow = 'DELAYED';
                break;

            default:
                statusToShow = '';
                break;
        }
        return statusToShow;
    }

    checkStatusState(flightRemark) {
        let statusToShow: string = '';
        if (flightRemark.toUpperCase() == 'ON TIME') statusToShow = 'ON_TIME';
        else if (flightRemark.toUpperCase() == 'DELAYED')
            statusToShow = 'DELAYED';
        else if (flightRemark.toUpperCase() == 'EARLY') statusToShow = 'EARLY';
        else statusToShow = '';

        return statusToShow;
    }

    mapCategories() {
        let static_content = [];

        const dataBusState$ = this.store.select(selectDataBus);

        dataBusState$.subscribe((dataBusItem: any) => {
            dataBusItem.staticInfo.forEach((node) => {
                let categoryName: string =
                    node.parentCategory[this.translate.currentLang];

                node.subCategories?.forEach((subCategory) => {
                    let obj;
                    if (subCategory.sections) {
                        subCategory.sections.forEach((element) => {
                            obj = {
                                logoUrl: null,
                                mapElementId: null,
                                id: element.id,
                                hoursOfOperation: [],

                                openingHourMon: [],
                                closingHourMon: [],

                                openingHourTues: [],
                                closingHourTues: [],

                                openingHourWed: [],
                                closingHourWed: [],

                                openingHourThurs: [],
                                closingHourThurs: [],

                                openingHourFri: [],
                                closingHourFri: [],

                                openingHourSat: [],
                                closingHourSat: [],

                                openingHourSun: [],
                                closingHourSun: [],

                                name: subCategory.category[
                                    this.translate.currentLang
                                ],
                                telephoneNo: '',
                                shortName:
                                    element.title[this.translate.currentLang] ||
                                    '',
                                airside: false,
                                showLogo: true,
                                imageUrl: null,
                                categoryId:
                                    categoryName == 'ADA'
                                        ? 12
                                        : categoryName ==
                                          this.translate.instant(
                                              'INFO_AND_TIPS'
                                          )
                                        ? 14
                                        : null,
                                icon:
                                    categoryName == 'ADA'
                                        ? 'accessible'
                                        : categoryName ==
                                          this.translate.instant(
                                              'INFO_AND_TIPS'
                                          )
                                        ? 'info'
                                        : null,
                                details: {
                                    en: {
                                        shortDescription: null,
                                        fullDescription: null,
                                        shortLocation:
                                            element.title[
                                                this.translate.currentLang
                                            ],
                                        fullLocation:
                                            element.title[
                                                this.translate.currentLang
                                            ],
                                        id: element.id,
                                        keywordSearch: '',
                                        languageId: 1,
                                    },
                                    es: {
                                        shortDescription: null,
                                        fullDescription: null,
                                        shortLocation:
                                            element.title[
                                                this.translate.currentLang
                                            ],
                                        fullLocation:
                                            element.title[
                                                this.translate.currentLang
                                            ],
                                        id: element.id,
                                        keywordSearch: '',
                                        languageId: 1,
                                    },
                                },
                                webUrl: '',
                            };
                            static_content.push(obj);
                        });
                    } else {
                        obj = {
                            logoUrl: null,
                            mapElementId: null,
                            id: null,
                            hoursOfOperation: [],

                            openingHourMon: [],
                            closingHourMon: [],

                            openingHourTues: [],
                            closingHourTues: [],

                            openingHourWed: [],
                            closingHourWed: [],

                            openingHourThurs: [],
                            closingHourThurs: [],

                            openingHourFri: [],
                            closingHourFri: [],

                            openingHourSat: [],
                            closingHourSat: [],

                            openingHourSun: [],
                            closingHourSun: [],

                            name: subCategory.category[
                                this.translate.currentLang
                            ],
                            telephoneNo: '',
                            shortName: '',
                            airside: false,
                            showLogo: true,
                            imageUrl: null,
                            categoryId:
                                categoryName == 'ADA'
                                    ? 12
                                    : categoryName ==
                                      this.translate.instant('INFO_AND_TIPS')
                                    ? 14
                                    : null,
                            icon:
                                categoryName == 'ADA'
                                    ? 'accessible'
                                    : categoryName ==
                                      this.translate.instant('INFO_AND_TIPS')
                                    ? 'info'
                                    : null,
                            details: {
                                en: {
                                    shortDescription: null,
                                    fullDescription: null,
                                    shortLocation: '',
                                    fullLocation: '',
                                    id: null,
                                    keywordSearch: '',
                                    languageId: 1,
                                },
                                es: {
                                    shortDescription: null,
                                    fullDescription: null,
                                    shortLocation: '',
                                    fullLocation: '',
                                    id: null,
                                    keywordSearch: '',
                                    languageId: 1,
                                },
                            },
                            webUrl: '',
                        };
                        static_content.push(obj);
                    }
                });
            });
        });

        return static_content;
    }

    mapSearchVideos() {
        let videos_content = [];

        const dataBusState$ = this.store.select(selectDataBus);

        dataBusState$.subscribe((dataBusItem: any) => {
            dataBusItem.cmsVideos.videos.forEach((category) => {
                let categoryName = category.categoryName;
                category.videos.forEach((video) => {
                    let obj;
                    obj = {
                        logoUrl: null,
                        mapElementId: null,
                        id: video.id,
                        hoursOfOperation: [],

                        openingHourMon: [],
                        closingHourMon: [],

                        openingHourTues: [],
                        closingHourTues: [],

                        openingHourWed: [],
                        closingHourWed: [],

                        openingHourThurs: [],
                        closingHourThurs: [],

                        openingHourFri: [],
                        closingHourFri: [],

                        openingHourSat: [],
                        closingHourSat: [],

                        openingHourSun: [],
                        closingHourSun: [],

                        name: video.videoTitle || '',
                        telephoneNo: '',
                        shortName: categoryName,
                        airside: false,
                        showLogo: true,
                        imageUrl: video.thumbnailURL,
                        categoryId: 13,
                        icon: null,
                        details: {
                            en: {
                                shortDescription: null,
                                fullDescription: null,
                                shortLocation: categoryName,
                                fullLocation: 'LAX TV',
                                id: video.id,
                                keywordSearch: '',
                                languageId: 1,
                            },
                            es: {
                                shortDescription: null,
                                fullDescription: null,
                                shortLocation: categoryName,
                                fullLocation: 'LAX TV',
                                id: video.id,
                                keywordSearch: '',
                                languageId: 1,
                            },
                        },
                        webUrl: '',
                    };
                    videos_content.push(obj);
                });
            });
        });

        return videos_content;
    }
}
