import {
    UntypedFormBuilder,
    Validators,
    UntypedFormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { GIT_REV, VERSION } from '../../../environments/version';
import { UtilityService } from '@app/services/utility.service';
import { AppConfig } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { selectUI } from '@app/store/selectors';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CopyRightComponent } from '../../components/copy-right/copy-right.component';
import { AlertBoxComponent } from '../../components/alert-box/alert-box.component';
import { NgIf, NgStyle } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

type FormErrors = { [u in UserFields]: string };
type UserFields = 'email' | 'password';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        NgIf,
        AlertBoxComponent,
        FormsModule,
        ReactiveFormsModule,
        NgStyle,
        CopyRightComponent,
        TranslateModule,
    ],
})
export class LoginComponent implements OnInit {
    public formErrors: FormErrors = {
        email: '',
        password: '',
    };
    buildNumber: number = GIT_REV;
    public loginForm: UntypedFormGroup;
    isLoading: boolean = true;
    version: string = VERSION;
    public errorMessage: any;
    public newUser = false;
    bgImageNumber: any;

    constructor(
        public utilityService: UtilityService,
        private translate: TranslateService,
        public authService: AuthService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private store: Store<any>
    ) {
        document.title = AppConfig.pageName;

        this.loginForm = fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });
        translate.setDefaultLang('en');
    }

    ngOnInit() {
        const uiState$ = this.store.select(selectUI);
        uiState$.subscribe((uiItem) => {
            this.bgImageNumber = uiItem.images.randomBgImage || 1;
        });
        this.utilityService.randomizeBgImage(this.bgImageNumber);
    }

    login() {
        this.authService.isLoading = true;
        this.authService.signIn(
            this.loginForm.value['email'],
            this.loginForm.value['password']
        );
    }
}
