import { selectDataBus } from "@app/store/selectors";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

@Component({
    selector: "app-emergency-alert",
    templateUrl: "./emergency-alert.component.html",
    styleUrls: ["./emergency-alert.component.scss"],
    standalone: true
})
export class EmergencyAlertComponent implements OnInit {
  emergencyBroadcastInfo;
  dataBusSubscription;

  constructor(private store: Store<any>) {}

  ngOnInit() {
    const dataBusState$ = this.store.select(selectDataBus);

    this.dataBusSubscription = dataBusState$.subscribe(dataBusItem => {
      this.emergencyBroadcastInfo = dataBusItem.emergencyBroadcast;
    });
  }

  ngOnDestroy() {
    this.dataBusSubscription.unsubscribe();
  }
}
