/* src/app/components/hotel-item/hotel-item.component.scss */
.hotel-item-container {
  display: flex;
  width: 100%;
  cursor: pointer;
}
.hotel-item-container .emptyFlex {
  width: 1em;
}
.hotel-item-container .image-container {
  flex: 0.4;
  padding: 0%;
  display: flex;
  aspect-ratio: 3/2;
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.3);
}
.hotel-item-container .image-container .hotel-image {
  width: 100%;
  height: 100%;
  border-radius: 5%;
}
.hotel-item-container .hotel-info-container {
  justify-content: center;
  flex-direction: column;
  display: flex;
  flex: 0.6;
  padding-right: 0.875em;
}
.hotel-item-container .hotel-info-container .title span {
  font-weight: 700;
  color: black;
  text-transform: capitalize;
}
.hotel-item-container .hotel-info-container .rating {
  flex: 1;
  display: flex;
  align-items: flex-end;
}
.googleMapWindowInfo {
  padding: 0.5em;
  cursor: unset;
}
app-featured-hotel {
  cursor: pointer;
}
.hotel-info-container .title span {
  font-size: 0.9em;
  transition: font-size 500ms linear;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .hotel-info-container {
    padding-right: 1.75em !important;
  }
  .image-container {
    padding: 0% !important;
  }
  .title span {
    font-size: 2em !important;
    transition: font-size 500ms linear;
  }
  .googleMapWindowInfo {
    padding: 1em !important;
  }
}
/*# sourceMappingURL=hotel-item.component-3JR6YZNR.css.map */
