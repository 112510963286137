<div class="survey-container">
    <div class="survey-model">
        <div class="header">
            <img
                class="logo"
                loading="lazy"
                [src]="'assets/branding/images/login_logo.svg'"
            />
        </div>
        <div class="body">
            <div class="question-container">
                <span class="text">{{ title }}</span>
            </div>

            <div
                class="answer-container"
                *ngIf="
                    state == 'process' || (state == 'done' && textButton != '')
                "
            >
                <div
                    class="container"
                    *ngFor="let item of feedbackConfig; last as isLast"
                    [ngClass]="{
                        'is-last-active':
                            isLast && item.id == selectedFeedback.id,
                        'is-last': isLast && item.id != selectedFeedback.id,
                        active: item.id == selectedFeedback.id && !isLast
                    }"
                >
                    <div class="emoji">
                        <div class="icon-container">
                            <img
                                [ngStyle]="{
                                    filter: getColor(
                                        item.id == selectedFeedback.id
                                    )
                                }"
                                (click)="onFeedBackPress(item)"
                                class="emoji-icon"
                                loading="lazy"
                                [src]="item.icon"
                            />
                        </div>
                        <div class="line-container" *ngIf="!isLast">
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="emoji-text">
                        <span class="text">{{ item.label | translate }}</span>
                        <div class="empty-div"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div
                *ngIf="textButton != ''"
                class="button-container"
                (click)="onSurveyButtonClick()"
                [ngClass]="{
                    'disable-btn': selectedFeedback.id == -1 && state != 'start'
                }"
            >
                <span class="text">{{
                    textButton | translate | uppercase
                }}</span>
            </div>
        </div>
    </div>
    <div class="dismiss-container" (click)="onModalDismiss()"></div>
</div>
