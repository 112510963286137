/* src/app/components/flight-partner-row/flight-partner-row.component.scss */
.flight-partner-container {
  border-bottom: 1px solid rgba(199, 199, 199, 0.5);
  border-right: 1px solid rgba(199, 199, 199, 0.5);
  border-left: 1px solid rgba(199, 199, 199, 0.5);
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
  width: 99.7%;
  background: rgba(199, 199, 199, 0.2);
}
.flight-partner-container .flight-partner-animation {
  overflow: hidden;
  display: flex;
  height: 100%;
  width: auto;
}
.flight-partner-container .flight-partner-animation .partner-header {
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  font-weight: 400;
  padding-left: 0.55em;
  display: flex;
  z-index: 1;
}
.flight-partner-container .flight-partner-animation .partner-information-container {
  display: flex;
  height: 100%;
  width: 100%;
  white-space: nowrap;
}
.flight-partner-container .flight-partner-animation .partner-information-container .partner-information {
  display: flex;
  height: 100%;
  width: 100%;
}
.flight-partner-container .main-div1 {
  white-space: nowrap;
  overflow: hidden;
}
.flight-partner-container .main-div2 {
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}
.flight-partner-container .div-1 {
  animation: marquee1 20s linear;
  display: inline-block;
  position: absolute;
  width: 0%;
}
.flight-partner-container .div-2 {
  animation: marquee2 20s linear infinite;
  display: inline-block;
}
.flight-partner-container .div-3 {
  animation: marquee3 20s linear infinite;
  display: inline-block;
  animation-delay: 10s;
}
.active-flight-item-container-no-back-arrow {
  background-color: black !important;
  width: 99.8%;
  color: white;
}
.active-flight-item-container-with-back-arrow {
  background-color: black !important;
  width: 99.8%;
  color: white;
}
.flight-selected-bg-color-no-back-arrow {
  background-color: black !important;
}
.flight-selected-bg-color-with-back-arrow {
  background-color: black !important;
}
@keyframes marquee1 {
  from {
    width: auto;
    transform: translateX(0%);
  }
  to {
    width: auto;
    transform: translateX(-200%);
  }
}
@keyframes marquee2 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes marquee3 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}
@media screen and (min-width: 1601px), (min-height: 870px) {
  .flight-partner-container .flight-partner-animation .partner-header {
    font-size: 0.8em !important;
    padding-left: 1em !important;
  }
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .flight-partner-container .flight-partner-animation .partner-header {
    font-size: 1.7em !important;
    padding-left: 1em !important;
  }
}
/*# sourceMappingURL=flight-partner-row.component-HKEN32VD.css.map */
