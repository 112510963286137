import { animationState } from '../state/animation.state';
import { Action, createReducer, on } from '@ngrx/store';
import { toggleAnimationState } from '../actions';

const initialState: animationState = {
    animationState: '',
    fadeInOut: false,
    isSideInfo: false,
};
const animationReducer = createReducer(
    initialState,
    on(toggleAnimationState, (state, action) => {
        const _a = { ...state };
        _a.animationState = action.animationState;
        _a.fadeInOut = action.fadeInOut;
        _a.isSideInfo = action.isSideInfo;
        return _a;
    })
);

export default function reducer(state: animationState, action: Action) {
    return animationReducer(state, action);
}
