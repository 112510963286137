import { createAction, props } from "@ngrx/store";
import { types } from "./types";

export const getFlight = createAction(types.GET_FLIGHT);

export const getFlightSuccess = createAction(
  types.GET_FLIGHT_SUCCESS,
  props<{ payload: any; adi: string }>()
);

export const getFlightFailed = createAction(
  types.GET_FLIGHT_FAILED,
  props<{ payload: any }>()
);

export const getFlightLax = createAction(types.GET_FLIGHT_LAX);

export const getFlightLaxSuccess = createAction(
  types.GET_FLIGHT_LAX_SUCCESS,
  props<{ payload: any }>()
);

export const getFlightLaxFailed = createAction(
  types.GET_FLIGHT_LAX_FAILED,
  props<{ payload: any }>()
);
