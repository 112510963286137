/* src/app/components/survey/survey.component.scss */
.survey-container {
  background-color: rgba(25, 25, 25, 0.7);
  justify-content: center;
  align-items: center;
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.survey-container .survey-model {
  background-color: white;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
  min-width: 860px;
  display: flex;
  height: 60%;
  width: 50%;
  position: fixed;
  top: 14.5%;
  left: 23%;
  z-index: 1;
}
.survey-container .survey-model .header {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  flex: 0.4;
}
.survey-container .survey-model .body {
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  flex: 1;
}
.survey-container .survey-model .body .question-container {
  justify-content: center;
  align-items: center;
  min-width: 860px;
  display: flex;
  flex: 1;
}
.survey-container .survey-model .body .question-container .text {
  text-align: center;
  font-size: 2em;
  width: 75%;
}
.survey-container .survey-model .body .answer-container {
  justify-content: center;
  display: flex;
  flex: 1;
}
.survey-container .survey-model .body .answer-container .container {
  flex-direction: column;
  display: flex;
  width: 15%;
}
.survey-container .survey-model .body .answer-container .container .emoji {
  display: flex;
  flex: 1;
}
.survey-container .survey-model .body .answer-container .container .emoji .icon-container {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 65%;
}
.survey-container .survey-model .body .answer-container .container .emoji .icon-container .emoji-icon {
  width: 6em;
}
.survey-container .survey-model .body .answer-container .container .emoji .line-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
}
.survey-container .survey-model .body .answer-container .container .emoji .line-container .line {
  border-bottom: 5px solid #000;
  width: 100%;
}
.survey-container .survey-model .body .answer-container .container .emoji-text {
  justify-content: center;
  display: flex;
}
.survey-container .survey-model .body .answer-container .container .emoji-text .text {
  text-align: center;
  font-size: 1.5em;
  width: 65%;
}
.survey-container .survey-model .body .answer-container .container .emoji-text .empty-div {
  flex: 1;
}
.survey-container .survey-model .body .answer-container .is-last {
  justify-content: center;
  width: 14% !important;
}
.survey-container .survey-model .footer {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  flex: 0.4;
}
.survey-container .survey-model .footer .button-container {
  justify-content: center;
  align-items: center;
  background: #00a5ca;
  border-radius: 5px;
  display: flex;
  height: 65%;
  width: 40%;
}
.survey-container .survey-model .footer .button-container .text {
  font-size: 2.6em;
  color: white;
}
.survey-container .survey-model .footer .disable-btn {
  pointer-events: none;
  opacity: 0.5;
}
.dismiss-container {
  position: absolute;
  height: 100%;
  width: 100%;
}
.active {
  width: 20%;
}
.active .emoji .icon-container .emoji-icon {
  width: 8em;
}
.active .emoji-text .text {
  font-weight: 900;
}
.is-last-active {
  justify-content: center;
  width: 12%;
}
.is-last-active .emoji .icon-container .emoji-icon {
  width: 8em;
}
.is-last-active .emoji-text .text {
  font-weight: 900;
}
@media screen and (max-width: 1920px) {
  .active {
    width: 20% !important;
  }
  .active .icon-container .emoji-icon {
    width: 8em !important;
  }
  .is-last-active {
    justify-content: center;
    width: 19% !important;
  }
  .is-last-active .icon-container .emoji-icon {
    width: 8em !important;
  }
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .header .logo {
    width: 22em;
  }
  .question-container .text {
    font-size: 5em !important;
  }
  .icon-container .emoji-icon {
    width: 12em !important;
  }
  .active {
    width: 20% !important;
  }
  .active .icon-container .emoji-icon {
    width: 16em !important;
  }
  .is-last-active {
    justify-content: center;
    width: 20% !important;
  }
  .is-last-active .icon-container .emoji-icon {
    width: 16em !important;
  }
  .button-container .text {
    font-size: 5.6em !important;
  }
  .emoji-text .text {
    font-size: 4em !important;
  }
}
/*# sourceMappingURL=survey.component-DEWDH3UU.css.map */
