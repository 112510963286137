import { UtilityService } from '../../services/utility.service';
import { MappingService } from '../../services/mapping.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { Injectable } from '@angular/core';
import { types } from '../actions';
import { of } from 'rxjs';

@Injectable()
export class DataBusEffects {
    getWeather$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_WEATHER),
            mergeMap(() =>
                this.api.getWeatherInformation().pipe(
                    map((weather) => {
                        return {
                            type: types.GET_WEATHER_SUCCESS,
                            payload: weather,
                        };
                    }),
                    catchError(() =>
                        of({
                            type: types.GET_WEATHER_FAILED,
                        })
                    )
                )
            )
        )
    );

    saveSelfie$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_SELFIE_ATTEMPT),
            mergeMap((action) =>
                this.api.saveSelfie(action['data']).pipe(
                    map((content) => {
                        return {
                            code: content.code,
                            type: types.GET_SELFIE_SUCCESS,
                        };
                    }),
                    catchError(() =>
                        of({
                            type: types.GET_SELFIE_FAILED,
                        })
                    )
                )
            )
        )
    );

    constructor(
        private utility: UtilityService,
        private mapping: MappingService,
        private actions$: Actions,
        private api: ApiService
    ) {}
}
