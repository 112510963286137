/* src/app/components/side-menu-info/side-menu-info.component.scss */
.side-info-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.side-info-container .image-container {
  display: flex;
  background: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
  height: 30%;
}
.side-info-container .cover-image {
  background-size: cover;
}
.side-info-container .no-image-container {
  height: 30%;
}
.side-info-container .title-container {
  width: 100%;
  flex: 0.12;
}
.side-info-container .title-container .header-container {
  color: white;
  display: flex;
  height: 100%;
  width: 130%;
}
.side-info-container .title-container .header-container-agent {
  color: white;
  display: flex;
  height: 100%;
  width: 130%;
}
.side-info-container .title-container .back-poi-item {
  display: inline-block;
  width: 77%;
}
.side-info-container .title-container .send-to-client {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-color: black;
  display: inline-block;
  position: relative;
  font-size: 0.85em;
  font-weight: 600;
  height: 100%;
  width: 23.2%;
}
.side-info-container .navTabs-container {
  display: flex;
  flex: 0.2;
  max-height: 3rem;
  min-height: 3rem;
  background: #00a6ce;
}
.side-info-container .navTabs-container .info-tab-container,
.side-info-container .navTabs-container .route-tab-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  column-gap: 0.2rem;
  border-bottom: 3px rgb(224, 222, 222) solid;
  transition: border-bottom 0.5s ease;
  overflow: auto;
}
.side-info-container .navTabs-container .info-tab-container i,
.side-info-container .navTabs-container .route-tab-container i {
  color: white;
  font-size: 1.4rem;
}
.side-info-container .navTabs-container .info-tab-container span,
.side-info-container .navTabs-container .route-tab-container span {
  color: white;
  font-size: 1.2rem;
}
.side-info-container .navTabs-container .main-tab-active {
  border-bottom: 3px black solid;
  transition: border-bottom 0.5s ease;
  font-weight: bold;
}
.side-info-container .align-tab {
  align-items: center !important;
  justify-content: flex-start !important;
  padding-left: 5%;
}
.side-info-container .info {
  display: flex;
  flex: 1;
  overflow-y: scroll;
  width: 100%;
  background: white;
}
.side-info-container .info .info-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
}
.side-info-container .info .description-container {
  padding: 5%;
  line-height: 1.4em;
  align-content: center;
  justify-self: center;
  color: rgb(68, 68, 68);
  max-width: 100%;
  word-wrap: break-word;
  text-transform: none;
}
.side-info-container .info .description-container p::first-letter {
  text-transform: capitalize;
}
.side-info-container .info .info-items-container {
  padding-left: 5%;
}
.side-info-container .info .route-info-container {
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.side-info-container .info .route-info-container .controller-container {
  background: #f6f6f6;
  min-height: 4em;
  display: flex;
  height: 4em;
}
.side-info-container .info .route-info-container .controller-container .controller-button {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.side-info-container .info .route-info-container .controller-container .controller-button .controller-icon-container {
  justify-content: center;
  align-items: flex-end;
  display: flex;
  flex: 2;
}
.side-info-container .info .route-info-container .controller-container .controller-button .controller-icon-container .icon {
  font-size: 1.7em;
}
.side-info-container .info .route-info-container .controller-container .controller-button .controller-icon-container .svg {
  width: 2em;
}
.side-info-container .info .route-info-container .controller-container .controller-button .text-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
}
.side-info-container .info .route-info-container .controller-container .controller-button .text-container .text {
  font-size: 0.9em;
}
.side-info-container .info .route-info-container .directions-container {
  overflow: auto;
  width: 100%;
}
.side-info-container .info .route-info-container .directions-container .row {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 1rem;
  max-height: 2em;
  min-height: 2em;
  column-gap: 0.7rem;
}
.side-info-container .info .route-info-container .directions-container .row .instruction-step-container {
  column-gap: 1rem;
  padding: 1rem;
  display: flex;
  height: 100%;
  width: 100%;
}
.side-info-container .info .route-info-container .directions-container .row .instruction-step-container.icon-container .material-icons,
.side-info-container .info .route-info-container .directions-container .row .icon-container .material-icons {
  color: black;
  font-size: 2em;
}
.side-info-container .info .route-info-container .directions-container .row .instruction-step-container.direction-description,
.side-info-container .info .route-info-container .directions-container .row .direction-description {
  flex-direction: column;
  display: flex;
  width: 75%;
}
.side-info-container .info .route-info-container .directions-container .row .instruction-step-container.direction-description .instruction,
.side-info-container .info .route-info-container .directions-container .row .direction-description .instruction {
  color: rgb(52, 61, 67);
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.267em;
  font-weight: 500;
}
.side-info-container .info .route-info-container .directions-container .row .instruction-step-container.direction-description .walk-time,
.side-info-container .info .route-info-container .directions-container .row .direction-description .walk-time {
  font-size: 12px;
  color: rgb(108, 108, 108);
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.25em;
}
.side-info-container .info .route-info-container .directions-container .instructions {
  margin: 0.9rem;
}
.side-info-container .empty-flex {
  flex: 0.1;
}
.side-info-container .highlightText {
  background: rgba(241, 233, 233, 0.45);
  font-weight: bold;
}
.collapse-side-info .image-container {
  min-height: 0em;
}
.collapse-side-info .title-container {
  height: fit-content;
}
.icon-container .material-icons {
  transition: font-size 500ms linear;
  font-size: 2.5em;
}
.name-container {
  transition: font-size 500ms linear;
  font-size: 1.2em;
}
.description-container {
  transition: font-size 500ms linear;
  font-size: 1.1em;
}
.poi-id-overlay {
  background: rgba(0, 0, 0, 0.5490196078);
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
  display: flex;
  height: 2%;
  width: 11%;
  left: 89%;
  top: 28%;
}
.icon-walk-direction {
  width: 2rem;
}
@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .title-container .send-to-client {
    font-size: 0.85em !important;
  }
}
@media screen and (min-width: 3840px) and (min-height: 2160px) {
  .title-container .send-to-client {
    font-size: 1.7em !important;
  }
  .navTabs-container {
    max-height: 8em !important;
    min-height: 8em !important;
  }
  .navTabs-container .info-tab-container,
  .navTabs-container .route-tab-container {
    border-bottom: 5px rgb(224, 222, 222) solid !important;
  }
  .navTabs-container .info-tab-container i,
  .navTabs-container .route-tab-container i {
    font-size: 3.8em !important;
  }
  .navTabs-container .info-tab-container span,
  .navTabs-container .route-tab-container span {
    font-size: 3em !important;
  }
  .navTabs-container .main-tab-active {
    border-bottom: 5px black solid !important;
  }
  .icon-container .material-icons {
    transition: font-size 500ms linear;
    font-size: 5em !important;
  }
  .description-container {
    font-size: 2.5em !important;
  }
  .route-info-container .direction-container .row {
    padding: 1.6em 0 !important;
    max-height: 6em !important;
    min-height: 6em !important;
  }
  .route-info-container .direction-container .row .icon-container img {
    width: 4em !important;
  }
  .route-info-container .direction-container .row .icon-container .material-icons {
    font-size: 4em !important;
  }
  .route-info-container .direction-container .row .direction-description {
    width: 75%;
    font-size: 2.2em !important;
    transition: font-size 500ms linear;
  }
  .controller-container {
    min-height: 8em !important;
    height: 8em !important;
  }
  .controller-container .controller-button .controller-icon-container .icon {
    font-size: 4.5em !important;
  }
  .controller-container .controller-button .controller-icon-container .svg {
    width: 5em !important;
  }
  .controller-container .controller-button .text-container .text {
    font-size: 1.5em !important;
  }
  .directions-container .row {
    max-height: 4rem !important;
    min-height: 4rem !important;
  }
  .directions-container .row .instruction-step-container.icon-container .material-icons,
  .directions-container .row .icon-container .material-icons {
    font-size: 4em !important;
  }
  .directions-container .row .instruction-step-container.direction-description .instruction,
  .directions-container .row .direction-description .instruction {
    font-size: 2rem !important;
  }
  .directions-container .row .instruction-step-container.direction-description .walk-time,
  .directions-container .row .direction-description .walk-time {
    font-size: 1.5rem !important;
  }
  .directions-container .row .instruction-step-container.icon-container .material-icons,
  .directions-container .row .icon-container .material-icons {
    font-size: 4em !important;
  }
  .icon-walk-direction {
    width: 4rem;
  }
}
.disable-btn {
  pointer-events: none;
  opacity: 0.4;
}
.active-step {
  background: #e0dddd;
}
/*# sourceMappingURL=side-menu-info.component-B4G7KNHS.css.map */
