import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    AfterViewInit,
} from '@angular/core';
import { UtilityService } from '@app/services/utility.service';
import { toggleVideoPlayerModal } from '@app/store/actions';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import Player from '@vimeo/player';
import { YouTubePlayer } from '@angular/youtube-player';
import { EmergencyAlertComponent } from '../emergency-alert/emergency-alert.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
    standalone: true,
    imports: [NgIf, EmergencyAlertComponent, YouTubePlayer, NgClass],
})
export class VideoPlayerComponent implements OnInit, AfterViewInit {
    @ViewChild('customPlayer') customPlayer: ElementRef;
    @ViewChild('vimeoPlayer') vimeoPlayer: ElementRef;
    @ViewChild('youtubePlayer') youtubePlayer;

    @Input('isEmergencyStateEnabled')
    isEmergencyStateEnabled: boolean;
    @Input('videoList') playlist: Array<string>;
    @Input('isLaxTV') isLaxTV: boolean;
    @Input('videoUrl') videoUrl;
    @Input('videoType') videoType;
    @Input('videoFormat') videoFormat;

    hideVideoSection: boolean;
    videoToPlay: string;
    videoIndex = 0;
    playerVars = {
        enablejsapi: 1,
        cc_load_policy: 1,
        autoplay: 1,
    };

    public reframed: Boolean = false;
    public player: any;
    public video: any;
    public YT: any;

    constructor(
        private utility: UtilityService,
        public sanitizer: DomSanitizer,
        private store: Store<any>
    ) {}

    ngOnInit() {
        if (this.playlist)
            this.videoToPlay =
                this.playlist[
                    this.utility.generateRandomNumber(-1, this.playlist.length)
                ];

        switch (this.videoType) {
            case 'youtube':
                this.videoToPlay =
                    this.playlist[
                        this.utility.generateRandomNumber(
                            -1,
                            this.playlist.length
                        )
                    ];
                break;

            case 'youtube-single':
                this.videoToPlay = this.getYouTubeID(this.videoUrl);

                break;

            case 'vimeo':
                let options = {
                    url: this.videoUrl,
                    loop: false,
                };

                setTimeout(() => {
                    var player = new Player(
                        this.vimeoPlayer.nativeElement,
                        options
                    );

                    player.on('loaded', (event) => {
                        player.play();
                    });

                    player.on('ended', (event) => {
                        setTimeout(() => {
                            this.store.dispatch(
                                toggleVideoPlayerModal({ shouldOpen: false })
                            );
                        }, 200);
                    });

                    player.enableTextTrack('en').then((track) => {
                        // track.language = the iso code for the language
                        track.kind = 'captions'; // r 'subtitles'
                        // track.label = the human-readable label
                    });
                }, 500);

                break;

            case 'custom':
                break;

            default:
                break;
        }
    }

    ngOnDestroy() {}

    ngAfterViewInit() {
        if (this.videoType != 'vimeo' && this.videoType != 'custom')
            setTimeout(() => {
                this.youtubePlayer.playVideo();
            }, 1000);
    }

    /* 4. It will be called when the Video Player is ready */
    onPlayerReady(event) {
        event.target.playVideo();
    }

    /* 5. API will call this function when Player State changes like PLAYING, PAUSED, ENDED */
    onPlayerStateChange(event) {
        event.target.setOption('captions', 'track', { languageCode: 'en' }); // undocumented call
        if (event.data == 0) {
            this.videoIndex = this.videoIndex + 1;

            if (this.videoIndex == this.playlist.length) this.videoIndex = 0;

            this.videoToPlay = this.playlist[this.videoIndex];
            if (!this.isLaxTV)
                setTimeout(() => {
                    event.target.playVideo();
                }, 5);
            else {
                setTimeout(() => {
                    this.store.dispatch(
                        toggleVideoPlayerModal({ shouldOpen: false })
                    );
                }, 200);
            }
        }
    }

    onPlayerError(event) {
        switch (event.data) {
            case 2:
                break;
            case 100:
                break;
            case 101:
                break;
        }
    }

    getYouTubeID(url) {
        const regex =
            /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }

    onVideoEnded() {
        if (this.isLaxTV) {
            setTimeout(() => {
                this.store.dispatch(
                    toggleVideoPlayerModal({ shouldOpen: false })
                );
            }, 200);
        }
    }
}
