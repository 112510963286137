import { AppConfig } from '../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-partner-item',
    templateUrl: './partner-item.component.html',
    styleUrls: ['./partner-item.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class PartnerItemComponent implements OnInit {
    @Input('partnerData') data;

    url: string = '';

    ngOnInit() {
        this.url = `${AppConfig.airlineLogo}/${this.data
            .toString()
            .substring(0, 2)}.png`;
    }
}
