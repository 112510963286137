<div class="video-player-modal-container" (click)="close()">
    <app-video-player
        [videoList]="playlist"
        [isLaxTV]="true"
        [videoUrl]="videoUrl"
        [videoType]="videoType"
        [videoFormat]="mimeType"
    >
    </app-video-player>
</div>
<app-send-to-client
    *ngIf="!isAgent"
    [selectedCategory]="'LAX_TV'"
    [categoryInfo]="categoryInfo"
    [poiContent]="videoInfo"
    class="send-to-mobile"
    [selectedItem]=""
    [isAgent]="isAgent"
></app-send-to-client>
