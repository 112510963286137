import { ElectronService } from '@app/providers/electron.service';
import { Component, OnInit, Input, inject } from '@angular/core';
import { toggleSurveyModal } from '@app/store/actions';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ApiService } from '@app/services/api.service';
import { selectDataBus } from '@app/store/selectors';
import { AppConfig } from 'environments/environment';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { NgIf, NgFor, NgClass, NgStyle, UpperCasePipe } from '@angular/common';
import { TimeConversionService } from '@app/services/time-conversion.service';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, NgStyle, UpperCasePipe, TranslateModule],
})
export class SurveyComponent implements OnInit {
    @Input('activeSurvey') activeSurvey: string;

    timeService = inject(TimeConversionService);

    selectedFeedback = { id: -1, value: -1, icon: '', label: '' };
    dataBusSubscription;
    feedbackConfig = [
        {
            id: 1,
            value: 1,
            icon: 'assets/icon/disgusted.svg',
            label: 'TERRIBLE',
        },
        { id: 2, value: 2, icon: 'assets/icon/unhappy.svg', label: 'BAD' },
        { id: 3, value: 3, icon: 'assets/icon/indifferent.svg', label: 'OKAY' },
        { id: 4, value: 4, icon: 'assets/icon/happier.svg', label: 'GOOD' },
        { id: 5, value: 5, icon: 'assets/icon/happiest.svg', label: 'GREAT' },
    ];
    surveyConfig;

    textButton: string = 'START';
    questionIndex: number = 0;
    state: string = 'start';
    title: string = '';
    modalDismissTimeout: NodeJS.Timeout;
    answers = [];
    surveyAnswer;

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private electronService: ElectronService,
        private translate: TranslateService,
        private apiService: ApiService,
        private store: Store<any>
    ) {}

    ngOnInit() {
        const dataBusState$ = this.store.select(selectDataBus);

        this.dataBusSubscription = dataBusState$.subscribe((dataBusItem) => {
            this.surveyConfig = dataBusItem.surveyConfig[this.activeSurvey];
            this.answers = new Array(
                dataBusItem.surveyConfig[this.activeSurvey].questions.length
            ).fill({});

            if (this.state == 'start')
                this.title =
                    this.surveyConfig.introScreen[this.translate.currentLang];

            if (
                this.surveyConfig.introScreen[this.translate.currentLang] == ''
            ) {
                this.state = 'process';
                this.checkIFLastQuestion();
            }
        });

        this.googleAnalyticService.onSurveyOpen(this.activeSurvey);
    }

    ngOnDestroy() {
        clearTimeout(this.modalDismissTimeout);
        this.dataBusSubscription.unsubscribe();
    }

    onModalDismiss() {
        this.store.dispatch(
            toggleSurveyModal({ shouldOpen: false, activeSurvey: '' })
        );
    }

    onSurveyButtonClick() {
        if (this.state == 'start') {
            this.state = 'process';
            this.textButton = 'NEXT';
            this.checkIFLastQuestion();
        } else if (this.state == 'process') {
            if (this.surveyAnswer)
                this.googleAnalyticService.onSurveyAnswer(this.surveyAnswer);
            this.surveyAnswer = null;
            this.selectedFeedback = { id: -1, value: -1, icon: '', label: '' };

            this.questionIndex = this.questionIndex + 1;
            this.checkIFLastQuestion();
        } else if (this.state == 'done') {
            if (this.surveyAnswer)
                this.googleAnalyticService.onSurveyAnswer(this.surveyAnswer);
            this.surveyAnswer = null;
            if (
                this.surveyConfig.thankYouScreen[this.translate.currentLang] ==
                ''
            ) {
                this.fireSurveyApi();
                this.onModalDismiss();
            } else {
                this.title =
                    this.surveyConfig.thankYouScreen[
                        this.translate.currentLang
                    ];
                this.textButton = '';
                this.fireSurveyApi();

                this.modalDismissTimeout = setTimeout(() => {
                    this.onModalDismiss();
                }, AppConfig.surveyDismissTimeout);
            }
        }
    }

    onFeedBackPress(feedbackData) {
        this.selectedFeedback = feedbackData;
        if (this.selectedFeedback.id != -1) {
            this.surveyAnswer = {
                question: this.title,
                response: this.selectedFeedback.value,
            };
            this.answers[this.questionIndex] = this.surveyAnswer;
        }
    }

    checkIFLastQuestion() {
        if (this.surveyConfig.questions.length == this.questionIndex + 1) {
            this.title =
                this.surveyConfig.questions[this.questionIndex][
                    this.translate.currentLang
                ];
            this.state = 'done';

            if (
                this.surveyConfig.thankYouScreen[this.translate.currentLang] ==
                ''
            )
                this.textButton = 'DONE';
            else this.textButton = 'NEXT';
        } else {
            this.title =
                this.surveyConfig.questions[this.questionIndex][
                    this.translate.currentLang
                ];
            this.textButton = 'NEXT';
        }
    }

    getColor(selected) {
        if (!selected)
            return 'brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(0%) hue-rotate(234deg) brightness(96%) contrast(107%)'; //black

        if (this.selectedFeedback.id == 1)
            return 'brightness(0) saturate(100%) invert(29%) sepia(79%) saturate(3491%) hue-rotate(343deg) brightness(102%) contrast(101%)';
        //red
        else if (this.selectedFeedback.id == 2)
            return 'brightness(0) saturate(100%) invert(90%) sepia(69%) saturate(449%) hue-rotate(359deg) brightness(90%) contrast(92%)';
        //yellow
        else if (this.selectedFeedback.id == 3)
            return 'brightness(0) saturate(100%) invert(52%) sepia(5%) saturate(11%) hue-rotate(314deg) brightness(96%) contrast(81%)';
        //grey
        else if (this.selectedFeedback.id == 4)
            return 'brightness(0) saturate(100%) invert(90%) sepia(15%) saturate(1219%) hue-rotate(58deg) brightness(99%) contrast(88%)';
        //light-green
        else if (this.selectedFeedback.id == 5)
            return 'brightness(0) saturate(100%) invert(22%) sepia(87%) saturate(3377%) hue-rotate(93deg) brightness(97%) contrast(104%)'; //green
    }

    fireSurveyApi() {
        let body = {
            type: this.activeSurvey,
            time: this.timeService.getIsoString(),
            kioskLocation: this.electronService.isElectron()
                ? this.electronService.getComputerName()
                : 'Terminal 3',
            answers: this.answers,
        };

        this.apiService
            .submitSurvey(body)
            .subscribe((result) => console.log(result));
    }
}
