<div class="dialog-container">
  <dialog class="dialog">
    <div class="text-container">
      <span class="version">{{ dialog.title | translate }}</span>
      <span class="sita-copy-right">{{ dialog.subTitle | translate }}</span>
      <span class="cloud-function-version" *ngIf="dialog.showCloudFunction"
        >{{ "POWERED_BY_CLOUD_FUNCTIONS" | translate }} v{{
          dialog.version
        }}</span
      >
    </div>
    <div style="width: 100%; display: flex; justify-content: center">
      <button
        *ngIf="dialog.fromComponent == 'sticky-button-list'"
        (click)="onReactiveAgent()"
        class="dismiss-button"
        style="margin-right: 0.5em"
      >
        {{ "REACTIVATE" | translate }}
      </button>
      <button
        (click)="closeDialog()"
        class="dismiss-button"
        [ngClass]="{
          'button-width': dialog.fromComponent != 'sticky-button-list',
          'cancel-button': dialog.fromComponent == 'sticky-button-list'
        }"
      >
        {{
          (dialog.fromComponent == "sticky-button-list" ? "CANCEL" : "OK")
            | translate
        }}
      </button>
    </div>
  </dialog>
</div>
