<google-map
    [options]="options"
    [center]="center"
    [zoom]="zoom"
    height="100%"
    width="100%"
>
    @for (item of markerItems; track item) {
    <map-marker
        #marker="mapMarker"
        [attr.id]="item.element.placeID"
        [position]="{ lat: item.lat, lng: item.long }"
        (mapClick)="handleMarkerClick(item)"
    />
    }

    <map-info-window
        [options]="{
            headerDisabled: true
        }"
    >
        <app-hotel-item
            [hideFeaturedHotel]="true"
            [hotelItem]="hotelItem"
            [forGoogleMap]="true"
            *ngIf="hotelItem"
        ></app-hotel-item
    ></map-info-window>

    @if(showTrafficLayer){
    <map-traffic-layer [autoRefresh]="false" />

    }
</google-map>
