import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { selectPOI, selectDataBus } from '@app/store/selectors';
import { WebRTCService } from '@app/services/web-rtc.service';
import { AppConfig } from 'environments/environment';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    toggleVideoPlayerModal,
    toggleStaticInfoModal,
    toggleSubSideMenu,
    setSelectedPoi,
} from '@app/store/actions';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { SendToClientComponent } from '../send-to-client/send-to-client.component';
import { ExpandableItemComponent } from '../expandable-item/expandable-item.component';
import { LaxTVComponent } from '../lax-tv/lax-tv.component';
import { BackPoiItemComponent } from '../back-poi-item/back-poi-item.component';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-static-content',
    templateUrl: './static-content.component.html',
    styleUrls: ['./static-content.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        BackPoiItemComponent,
        NgFor,
        NgIf,
        LaxTVComponent,
        ExpandableItemComponent,
        SendToClientComponent,
        SafeHtmlPipe,
    ],
})
export class StaticContentComponent implements OnInit {
    isAgent: boolean = AppConfig.isAgent;
    isVideoSelected: boolean = false;
    selectedItem: string = 'Home';
    showQRCode: boolean = true;
    selectedCategoryData: {};
    dataBusSubscription: any;
    staticInfoCategory: any;
    subscribeLanguage: any;
    selectedSectionId: any;
    selectedItemData: any;
    selectedCategory: any;
    subCategoryTitle: any;
    poiSubscription: any;
    subCategoryBody: any;
    sectionStatus: any;
    subCategories: any;
    categoryTitle: any;
    selectedId: any;
    categoryDetails;
    sections: any;
    cmsVideo: any;
    categoryInfo;
    staticInfo;
    poiContent;

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        public translate: TranslateService,
        public webRTCService: WebRTCService,
        private store: Store<any>
    ) {
        this.webRTCService.selectedItem.subscribe((value) =>
            this.selectItem(value, true)
        );
    }

    ngOnInit() {
        const dataBusState$ = this.store.select(selectDataBus);
        const poiState$ = this.store.select(selectPOI);

        this.poiSubscription = poiState$.subscribe((poiItem: any) => {
            this.selectedCategory = poiItem.poiContent.category;

            this.categoryInfo = poiItem.categoryInfo;
            this.poiContent = poiItem.poiContent;
            this.categoryDetails = poiItem.poiContent.categoryDetails || null;
        });

        this.dataBusSubscription = dataBusState$.subscribe((dataBusItem) => {
            this.staticInfo = dataBusItem.staticInfo;
            this.cmsVideo = dataBusItem.cmsVideos.videos;
        });

        this.initializePage();

        this.subscribeLanguage = this.translate.onLangChange.subscribe(
            (event: LangChangeEvent) => {
                this.initializePage();
            }
        );
    }

    ngOnDestroy() {
        this.dataBusSubscription.unsubscribe();
        if (this.subscribeLanguage) this.subscribeLanguage.unsubscribe();
        this.poiSubscription.unsubscribe();
    }

    initializePage() {
        switch (this.selectedCategory) {
            case 'LAX_TV':
                this.categoryTitle = 'LAX TV';
                this.subCategories = this.mapVideoCMS(this.cmsVideo);
                this.selectedId = this.selectedId ? this.selectedId : 0;

                break;
            case 'INFO_TIPS':
                this.mapStaticInfo(
                    this.staticInfo,
                    this.translate.instant('INFO_AND_TIPS')
                );
                break;
            case 'ADA':
                this.mapStaticInfo(this.staticInfo, 'ADA');
                break;

            default:
                break;
        }

        if (this.categoryDetails) {
            let data;
            if (this.selectedCategory == 'LAX_TV') {
                this.subCategories.forEach((element) => {
                    if (
                        element.categoryName ==
                        this.categoryDetails.categoryName
                    ) {
                        data = element;
                    }
                });
            } else {
                this.subCategories.forEach((element) => {
                    if (
                        element.category[this.translate.currentLang] ==
                        this.categoryDetails.subCategoryName
                    ) {
                        data = element;
                    }
                });
            }
            this.selectItem(data);
        }
    }

    mapStaticInfo(info, parentCategory) {
        info.forEach((element) => {
            if (
                parentCategory ==
                element.parentCategory[this.translate.currentLang]
            ) {
                if (
                    element.parentCategory[this.translate.currentLang] &&
                    element.subCategories
                ) {
                    this.categoryTitle =
                        element.parentCategory[this.translate.currentLang];
                    this.subCategories = element.subCategories;
                    this.selectedId = this.selectedId
                        ? this.selectedId
                        : element.subCategories[0].id;

                    this.subCategories.forEach((element) => {
                        if (element.id == this.selectedId) {
                            this.sections = element.sections;

                            this.subCategoryTitle =
                                element.category[this.translate.currentLang];

                            if (element.body[this.translate.currentLang])
                                this.subCategoryBody =
                                    element.body[this.translate.currentLang];

                            if (this.sections) {
                                this.selectedSectionId = this.sections[0].id;
                            }
                        }
                    });
                }
            }
        });
    }

    mapVideoCMS(videoData) {
        let mappedVideos = [
            {
                categoryName: 'Home',
                id: 0,
                videos: [],
            },
        ];

        for (let i = 0; i < videoData.length; i++) {
            mappedVideos[0].videos = mappedVideos[0].videos.concat(
                videoData[i].videos
            );
            mappedVideos.push({ ...videoData[i], id: i + 1 });
        }

        return mappedVideos;
    }

    selectItem(item, isControlled?) {
        if (!isControlled)
            this.googleAnalyticService.onStaticInformationMenuClick(
                this.categoryInfo.storeName,
                item
            );

        this.selectedItemData = item;

        console.log(item);

        switch (this.selectedCategory) {
            case 'LAX_TV':
                this.selectedItem = item.categoryName; //maybe make it one with below later:hg
                this.selectedCategoryData = item;
                this.selectedId = item.id;

                if (this.categoryDetails) {
                    item.videos.forEach((video) => {
                        console.log(video);
                        if (video.id == this.categoryDetails?.id) {
                            this.categoryDetails = null;
                            this.store.dispatch(
                                setSelectedPoi({
                                    poiContent: {
                                        videoId: video.videoId,
                                        category: 'LAX_TV',
                                        selectedMenu: this.selectedItem,
                                        videoTitle: video.videoTitle,
                                        videoURL: video.videoURL,
                                        mimeType: video.mimeType,
                                        videoType: video.type,
                                    },
                                })
                            );
                            setTimeout(() => {
                                this.store.dispatch(
                                    toggleVideoPlayerModal({ shouldOpen: true })
                                );
                            }, 500);
                        }
                        return;
                    });
                }
                break;

            case 'INFO_TIPS':
                if (this.categoryDetails) {
                    this.selectedId = item.id;
                    this.subCategoryTitle =
                        item.category[this.translate.currentLang];
                    if (item.body[this.translate.currentLang])
                        this.subCategoryBody =
                            item.body[this.translate.currentLang];
                    else this.subCategoryBody = '';
                    this.sections = item.sections;
                    if (this.sections)
                        this.selectedSectionId = this.categoryDetails.sectionID;

                    this.scrollToTop();
                } else {
                    this.selectedId = item.id;
                    this.subCategoryTitle =
                        item.category[this.translate.currentLang];
                    this.sections = item.sections;
                    if (item.body[this.translate.currentLang])
                        this.subCategoryBody =
                            item.body[this.translate.currentLang];
                    else this.subCategoryBody = '';
                    if (this.sections)
                        this.selectedSectionId = this.sections[0].id;
                }

                this.showQRCode = false;
                setTimeout(() => {
                    this.showQRCode = true;
                }, 10);

                break;

            case 'ADA':
                if (this.categoryDetails) {
                    this.selectedId = item.id;
                    this.subCategoryTitle =
                        item.category[this.translate.currentLang];
                    if (item.body[this.translate.currentLang])
                        this.subCategoryBody =
                            item.body[this.translate.currentLang];
                    else this.subCategoryBody = '';
                    this.sections = item.sections;
                    if (this.sections)
                        this.selectedSectionId = this.categoryDetails.sectionID;

                    this.scrollToTop();
                } else {
                    this.selectedId = item.id;
                    this.subCategoryTitle =
                        item.category[this.translate.currentLang];
                    if (item.body[this.translate.currentLang])
                        this.subCategoryBody =
                            item.body[this.translate.currentLang];
                    else this.subCategoryBody = '';
                    this.sections = item.sections;
                    if (this.sections)
                        this.selectedSectionId = this.sections[0].id;
                }

                this.showQRCode = false;
                setTimeout(() => {
                    this.showQRCode = true;
                }, 10);

                break;

            default:
                break;
        }

        let targetOne = document.getElementById('section-scroll');
        if (targetOne) targetOne.scrollTop = 0;

        let targetTwo = document.getElementById('list-scroll');
        if (targetTwo) targetTwo.scrollTop = 0;
    }

    selectSection(item) {
        this.googleAnalyticService.onStaticInformationSectionExpansion(
            this.categoryInfo.storeName,
            item
        );
        this.selectedSectionId = item.id;
        this.scrollToTop();
    }

    scrollToTop() {
        if (this.selectedSectionId || this.selectedId) {
            setTimeout(() => {
                let str: string = 'item_' + this.selectedSectionId;
                let element = document.getElementById(str);
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 500);

            setTimeout(() => {
                let str: string = 'subcategory_' + this.selectedId;
                let element = document.getElementById(str);
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 1000);
        }
    }

    close() {
        this.store.dispatch(toggleStaticInfoModal({ shouldOpen: false }));

        this.store.dispatch(
            toggleSubSideMenu({
                shouldOpen: false,
                selectedCategory: '',
                categoryInfo: {},
            })
        );
    }
}
