/* src/app/components/lax-tv/lax-tv.component.scss */
.lax-tv-container {
  min-width: 1100px;
  min-height: 600px;
  display: flex;
  height: 100%;
  width: 100%;
}
.lax-tv-container .lax-tv-side-menu {
  background: #e8e8e8;
  flex-direction: column;
  min-width: 480px;
  width: 25%;
  display: flex;
}
.lax-tv-container .lax-tv-side-menu .side-menu-header {
  align-items: center;
  display: flex;
  flex: 0.12;
  background: black;
}
.lax-tv-container .lax-tv-side-menu .side-menu-header .icon-container {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lax-tv-container .lax-tv-side-menu .side-menu-header .icon-container .arrow-icon {
  color: white;
}
.lax-tv-container .lax-tv-side-menu .side-menu-header .icon-container .tv-icon {
  color: white;
  padding-bottom: 12%;
}
.lax-tv-container .lax-tv-side-menu .side-menu-header .text-container {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}
.lax-tv-container .lax-tv-side-menu .side-menu-header .text-container .text {
  color: white;
  padding-left: 5%;
}
.lax-tv-container .lax-tv-side-menu .side-menu-listing {
  overflow: scroll;
  flex: 1;
}
.lax-tv-container .lax-tv-side-menu .side-menu-listing .category-name {
  align-items: center;
  display: flex;
  height: 7%;
}
.lax-tv-container .lax-tv-side-menu .side-menu-listing .category-name span {
  padding-left: 16%;
  color: black;
}
.lax-tv-container .lax-tv-content {
  flex-direction: column;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  width: 100%;
}
.lax-tv-container .lax-tv-content .category-content {
  scrollbar-width: none;
  flex-direction: column;
  min-height: 350px;
  padding-top: 1em;
  display: flex;
}
.lax-tv-container .lax-tv-content .category-content .title {
  align-items: center;
  padding-left: 1.6%;
  display: flex;
  flex: 0.2;
}
.lax-tv-container .lax-tv-content .category-content .title span {
  text-transform: uppercase;
  font-size: 1.7em;
  color: #00a6ce;
  padding-bottom: 1em;
}
.lax-tv-container .lax-tv-content .category-content .videos-horizontal-list {
  scrollbar-width: none;
  align-items: center;
  flex-wrap: nowrap;
  display: flex;
  flex: 1;
}
.lax-tv-container .lax-tv-content .category-content .videos-horizontal-list .video-info-container {
  height: 300px;
  min-width: 400px;
  max-width: 400px;
  cursor: pointer;
}
.lax-tv-container .lax-tv-content .category-content .videos-horizontal-list .video-info-container .container {
  background-size: cover !important;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.lax-tv-container .lax-tv-content .category-content .videos-horizontal-list .video-info-container .video-title {
  padding-top: 5%;
}
.lax-tv-container .lax-tv-content .category-content .videos-horizontal-list .video-info-container .video-title span {
  color: black;
}
.lax-tv-container .lax-tv-content .category-container {
  flex-direction: column;
  overflow: hidden;
  display: flex;
  height: 100vw;
  width: 80vw;
}
.lax-tv-container .lax-tv-content .category-container .title {
  align-items: center;
  min-height: 3.7em;
  padding-left: 1.2%;
  display: flex;
}
.lax-tv-container .lax-tv-content .category-container .title span {
  text-transform: uppercase;
  font-size: 1.7em;
  color: #00a6ce;
}
.lax-tv-container .lax-tv-content .category-container .video-listing {
  overflow-y: auto;
  margin: 1.7%;
  width: 73.5%;
}
.lax-tv-container .lax-tv-content .category-container .video-listing .video-info-container {
  height: 300px;
  min-width: 365px;
  max-width: 365px;
  cursor: pointer;
}
.lax-tv-container .lax-tv-content .category-container .video-listing .video-info-container .container {
  background-size: cover !important;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.lax-tv-container .lax-tv-content .category-container .video-listing .video-info-container .video-title {
  padding-top: 5%;
}
.lax-tv-container .lax-tv-content .category-container .video-listing .video-info-container .video-title span {
  color: black;
}
.active-category {
  background: #00a6ce;
}
.active-category span {
  color: #00a6ce !important;
}
.selected-video {
  background: white;
  height: 100%;
}
.selected-video .video-title-header {
  background: black;
  align-items: center;
  min-height: 4em;
  display: flex;
  height: 9%;
  flex: 0.1;
}
.selected-video .video-title-header .icon-container {
  width: 4%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected-video .video-title-header .icon-container .arrow-icon {
  color: white;
}
.selected-video .video-title-header .text-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected-video .video-title-header .text-container .title {
  width: 100%;
  color: white;
}
.selected-video .video-body {
  display: flex;
  height: 100%;
  flex: 1;
}
.selected-video .video-body .video-container {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 87%;
  margin: 1%;
  flex: 1;
}
.selected-video .video-body .video-container iframe {
  height: 100%;
  width: 96%;
}
.selected-video .video-body .suggestion-container {
  transition: flex 500ms linear;
  flex-direction: column;
  min-width: 265px;
  display: flex;
  height: 87%;
  flex: 0.17;
}
.selected-video .video-body .suggestion-container .suggestion-header {
  align-items: center;
  display: flex;
  flex: 0.1;
}
.selected-video .video-body .suggestion-container .suggestion-header span {
  color: #00a6ce;
}
.selected-video .video-body .suggestion-container .suggestion-listing {
  overflow-y: auto;
  flex: 1;
}
.selected-video .video-body .suggestion-container .suggestion-listing .suggested-video {
  padding-bottom: 1.3em;
}
.selected-video .video-body .suggestion-container .suggestion-listing .suggested-video .suggested-thumbnail {
  background-size: cover !important;
  height: 150px;
  width: 100%;
}
.selected-video .video-body .suggestion-container .suggestion-listing .suggested-video .suggested-thumbnail img {
  height: 180px;
  width: 100%;
}
.selected-video .video-body .suggestion-container .suggestion-listing .suggested-video .suggested-title {
  padding-top: 1em;
}
.selected-video .video-body .suggestion-container .suggestion-listing .suggested-video .suggested-title span {
  color: #00a6ce;
}
.selected-video .video-body .empty-column {
  flex: 0.04;
}
.video-listing {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0px;
  margin-top: 0px;
}
.video-listing .video-info-container {
  flex: 1 0 300px;
  margin-left: 0px;
  margin-top: 0px;
}
@media (min-width: 600px) {
  .video-listing .video-info-container {
    max-width: calc(50% - 0px);
  }
}
@media (min-width: 900px) {
  .video-listing .video-info-container {
    max-width: calc(33.3333333333% - 0px);
  }
}
@media (min-width: 1200px) {
  .video-listing .video-info-container {
    max-width: calc(25% - 0px);
  }
}
@media (min-width: 1200px) {
  .video-listing .video-info-container {
    min-width: calc(25% - 0px);
  }
}
@media screen and (min-width: 1920px) and (min-height: 900px) {
  .suggestion-header span {
    font-size: 1.5em;
  }
  .video-title span {
    font-size: 1.3em;
  }
  .suggested-video .suggested-title span {
    font-size: 1.3em;
  }
  .lax-tv-side-menu .side-menu-header .icon-container .arrow-icon {
    font-size: 2em;
  }
  .lax-tv-side-menu .side-menu-header .icon-container .tv-icon {
    font-size: 3em;
  }
  .lax-tv-side-menu .side-menu-header .text-container .text {
    font-size: 1.4em;
  }
  .video-listing .video-info-container .video-title span {
    font-size: 1.3em;
  }
  .video-title-header .icon-container .arrow-icon {
    font-size: 3em;
  }
  .video-title-header .text-container .title {
    font-size: 2em;
  }
  .lax-tv-content {
    width: calc(67% - 1em);
  }
  .selected-video {
    width: calc(92% - 1em);
  }
}
@media screen and (min-width: 3840px) and (min-height: 2160px) {
  .suggestion-header span {
    font-size: 1.8em !important;
  }
  .video-title span {
    font-size: 2.8em !important;
  }
  .suggested-video .suggested-title span {
    font-size: 3em !important;
  }
  .lax-tv-side-menu .side-menu-header .icon-container .arrow-icon {
    font-size: 5em !important;
  }
  .lax-tv-side-menu .side-menu-header .icon-container .tv-icon {
    font-size: 7em !important;
  }
  .lax-tv-side-menu .side-menu-header .text-container .text {
    font-size: 3em !important;
  }
  .category-name span {
    font-size: 2.5em !important;
  }
  .video-listing .video-info-container .video-title span {
    font-size: 1.5em !important;
  }
  .video-title-header .icon-container .arrow-icon {
    font-size: 4em !important;
  }
  .video-title-header .text-container .title {
    font-size: 3em !important;
  }
  .video-title-header .title {
    font-size: 3em !important;
  }
  .lax-tv-content {
    width: calc(67% - 1em);
  }
  .lax-tv-content .category-content {
    padding-top: 1.5em !important;
  }
  .lax-tv-content .category-content .title {
    padding-left: 1.6%;
  }
  .lax-tv-content .category-content .title span {
    font-size: 3.4em !important;
    padding-bottom: 1em;
  }
  .lax-tv-content .category-content .videos-horizontal-list {
    flex: 1;
  }
  .lax-tv-content .category-content .videos-horizontal-list .video-info-container {
    height: 600px !important;
    min-width: 800px !important;
    max-width: 800px !important;
  }
  .lax-tv-content .category-container .title {
    min-height: 7.4em !important;
    padding-left: 1.2% !important;
  }
  .lax-tv-content .category-container .title span {
    font-size: 3.4em !important;
  }
  .lax-tv-content .category-container .video-listing {
    margin: 1.7%;
    width: 73.5%;
  }
  .lax-tv-content .category-container .video-listing .video-info-container {
    height: 600px !important;
    min-width: 730px !important;
    max-width: 730px !important;
    cursor: pointer;
  }
  .lax-tv-content .category-container .video-listing .video-info-container .video-title {
    padding-top: 5%;
  }
  .lax-tv-content .category-container .video-listing .video-info-container .video-title span {
    font-size: 2.8em !important;
  }
}
@media screen and (max-width: 1920px) {
  .lax-tv-content {
    width: calc(100% - 480px - 8% - 1em);
  }
  .selected-video {
    width: calc(92% - 1em);
  }
  .lax-tv-side-menu .side-menu-header .icon-container .arrow-icon {
    font-size: 2em;
  }
  .lax-tv-side-menu .side-menu-header .icon-container .tv-icon {
    font-size: 3em;
  }
  .lax-tv-side-menu .side-menu-header .text-container .text {
    font-size: 1.4em;
  }
}
/*# sourceMappingURL=lax-tv.component-GH2TY2XE.css.map */
