import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Injectable, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getContentSuccess, types } from '@app/store/actions';
import { MappingService } from './mapping.service';

@Injectable({
    providedIn: 'root',
})
export class RealTimeDatabaseSyncService {
    database: AngularFireDatabase = inject(AngularFireDatabase);
    store: Store<any> = inject(Store);
    mapping: MappingService = inject(MappingService);

    subscriptions = {};

    cmsRTDBSync(): void {
        if (this.subscriptions['cms']) {
            return;
        }
        this.subscriptions['cms'] = this.database
            .object('cms')
            .valueChanges()
            .subscribe({
                next: (data: { [key: string]: any }) => {
                    this.store.dispatch(
                        getContentSuccess({
                            turnServers: data.turnServers,
                            hotels: Array.isArray(data.hotels)
                                ? this.mapping.mapHotelsAndAttractions(
                                      data.hotels,
                                      'hotel'
                                  )
                                : [],
                            attractions: Array.isArray(data.attractions)
                                ? this.mapping.mapHotelsAndAttractions(
                                      data.attractions,
                                      'attraction'
                                  )
                                : [],
                            overridePois: Array.isArray(data.pois)
                                ? data.pois
                                : [],
                            staticInfo: Array.isArray(data.pages)
                                ? data.pages
                                : [],
                            cmsVideos: this.mapping.mapVideos(data.videos),
                            emergencyBroadcast: data.emergencyBroadcast,
                            surveyConfig: data.surveyConfig,
                            kioskNames: data.kioskNames
                                ? data.kioskNames
                                : {
                                      'MSC-01-C1-IK1': {
                                          computerName: 'MSC-01-C1-IK1',
                                          poiID: '6490e20a1b7d4018524f9009',
                                          kioskLocation: 'L4, Terminal B',
                                      },
                                  },
                            idleState: data.idleState,
                        })
                    );
                },
                error: (error) => {
                    console.log(error);
                },
            });
    }

    clearSubscriptions(): void {
        Object.values(this.subscriptions)?.forEach((subscription) => {
            if (subscription instanceof Subscription) {
                subscription?.unsubscribe();
            }
        });
        this.subscriptions = {};
    }
}
