import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ButtonIcon } from '../../models/ui/button.icon.model';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf, NgStyle, UpperCasePipe } from '@angular/common';

@Component({
    selector: 'app-button-icon',
    templateUrl: './button-icon.component.html',
    styleUrls: ['./button-icon.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, NgStyle, UpperCasePipe, TranslateModule],
})
export class ButtonIconComponent implements OnInit {
    @Input('backgroundColor') backgroundColor: string;
    @Output() onClick = new EventEmitter<string>();
    @Input('isHorizontal') isHorizontal: boolean;
    @Input('buttonInfo') buttonInfo;
    @Input('isSelected') isSelected: boolean;
    @Input('hasRipple') hasRipple: boolean;
    @Input('textColor') textColor: string;
    @Input('iconColor') iconColor: string;
    @Input('iconNames') iconName: Object;
    @Input('iconSize') iconSize: string;
    constructor() {}

    ngOnInit() {}

    onButtonClick(category: string) {
        this.onClick.emit(category);
    }
}
