import { GoogleAnalyticsService } from "@app/services/google-analytics.service";
import { WebRTCService } from "@app/services/web-rtc.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Component, OnInit } from "@angular/core";
import { showDialog } from "@app/store/actions";
import { Store } from "@ngrx/store";

@Component({
    selector: "app-snack-bar",
    templateUrl: "./snack-bar.component.html",
    styleUrls: ["./snack-bar.component.scss"],
    standalone: true,
    imports: [TranslateModule],
})
export class SnackBarComponent implements OnInit {
  constructor(
    private googleAnalyticService: GoogleAnalyticsService,
    private webRTCService: WebRTCService,
    private translate: TranslateService,
    private store: Store<any>
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.webRTCService.showErrorMessage = false;
    }, 5000);
  }

  onRetryButtonPress() {
    if (
      this.webRTCService.busyAgentList.length > 0 &&
      !this.webRTCService.isAgentActive
    )
      this.store.dispatch(
        showDialog({
          showDialog: true,
          title: this.translate.instant("AGENT_BUSY"),
          subTitle: this.translate.instant("TRY_AGAIN"),
          showCloudFunction: false,
        })
      );
    else {
      this.webRTCService.didClientInitiateCall.emit(true);
      this.googleAnalyticService.onCallRing();
      this.webRTCService.startCallTimer();
    }
  }
}
