/* src/app/pages/home/home.component.scss */
.home-container {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}
.home-container #traffic-map-container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
}
.home-container #map-container {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: -2;
  opacity: 0;
}
.home-container .show-map {
  z-index: 2 !important;
  opacity: 1 !important;
}
/*# sourceMappingURL=home.component-6APMXWPN.css.map */
