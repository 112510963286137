import { Component, OnInit, Input } from "@angular/core";
@Component({
    selector: "app-info-item",
    templateUrl: "./info-item.component.html",
    styleUrls: ["./info-item.component.scss"],
    standalone: true
})
export class InfoItemComponent implements OnInit {
  @Input("subtitle") subtitle;
  @Input("title") title;
  @Input("icon") icon;
  constructor() {}

  ngOnInit() {}

  // checkSubtitleType(subtitle) {
  //   return Array.isArray(subtitle);
  // }
}
