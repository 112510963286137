import { getFlightLaxSuccess } from '../actions/flight.actions';
import { FlightState } from '../state/flights.state';
import { Action, createReducer, on } from '@ngrx/store';

const initialState: FlightState = {
    arrivalsFlights: [],
    departureFlights: [],
};

const flightReducer = createReducer(
    initialState,
    on(getFlightLaxSuccess, (state, action) => {
        let _f = { ...state };
        _f.arrivalsFlights = action.payload.arrivalsFlights;
        _f.departureFlights = action.payload.departureFlights;
        return _f;
    })
);

export default function reducer(state: FlightState, action: Action) {
    return flightReducer(state, action);
}
