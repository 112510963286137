/* src/app/components/selfie-modal/selfie-modal.component.scss */
.selfie-container {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(25, 25, 25, 0.7);
}
.selfie-inside-container {
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 70%;
  min-width: 650px;
  background: white;
  font-weight: bold;
  position: absolute;
  top: 14.5%;
  left: 23%;
  font-size: 2em;
  transition: font-size 500ms linear;
}
.selfie-inside-container .header-container {
  flex: 0.14;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  min-height: 3em;
}
.selfie-inside-container .header-container .empty-div {
  flex: 0.1;
}
.selfie-inside-container .header-container .title-container {
  flex: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  transition: font-size 500ms linear;
}
.selfie-inside-container .header-container .close-button-container {
  cursor: pointer;
  flex: 0.1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.selfie-inside-container .header-container .close-button-container .close-button {
  align-items: center;
  border-radius: 50%;
  display: flex;
  padding: 2%;
}
.selfie-inside-container .header-container .close-button-container .close-button .material-icons {
  font-size: 1.2em;
  transition: font-size 500ms linear;
}
.selfie-inside-container .webcam-phase-container {
  flex: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}
.selfie-inside-container .webcam-phase-container .flash {
  opacity: 1;
  -webkit-animation: flash 1s;
  animation: flash 1s;
}
@-webkit-keyframes flash {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.selfie-inside-container .webcam-phase-container .image-container {
  overflow: hidden;
}
.selfie-inside-container .webcam-phase-container .image-container .bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.selfie-inside-container .webcam-phase-container .footer-content-container {
  flex-direction: column;
  display: flex;
  flex: 1;
  height: 100%;
}
.selfie-inside-container .webcam-phase-container .footer-content-container .selfie-buttons-container {
  flex: 0.2;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.selfie-inside-container .no-webcam-container {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1.3;
  height: 100%;
  background: lightgray;
  width: 640px;
}
.selfie-inside-container .no-webcam-container .loader-container {
  background: transparent !important;
}
.selfie-inside-container .no-webcam-container .loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4em;
  height: 4em;
  z-index: 5;
}
.selfie-inside-container .no-webcam-container .loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px dashed;
  border-color: black;
  animation: loader 1.2s linear infinite;
}
.selfie-inside-container .share-content-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.selfie-inside-container .share-content-container .share-content-info {
  position: relative;
  display: flex;
  flex: 1;
}
.selfie-inside-container .share-content-container .share-content-info .left-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 0.5;
  height: 100%;
}
.selfie-inside-container .share-content-container .share-content-info .left-container .qrcode-container {
  flex: 0.4;
  border: solid 10px black;
  border-radius: 10px;
  margin-bottom: 0.3em;
}
.selfie-inside-container .share-content-container .share-content-info .left-container .scan-me-container {
  flex-direction: column;
  overflow: hidden;
  display: flex;
}
.selfie-inside-container .share-content-container .share-content-info .left-container .scan-me-container .title-container {
  text-align: center;
}
.selfie-inside-container .share-content-container .share-content-info .left-container .scan-me-container .title-container span {
  color: black;
  font-size: 1.15em;
  font-weight: 400;
}
.selfie-inside-container .share-content-container .share-content-info .left-container .scan-me-container .sub-title-container {
  display: flex;
  align-items: center;
}
.selfie-inside-container .share-content-container .share-content-info .left-container .scan-me-container .sub-title-container span {
  color: black;
  font-size: 0.4em;
}
.selfie-inside-container .share-content-container .share-content-info .right-container {
  display: flex;
  flex-direction: row;
  flex: 0.5;
  border-left: 1px solid black;
}
.selfie-inside-container .share-content-container .share-content-info .right-container .right-content-container {
  display: flex;
  flex: 0.9;
  flex-direction: column;
}
.selfie-inside-container .share-content-container .share-content-info .right-container .right-content-container .text {
  font-size: 1.15em;
  font-weight: 400;
}
.selfie-inside-container .share-content-container .share-content-info .right-container .right-content-container #url-text {
  font-weight: 700;
  font-size: 1.5em;
  padding-top: 0.4em;
  padding-bottom: 1em;
}
.selfie-inside-container .share-content-container .share-content-info .right-container .right-content-container .code-container {
  height: 2.5em;
  padding-top: 0.3em;
}
.selfie-inside-container .share-content-container .share-content-info .right-container .right-content-container .unmask-button-container {
  width: 15em;
  margin-left: -0.75em;
}
.selfie-inside-container .share-content-container .or-circle-container {
  position: absolute;
  top: 50%;
  left: 47%;
  border-radius: 50%;
  border: 1px solid black;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 2em;
  height: 2em;
}
.selfie-inside-container .share-content-container .or-circle-container span {
  color: black;
  font-size: 1em;
}
.selfie-inside-container .footer-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 0.2;
}
.selfie-inside-container .footer-container .capture-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
}
.selfie-inside-container .footer-container .capture-button-container .selfie-timer-container {
  color: black;
  font-size: 1.5em;
  transition: font-size 500ms linear;
  padding-bottom: 0.4em;
}
.selfie-inside-container .footer-container .capture-button-container .capture-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(209, 204, 204);
  border-radius: 50%;
  height: 90px;
  width: 90px;
  cursor: pointer;
}
.selfie-inside-container .footer-container .capture-button-container .capture-button .capture-inner-circle {
  background-color: grey;
  border-radius: 50%;
  height: 60%;
  width: 60%;
}
.image-selfie {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
::ng-deep .webcam-wrapper video {
  transform: unset !important;
  width: 100% !important;
  height: 100% !important;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .selfie-inside-container .content-container .image-container {
    flex: 1.45 !important;
  }
}
@media screen and (min-width: 3840px) and (min-height: 2160px) {
  .selfie-inside-container {
    font-size: 4em !important;
    transition: font-size 500ms linear;
  }
  .selfie-inside-container .header-container {
    min-height: 3.5em !important;
  }
  .selfie-inside-container .header-container .title-container {
    font-size: 1.3em !important;
    transition: font-size 500ms linear;
  }
  .selfie-inside-container .header-container .close-button-container .material-icons {
    font-size: 1.4em !important;
    transition: font-size 500ms linear;
  }
  .selfie-inside-container .webcam-phase-container .image-container {
    flex: 5 !important;
    width: 1725px !important;
  }
  .selfie-inside-container .no-webcam-container {
    width: 1725px !important;
  }
  .selfie-inside-container .no-webcam-container .loader {
    width: 2em !important;
    height: 2em !important;
  }
  .selfie-inside-container .no-webcam-container .loader:after {
    width: 100px !important;
    height: 100px !important;
    margin: 8px;
  }
  .selfie-inside-container .share-content-container .share-content-info .left-container .qrcode-container {
    flex: 0.4;
    border: solid 15px black !important;
    border-radius: 10px;
    margin-bottom: 0.3em;
  }
  .selfie-inside-container .share-content-container .share-content-info .left-container .scan-me-container {
    display: flex;
    flex: 0.25 !important;
  }
  .selfie-inside-container .share-content-container .share-content-info .left-container .scan-me-container .title-container span {
    font-size: 1.15em;
    font-weight: 400;
  }
  .selfie-inside-container .share-content-container .share-content-info .right-container {
    border-left: 1px solid black;
  }
  .selfie-inside-container .share-content-container .share-content-info .right-container .right-content-container .unmask-button-container {
    margin-left: -0.7em !important;
  }
  .selfie-inside-container .share-content-container .or-circle-container span {
    font-size: 1em;
  }
  .selfie-inside-container .footer-container .capture-button-container {
    margin-bottom: 2% !important;
  }
  .selfie-inside-container .footer-container .capture-button-container .selfie-timer-container {
    font-size: 1.8em !important;
    transition: font-size 500ms linear;
  }
  .selfie-inside-container .footer-container .capture-button-container .capture-button {
    height: 3em !important;
    width: 3em !important;
  }
  .selfie-inside-container .footer-container .capture-button-container .capture-button .capture-inner-circle {
    height: 60%;
    width: 60%;
  }
  ::ng-deep .webcam-wrapper video {
    width: 1900px !important;
    height: 1300px !important;
  }
}
.selfie-image {
  width: 38rem;
  height: fit-content;
}
@media screen and (min-width: 3840px) and (min-height: 2160px) {
  .selfie-image {
    width: 88rem !important;
    height: fit-content;
  }
}
/*# sourceMappingURL=selfie-modal.component-TBRHCDQZ.css.map */
