import { Component, OnInit, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TimeConversionService } from '@app/services/time-conversion.service';

@Component({
    selector: 'app-copy-right',
    templateUrl: './copy-right.component.html',
    styleUrls: ['./copy-right.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class CopyRightComponent implements OnInit {
    timeService = inject(TimeConversionService);
    currentYear: any =
        this.timeService.formatDate('YYYY') === '2019'
            ? '2019'
            : `2019${this.timeService.formatDate('-YYYY')}`;

    constructor() {}

    ngOnInit() {}
}
