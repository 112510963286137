import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgStyle, NgClass, NgIf } from "@angular/common";

@Component({
    selector: "app-horizontal-button-icon",
    templateUrl: "./horizontal-button-icon.component.html",
    styleUrls: ["./horizontal-button-icon.component.scss"],
    standalone: true,
    imports: [NgStyle, NgClass, NgIf]
})
export class HorizontalButtonIconComponent implements OnInit {
  @Input("buttonInfo") buttonInfo: any;
  @Output() onClick = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {}

  onButtonClick(buttonName: string) {
    this.onClick.emit(buttonName);
  }
}
