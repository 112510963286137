/* src/app/components/mask-code/mask-code.component.scss */
.mask-code-container {
  display: flex;
  flex: 1;
}
.mask-code-container .code-element {
  padding-right: 0.2em;
  font-weight: 700;
  font-size: 2.8em;
}
/*# sourceMappingURL=mask-code.component-HL6ZQ3OR.css.map */
