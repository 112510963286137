import { AppConfig } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { selectAgent } from '../store/selectors/index';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    userToken: string = '';
    constructor(
        public authService: AuthService,
        public router: Router,
        private store: Store<any>
    ) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const agentState$ = this.store.select(selectAgent);
        agentState$.subscribe((agent) => {
            if (AppConfig.isAgent)
                this.authService.getCurrentUser().then((user) => {
                    if (user) {
                        return true;
                    } else {
                        this.authService.signOut();
                        return false;
                    }
                });
        });

        return true;
    }
}
