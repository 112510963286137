import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectPOI, selectDataBus } from '@app/store/selectors';
import { toggleVideoPlayerModal } from '@app/store/actions';
import { AppConfig } from 'environments/environment';
import { SendToClientComponent } from '../send-to-client/send-to-client.component';
import { NgIf } from '@angular/common';
import { VideoPlayerComponent } from '../video-player/video-player.component';

@Component({
    selector: 'app-video-player-modal',
    templateUrl: './video-player-modal.component.html',
    styleUrls: ['./video-player-modal.component.scss'],
    standalone: true,
    imports: [VideoPlayerComponent, NgIf, SendToClientComponent],
})
export class VideoPlayerModalComponent implements OnInit {
    isAgent: boolean = AppConfig.isAgent;
    playlist: Array<string> = [];
    poiSubscription: any;
    categoryInfo: any;
    videoInfo;
    videoUrl;
    videoType;
    mimeType;
    constructor(private store: Store<any>) {}

    ngOnInit() {
        const poiState$ = this.store.select(selectPOI);

        this.poiSubscription = poiState$.subscribe((poiItem: any) => {
            this.videoInfo = poiItem.poiContent;
            this.categoryInfo = poiItem.categoryInfo;

            if (
                poiItem.poiContent.videoId ||
                poiItem.poiContent.videoType === 'custom'
            ) {
                this.videoUrl = poiItem.poiContent.videoURL;
                this.mimeType = poiItem.poiContent.mimeType;
                this.videoType = poiItem.poiContent.videoType
                    .toString()
                    .toLowerCase();

                this.playlist = [];
                this.playlist.push(poiItem.poiContent.videoId);
            }
        });
    }

    ngOnDestroy() {
        this.poiSubscription.unsubscribe();
    }

    close() {
        setTimeout(() => {
            this.store.dispatch(toggleVideoPlayerModal({ shouldOpen: false }));
        }, 200);
    }
}
