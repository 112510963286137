import { createAction, props } from '@ngrx/store';
import { types } from './types';

export const getSetting = createAction(types.GET_SETTING);

export const getSettingSuccess = createAction(
    types.GET_SETTING_SUCCESS,
    props<{ payload: any }>()
);

export const getSettingFailed = createAction(types.GET_SETTING_FAILED);

export const setComputerName = createAction(
    types.SET_COMPUTER_NAME,
    props<{ computerName: any }>()
);

export const getWeatherInformation = createAction(types.GET_WEATHER);

export const getWeatherInformationSuccess = createAction(
    types.GET_WEATHER_SUCCESS,
    props<{ payload: any }>()
);

export const getWeatherInformationFailed = createAction(
    types.GET_WEATHER_FAILED
);

export const getPOI = createAction(types.GET_POI);

export const getPOIFailed = createAction(types.GET_POI_FAILED);

export const getContentSuccess = createAction(
    types.GET_CONTENT_SUCCESS,
    props<{
        hotels: any;
        attractions: any;
        overridePois: any;
        staticInfo: any;
        cmsVideos: any;
        version?: string;
        lastUpdated?: string;
        emergencyBroadcast: any;
        surveyConfig: any;
        kioskNames: any;
        turnServers: Array<string>;
        idleState: Array<any>;
    }>()
);
export const getContentFailed = createAction(types.GET_CONTENT_FAILED);

export const saveSelfie = createAction(
    types.GET_SELFIE_ATTEMPT,
    props<{ data: string }>()
);

export const saveSelfieSuccess = createAction(
    types.GET_SELFIE_SUCCESS,
    props<{
        code: any;
    }>()
);
export const saveSelfieFailed = createAction(types.GET_SELFIE_FAILED);

export const setLocusCategories = createAction(
    types.SETTING_LOCUS_LAB_CATEGORIES,
    props<{ payload: any }>()
);

export const setCheckIn = createAction(
    types.SET_CHECK_IN,
    props<{ payload: any }>()
);
