<div class="left-side-menu">
    <div class="airport-info-container">
        <div class="image-container" (dblclick)="onLogoDoubleClick()">
            <img
                [src]="'assets/branding/images/airportLogo.svg'"
                class="airport-logo"
            />
        </div>

        <!--Time and Weather-->
        <div class="local-information">
            <!--Time-->
            <app-time
                [timeValueColor]="'white'"
                [dateValueColor]="'white'"
            ></app-time>
            <!--Weather-->
            <app-weather
                [info]="weatherInfo"
                [tempTextColor]="'white'"
                *ngIf="weatherInfo.value !== ''"
            ></app-weather>
        </div>
    </div>

    <div class="menu-container">
        <div class="menu">
            <div class="list">
                <div
                    class="menu-button"
                    *ngFor="
                        let item of sideMenuButtons;
                        trackBy: utility.trackByFunction
                    "
                    [ngClass]="{
                        'hide-menu-button':
                            item.query == 'ADA' && hideADACategory,
                        'selected-category': forceSelect === item.storeName,
                        'hide-menu-button-dupe':
                            item.query == 'INFO_TIPS' && hideINFOCategory
                    }"
                    (click)="onMenuButtonClick(item)"
                >
                    <div class="icon-container">
                        <i
                            *ngIf="
                                item.label != 'TRAFFIC' &&
                                item.label != 'TOURISM' &&
                                item.label != 'HOTELS'
                            "
                            class="material-icons icon"
                        >
                            {{ item.icon }}
                        </i>

                        <i
                            *ngIf="
                                item.label == 'HOTELS' &&
                                showLoader !== item.label
                            "
                            class="material-icons icon"
                        >
                            {{ item.icon }}
                        </i>
                        <img
                            *ngIf="
                                (item.label == 'TRAFFIC' ||
                                    item.label == 'TOURISM') &&
                                showLoader !== item.label
                            "
                            [src]="item.icon"
                            class="material-icons svg"
                        />

                        <div
                            class="loader"
                            *ngIf="
                                showLoader === item.label &&
                                (item.label == 'TRAFFIC' ||
                                    item.label == 'HOTELS' ||
                                    item.label == 'TOURISM')
                            "
                        ></div>
                    </div>
                    <div class="text-container">
                        <span
                            class="text"
                            [ngStyle]="{
                                'padding-left':
                                    translate
                                        .instant(item.label)
                                        .toUpperCase() == 'INFO & CONSEJOS'
                                        ? 15 + '%'
                                        : 0 + '%',
                                'padding-right':
                                    translate
                                        .instant(item.label)
                                        .toUpperCase() == 'INFO & CONSEJOS'
                                        ? 4 + '%'
                                        : 0 + '%'
                            }"
                            >{{ item.label | translate | uppercase }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
