<div class="weather-container" *ngIf="info.shouldShow">
  <div class="icon-container">
    <img [src]="info.imageUrl" alt="Weather Icon" class="icon" />
  </div>
  <div class="value-container">
    <span id="weather-value" [ngStyle]="{ color: tempTextColor }"
      >{{ tempValue }}°{{ tempUnit }}</span
    >
  </div>
</div>
