import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { selectUI, selectPOI, selectDataBus } from '../../store/selectors';
import { PointOfInterest } from '../../models/point.of.interest.model';
import { animationConfig } from '@app/angular-animation/animation';
import { UtilityService } from '../../services/utility.service';
import { WebRTCService } from '@app/services/web-rtc.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MapService } from '@app/services/map.service';
import { AppConfig } from 'environments/environment';
import { Store } from '@ngrx/store';
import {
    AfterViewInit,
    EventEmitter,
    HostListener,
    SimpleChange,
    Component,
    OnInit,
    Input,
    Output,
} from '@angular/core';
import { InfoItemComponent } from '../info-item/info-item.component';
import { SendToClientComponent } from '../send-to-client/send-to-client.component';
import { FLightItemComponent } from '../flight-item/flight-item.component';
import { BackPoiItemComponent } from '../back-poi-item/back-poi-item.component';
import { NgStyle, NgClass, NgIf, NgFor } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
    selector: 'side-menu-info',
    templateUrl: './side-menu-info.component.html',
    styleUrls: ['./side-menu-info.component.scss'],
    animations: [animationConfig.collapseImage],
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        NgIf,
        BackPoiItemComponent,
        FLightItemComponent,
        SendToClientComponent,
        InfoItemComponent,
        NgFor,
        TranslateModule,
        MatDividerModule,
    ],
})
export class SideMenuInfoComponent implements OnInit {
    @Input('sideMenuInfo') sideInfo: PointOfInterest;
    @Output('onClose') onClose = new EventEmitter();

    ratingColor: string = 'rgb(224, 224, 224)';
    isAgent: boolean = AppConfig.isAgent;
    normalizedInstructions: any[] = [];
    showEmptyImageDiv: boolean = false;
    isImageCollapsed: boolean = false;
    isFlights: boolean = true;
    selectedCategory: any;
    paddingBottom: string;
    animateInfo: boolean;
    searchError: boolean;
    poiSubscription: any;
    uiSubscription: any;
    dataBusSubscription;
    isHotel: boolean;
    categoryInfo: any;
    segmentIndex = 0;

    autoPlayIcon = 'play_arrow';
    autoPlayText = 'PLAY';

    disablePreviousButton: boolean = false;
    disableNextButton: boolean = true;
    mapStepPlayInterval;
    gateInfo;
    routeInitialized: boolean;
    showAccessibleRoute: boolean;
    filterType: string;

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        public webRTCService: WebRTCService,
        public translate: TranslateService,
        private utility: UtilityService,
        private mapService: MapService,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.normalizedInstructions = this.sideInfo.instructions;

        // if (
        //   this.sideInfo.accessibleInstructions?.length ===
        //   this.sideInfo.instructions?.length
        // ) {
        //   this.showAccessibleRoute = false;
        // } else {
        //   this.showAccessibleRoute = true;
        // }

        if (this.sideInfo.accessibleInstructions) {
            this.showAccessibleRoute = true;
        }

        this.sideInfo.details &&
        this.sideInfo.details[this.translate.currentLang].fullDescription &&
        this.sideInfo.details[this.translate.currentLang].shortDescription
            ? this.selectMainFilter('INFO')
            : this.selectMainFilter('ROUTE');

        const dataBusState$ = this.store.select(selectDataBus);
        const poiState$ = this.store.select(selectPOI);
        const uiState$ = this.store.select(selectUI);

        this.uiSubscription = uiState$.subscribe((uiItem) => {
            this.animateInfo = uiItem.windows.animateSideInfo;
        });

        this.dataBusSubscription = dataBusState$.subscribe(
            (dataBusItem: any) => {
                this.gateInfo = dataBusItem.pois.Gates.find(
                    (node) => node.name == this.sideInfo['gate']
                );
            }
        );

        this.poiSubscription = poiState$.subscribe((poiItem) => {
            this.selectedCategory = poiItem.selectedCategory;
            this.categoryInfo = poiItem.categoryInfo;

            if (
                this.selectedCategory == 'Hotels' ||
                this.selectedCategory == 'tourism'
            ) {
                this.selectMainFilter('INFO');
                this.isFlights = false;
                this.isHotel = true;
            } else {
                if (this.selectedCategory == 'Flights') {
                    this.selectMainFilter('ROUTE');
                    this.showAccessibleRoute = true;
                    this.isFlights = true;
                    this.isHotel = false;
                    this.normalizedInstructions = [];
                    this.normalizedInstructions = poiItem.poiInstructions;
                    if (this.normalizedInstructions)
                        this.segmentIndex =
                            this.normalizedInstructions.length - 1;
                } else {
                    this.isHotel = false;
                    this.isFlights = false;
                }
            }
        });

        if (this.normalizedInstructions)
            this.segmentIndex = this.normalizedInstructions.length - 1;
    }

    //To refresh fullDescription and shortDescription values
    ngOnChanges(change: SimpleChange) {
        if (change.currentValue) this.sideInfo = change.currentValue;
        this.normalizedInstructions = this.sideInfo.instructions;
    }

    ngOnDestroy() {
        this.utility.isPoiInfoPlayingSteps.emit(false);
        clearInterval(this.mapStepPlayInterval);
        this.dataBusSubscription.unsubscribe();
        this.poiSubscription.unsubscribe();
        this.uiSubscription.unsubscribe();
        this.selectMainFilter('INFO');
        this.mapService.resetMap();
    }

    selectMainFilter(mainFilterValue: string) {
        this.filterType = mainFilterValue;

        if (this.filterType !== 'INFO') {
            setTimeout(() => {
                this.smoothScrollOnDirectionChange();
            }, 400);
        }

        if (this.filterType === 'ROUTE') {
            this.normalizedInstructions = this.sideInfo.instructions;

            if (this.selectedCategory == 'Flights') {
                this.normalizedInstructions = this.gateInfo?.instructions;
            }
        } else if (this.filterType === 'ACCESSIBLE_ROUTE') {
            this.normalizedInstructions = this.sideInfo.accessibleInstructions;

            if (this.selectedCategory == 'Flights') {
                this.normalizedInstructions =
                    this.gateInfo?.accessibleInstructions;
            }
        }
        if (this.routeInitialized) {
            this.mapService.showNavigationRoute(
                this.selectedCategory == 'Flights'
                    ? this.gateInfo
                    : this.sideInfo,
                false,
                this.filterType === 'ACCESSIBLE_ROUTE'
            );
            this.onCurrentLocationPress();
        }

        this.isImageCollapsed = false;
    }

    playMapSteps(poiData) {
        this.initializeRoute();
        if (this.autoPlayText == 'PAUSE') {
            this.shouldDisableNavigationButton();
            this.autoPlayIcon = 'play_arrow';
            this.autoPlayText = 'PLAY';
            this.utility.isPoiInfoPlayingSteps.emit(false);

            return clearInterval(this.mapStepPlayInterval);
        }

        this.utility.isPoiInfoPlayingSteps.emit(true);

        this.segmentIndex = this.segmentIndex + 1;

        if (this.autoPlayText == 'REPLAY' || this.autoPlayText == 'PLAY') {
            if (this.autoPlayText == 'PLAY')
                this.googleAnalyticService.onPlayButtonClick(
                    this.categoryInfo.storeName,
                    poiData
                );

            this.segmentIndex = 0;
            this.smoothScrollOnDirectionChange();
        }

        this.shouldDisableNavigationButton();

        if (this.segmentIndex == this.normalizedInstructions.length) {
            this.autoPlayIcon = 'replay';
            this.autoPlayText = 'REPLAY';
            this.segmentIndex = this.normalizedInstructions.length - 1;
        } else {
            this.autoPlayIcon = 'pause';
            this.autoPlayText = 'PAUSE';
            this.mapService.showMapSteps(
                this.selectedCategory == 'Flights'
                    ? this.gateInfo
                    : this.sideInfo,
                this.segmentIndex,
                this.filterType === 'ACCESSIBLE_ROUTE'
            );
        }

        this.mapStepPlayInterval = setInterval(() => {
            this.segmentIndex = this.segmentIndex + 1;
            this.shouldDisableNavigationButton();

            if (this.segmentIndex == this.normalizedInstructions.length) {
                clearInterval(this.mapStepPlayInterval);
                this.utility.isPoiInfoPlayingSteps.emit(false);

                this.autoPlayIcon = 'replay';
                this.autoPlayText = 'REPLAY';
                this.segmentIndex = this.normalizedInstructions.length - 1;
            } else {
                this.autoPlayIcon = 'pause';
                this.autoPlayText = 'PAUSE';
                this.smoothScrollOnDirectionChange();
                this.mapService.showMapSteps(
                    this.selectedCategory == 'Flights'
                        ? this.gateInfo
                        : this.sideInfo,
                    this.segmentIndex,
                    this.filterType === 'ACCESSIBLE_ROUTE'
                );
            }
        }, 5000);
    }

    onMapStepControlTrigger(isActionNext) {
        this.initializeRoute();
        this.utility.isPoiInfoPlayingSteps.emit(false);

        clearInterval(this.mapStepPlayInterval);
        this.resetAutoPlay();

        if (isActionNext) this.segmentIndex = this.segmentIndex + 1;
        else this.segmentIndex = this.segmentIndex - 1;

        this.shouldDisableNavigationButton();
        this.mapService.showMapSteps(
            this.selectedCategory == 'Flights' ? this.gateInfo : this.sideInfo,
            this.segmentIndex,
            this.filterType === 'ACCESSIBLE_ROUTE'
        );
    }

    onCurrentLocationPress() {
        this.utility.isPoiInfoPlayingSteps.emit(false);

        this.resetAutoPlay();
        clearInterval(this.mapStepPlayInterval);
        this.mapService.goToKioskLocation();
        this.disablePreviousButton = true;
        this.disableNextButton = false;
        this.segmentIndex = 0;
        this.smoothScrollOnDirectionChange();
    }

    onDestinationLocationPress() {
        this.utility.isPoiInfoPlayingSteps.emit(false);
        this.resetAutoPlay();
        this.segmentIndex = this.normalizedInstructions.length - 1;
        clearInterval(this.mapStepPlayInterval);
        this.mapService.goToPoiLocation(
            this.selectedCategory == 'Flights' ? this.gateInfo : this.sideInfo
        );
        //
        this.disablePreviousButton = false;
        this.disableNextButton = true;
        this.smoothScrollOnDirectionChange();
    }

    smoothScrollOnDirectionChange() {
        let target = document.getElementById(`direction-${this.segmentIndex}`);
        if (target)
            target.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
    }

    shouldDisableNavigationButton() {
        if (this.segmentIndex == this.normalizedInstructions.length - 1)
            this.disableNextButton = true;
        else this.disableNextButton = false;

        if (this.segmentIndex < 1) this.disablePreviousButton = true;
        else this.disablePreviousButton = false;
    }

    resetAutoPlay() {
        this.autoPlayIcon = 'play_arrow';
        this.autoPlayText = 'PLAY';
    }

    initializeRoute() {
        if (this.routeInitialized) return;

        this.mapService.showNavigationRoute(
            this.selectedCategory == 'Flights' ? this.gateInfo : this.sideInfo,
            false,
            this.filterType === 'ACCESSIBLE_ROUTE'
        );

        this.routeInitialized = true;
    }

    @HostListener('window:scroll', ['$event'])
    @HostListener('touch')
    collapseImage(event) {
        if (
            event.target.scrollTop != 0 &&
            event.target.offsetHeight + event.target.scrollTop <
                event.target.scrollHeight
        ) {
            this.isImageCollapsed = true;
            this.showEmptyImageDiv = true;
        } else if (event.target.scrollTop == 0) {
            this.isImageCollapsed = false;
            this.showEmptyImageDiv = false;
        }
    }
}
