import { LoginComponent } from '../pages/login/login.component';
import { KioskComponent } from '../pages/kiosk/kiosk.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guard/auth.guard';

export const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'kiosk',
        component: KioskComponent,
        canActivate: [AuthGuard],
    },
    { path: '**', redirectTo: '/login' },
];
