/* src/app/components/back-poi-item/back-poi-item.component.scss */
.back-poi-container {
  background: black;
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
}
.back-poi-container .back-icon-container {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0.15;
}
.back-poi-container .back-icon-container .material-icons {
  color: white;
  font-size: 2.5em;
}
.back-poi-container .back-info-container {
  align-self: center;
  width: 60%;
}
.back-poi-container .back-info-container .poi-title-text {
  transition: font-size 500ms linear;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 1%;
  font-size: 1.1em;
  font-weight: 600;
  overflow: hidden;
  color: white;
  width: 100%;
}
.back-poi-container .back-info-container .poi-subtitle {
  transition: font-size 500ms linear;
  text-transform: capitalize;
  font-size: 1em;
  color: white;
  flex: 1;
}
.back-poi-container .back-info-container .rating {
  color: white;
  flex: 1;
}
.back-poi-container .walkTime-container {
  justify-content: center;
  flex-direction: column;
  text-align: center;
  display: flex;
  flex: 0.2;
}
.back-poi-container .walkTime-container .walkTime-icon {
  text-align: center !important;
}
.back-poi-container .walkTime-container .walkTime-icon .material-icons {
  transition: font-size 500ms linear;
  font-size: 2em;
  color: white;
}
.back-poi-container .walkTime-container .walkTime-value {
  transition: font-size 500ms linear;
  color: white;
  font-size: 0.8em;
  font-weight: 700;
}
.flex {
  flex: 0.05;
}
.font-size-bigger {
  font-size: 1.6em !important;
}
@media screen and (min-height: 900px) and (min-width: 1920px) {
  .font-size-bigger {
    font-size: 2em !important;
  }
}
@media screen and (min-width: 3840px) and (min-height: 2160px) {
  .back-poi-container .back-icon-container .material-icons {
    font-size: 5em !important;
    transition: font-size 500ms linear;
  }
  .back-poi-container .back-info-container .poi-title-text {
    transition: font-size 500ms linear;
    padding-bottom: 1%;
    font-size: 2.2em !important;
  }
  .back-poi-container .back-info-container .font-size-bigger {
    font-size: 3.2em !important;
  }
  .back-poi-container .back-info-container .poi-subtitle {
    transition: font-size 500ms linear;
    font-size: 2em !important;
  }
  .back-poi-container .back-info-container .rating {
    flex: 1;
    color: white;
  }
  .back-poi-container .walkTime-container .walkTime-icon .material-icons {
    font-size: 4em !important;
    transition: font-size 500ms linear;
  }
  .back-poi-container .walkTime-container .walkTime-value {
    font-size: 1.6em !important;
    transition: font-size 500ms linear;
  }
}
/*# sourceMappingURL=back-poi-item.component-CE2R2Y5A.css.map */
