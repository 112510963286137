<div
    class="hotel-item-container"
    *ngIf="!hotelItem.isFeatured || hideFeaturedHotel"
    [ngStyle]="{ cursor: forGoogleMap ? 'unset' : 'pointer' }"
    [ngClass]="{ googleMapWindowInfo: forGoogleMap }"
>
    <div class="emptyFlex" *ngIf="!forGoogleMap"></div>

    <div class="image-container">
        <img
            loading="lazy"
            [src]="hotelItem.imageUrl"
            alt=""
            class="hotel-image"
        />
    </div>
    <div class="emptyFlex"></div>
    <div class="hotel-info-container">
        <div class="title">
            <span>{{ hotelItem.name }}</span>
            <div class="rating">
                <app-rating
                    [rating]="hotelItem.rating"
                    *ngIf="hotelItem"
                ></app-rating>
            </div>
        </div>
    </div>
</div>

<app-featured-hotel
    [hotelItem]="hotelItem"
    *ngIf="hotelItem.isFeatured && !hideFeaturedHotel"
></app-featured-hotel>
