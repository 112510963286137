import { Pipe, PipeTransform, inject } from '@angular/core';
import { TimeConversionService } from '../time-conversion.service';

@Pipe({
    name: 'sortFlights',
    standalone: true,
})
export class SortFlightsPipe implements PipeTransform {
    timeService = inject(TimeConversionService);

    transform(array: any, fieldName: string): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: any, b: any) => {
            if (fieldName == 'scheduled') {
                if (
                    this.timeService.getUnix(a.scheduled) ==
                    this.timeService.getUnix(b.scheduled)
                ) {
                    if (parseFloat(a.flightNumber) > parseFloat(b.flightNumber))
                        return 1;
                    if (parseFloat(a.flightNumber) < parseFloat(b.flightNumber))
                        return -1;
                }
                if (
                    this.timeService.getUnix(a.scheduled) >
                    this.timeService.getUnix(b.scheduled)
                )
                    return 1;
                if (
                    this.timeService.getUnix(a.scheduled) <
                    this.timeService.getUnix(b.scheduled)
                )
                    return -1;
                return 0;
            } else {
                if (fieldName == 'cityName') {
                    if (
                        a[fieldName].toUpperCase() < b[fieldName].toUpperCase()
                    ) {
                        return -1;
                    } else if (
                        a[fieldName].toUpperCase() > b[fieldName].toUpperCase()
                    ) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else {
                    if (a[fieldName] < b[fieldName]) {
                        return -1;
                    } else if (a[fieldName] > b[fieldName]) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            }
        });

        return array;
    }
}
