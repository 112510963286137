import { MappingService } from "../../services/mapping.service";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, mergeMap, catchError } from "rxjs/operators";
import { ApiService } from "../../services/api.service";
import { Injectable } from "@angular/core";
import { types } from "../actions";
import { of } from "rxjs";

@Injectable()
export class FlightEffects {
  getFlightsLax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(types.GET_FLIGHT_LAX),
      mergeMap(() =>
        this.api.getFlightsLax().pipe(
          map((laxFlights) => {
            let flights = {
              arrivalsFlights: this.mappingService.mapFlights(
                laxFlights.data.Arrivals,
                "A"
              ),
              departureFlights: this.mappingService.mapFlights(
                laxFlights.data.Departures,
                "D"
              ),
            };

            return {
              type: types.GET_FLIGHT_LAX_SUCCESS,
              payload: flights,
            };
          }),
          catchError(() =>
            of({
              type: types.GET_FLIGHT_LAX_FAILED,
              payload: "",
            })
          )
        )
      )
    )
  );

  constructor(
    private mappingService: MappingService,
    private actions$: Actions,
    private api: ApiService
  ) {}
}
