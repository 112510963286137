/* src/app/components/snack-bar/snack-bar.component.scss */
.snack-bar-container {
  border-radius: 0px 0px 0px 21px;
  background: #4a4e52;
  display: flex;
  opacity: 0.8;
  width: 20em;
  height: 4em;
  flex: 1;
}
.snack-bar-container .snack-bar-message-container {
  display: flex;
  flex: 0.7;
  align-items: center;
  justify-content: center;
}
.snack-bar-container .snack-bar-message-container .message-container {
  color: white;
  font-size: 0.9em;
}
.snack-bar-container .snack-bar-action-container {
  display: flex;
  flex: 0.3;
}
.snack-bar-container .snack-bar-action-container .action-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.snack-bar-container .snack-bar-action-container .action-container .action {
  color: red;
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: bold;
}
/*# sourceMappingURL=snack-bar.component-7ZXFPIQU.css.map */
