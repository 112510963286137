<div
    class="flight-partner-container"
    [ngClass]="{
        'active-flight-item-container-no-back-arrow':
            data.id == flightSelected && !hasBackArrow,
        'active-flight-item-container-with-back-arrow':
            data.id == flightSelected && hasBackArrow
    }"
    *ngIf="data.codeShareFlights[0] != ''"
>
    <!--For data.codeShareFlights.length greater than 3-->
    <div
        class="main-div1"
        *ngIf="
            widthResolution >= 1870
                ? data.codeShareFlights.length > 4
                : data.codeShareFlights.length > 3
        "
    >
        <div class="div-1">
            <div class="flight-partner-animation">
                <div
                    class="partner-header"
                    [ngClass]="{
                        'flight-selected-bg-color-no-back-arrow':
                            data.id == flightSelected && !hasBackArrow,
                        'flight-selected-bg-color-with-back-arrow':
                            data.id == flightSelected && hasBackArrow
                    }"
                >
                    <div class="icon-container">
                        {{ 'PARTNERS' | translate }}
                    </div>
                </div>
                <div
                    class="partner-information-container"
                    [ngClass]="{
                        'flight-selected-bg-color-no-back-arrow':
                            data.id == flightSelected && !hasBackArrow,
                        'flight-selected-bg-color-with-back-arrow':
                            data.id == flightSelected && hasBackArrow
                    }"
                >
                    <div class="partner-information">
                        <app-partner-item
                            class="partner-items"
                            *ngFor="let item of data.codeShareFlights"
                            [partnerData]="item"
                        >
                        </app-partner-item>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="main-div2"
        *ngIf="
            widthResolution >= 1870
                ? data.codeShareFlights.length > 4
                : data.codeShareFlights.length > 3
        "
    >
        <div class="div-2">
            <!-- [ngClass]="{ animationDiv1: flag, animationDiv11: !flag }" -->
            <div class="flight-partner-animation">
                <div
                    class="partner-header"
                    [ngClass]="{
                        'flight-selected-bg-color-no-back-arrow':
                            data.id == flightSelected && !hasBackArrow,
                        'flight-selected-bg-color-with-back-arrow':
                            data.id == flightSelected && hasBackArrow
                    }"
                >
                    <div class="icon-container">
                        {{ 'PARTNERS' | translate }}
                    </div>
                </div>
                <div
                    class="partner-information-container"
                    [ngClass]="{
                        'flight-selected-bg-color-no-back-arrow':
                            data.id == flightSelected && !hasBackArrow,
                        'flight-selected-bg-color-with-back-arrow':
                            data.id == flightSelected && hasBackArrow
                    }"
                >
                    <div class="partner-information">
                        <app-partner-item
                            class="partner-items"
                            *ngFor="let item of data.codeShareFlights"
                            [partnerData]="item"
                        >
                        </app-partner-item>
                    </div>
                </div>
            </div>
        </div>

        <div class="div-3">
            <!-- [ngClass]="{ animationDiv2: flag, animationDiv22: !flag }" -->
            <div class="flight-partner-animation">
                <div
                    class="partner-header"
                    [ngClass]="{
                        'flight-selected-bg-color-no-back-arrow':
                            data.id == flightSelected && !hasBackArrow,
                        'flight-selected-bg-color-with-back-arrow':
                            data.id == flightSelected && hasBackArrow
                    }"
                >
                    <div class="icon-container">
                        {{ 'PARTNERS' | translate }}
                    </div>
                </div>
                <div
                    class="partner-information-container"
                    [ngClass]="{
                        'flight-selected-bg-color-no-back-arrow':
                            data.id == flightSelected && !hasBackArrow,
                        'flight-selected-bg-color-with-back-arrow':
                            data.id == flightSelected && hasBackArrow
                    }"
                >
                    <div class="partner-information">
                        <app-partner-item
                            class="partner-items"
                            *ngFor="let item of data.codeShareFlights"
                            [partnerData]="item"
                        >
                        </app-partner-item>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--For data.codeShareFlights.length less than 4-->
    <div
        class="flight-partner-animation"
        *ngIf="
            widthResolution >= 1870
                ? 5 > data.codeShareFlights.length
                : 4 > data.codeShareFlights.length
        "
    >
        <div
            class="partner-header"
            [ngClass]="{
                'flight-selected-bg-color-no-back-arrow':
                    data.id == flightSelected && !hasBackArrow,
                'flight-selected-bg-color-with-back-arrow':
                    data.id == flightSelected && hasBackArrow
            }"
        >
            <div class="icon-container">
                {{ 'PARTNERS' | translate }}
            </div>
        </div>
        <div
            class="partner-information-container"
            [ngClass]="{
                'flight-selected-bg-color-no-back-arrow':
                    data.id == flightSelected && !hasBackArrow,
                'flight-selected-bg-color-with-back-arrow':
                    data.id == flightSelected && hasBackArrow
            }"
        >
            <div class="partner-information">
                <app-partner-item
                    class="partner-items"
                    *ngFor="let item of data.codeShareFlights"
                    [partnerData]="item"
                >
                </app-partner-item>
            </div>
        </div>
    </div>
</div>
