export const types = {
    //Flight Actions
    GET_FLIGHT_SUCCESS: '[Flight] GET Flight Success',
    GET_FLIGHT_FAILED: '[Flight] GET Flight Failed',
    GET_FLIGHT: '[Flight] GET Flight Attempt',

    //Flight Actions
    GET_FLIGHT_LAX_SUCCESS: '[Flight] GET Flight Lax Success',
    GET_FLIGHT_LAX_FAILED: '[Flight] GET Flight Lax Failed',
    GET_FLIGHT_LAX: '[Flight] GET Flight Lax Attempt',

    // UI Actions
    TOGGLE_LAX_TV: '[UI] Toggle LAX TV',
    TOGGLE_WEATHER_MODAL: '[UI] Toggle Alert Weather Modal',
    TOGGLE_SUB_SIDE_MENU: '[UI] Toggle Sub Side Menu',
    TOGGLE_SEARCH_BAR: '[UI] Toggle Search Bar Model',
    TOGGLE_STATIC_INFO: '[UI] Toggle Static Info Modal',
    SIDE_MENU_INFO: '[UI] Toggle Side Menu Info',
    TOGGLE_SELFIE: '[UI] Toggle Selfie Modal',
    TOGGLE_SURVEY: '[UI] Toggle Survey Modal',
    CHECK_IS_AGENT: '[UI] Check Is AGENT',
    MAP_INTIALIZED: '[UI] MAP_INTIALIZED',
    TOGGLE_DIALOG: '[UI] TOGGLE_DIALOG',
    RESET_APP: '[UI] Resetting the app',
    SHOW_IDLE_STATE: '[UI] Show Idle State',

    TOGGLE_DID_APP_RESET: '[UI] Toggling Did App Reload',
    TOGGLE_VIDEO_PLAYER_MODAL: '[UI] Toggle video player modal',
    SET_AGENT_STATUS: '[UI] Set agent Status',

    GET_SETTING_SUCCESS: '[UI] GET_SETTING_SUCCESS',
    GET_SETTING_FAILED: '[UI] GET_SETTING_FAILED',
    GET_SETTING: '[UI] GET_SETTING',

    GET_WEATHER_SUCCESS: '[UI] GET_WEATHER_SUCCESS',
    GET_WEATHER_FAILED: '[UI] GET_WEATHER_FAILED',
    GET_WEATHER: '[UI] GET_WEATHER',

    //POI actions
    SET_SELECTED_POI: '[POI] Set the poi selected in the subMenu in the store',
    CACHE_WALK_TIME: '[POI] Cache Walk times in the store',

    //Animation actions
    TOGGLE_ANIMATION_STATE: '[AI] Toggle animation state',

    //data bus Actions
    GET_POI_SUCCESS: '[DATA-BUS] GET_POI_SUCCESS',
    GET_POI_FAILED: '[DATA-BUS] GET_POI_FAILED',
    GET_POI: '[DATA-BUS] GET_POI',
    GET_CONTENT_ATTEMPT: '[DATA-BUS] GET_CONTENT',
    GET_CONTENT_FAILED: '[DATA-BUS] GET_CONTENT_FAILED',
    GET_CONTENT_SUCCESS: '[DATA-BUS] GET_CONTENT_SUCCESS',
    SETTING_LOCUS_LAB_CATEGORIES:
        '[DATA-BUS] Setting Locus Lab Categories in the store',
    GET_SELFIE_ATTEMPT: '[DATA-BUS] GET_SELFIE',
    GET_SELFIE_FAILED: '[DATA-BUS] GET_SELFIE_FAILED',
    GET_SELFIE_SUCCESS: '[DATA-BUS] GET_SELFIE_SUCCESS',
    SET_COMPUTER_NAME: '[DATA-BUS] Setting computer Name',
    SET_CHECK_IN: '[DATA-BUS] Set Check in',

    SET_RANDOM_BG_IMAGE: '[UI] Setting random background-Image for the app',

    //Agents
    SET_AGENT_TOKEN: '[AGENT] Setting the agent token in the state',
    RESET_AGENT_TOKEN: '[AGENT] Reset the agent token after log out',
    SET_AGENT_TOKEN_AND_LOGIN_TIME:
        'setting the agent token and the loginTime in the state',

    FLIGHT_SEARCH: '[FLIGHT] Flight search data',
    FLIGHT_TYPE: '[FLIGHT] Flight type if departure or arrival',
    ARRIVAL_SCROLL: '[FLIGHT] Flight scrolling position in arrivals',
    DEPARTURE_SCROLL: '[FLIGHT] Flight scrolling position in departures',
    RECENT_SELECTED: 'Recent Selected',
};
