/* src/app/components/horizontal-button-icon/horizontal-button-icon.component.scss */
.h-button-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  min-width: 12em;
  min-height: 2em;
  margin-top: 1em;
  cursor: pointer;
}
.h-button-container .icon-container {
  display: flex;
  flex: 0.2;
}
.h-button-container .icon-container .icon {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.h-button-container .icon-container .icon .material-icons {
  font-size: 1.5em;
}
.h-button-container .title-container {
  display: flex;
  flex: 0.8;
  text-transform: uppercase;
  font-weight: 400;
}
.h-button-container .title-container span {
  font-size: 0.7em;
}
.h-button-container .fontSizeBigger {
  font-size: 1em !important;
}
.h-button-container .m-left {
  margin-left: 1em !important;
}
@media screen and (min-width: 3840px) and (min-height: 2160px) {
  .m-left {
    margin-left: 3em !important;
  }
}
/*# sourceMappingURL=horizontal-button-icon.component-EWUVG3XU.css.map */
