<div class="emergency-message">
  <div class="alert-message">
    <div class="message-title">
      <span class="text">{{ emergencyBroadcastInfo.title.en }}</span>
    </div>
    <div class="message-body">
      <span class="text">{{ emergencyBroadcastInfo.subtitle.en }}</span>
    </div>
  </div>
  <div class="divider"></div>
  <div class="alert-message">
    <div class="message-title">
      <span class="text">{{ emergencyBroadcastInfo.title.es }}</span>
    </div>
    <div class="message-body">
      <span class="text">{{ emergencyBroadcastInfo.subtitle.es }}</span>
    </div>
  </div>
</div>
