/* src/app/components/alert-modal/alert-modal.component.scss */
.dialog-container {
  background-color: rgba(25, 25, 25, 0.7);
  justify-content: center;
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  z-index: 9;
  left: 0;
  top: 0;
}
.dialog {
  transition: font-size 500ms linear;
  border-radius: 0.3125em;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  background: #ecf1f9;
  font-family: inherit;
  position: absolute;
  align-self: center;
  max-width: 100%;
  padding: 1.25em;
  font-size: 1.3em;
  display: flex;
  border: none;
  z-index: 10;
  width: 30%;
  top: 43%;
}
@media screen and (max-width: 560px) {
  .dialog {
    transition: font-size 500ms linear;
    font-size: 1em !important;
  }
}
.dialog .text-container {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.dialog .text-container .version {
  font-size: 0.8em;
}
.dialog .text-container .sita-copy-right {
  font-size: 0.74em;
}
.dialog .text-container .cloud-function-version {
  font-size: 0.6em;
}
.dialog .dismiss-button {
  transition: font-size 500ms linear;
  background-color: black;
  border-radius: 0.1875em;
  padding: 0.2em 2.1875em;
  white-space: nowrap;
  font-size: 1.125em;
  box-shadow: none;
  font-weight: 500;
  cursor: pointer;
  margin-top: 4%;
  color: white;
  border: 0;
}
.dialog .button-width {
  width: 100%;
}
.dialog .cancel-button {
  background-color: #999;
}
/*# sourceMappingURL=alert-modal.component-Y7TB3YW5.css.map */
