<div
    class="poi-item-container"
    [ngClass]="{
        'active-poi-item-container': isSideMenuInfo && data.id == poiSelected
    }"
>
    <div class="poi-info-container">
        <div
            class="emptyFlex"
            [ngClass]="{
                letterCircleEmptyFlex:
                    !data.imageUrl && !data.logoUrl && !data.icon
            }"
        ></div>

        <div class="media-container">
            <div
                class="poi-image-container"
                *ngIf="!data.imageUrl && !data.logoUrl && !data.icon"
            >
                <div class="letter-container-lax">
                    <i class="material-icons sub-menu-icon" *ngIf="isSubMenu">
                        {{ data.icon }}
                    </i>
                    <span
                        class="letter-lax"
                        *ngIf="!isSubMenu && !data.gateNumber"
                        >{{ data.name | slice : 0 : 1 }}</span
                    >
                    <span *ngIf="data.gateNumber" class="letter-lax">{{
                        data.gateNumber
                    }}</span>
                </div>
            </div>

            <!--Icon for ADA and Info & Tips-->
            <div
                class="poi-image-container"
                *ngIf="
                    data.icon &&
                    (data.categoryId == 12 || data.categoryId == 14)
                "
            >
                <div class="static-icon-container">
                    <i class="material-icons">
                        {{ data.icon }}
                    </i>
                </div>
            </div>

            <!-- Image -->
            <div
                class="poi-image-container"
                *ngIf="data.imageUrl && !data.logoUrl"
            >
                <div
                    class="imageLAX"
                    [ngClass]="{
                        videoAspectRatio: data.categoryId == 13,
                        'cover-image':
                            selectedCategory && data.imageUrl !== data.logoUrl
                    }"
                    [style.background-image]="'url(' + data.imageUrl + ')'"
                >
                    <i
                        class="material-icons play-icon"
                        *ngIf="data.categoryId == 13"
                    >
                        play_circle_filled
                    </i>
                </div>
            </div>

            <div
                class="poi-image-container"
                *ngIf="
                    data.icon &&
                    !data.imageUrl &&
                    !data.logoUrl &&
                    data.categoryId != 12 &&
                    data.categoryId != 14
                "
            >
                <div
                    class="image"
                    [style.background-image]="'url(' + data.icon + ')'"
                    [ngStyle]="{
                        width:
                            data.categoryId !== 3 && data.categoryId !== 9
                                ? '6em'
                                : '3.5em',
                        height:
                            data.categoryId !== 3 && data.categoryId !== 9
                                ? '6em'
                                : '3.5em'
                    }"
                    style="
                        filter: brightness(0) saturate(100%) invert(0%)
                            sepia(7%) saturate(5%) hue-rotate(14deg)
                            brightness(88%) contrast(101%);
                    "
                ></div>
            </div>

            <div
                class="poi-image-container"
                *ngIf="data.logoUrl && !data.imageUrl"
            >
                <!-- class="image" -->
                <div
                    class="imageLAX"
                    [ngClass]="{
                        'cover-image':
                            selectedCategory && data.imageUrl !== data.logoUrl
                    }"
                    [style.background-image]="'url(' + data.logoUrl + ')'"
                ></div>
            </div>

            <div
                class="poi-image-container"
                *ngIf="data.logoUrl && data.imageUrl"
            >
                <div
                    class="imageLAX"
                    [ngClass]="{
                        videoAspectRatio: data.categoryId == 13,
                        'cover-image':
                            selectedCategory && data.imageUrl !== data.logoUrl
                    }"
                    [style.background-image]="'url(' + data.imageUrl + ')'"
                >
                    <i
                        class="material-icons play-icon"
                        *ngIf="data.categoryId == 13"
                    >
                        play_circle_filled
                    </i>
                </div>
            </div>
        </div>
        <div
            class="emptyFlex"
            [ngClass]="{
                letterCircleEmptyFlex:
                    !data.imageUrl && !data.logoUrl && !data.icon
            }"
        ></div>

        <div
            class="info-container"
            [ngStyle]="{ 'min-width': isHotel ? '80%' : '60%' }"
        >
            <div
                class="poi-title-text-lax"
                [ngClass]="{
                    'poi-title-text-nowrap': isSearchItem,
            
                }"
            >
                {{ data.name }}
            </div>
            <div
                class="poi-subtitle-lax"
                *ngIf="
                    data.details &&
                    data.details[translate.currentLang] &&
                    !isHotel &&
                    !isLaxTV &&
                    data.categoryId != 10 &&
                    data.categoryId != 15
                "
            >
                {{ data.details[translate.currentLang].shortLocation }}
            </div>

            <div
                class="poi-subtitle-lax"
                *ngIf="
                    data.details &&
                    data.details[translate.currentLang] &&
                    isLaxTV &&
                    data.categoryId != 10 &&
                    data.categoryId != 15
                "
            >
                {{ data.details[translate.currentLang].fullLocation }}
            </div>

            <div
                class="rating"
                *ngIf="
                    data.categoryId == 10 &&
                    data.categoryId == 15 &&
                    data.rating
                "
            >
                <app-rating
                    [rating]="data.rating"
                    [ratingColor]="'black'"
                ></app-rating>
            </div>
        </div>

        <div
            class="walkTime-container"
            *ngIf="!isHotel"
            [ngStyle]="{ visibility: isHotel ? 'hidden' : 'visible' }"
        >
            <div class="walkTime-icon-lax">
                <i class="material-icons" *ngIf="data.mapElementId">
                    directions_walk
                </i>
            </div>
            <div class="walkTime-value-lax" *ngIf="data.mapElementId">
                {{ data.walkTime }} {{ 'MIN' | translate }}
            </div>
        </div>
    </div>
</div>
