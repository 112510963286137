import {
    resetAgentToken,
    setAgentToken,
    setAgentToken_And_LoginTime,
} from '../actions/agent.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { AgentState } from '../state/agent.state';

const initialState = {
    agentLoginTime: '',
    agentToken: '',
    languages: [],
    email: '',
    uid: '',
} as AgentState;

const agentReducer = createReducer(
    initialState,
    on(setAgentToken, (state, action) => {
        const _a: AgentState = { ...state };
        _a.agentLoginTime = action.agentLoginTime;
        _a.agentToken = action.agentToken;
        _a.languages = action.languages || [];
        _a.email = action.email || '';
        _a.uid = action.uid || '';
        return _a;
    }),
    on(resetAgentToken, (state, action) => {
        const _a: AgentState = { ...state };
        _a.agentLoginTime = '';
        _a.agentToken = '';
        _a.languages = [];
        _a.email = '';
        _a.uid = '';

        return _a;
    }),
    on(setAgentToken_And_LoginTime, (state, action) => {
        const _a: AgentState = { ...state };
        _a.agentToken = action.agentToken;
        _a.agentLoginTime = action.agentLoginTime;
        return _a;
    })
);

export default function reducer(state: AgentState, action: Action) {
    return agentReducer(state, action);
}
