<div class="back-poi-container">
  <div class="back-icon-container" (click)="close()">
    <i class="material-icons">
      arrow_back
    </i>
  </div>
  <div class="flex" *ngIf="!hideWalkTime"></div>
  <div class="back-info-container" [ngStyle]="{ flex: isHotel ? '1' : '0.6' }">
    <div
      class="poi-title-text"
      [ngClass]="{ 'font-size-bigger': fontSizeShouldIncrease }"
      [ngStyle]="{
        'align-items':
          !subtitle || (isHotel && !data.rating.ratingNumber)
            ? 'center'
            : 'flex-end'
      }"
    >
      {{ title }}
    </div>
    <div class="poi-subtitle" *ngIf="!hideWalkTime">
      {{ subtitle }}
    </div>
    <div
      class="rating"
      *ngIf="isHotel && data && data.rating && data.rating.ratingNumber"
    >
      <app-rating [rating]="data.rating" [ratingColor]="'white'"></app-rating>
    </div>
  </div>

  <div
    class="walkTime-container"
    *ngIf="!hideWalkTime"
    [ngStyle]="{ visibility: isHotel ? 'hidden' : 'visible' }"
  >
    <div class="walkTime-icon">
      <i class="material-icons">
        directions_walk
      </i>
    </div>
    <div class="walkTime-value">
      {{ data.walkTime }} {{ "MIN" | translate }}
    </div>
  </div>
</div>
