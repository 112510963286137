<div
    class="video-container"
    [ngStyle]="{
        display: showIncomingCallState ? 'none' : 'block'
    }"
>
    <app-snack-bar
        *ngIf="
            !isAgent &&
            !webRTCService.showCallingState &&
            webRTCService.showErrorMessage
        "
    ></app-snack-bar>
    <div
        class="agent-receiving-call-container"
        *ngIf="webRTCService.showCallingState"
    >
        <div
            class="chatting-container"
            [ngClass]="{ 'animate-chatting-container': isMaximized }"
        >
            <div class="web-rtc-container">
                <div class="header-container">
                    <div
                        class="icon-container"
                        (click)="isMaximized = !isMaximized"
                    >
                        <div class="icon">
                            <i class="material-icons" *ngIf="!isMaximized">
                                fullscreen
                            </i>
                            <i class="material-icons" *ngIf="isMaximized">
                                fullscreen_exit
                            </i>
                        </div>
                    </div>
                    <div
                        class="title-container"
                        [ngStyle]="{
                            'flex-direction': isAgent ? 'column' : 'row'
                        }"
                    >
                        <span
                            class="title"
                            *ngIf="!isAgent && !webRTCService.showRemoteVideo"
                        >
                            {{ 'CALLING_AGENT' | translate }}</span
                        >
                        <span class="title" *ngIf="isAgent">
                            {{ kioskInformation.kioskLocation }}
                        </span>
                        <span class="title" *ngIf="isAgent">
                            {{ kioskInformation.computerName }}
                        </span>
                    </div>
                </div>
                <div
                    (mouseleave)="showVolume = false"
                    (mouseenter)="showVolume = true"
                    class="web-rtc-content"
                >
                    <ng-container>
                        <video
                            #localVideo
                            class="fit-video-container"
                            [ngClass]="{
                                localVideo: webRTCService.showRemoteVideo
                            }"
                            [ngStyle]="{
                                display: webRTCService.showLocalVideo
                                    ? 'block'
                                    : 'none'
                            }"
                        ></video>
                        <video
                            #remoteVideo
                            name="remote"
                            class="remoteVideo"
                            [ngStyle]="{
                                display: webRTCService.showRemoteVideo
                                    ? 'block'
                                    : 'none'
                            }"
                        ></video>
                    </ng-container>
                </div>

                <div class="footer-container">
                    <div class="btn-container">
                        <div
                            [ngStyle]="{ opacity: showVolume ? '1' : '0' }"
                            (mouseleave)="showVolume = false"
                            (mouseenter)="showVolume = true"
                            class="volume-container"
                        >
                            <i
                                class="material-icons icon"
                                style="padding-bottom: 0.2em"
                            >
                                volume_up
                            </i>
                            <mat-slider
                                (change)="onSliderChange($event)"
                                [value]="100"
                                [max]="100"
                                [step]="5"
                                [min]="0"
                                vertical
                            >
                            </mat-slider>
                            <i class="material-icons icon"> volume_down </i>
                        </div>

                        <div
                            class="hangOut-button-container"
                            (click)="hangUp()"
                            style="background: red"
                            *ngIf="!isAgent || didAgentAnswer"
                        >
                            <div class="icon">
                                <i
                                    class="material-icons"
                                    style="font-size: 1em"
                                >
                                    call_end
                                </i>
                            </div>
                        </div>
                        <div
                            class="hangOut-button-container"
                            (click)="showConferenceState()"
                            style="background: rgb(42, 207, 42)"
                            *ngIf="isAgent && !didAgentAnswer"
                        >
                            <div class="icon">
                                <i
                                    class="material-icons"
                                    style="font-size: 1em"
                                >
                                    call
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<audio #ringing>
    <source src="assets/audio/phone_ringing.mp3" type="audio/mp3" />
</audio>
<audio #agentRinging>
    <source src="assets/audio/receiving_call.mp3" type="audio/mp3" />
</audio>

<div class="agent-main-container" *ngIf="showIncomingCallState">
    <!-- <div class="header-container">
    <div class="left-container">
      <div class="title-container">
        <span class="title"> </span>
      </div>

      <div class="subtitle-container">
        <span class="subtitle"></span>
      </div>
    </div>
    <div class="right-container">
      <div class="expand-icon-container">
        <div class="icon" (click)="isConnected = true">
          <i class="material-icons">
            tab
          </i>
        </div>
      </div>
      <div class="empty-space"></div>
    </div>
  </div> -->

    <div class="chatting-container">
        <div class="video-cam-container">
            <div class="icon">
                <i class="material-icons"> videocam </i>
            </div>
        </div>
        <div class="title-container">
            <div class="info-container">
                <span class="title">
                    {{ kioskInformation.kioskLocation }}
                </span>
            </div>
            <div class="info-container">
                <span class="sub-title">
                    {{ kioskInformation.computerName }}
                </span>
            </div>
        </div>
    </div>

    <div class="footer-container">
        <div (click)="ignoreIncomingCall()" class="button-container">
            <div class="icon">
                <i class="material-icons"> close </i>
            </div>
            <div class="empty-space"></div>
            <div class="text-container">{{ 'IGNORE' | translate }}</div>
        </div>
        <div
            (click)="showConferenceState()"
            class="button-container"
            style="background-color: rgba(120, 150, 12, 0.952)"
            [ngClass]="{ 'disable-button': disableAgentAnswerButton }"
        >
            <div class="icon">
                <i class="material-icons"> call </i>
            </div>
            <div class="text-container">{{ 'ANSWER' | translate }}</div>
        </div>
    </div>
</div>
