<div class="search-bar-container">
    <div
        [ngClass]="{
            'search-bar-agent-body': isAgent
        }"
        class="search-bar-body"
    >
        <div class="list-container">
            <div class="alert-container" *ngIf="showAlert">
                <div class="icon-container">
                    <div class="icon">
                        <i class="material-icons"> search </i>
                    </div>
                </div>
                <span class="alert-text">
                    {{ 'SEARCH_NO_RESULT' | translate }}</span
                >
            </div>
            <div class="content">
                <div class="listing-pois-container">
                    <div
                        class="list-item-container"
                        *ngFor="let item of pois"
                        [ngStyle]="{
                            display: item.walkTime == -1 ? 'none' : 'block'
                        }"
                    >
                        <app-poi
                            [poiData]="item"
                            (click)="selectPoi(item)"
                            [isSearchItem]="true"
                            *ngIf="item.walkTime != -1"
                        ></app-poi>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="search-bar-footer">
        <div class="search-bar-component">
            <div class="left-container">
                <i class="material-icons"> search </i>
            </div>
            <div class="middle-container">
                <div class="search-bar-filter">
                    <input
                        #search
                        placeholder="{{
                            'WHAT_ARE_YOU_LOOKING_FOR' | translate
                        }}"
                        (input)="onInputChange($event)"
                        value="{{ value }}"
                        type="text"
                        ref
                    />
                </div>
            </div>
            <div class="right-container">
                <div class="close-icon-container ripple">
                    <i class="material-icons" (click)="close()"> close </i>
                </div>
            </div>
        </div>
        <div class="simple-keyboard" [hidden]="isAgent"></div>
    </div>
</div>
