/* src/app/components/button-icon/button-icon.component.scss */
.button-icon-container {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 100%;
  cursor: pointer;
  width: 100%;
}
.button-icon-container .icon {
  justify-content: center;
  pointer-events: none;
  align-items: center;
  color: black;
  display: flex;
  height: 30px;
  width: 100%;
  flex: 1;
}
.text-container {
  justify-content: center;
  user-select: none;
  display: flex;
  width: 100%;
  flex: 0.5;
}
.text-container .text {
  text-align: center;
  font-weight: 400;
  color: black;
}
.text-container .white {
  color: white;
}
.horizontal-button-icon-container {
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  display: flex;
  height: 100%;
}
.horizontal-button-icon-container .icon {
  justify-content: flex-end;
  height: 100%;
  flex: 0.3;
}
.horizontal-button-icon-container .text-container {
  align-items: center;
  height: 100%;
  flex: 1;
}
.grey-background {
  border: 2px solid white;
}
.grey-background.selected {
  border: 2px solid black;
}
.svg {
  filter: brightness(0) saturate(100%) invert(10%) sepia(71%) saturate(4502%) hue-rotate(226deg) brightness(100%) contrast(94%);
}
.text-container .text {
  font-size: 1.2em;
  transition: font-size 500ms linear;
}
.button-icon-container .material-icons {
  font-size: 4em;
  transition: font-size 500ms linear;
}
.icon-size-3 {
  font-size: 3em;
  transition: font-size 500ms linear;
}
.svg {
  transition: width 500ms linear;
  width: 35%;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .text-container .text {
    font-size: 2.4em !important;
    transition: font-size 500ms linear;
  }
  .button-icon-container .material-icons {
    font-size: 8em !important;
    transition: font-size 500ms linear !important;
  }
  .icon-size-3 {
    font-size: 6em !important;
    transition: font-size 500ms linear !important;
  }
  .svg {
    transition: width 500ms linear !important;
    width: 35% !important;
  }
}
/*# sourceMappingURL=button-icon.component-7WZFRREF.css.map */
