<div class="side-content-container">
    <div class="left-section">
        <div class="header-container">
            <app-back-poi-item
                [poiData]="subCategories"
                [title]="categoryTitle"
                [selectedCategory]="selectedCategory"
            >
            </app-back-poi-item>
        </div>

        <div class="list-container" id="list-scroll">
            <div
                class="item-container"
                [ngClass]="{
                    highlightText: selectedId == item.id
                }"
                *ngFor="let item of subCategories"
                (click)="selectItem(item)"
            >
                <div class="sub-category-title colorBlk">
                    <span
                        *ngIf="selectedCategory == 'LAX_TV'"
                        id="subcategory_{{ item.id }}"
                    >
                        {{ item.categoryName }}
                    </span>
                    <span
                        *ngIf="selectedCategory != 'LAX_TV'"
                        id="subcategory_{{ item.id }}"
                    >
                        {{ item.category[translate.currentLang] }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!--Body section-->
    <div class="right-section">
        <div
            id="section-scroll"
            class="section-info-container"
            [ngClass]="{ 'lax-tv-section': selectedCategory == 'LAX_TV' }"
        >
            <app-lax-tv
                *ngIf="selectedCategory == 'LAX_TV'"
                [selectedCategoryData]="selectedCategoryData"
                [selectedItem]="selectedItem"
                [categoryInfo]="categoryInfo"
                [data]="subCategories"
            ></app-lax-tv>

            <div class="info-container" style="width: 100%; height: 100%">
                <div class="header-title-container">
                    <span class="title-text">
                        {{ subCategoryTitle }}
                    </span>
                </div>
                <br />
                <div
                    *ngIf="subCategoryBody"
                    class="subcategory-body-container"
                    [innerHTML]="subCategoryBody | safeHtml"
                ></div>
                <br />
                <div
                    *ngFor="let item of sections"
                    class="item-container-expandable"
                    id="item_{{ item.id }}"
                >
                    <app-expandable-item
                        (onClick)="selectSection($event)"
                        [isExpandable]="
                            selectedSectionId == item.id ? true : false
                        "
                        [info]="item"
                    ></app-expandable-item>
                </div>
            </div>
            <app-send-to-client
                *ngIf="
                    (!isAgent && selectedCategory != 'LAX_TV' && showQRCode) ||
                    (isAgent && webRTCService.isConnected)
                "
                [selectedItemData]="selectedItemData"
                [selectedCategory]="selectedCategory"
                [staticInformationData]="staticInfo"
                [categoryInfo]="categoryInfo"
                [selectedItem]="selectedId"
                class="send-to-mobile"
                [isAgent]="isAgent"
            ></app-send-to-client>
        </div>
    </div>
</div>
