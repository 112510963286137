<div
    class="h-button-container"
    [ngStyle]="{
        background: buttonInfo.backgroundColor,
        'margin-left': buttonInfo.clickEvent == 'take' ? '1em' : '0em',
        'min-width': buttonInfo.minWidth
    }"
    [ngClass]="{ 'm-left': buttonInfo.clickEvent == 'take' }"
    (click)="onButtonClick(buttonInfo.clickEvent)"
>
    <div class="icon-container">
        <div class="icon">
            <i
                class="material-icons"
                [ngStyle]="{
                    color: buttonInfo.contentColor,
                    'font-size': buttonInfo.iconSize
                        ? buttonInfo.iconSize
                        : '1.5em'
                }"
                >{{ buttonInfo.iconName }}</i
            >
        </div>
    </div>
    <div class="title-container">
        <div style="flex: 0.1" *ngIf="buttonInfo.clickEvent !== 'unmask'"></div>
        <span
            [ngStyle]="{
                color: buttonInfo.contentColor,
                'font-size': buttonInfo.fontSize
            }"
            [ngClass]="{ fontSizeBigger: buttonInfo.isFontSizeBigger }"
            >{{ buttonInfo.title }}</span
        >
    </div>
</div>
