import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { selectDataBus, selectPOI, selectFlight } from '../../store/selectors';
import { EventHubService } from '@app/services/event-hub.service';
import { sideMenuDummyData } from '../../dummyData/sideMenu.js';
import { UtilityService } from '@app/services/utility.service';
import { MappingService } from '@app/services/mapping.service';
import { AppConfig } from '../../../environments/environment';
import { MapService } from '@app/services/map.service';
import Keyboard from 'simple-keyboard';
import { Store } from '@ngrx/store';
import {
    toggleStaticInfoModal,
    toggleSearchBarModel,
    toggleSideMenuInfo,
    toggleSubSideMenu,
    setSelectedPoi,
    flightSearch,
} from '../../store/actions';
import {
    ViewEncapsulation,
    ElementRef,
    Component,
    ViewChild,
    OnInit,
    AfterViewInit,
    Input,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { POIComponent } from '../poi-item/poi-item.component';
import { NgClass, NgIf, NgFor, NgStyle } from '@angular/common';

@Component({
    selector: 'app-search-bar',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './search-bar.component.html',
    styleUrls: [
        '../../../../node_modules/simple-keyboard/build/css/index.css',
        './search-bar.component.scss',
    ],
    standalone: true,
    imports: [NgClass, NgIf, NgFor, NgStyle, POIComponent, TranslateModule],
})
export class SearchBarComponent implements OnInit, AfterViewInit {
    @ViewChild('search', { static: false }) search: ElementRef;
    @Input('isFlightSearch') isFlightSearch: boolean;
    isAgent: boolean = AppConfig.isAgent;
    allPois: Array<any> = [];
    isDepartures: boolean;
    selectedCategory: any;
    categories: any = [];
    lang: string = 'en';
    showAlert: boolean;
    keyboard: Keyboard;
    categoryInfo: any;
    departures = [];
    arrivals = [];
    value = '';
    pois = [];

    dataBusSubscription: any;
    flightSubscription: any;
    poiSubscription: any;

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private mappingService: MappingService,
        private eventHub: EventHubService,
        private utility: UtilityService,
        private mapService: MapService,
        private store: Store<any>
    ) {
        this.eventHub.clearSearchInput.subscribe((value) => {
            setTimeout(() => {
                if (this.search)
                    this.filterFlight(this.search.nativeElement.value, true);
            }, 0);
        });
    }

    ngOnInit() {
        this.value = '';

        const dataBusState$ = this.store.select(selectDataBus);
        const flightsState$ = this.store.select(selectFlight);
        const poiState$ = this.store.select(selectPOI);

        if (this.isFlightSearch) {
            this.flightSubscription = flightsState$.subscribe((flightItem) => {
                this.departures = flightItem.departureFlights;
                this.arrivals = flightItem.arrivalsFlights;
                this.pois = [];
            });
            this.departures = JSON.parse(JSON.stringify(this.departures));
            this.arrivals = JSON.parse(JSON.stringify(this.arrivals));

            this.poiSubscription = poiState$.subscribe((flightConfig) => {
                this.isDepartures = flightConfig.isDepartureActive;
                this.pois = [];
            });
        } else {
            this.dataBusSubscription = dataBusState$.subscribe(
                (dataBusItem) => {
                    this.categories = sideMenuDummyData.categories;

                    for (let key in dataBusItem.pois) {
                        this.allPois = this.allPois.concat(
                            dataBusItem.pois[key]
                        );
                    }
                }
            );

            this.allPois = this.allPois
                .concat(this.mappingService.mapCategories())
                .concat(this.mappingService.mapSearchVideos());
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.search.nativeElement.focus();
        }, 0);

        this.search.nativeElement.value = '';
        this.value = '';

        this.keyboard = new Keyboard({
            onKeyPress: (button) => this.onKeyPress(button),
            onChange: (input) => this.onChange(input),
            theme: 'hg-theme-default keyboard-theme',
        });
    }

    ngOnDestroy() {
        this.search.nativeElement.value = '';
        this.value = '';

        if (this.dataBusSubscription) this.dataBusSubscription.unsubscribe();
        if (this.flightSubscription) this.flightSubscription.unsubscribe();
        if (this.poiSubscription) this.poiSubscription.unsubscribe();
    }

    onKeyPress = (button: string) => {
        /**
         * If you want to handle the shift and caps lock buttons
         */
        if (button === '{shift}' || button === '{lock}') this.handleShift();
    };

    handleShift = () => {
        let currentLayout = this.keyboard.options.layoutName;
        let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

        this.keyboard.setOptions({
            layoutName: shiftToggle,
        });
    };

    onChange = (input: string) => {
        this.value = input;
        if (input == '') {
            this.showAlert = false;
            this.value = '';
            this.pois = [];
        }

        let modifiedInput = input.trim(); // Trim any leading/trailing spaces

        // Check if the first character is numeric or the length is at least 2
        if (
            modifiedInput.length >= 2 ||
            /^[0-9]/.test(modifiedInput.charAt(0))
        ) {
            // Remove 'Gate' prefix if it exists (case insensitive)
            if (modifiedInput.toLowerCase().startsWith('gate ')) {
                modifiedInput = modifiedInput.substring(5); // Remove 'Gate ' (5 characters)
            }

            // Trigger search with the modified modifiedInput
            this.filterPois(modifiedInput);
        }

        if (this.isFlightSearch) this.filterFlight(input);
    };

    onInputChange = (event: any) => {
        this.value = event.target.value;

        this.keyboard.setInput(event.target.value);

        if (this.isFlightSearch) {
            this.pois = [];
            if (event.target.value.length == 0) {
                this.showAlert = false;
                this.store.dispatch(
                    flightSearch({
                        isFlightSearch: true,
                        isDepartureActive: this.isDepartures,
                        flights: [],
                    })
                );
            } else this.filterFlight(event.target.value);
        } else {
            if (event.target.value.length == 0) {
                this.showAlert = false;
                this.pois = [];
            }
            let input = event.target.value.trim(); // Trim any leading/trailing spaces

            // Check if the first character is numeric or the length is at least 2
            if (input.length >= 2 || /^[0-9]/.test(input.charAt(0))) {
                // Remove 'Gate' prefix if it exists (case insensitive)
                if (input.toLowerCase().startsWith('gate ')) {
                    input = input.substring(5); // Remove 'Gate ' (5 characters)
                }

                // Trigger search with the modified input
                this.filterPois(input);
            }
        }
    };

    filterPois(inputValue) {
        this.pois = this.mapService.sortByWalkTime(
            this.utility.filterPois(inputValue, this.allPois, this.lang)
        );

        if (this.pois.length == 0) this.showAlert = true;
        else this.showAlert = false;
    }

    filterFlight(inputValue, clearSearch: boolean = false) {
        this.showAlert = false;
        this.utility.filterFlight(
            inputValue,
            this.isDepartures,
            this.arrivals,
            this.departures,
            clearSearch
        );
    }

    selectPoi(item) {
        let query: string = '';

        this.categories.forEach((element) => {
            if (item.categoryId == element.id) {
                this.selectedCategory = element.storeName;
                this.categoryInfo = element;
                query = element.query;
            }
        });

        if (!this.categoryInfo) {
            this.selectedCategory = 'Search';
            this.categoryInfo = {
                icon: '',
                storeName: 'Search',
                label: 'Search',
                dataType: 'LL',
                query: 'Search',
                subMenu: [],
                id: 90,
            };

            query = 'Search';
        }

        if (this.selectedCategory == 'Check-in')
            this.selectedCategory = 'Check-In';

        this.store.dispatch(toggleSearchBarModel({ shouldOpen: false }));

        if (query == 'INFO_TIPS' || query == 'ADA' || query == 'LAX_TV') {
            this.store.dispatch(
                toggleSubSideMenu({
                    shouldOpen: false,
                    selectedCategory: this.selectedCategory,
                    categoryInfo: this.categoryInfo,
                })
            );

            if (
                this.selectedCategory == 'INFO & TIPS' ||
                this.selectedCategory == 'ADA'
            )
                this.store.dispatch(
                    setSelectedPoi({
                        poiContent: {
                            category:
                                this.selectedCategory == 'INFO & TIPS'
                                    ? 'INFO_TIPS'
                                    : 'ADA',
                            categoryDetails: {
                                subCategoryName: item.name,
                                sectionTitle: item.shortName || '',
                                sectionID: item.id || null,
                            },
                        },
                        instructions: [],
                    })
                );

            if (this.selectedCategory == 'LAX TV')
                this.store.dispatch(
                    setSelectedPoi({
                        poiContent: {
                            category: 'LAX_TV',
                            categoryDetails: {
                                categoryName: item.shortName,
                                id: item.id || null,
                            },
                        },
                        instructions: [],
                    })
                );

            setTimeout(() => {
                this.store.dispatch(
                    toggleStaticInfoModal({ shouldOpen: true })
                );
            }, 500);

            this.googleAnalyticService.onStaticInformationSearchResultClick(
                this.categoryInfo.storeName,
                this.selectedCategory == 'LAX TV' ? item.shortName : item.name
            );
        } else {
            this.googleAnalyticService.onSearchResultClick(
                this.categoryInfo.storeName,
                item
            );

            this.store.dispatch(
                toggleSubSideMenu({
                    shouldOpen: true,
                    selectedCategory: this.selectedCategory,
                    categoryInfo: this.categoryInfo,
                })
            );

            setTimeout(() => {
                this.store.dispatch(
                    toggleSideMenuInfo({
                        shouldOpen: true,
                        selectedCategory: this.selectedCategory,
                        animateSideInfo: true,
                    })
                );
            }, 1000);

            if (
                this.selectedCategory != 'tourism' &&
                this.selectedCategory != 'Hotels'
            )
                this.mapService.goToPoiLocation(item);

            this.store.dispatch(
                setSelectedPoi({
                    poiContent: item,
                    instructions: [],
                })
            );
        }
    }

    close() {
        if (this.isFlightSearch) {
            //To close the search section
            this.store.dispatch(toggleSearchBarModel({ shouldOpen: false }));

            if (!this.categoryInfo)
                this.categoryInfo = {
                    icon: 'flight',
                    storeName: 'Flights',
                    label: 'FLIGHTS',
                    dataType: '',
                    query: '',
                    subMenu: [],
                    id: 0,
                };

            this.store.dispatch(
                toggleSubSideMenu({
                    shouldOpen: false,
                    selectedCategory: 'Flights',
                    isFlightSearch: false,
                    isDepartureActive: this.isDepartures,
                    flights: [],
                    searchError: false,
                    categoryInfo: this.categoryInfo,
                })
            );

            setTimeout(() => {
                this.store.dispatch(
                    toggleSubSideMenu({
                        shouldOpen: true,
                        selectedCategory: 'Flights',
                        isFlightSearch: false,
                        isDepartureActive: this.isDepartures,
                        flights: [],
                        categoryInfo: this.categoryInfo,
                    })
                );
            }, 200);
        } else this.store.dispatch(toggleSearchBarModel({ shouldOpen: false }));
    }
}
