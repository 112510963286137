import { toggleAnimationState } from '../../store/actions/animation.actions';
import { animationConfig } from '@app/angular-animation/animation';
import { AppConfig } from '../../../environments/environment';
import { selectPOI, selectUI } from '../../store/selectors';
import { Component, OnInit, Input } from '@angular/core';
import { toggleSideMenuInfo } from '../../store/actions';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { RatingComponent } from '../rating/rating.component';
import { NgClass, NgIf, NgStyle, SlicePipe } from '@angular/common';

@Component({
    selector: 'app-poi',
    templateUrl: './poi-item.component.html',
    styleUrls: ['./poi-item.component.scss'],
    animations: [animationConfig.fadeInOut],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        NgStyle,
        RatingComponent,
        SlicePipe,
        TranslateModule,
    ],
})
export class POIComponent implements OnInit {
    @Input('isSearchItem') isSearchItem;
    @Input('isSubMenu') isSubMenu;
    @Input('poiData') data;

    isHoveredPoiMapIDSet: boolean = false;
    selectedCategory: any;
    poiSelected: number;
    isHotel: boolean;
    isLaxTV: boolean;
    poiSubscription;
    isSideMenuInfo;
    uiSubscription;

    constructor(
        public translate: TranslateService,
        private store: Store<any>
    ) {}

    ngOnInit() {
        const poiState$ = this.store.select(selectPOI);
        const uiState$ = this.store.select(selectUI);

        this.uiSubscription = uiState$.subscribe((uiItem) => {
            this.isSideMenuInfo = uiItem.windows.sideMenuInfo;
        });

        this.poiSubscription = poiState$.subscribe((poiItem: any) => {
            this.poiSelected =
                poiItem.poiContent.id !== null ? poiItem.poiContent.id : null;
            this.selectedCategory = poiItem.selectedCategory;
            if (
                this.selectedCategory == 'Hotels' ||
                this.selectedCategory == 'tourism'
            )
                this.isHotel = true;
            else this.isHotel = false;

            if (this.selectedCategory == 'LAX_TV') this.isLaxTV = true;
        });
    }

    ngOnDestroy() {
        this.poiSubscription.unsubscribe();
        this.uiSubscription.unsubscribe();
    }

    close() {
        // this.categories.forEach(element => {
        //   if (this.data.categoryId == element.id)
        //     this.selectedCategory = element.title;
        // });

        this.store.dispatch(
            toggleAnimationState({
                animationState: 'right',
                fadeInOut: false,
                isSideInfo: true,
            })
        );

        // this.store.dispatch(
        //   setSelectedPoi({
        //     poiContent: {},
        //     instructions: []
        //   })
        // );

        setTimeout(() => {
            this.store.dispatch(
                toggleAnimationState({
                    animationState: '',
                    fadeInOut: false,
                    isSideInfo: false,
                })
            );

            this.store.dispatch(
                toggleSideMenuInfo({
                    shouldOpen: false,
                    selectedCategory: this.selectedCategory,
                    animateSideInfo: false,
                })
            );
        }, 350);
    }
}
