/* src/app/components/send-to-client/send-to-client.component.scss */
.main-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
}
.main-container .content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}
.main-container .content .icon-container {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
.main-container .content .icon-container .send-icon {
  transform: rotateY(180deg);
  font-size: 2em;
}
.main-container .content .icon-container .done-icon {
  font-weight: 600;
  font-size: 2em;
  color: green;
}
.main-container .content .text-container {
  justify-content: center;
  align-items: center;
  padding: 4% 0 0 0;
  display: flex;
  height: 25%;
  width: 100%;
}
.main-container .content .text-container .send-text {
  text-transform: uppercase;
  margin-bottom: 1%;
}
.main-container .QR-code-container {
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}
.main-container .QR-code-container .close {
  justify-content: flex-end;
  display: flex;
  height: 2rem;
  width: 100%;
  font-size: 1rem;
  align-items: center;
}
.main-container .QR-code-container .close .close-icon {
  font-size: 2em;
  color: white;
}
.main-container .QR-code-container .static-close {
  margin-right: -3em;
  justify-content: flex-end;
  display: flex;
  width: 100%;
  padding-bottom: 0.5em;
}
.main-container .QR-code-container .qr-code {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  width: 100%;
}
.main-container .QR-code-container .scan-text {
  text-transform: uppercase;
  justify-content: center;
  display: flex;
  height: 2rem;
  width: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-container .style-primary {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-color: black;
}
.main-container .style-lightBlue {
  border-bottom-left-radius: 10px;
  background-color: #00a6ce;
}
.qr-code-main {
  height: 335px;
  width: 335px;
}
.qr-code-main-static-content {
  height: 100px;
  width: 220px;
}
.qr-code-main-lax-tv {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-color: black;
  height: 100px;
  width: 220px;
}
.default-main-style {
  height: 100%;
  width: 100%;
}
.loader {
  margin: 0.1em;
}
.circle {
  border-top: 0.2em solid white;
  border-bottom: 0.2em solid white;
  border-right-color: transparent;
  border-left-color: transparent;
  border-radius: 37%;
  background-color: transparent;
  animation: spin 1s linear infinite;
  height: 2em;
}
.QR-code-padding {
  padding-bottom: 3.5em !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
  padding-top: 0.2em !important;
}
@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .static-close {
    margin-right: -4em !important;
  }
  .static-close .close-icon {
    font-size: 2em;
  }
}
@media screen and (min-width: 3840px) and (min-height: 2160px) {
  .content .icon-container {
    font-size: 2rem;
  }
  .content .text-container {
    font-size: 1.5rem;
  }
  .qr-code-main {
    height: 42rem;
    width: 42rem;
  }
  .qr-code-main .QR-code-container .close {
    height: 5rem;
    font-size: 1.5rem;
    padding-right: 1rem;
  }
  .qr-code-main .QR-code-container .scan-text {
    height: 5rem;
    font-size: 2rem;
  }
  .qr-code-main-static-content {
    height: 165px !important;
    width: 300px !important;
  }
  .qr-code-main-lax-tv {
    height: 165px !important;
    width: 300px !important;
  }
  .static-close {
    margin-right: -13em !important;
    margin-top: -2em !important;
  }
  .static-close .close-icon {
    font-size: 2em;
    margin-top: -0.2em !important;
  }
  .QR-code-padding {
    padding-bottom: 8em !important;
    padding-left: 6.5em !important;
    padding-right: 6.5em !important;
    padding-top: 2.7em !important;
  }
  .static-content-container {
    height: 335px !important;
    width: 335px !important;
  }
}
/*# sourceMappingURL=send-to-client.component-P4IFA5W4.css.map */
