/* src/app/components/expandable-item/expandable-item.component.scss */
.item-container {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
}
.item-container .header-container {
  display: flex;
  flex: 1;
  height: 4em;
  min-height: 4em;
  background: #e8e8e8;
}
.item-container .header-container .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5em;
  cursor: pointer;
}
.item-container .header-container .icon-container .arrow-left-icon {
  font-size: 2.5em;
  color: black;
}
.item-container .header-container .icon-container .arrow-left-icon-lax {
  font-size: 2.5em;
  color: #00a6ce;
}
.item-container .content-container {
  display: flex;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.item-container .content-container .description {
  flex-direction: column;
  font-size: 1.5em;
  max-width: 100%;
  display: flex;
}
.title-container {
  padding-left: 0.5em;
  align-items: center;
  display: flex;
  width: 100%;
  flex: 1;
}
.title-container span {
  font-size: 1.6em;
  font-weight: 500;
}
.uppercase {
  text-transform: uppercase;
}
.expanded-header {
  color: black !important;
}
.expanded-header-lax {
  color: #00a6ce !important;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .item-container .header-container {
    height: 8em !important;
    min-height: 8em !important;
  }
  .item-container .header-container .icon-container {
    padding-left: 1em !important;
  }
  .item-container .header-container .icon-container .arrow-left-icon {
    font-size: 5em !important;
    color: black;
  }
  .item-container .header-container .icon-container .arrow-left-icon-lax {
    font-size: 3.5em !important;
  }
  .item-container .content-container {
    padding-left: 3em !important;
    padding-right: 3em !important;
  }
  .item-container .content-container .description {
    font-size: 3em !important;
  }
  .title-container span {
    font-size: 3.2em !important;
  }
}
/*# sourceMappingURL=expandable-item.component-4PPE2NCZ.css.map */
