export const AppConfig = {
    surveyDismissTimeout: 8000,
    agentMouseEventIdleRefreshRate: 300000,
    activeStateTransitionDuration: 500,
    flifoRefreshInterval: 300000,
    sessionTokenCheckInterval: 300000,
    sessionTokenExtension: false,

    appResetTimer: 90000,
    callAgentTimer: 20000,
    getFlightsAfter: -30,
    production: false,
    environment: 'QA',
    isAgent: false,
    firebase: {
        apiKey: 'AIzaSyBE0QjB6K5Q5oOg0LpxYUtjvhnxGe3L5AY',
        authDomain: 'sita-pax-assist-qa.firebaseapp.com',
        databaseURL: 'https://sita-pax-assist-qa.firebaseio.com',
        projectId: 'sita-pax-assist-qa',
        storageBucket: 'sita-pax-assist-qa.appspot.com',
        messagingSenderId: '795758617869',
        appId: '1:795758617869:web:ae776d2258a4f426cafc44',
        measurementId: 'G-GD5BW24HX3',
    },
    googleMapKey: 'AIzaSyBE0QjB6K5Q5oOg0LpxYUtjvhnxGe3L5AY',
    airportCoordinates: {
        lat: '33.944695',
        long: '-118.399441',
    },
    googleAnalyticSecret: 'XSYJlzF2ROyg4mOMWDVDAQ',
    microSiteUrl: 'https://lax-selfie-qa.itxi.aero/',
    kioskName: 'MSC-01-C1-IK1',
    weatherInformation: {
        url: 'https://weather-qa.api.aero',
        apiKey: '17749a408c7351814b3efb6d269827c3',
        airportCode: 'LAX',
    },
    laxEndpoint: {
        url: 'https://lax-api-qa.itxi.aero',
        apiKey: 'auJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9',
    },
    airlineLogo: 'http://uds-static.api.aero/45X20/airline_logos',
    pageName: 'LAX PAXAssist by SITA [QA]',
};
