<div class="info-item-container">
  <div class="icon-container">
    <i class="material-icons">{{ icon }}</i>
  </div>
  <div class="empty-space"></div>
  <div class="description-container">
    <div class="info-item-title">
      <span class="title">
        {{ title }}
      </span>
    </div>
    <!-- <div
      class="info-item-subtitle"
      *ngIf="checkSubtitleType(infoItem.subtitle)"
    >
      <span class="subtitle" *ngFor="let item of infoItem.subtitle">
        <span class="title">
          {{ item.title }}
        </span>
        <span style="padding-top: 1%">
          {{ item.subtitle }}
        </span>
      </span>
    </div> -->
    <!-- *ngIf="!checkSubtitleType(infoItem.subtitle)" -->
    <div class="info-item-subtitle">
      <span class="subtitle">
        {{ subtitle }}
      </span>
    </div>
  </div>
</div>
