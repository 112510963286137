import { createAction, props } from "@ngrx/store";
import { types } from "./types";

export const setSelectedPoi = createAction(
  types.SET_SELECTED_POI,
  props<{ poiContent?: {}; instructions?: {}[] }>()
);

export const cacheWalkTime = createAction(
  types.CACHE_WALK_TIME,
  props<{ walkTimes: {} }>()
);

export const recentSelected = createAction(
  types.RECENT_SELECTED,
  props<{
    recentSelected: {
      Transport: any;
      Shopping: any;
      CheckIn: any;
      Lounges: any;
      Dining: any;
      Hotels: any;
      Attractions: any;
    };
  }>()
);
