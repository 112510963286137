import { GoogleAnalyticsService } from './google-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'environments/environment';
import { setAgentStatus } from '../store/actions';
import { Injectable, inject } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
    setAgentToken_And_LoginTime,
    resetAgentToken,
    setAgentToken,
} from '../store/actions/agent.actions';
import {
    signInWithEmailAndPassword,
    onAuthStateChanged,
    getAuth,
    signOut,
    Auth,
} from '@angular/fire/auth';
import { TimeConversionService } from './time-conversion.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    isLoading: boolean = false;
    showAlert: boolean = false;
    tokenExpiryTime: any;
    userToken: any;
    alertText: any;
    timer: any;

    auth: Auth = inject(Auth);
    timeService = inject(TimeConversionService);

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private translate: TranslateService,
        private store: Store<any>,
        private api: ApiService,
        public router: Router
    ) {}

    signIn(email, password) {
        if (this.timer) clearTimeout(this.timer);
        return signInWithEmailAndPassword(this.auth, email, password)
            .then((result) => {
                this.googleAnalyticService.onLoginSuccess(email);
                const currentTime = this.timeService.getCurrentTime();
                let originalTime = currentTime.clone().toString();

                result.user
                    .getIdTokenResult()
                    .then((tokenValue: any) => {
                        this.tokenExpiryTime = tokenValue.claims.exp;
                        this.api
                            .onLogEvent(
                                { action: 'login_success' },
                                tokenValue.token
                            )
                            .subscribe((result) => {});
                        this.store.dispatch(
                            setAgentToken({
                                agentLoginTime: originalTime,
                                agentToken: tokenValue.token,
                                languages: tokenValue.claims.languages,
                                email: result.user.email,
                                uid: result.user.uid,
                            })
                        );

                        this.store.dispatch(
                            setAgentStatus({
                                setAgentStatus: true,
                            })
                        );
                        this.router.navigate(['/kiosk']);

                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.googleAnalyticService.onLoginFailed(email);
                        this.showAlert = true;
                        this.alertText = this.translate.instant(
                            'USERNAME_PASSWORD_VALIDATION'
                        );
                        this.isLoading = false;
                        this.timer = setTimeout(() => {
                            this.showAlert = false;
                        }, 10000);
                    });
            })
            .catch((error) => {
                this.googleAnalyticService.onLoginFailed(email);

                switch (error.code) {
                    case 'auth/too-many-requests':
                        this.alertText = this.translate.instant(
                            'USERNAME_PASSWORD_VALIDATION_MANY_ATTEMPTS'
                        );
                        break;

                    case 'auth/user-disabled':
                        this.alertText = this.translate.instant(
                            'USERNAME_PASSWORD_VALIDATION_DISABLED'
                        );
                        break;

                    default:
                        this.alertText = this.translate.instant(
                            'USERNAME_PASSWORD_VALIDATION'
                        );
                        break;
                }

                this.showAlert = true;
                this.isLoading = false;
                this.timer = setTimeout(() => {
                    this.showAlert = false;
                }, 10000);
            });
    }

    signOut() {
        this.store.dispatch(resetAgentToken());
        this.isLoading = false;
        this.router.navigate(['/login']);
        signOut(this.auth)
            .then(() => {
                // Sign-out successful.
            })
            .catch((error) => {
                // An error happened.
            });
    }

    checkToken() {
        this.getCurrentUser()
            .then((user) => {
                if (user) {
                    if (AppConfig.sessionTokenExtension)
                        getAuth()
                            .currentUser.getIdTokenResult(true)
                            .then((idTokenResult) => {
                                this.tokenExpiryTime = idTokenResult.claims.exp;
                                this.store.dispatch(
                                    setAgentToken_And_LoginTime({
                                        agentToken: idTokenResult.token,
                                        agentLoginTime:
                                            idTokenResult.issuedAtTime,
                                    })
                                );
                            })
                            .catch((error) => {
                                this.signOut();
                            });
                    else {
                        this.signOut();
                    }
                } else {
                    this.signOut();
                }
            })
            .catch((err) => {
                this.signOut();
            });
    }

    getCurrentUser() {
        return new Promise<any>((resolve, reject) => {
            onAuthStateChanged(this.auth, (user) => {
                if (user) {
                    resolve(user);
                } else {
                    reject('No user logged in');
                }
            });
        });
    }
}
