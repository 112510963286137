<div class="lax-tv-container">
    <div *ngIf="selectedItem == 'Home'" class="lax-tv-content">
        <div class="category-content" *ngFor="let item of data">
            <div class="title">
                <span>{{ item.categoryName }}</span>
            </div>
            <div
                class="videos-horizontal-list"
                [ngStyle]="{
                    'overflow-x': item.videos.length > 2 ? 'auto' : 'hidden'
                }"
            >
                <div
                    *ngFor="
                        let video of item.videos;
                        first as isFirst;
                        let i = index;
                        last as isLast
                    "
                    (click)="onVideoClick(video)"
                    (mouseleave)="onHover(-1, item.categoryName)"
                    (mouseenter)="onHover(i, item.categoryName)"
                    class="video-info-container"
                    [ngStyle]="{
                        'padding-left': isFirst == true ? '2%' : '1%',
                        'padding-right': isLast == true ? '2%' : '0%',
                        opacity:
                            i == hoverIndex &&
                            hoverCategoryName == item.categoryName
                                ? '0.5'
                                : '1'
                    }"
                >
                    <div
                        [ngStyle]="{
                            background:
                                'url(' +
                                video.thumbnailURL +
                                ') center no-repeat'
                        }"
                        class="container"
                    ></div>
                    <div class="video-title">
                        <span>
                            {{ video.videoTitle }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- General Listing html -->

    <!-- Category Specific Listing html -->

    <div *ngIf="selectedItem != 'Home'" class="lax-tv-content">
        <div class="category-container">
            <div class="title">
                <span>{{ selectedCategoryData.categoryName }}</span>
            </div>
            <div class="video-listing">
                <div
                    *ngFor="
                        let video of selectedCategoryData.videos;
                        first as isFirst;
                        let i = index
                    "
                    (click)="onVideoClick(video)"
                    (mouseleave)="
                        onHover(-1, selectedCategoryData.categoryName)
                    "
                    (mouseenter)="onHover(i, selectedCategoryData.categoryName)"
                    class="video-info-container"
                    [ngStyle]="{
                        'padding-left': isFirst == true ? '0%' : '1%',

                        opacity:
                            i == hoverIndex &&
                            hoverCategoryName ==
                                selectedCategoryData.categoryName
                                ? '0.5'
                                : '1'
                    }"
                >
                    <div
                        [ngStyle]="{
                            background:
                                'url(' +
                                video.thumbnailURL +
                                ') center no-repeat'
                        }"
                        class="container"
                    ></div>
                    <div class="video-title">
                        <span>
                            {{ video.videoTitle }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Category Specific Listing html -->
</div>
