@if(mapInitializationProgress.totalPoiNumber
-1>mapInitializationProgress.currentPoiIndex ){
<div class="loader">
    <img [src]="'assets/branding/images/airportLogo.svg'" />
    <div class="loading-info">
        <span class="fade-in">{{ 'CONFIGURATION_LOADER' | translate }}</span>
        <span class="fade-in">{{ 'WEATHER_LOADER' | translate }}</span>
        <span class="fade-in">{{ 'FLIGHTS_LOADER' | translate }}</span>
        <span class="fade-in">{{ 'MAP_LOADER' | translate }}</span>
        <span class="fade-in"
            >Calculating walk times from this location
            {{ mapInitializationProgress?.currentPoiIndex }} of
            {{ mapInitializationProgress?.totalPoiNumber }} ({{
                mapInitializationProgress?.percentage
            }})
        </span>

        <span *ngIf="isAgent" class="fade-in">{{
            'AGENT_LOGIN_LOADER' | translate
        }}</span>
    </div>
</div>
}

<app-idle-state
    *ngIf="
        mapInitializationProgress.totalPoiNumber - 1 <=
            mapInitializationProgress.currentPoiIndex && showIdleState
    "
>
</app-idle-state>

<div class="app-container">
    <!--Side menu-->
    <div
        [@subMenuInfoAnimation]="didSubMenuOpen && didSideMenuInfo"
        [@subMenuAnimation]="didSubMenuOpen"
        [@flightSearch]="isFlightSearch"
        *ngIf="didSubMenuOpen"
        class="sub-menu"
    >
        <app-sub-menu-listing
            [selectedCategory]="selectedCategory"
            *ngIf="!didSideMenuInfo"
        ></app-sub-menu-listing>

        <side-menu-info
            [sideMenuInfo]="sideMenuInfo"
            *ngIf="didSideMenuInfo"
        ></side-menu-info>
    </div>
    <div
        *ngIf="!showIdleState"
        [@slideInOut]
        class="side-menu-container"
        [ngStyle]="{ 'z-index': isSideInfo || isFlightSearch ? '0' : '3' }"
    >
        <app-side-menu-lax></app-side-menu-lax>
    </div>

    <div
        class="content-container"
        [ngStyle]="{
            'z-index': isSearchBarOpen || isFlightSearch ? '10' : '0'
        }"
    >
        <!--Search bar component-->
        <div
            class="modal-container-lax"
            [@searchModelAnimation]="animateSearchBar"
        >
            <app-search-bar
                *ngIf="isSearchBarOpen || isFlightSearch"
                [isFlightSearch]="isFlightSearch"
            ></app-search-bar>
        </div>

        <!--Video chat component-->
        <div class="video-chat-container">
            <app-video-chat></app-video-chat>
        </div>

        <app-home></app-home>
    </div>

    <div
        class="static-info-container-lax"
        *ngIf="isStaticInfoOpen"
        [@fadeInOut]
    >
        <app-static-content> </app-static-content>
    </div>
</div>

<!--Alert modal-->
<app-alert-modal *ngIf="showDialog" class="alert-modal"></app-alert-modal>

<!--Selfie component-->
<div class="modal-container" [@searchModelAnimation]="animateSelfieModal">
    <app-selfie-modal *ngIf="isSelfieOpen"></app-selfie-modal>
    <app-survey
        [activeSurvey]="surveyUI.activeSurvey"
        *ngIf="surveyUI.showSurvey"
    ></app-survey>
</div>
<!--Video Player Modal component-->
<div class="video-modal-container" *ngIf="isVideoPlayerModalOpened">
    <app-video-player-modal></app-video-player-modal>
</div>

@if(showIdleState){
<div class="idle-state-image"></div>

}

<div
    class="background-image-container"
    *ngIf="!showIdleState"
    [ngStyle]="{
        background: 'url(' + randomBgImage + ')'
    }"
></div>

<app-sticky-button-list *ngIf="!showIdleState && !isEmergencyStateEnabled">
</app-sticky-button-list>

<div class="invisible-container" (dblclick)="logDoubleTap()">
    @if(iconToShow){
    <img [src]="iconToShow" />
    }
</div>
