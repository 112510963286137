import { Component, OnInit, Input } from "@angular/core";
import { NgIf, NgFor, NgStyle } from "@angular/common";

@Component({
    selector: "app-rating",
    templateUrl: "./rating.component.html",
    styleUrls: ["./rating.component.scss"],
    standalone: true,
    imports: [NgIf, NgFor, NgStyle]
})
export class RatingComponent implements OnInit {
  @Input("ratingColor") ratingColor;
  @Input("rating") rating;
  starArray;

  constructor() {}

  ngOnInit() {
    //Why does this enter here even tho it isn't the hotel category ??? :hg
    if (this.rating) this.starArray = new Array(this.rating.ratingSize);
  }

  evaluateStar(position) {
    return this.rating.ratingNumber >= position
      ? "star"
      : this.rating.ratingNumber < position &&
        Math.ceil(this.rating.ratingNumber) == position
      ? "star_half"
      : "star_border";
  }
}
