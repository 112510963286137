<!-- 'qr-code-main-static-content': isStaticContent && !isQRCode && !isAgent, -->
<!-- -->
<div
    class="main-container"
    [ngClass]="{
        'qr-code-main': isQRCode,
        'qr-code-main-static-content':
            (isStaticContent && !isQRCode) || (isAgent && isLaxTV && !isQRCode),
        'default-main-style': !isQRCode && !isStaticContent && !isLaxTV,
        'qr-code-main-lax-tv': isLaxTV && !isAgent && !isQRCode,
        'QR-code-padding': isQRCode && !isAgent && isStaticContent,
        'static-content-container': isQRCode && isStaticContent
    }"
    [ngStyle]="{
        position:
            isStaticContent || (isAgent && isLaxTV) ? 'relative' : 'absolute'
    }"
>
    <!--Is not a QR-Code-->
    <div class="content" *ngIf="!isQRCode" (click)="sendPoiIDToClient()">
        <span class="container loader" *ngIf="isLoading">
            <button type="button" class="circle"></button>
        </span>
        <div class="icon-container" *ngIf="!isLoading && isAgent">
            <i class="material-icons send-icon" *ngIf="!isSent"> reply </i>
            <i class="material-icons done-icon" *ngIf="isSent"> check </i>
        </div>
        <div class="icon-container" *ngIf="!isAgent">
            <i class="material-icons send-icon"> phonelink_ring </i>
        </div>

        <div class="text-container" *ngIf="isAgent">
            <span class="send-text" *ngIf="!isSent">{{
                'SEND_TO_CLIENT' | translate
            }}</span>
            <span class="send-text" *ngIf="isSent">{{
                'SENT' | translate
            }}</span>
        </div>

        <div class="text-container" *ngIf="!isAgent">
            <span class="send-text">{{ 'SEND_TO_MOBILE' | translate }}</span>
        </div>
    </div>

    <!--Is QR-Code-->
    <div
        [ngClass]="{
            'style-primary': !isStaticContent || isLaxTV,
            'style-lightBlue': isStaticContent
        }"
        class="QR-code-container"
        *ngIf="isQRCode"
    >
        <div
            class="close"
            [ngClass]="{
                'static-close': isQRCode && !isAgent && isStaticContent
            }"
        >
            <i class="material-icons close-icon" (click)="closeQRCode()">
                close
            </i>
        </div>
        <div class="qr-code">
            <qrcode [qrdata]="qrData" [width]="qrWidth"></qrcode>
        </div>
        <div class="scan-text">
            <span>
                {{ 'SCAN_QR_CODE' | translate }}
            </span>
        </div>
    </div>
</div>
