/* src/app/components/idle-state/idle-state.component.scss */
.idle-state-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  z-index: 5;
}
.idle-state-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
}
.idle-state-container .overlay .header-container {
  display: flex;
  flex: 0.24;
}
.idle-state-container .overlay .header-container app-header {
  width: 100%;
}
.idle-state-container .overlay .body-container {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 60%;
  flex: 1;
}
.idle-state-container .overlay .body-container .video-player-container {
  display: contents;
}
.idle-state-container .overlay .body-container .video-player-container app-video-player {
  display: contents;
}
.idle-state-container .overlay .body-container .image-container {
  display: flex;
  aspect-ratio: 16/9;
  height: 100%;
}
.idle-state-container .overlay .body-container .image-container .hotel-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.idle-state-container .overlay .footer {
  display: flex;
  flex: 0.24;
}
.idle-state-container .overlay .footer .interaction-touch-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
}
.idle-state-container .overlay .footer .interaction-touch-container .interaction-text-container span {
  text-transform: uppercase;
  font-size: 4rem;
  color: white;
}
.idle-state-container .overlay .footer .interaction-touch-container .interaction-text-container span {
  font-size: 4rem !important;
  transition: 500ms font-size linear;
}
.custom-video-player-container {
  display: flex !important;
  aspect-ratio: 16/9 !important;
  height: 100% !important;
  justify-content: center !important;
}
.custom-video-player-container app-video-player {
  display: contents !important;
}
.custom-video-player-container app-video-player ::ng-deep .video-player-container {
  aspect-ratio: 19/6;
  width: 100%;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .idle-state-container .overlay .footer .interaction-touch-container .interaction-text-container span {
    font-size: 11rem !important;
    transition: 500ms font-size linear;
  }
}
/*# sourceMappingURL=idle-state.component-L5Q3NSYI.css.map */
