/* src/app/components/weather/weather.component.scss */
.weather-container {
  height: 100%;
  min-width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.icon-container {
  justify-content: center;
  align-items: center;
  display: flex;
  pointer-events: none;
}
.value-container {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 10%;
  min-width: 72px;
}
.value-container span {
  color: black;
}
.icon {
  width: 4em;
  transition: width 500ms linear;
}
.value-container span {
  font-size: 2em;
  transition: font-size 500ms linear;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .icon {
    width: 9em;
    transition: width 500ms linear;
  }
  .value-container span {
    font-size: 3.8em;
    transition: font-size 500ms linear;
  }
}
/*# sourceMappingURL=weather.component-6XCTQIUU.css.map */
