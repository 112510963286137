import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { setSelectedPoi, toggleVideoPlayerModal } from '@app/store/actions';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgIf, NgFor, NgStyle } from '@angular/common';
@Component({
    selector: 'app-lax-tv',
    templateUrl: './lax-tv.component.html',
    styleUrls: ['./lax-tv.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgStyle],
})
export class LaxTVComponent implements OnInit {
    @Input('selectedCategoryData') selectedCategoryData;
    @Input('selectedItem') selectedItem;
    @Input('categoryInfo') categoryInfo;
    @Input('data') data;

    hoverCategoryName: string = 'Home';
    hoverIndex: number = -1;
    selectedVideoData: {};

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private store: Store<any>
    ) {}

    ngOnInit() {}

    //to main
    onCategoryClick(item) {
        this.selectedItem = item.categoryName; //maybe make it one with below later:hg
        this.selectedCategoryData = item;
    }

    onHover(i: number, categoryName) {
        this.hoverCategoryName = categoryName;
        this.hoverIndex = i;
    }

    onVideoClick(videoInfo) {
        this.googleAnalyticService.onVideoClick(
            this.categoryInfo.storeName,
            videoInfo
        );

        this.store.dispatch(
            setSelectedPoi({
                poiContent: {
                    selectedMenu: this.selectedItem,
                    videoId: videoInfo.videoId,
                    category: 'LAX_TV',
                    videoTitle: videoInfo.videoTitle,
                    videoURL: videoInfo.videoURL,
                    mimeType: videoInfo.mimeType,
                    videoType: videoInfo.type,
                },
            })
        );
        setTimeout(() => {
            this.store.dispatch(toggleVideoPlayerModal({ shouldOpen: true }));
        }, 500);
    }

    onNavigatingBack() {
        this.hoverCategoryName = 'Home';
        this.hoverIndex = -1;
    }
}
