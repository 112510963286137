<div class="home-container">
    <div
        [ngClass]="{ 'show-map': !hideMap }"
        id="map-container"
        class="map"
        #map
    ></div>

    <div *ngIf="showTrafficMap" id="traffic-map-container">
        <app-google-map
            [lat]="lat"
            [long]="long"
            [hotelItem]="hotelItem"
            [itemsArray]="itemsArray"
        >
        </app-google-map>
    </div>
</div>
