<div
    class="side-info-container"
    [ngStyle]="{ background: sideInfo.imageUrl ? 'white' : 'transparent' }"
>
    <!-- [ngClass]="{
  'collapse-side-info': !sideInfo.imageUrl
}" -->
    <div
        class="image-container"
        [ngClass]="{
            'no-image-container': !sideInfo.imageUrl,
            'cover-image':
                selectedCategory && sideInfo.imageUrl !== sideInfo.logoUrl
        }"
        [style.background-image]="'url(' + sideInfo.imageUrl + ')'"
    ></div>

    <div class="title-container">
        <div
            [ngClass]="{
                'header-container-agent': isAgent,
                'header-container': !isAgent
            }"
        >
            <app-back-poi-item
                class="back-poi-item"
                [poiData]="sideInfo"
                [title]="sideInfo.name"
                [subtitle]="
                    sideInfo.details[translate.currentLang].shortLocation
                "
                [selectedCategory]="selectedCategory"
                *ngIf="!isFlights"
            >
            </app-back-poi-item>

            <app-flight-item
                class="back-poi-item"
                [isDepartureActive]="false"
                [flightSelected]="sideInfo.id"
                [flightItem]="sideInfo"
                [hasBackArrow]="true"
                style="min-height: 5em"
                *ngIf="isFlights"
            ></app-flight-item>

            <app-send-to-client
                *ngIf="
                    (isAgent && webRTCService.isConnected) ||
                    (!isAgent && selectedCategory != 'Flights') ||
                    (!isAgent && selectedCategory == 'Flights' && gateInfo)
                "
                [isAgent]="isAgent"
                [gateInfo]="gateInfo"
                [selectedCategory]="selectedCategory"
                [categoryInfo]="categoryInfo"
                [isStaticContent]="false"
                [poiContent]="sideInfo"
                class="send-to-client"
            ></app-send-to-client>
        </div>
    </div>

    <div class="navTabs-container" *ngIf="!isHotel">
        <div
            *ngIf="
                !isFlights &&
                sideInfo.details &&
                sideInfo.details[translate.currentLang] &&
                sideInfo.details[translate.currentLang].fullDescription &&
                sideInfo.details[translate.currentLang].shortDescription
            "
            class="info-tab-container"
            (click)="selectMainFilter('INFO')"
            [ngClass]="{
                'main-tab-active': filterType === 'INFO',
                'align-tab': !sideInfo.mapElementId
            }"
        >
            <i class="material-icons-outlined"> info </i>

            <span>
                {{ 'INFO' | translate }}
            </span>
        </div>

        <div
            *ngIf="sideInfo.mapElementId || isFlights"
            class="route-tab-container"
            (click)="selectMainFilter('ROUTE')"
            [ngClass]="{
                'main-tab-active': filterType === 'ROUTE',
                'align-tab': !sideInfo.details || isFlights
            }"
        >
            <i class="material-icons-outlined"> directions </i>

            <span>
                {{ 'ROUTE' | translate }}
            </span>
        </div>

        <div
            *ngIf="showAccessibleRoute"
            class="route-tab-container"
            (click)="selectMainFilter('ACCESSIBLE_ROUTE')"
            [ngClass]="{
                'main-tab-active': filterType === 'ACCESSIBLE_ROUTE'
            }"
        >
            <i class="material-icons-outlined"> accessible </i>

            <span>
                {{ 'ACCESSIBLE' | translate }}
            </span>
        </div>
    </div>

    <div class="info">
        <!-- for colpasing Image  -->
        <!-- (scroll)="collapseImage($event)" -->
        <div class="info-container" *ngIf="filterType === 'INFO'">
            <div class="description-container">
                <span
                    *ngIf="
                        sideInfo.details &&
                        sideInfo.details[translate.currentLang]
                    "
                >
                    {{
                        sideInfo.details[translate.currentLang].fullDescription
                    }}
                </span>
            </div>

            <div class="info-items-container">
                <app-info-item
                    *ngIf="
                        sideInfo.categoryId == 10 &&
                        sideInfo.details &&
                        sideInfo.details[translate.currentLang]
                    "
                    [title]="
                        sideInfo.details[translate.currentLang].shortLocation
                    "
                    [icon]="'airport_shuttle'"
                    [subtitle]=""
                ></app-info-item>

                <app-info-item
                    *ngIf="sideInfo.telephoneNo"
                    [title]="sideInfo.telephoneNo"
                    [icon]="'call'"
                    [subtitle]=""
                ></app-info-item>
                <app-info-item
                    *ngIf="
                        sideInfo.details &&
                        sideInfo.categoryId !== 10 &&
                        sideInfo.details[translate.currentLang]
                    "
                    [title]="
                        sideInfo.details[translate.currentLang].shortLocation
                    "
                    [icon]="'location_on'"
                    [subtitle]=""
                ></app-info-item>
                <app-info-item
                    *ngIf="sideInfo.webUrl"
                    [title]="sideInfo.webUrl"
                    [icon]="'link'"
                    [subtitle]=""
                ></app-info-item>
            </div>
            <div
                class="image-container"
                *ngIf="showEmptyImageDiv"
                style="min-height: 20em"
            ></div>
        </div>
        <!-- for collapsing Image  -->
        <!-- (scroll)="collapseImage($event)" -->
        <div class="route-info-container" *ngIf="filterType !== 'INFO'">
            <div class="controller-container">
                <div
                    (click)="onCurrentLocationPress()"
                    class="controller-button"
                    style="flex: 1.4"
                >
                    <div class="controller-icon-container">
                        <img
                            loading="lazy"
                            [src]="'assets/icon/start-pin.svg'"
                            class="svg"
                        />
                    </div>
                    <div class="text-container">
                        <span class="text">{{
                            'CURRENT_LOCATION' | translate
                        }}</span>
                    </div>
                </div>
                <div
                    class="controller-button"
                    (click)="onMapStepControlTrigger(false)"
                    [ngClass]="{ 'disable-btn': disablePreviousButton }"
                >
                    <div class="controller-icon-container">
                        <i class="material-icons icon"> arrow_back_ios </i>
                    </div>
                    <div class="text-container">
                        <span class="text">{{ 'BACK' | translate }}</span>
                    </div>
                </div>
                <div class="controller-button" (click)="playMapSteps(sideInfo)">
                    <div class="controller-icon-container">
                        <i class="material-icons icon">
                            {{ autoPlayIcon }}
                        </i>
                    </div>
                    <div class="text-container">
                        <span class="text">{{ autoPlayText | translate }}</span>
                    </div>
                </div>
                <div
                    class="controller-button"
                    (click)="onMapStepControlTrigger(true)"
                    [ngClass]="{ 'disable-btn': disableNextButton }"
                >
                    <div class="controller-icon-container">
                        <i class="material-icons icon"> arrow_forward_ios </i>
                    </div>
                    <div class="text-container">
                        <span class="text">{{ 'NEXT' | translate }}</span>
                    </div>
                </div>
                <div
                    (click)="onDestinationLocationPress()"
                    class="controller-button"
                    style="flex: 1.4"
                >
                    <div class="controller-icon-container">
                        <img
                            loading="lazy"
                            [src]="'assets/icon/end-pin.svg'"
                            class="svg"
                        />
                    </div>
                    <div class="text-container">
                        <span class="text">{{
                            'DESTINATION' | translate
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="directions-container">
                <div class="row">
                    <div class="icon-container">
                        <i class="material-icons"> trip_origin </i>
                    </div>

                    <div class="direction-description">
                        <span class="instruction"> Interactive Kiosk </span>
                    </div>
                </div>
                <div
                    class="row instructions"
                    *ngFor="
                        let instruction of normalizedInstructions;
                        let i = index
                    "
                    [id]="'direction-' + i"
                >
                    <mat-divider
                        vertical="true"
                        style="height: 8em"
                    ></mat-divider>

                    <div
                        class="instruction-step-container"
                        [ngClass]="{ 'active-step': i == segmentIndex }"
                    >
                        <div class="icon-container">
                            <img
                                [src]="instruction.icon"
                                class="icon-walk-direction"
                                onerror="this.style.display= 'none'"
                            />
                        </div>

                        <div class="direction-description">
                            <span class="instruction">
                                {{ instruction.direction }}
                            </span>
                            <span class="walk-time">
                                {{ instruction.walkTimeString }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="icon-container">
                        <i class="material-icons"> location_on </i>
                    </div>

                    <div class="direction-description">
                        <span class="instruction">
                            {{ gateInfo ? gateInfo.name : sideInfo.name }}
                        </span>
                    </div>
                </div>
            </div>

            <div
                class="image-container"
                *ngIf="showEmptyImageDiv"
                style="min-height: 20em"
            ></div>
        </div>
    </div>
</div>

<div
    *ngIf="
        isAgent &&
        selectedCategory != 'Hotels' &&
        selectedCategory != 'tourism' &&
        selectedCategory != 'Flights'
    "
    class="poi-id-overlay"
>
    {{ sideInfo.id }}
</div>
