import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { animationConfig } from '@app/angular-animation/animation';
import { UtilityService } from '@app/services/utility.service';
import { WebRTCService } from '@app/services/web-rtc.service';
import { ButtonIcon } from '@app/models/ui/button.icon.model';
import { AppConfig } from '../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { headerDummyData } from '../../dummyData/header';
import { AuthService } from '@app/services/auth.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { WebcamUtil } from 'ngx-webcam';
import { Store } from '@ngrx/store';
import {
    selectDataBus,
    selectAgent,
    selectPOI,
    selectUI,
} from '@app/store/selectors';
import {
    toggleStaticInfoModal,
    toggleSearchBarModel,
    toggleSideMenuInfo,
    toggleSurveyModal,
    toggleSubSideMenu,
    setAgentStatus,
    showDialog,
} from '@app/store/actions';
import { EventHubService } from '../../services/event-hub.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ButtonIconComponent } from '../button-icon/button-icon.component';
import { NgStyle, NgIf, NgFor, NgClass } from '@angular/common';
@Component({
    selector: 'app-sticky-button-list',
    templateUrl: './sticky-button-list.component.html',
    styleUrls: ['./sticky-button-list.component.scss'],
    animations: [animationConfig.fadeInOut, animationConfig.fadeInOutBgColor],
    standalone: true,
    imports: [
        NgStyle,
        NgIf,
        ButtonIconComponent,
        NgFor,
        NgClass,
        MatSelect,
        MatOption,
        TranslateModule,
    ],
})
export class StickyButtonListComponent implements OnInit {
    @ViewChild('accountPanel') accountPanel;

    languageIcon: ButtonIcon = headerDummyData.languageIcon;
    searchIcon: ButtonIcon = headerDummyData.searchIcon;
    helpIcon: ButtonIcon = headerDummyData.helpIcon;
    rateUS = {
        label: 'RATE_US',
        icon: 'sentiment_satisfied_alt',
    };
    showIdleState: boolean = false;
    isAgent: boolean = false;
    isSearchBarOpen: boolean;
    isFlightSearch: boolean;
    uiSubscription: any;
    doesWebcamExist: boolean = false;
    selectedOption: string = 'AVAILABLE';
    selected: string = 'AVAILABLE';
    agentEmail: string = '';
    selectedCategory: any;
    agentStatus: {}[];
    didClientCall: boolean = false;
    buttonsList: {}[] = [
        {
            name: 'search',
            buttonInfo: this.searchIcon,
        },
        { name: 'language', buttonInfo: this.languageIcon },
    ];
    bgImageNumber: any;
    wasAgentOnlineBeforeCall: boolean = true;
    isRateUSButtonEnabled: boolean = false;
    manualDecision: boolean = false;
    dataBusSubscription: any;
    poiSubscription: any;

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private eventHubService: EventHubService,
        private translate: TranslateService,
        public webRTCService: WebRTCService,
        private authService: AuthService,
        private utility: UtilityService,
        private store: Store<any>
    ) {
        this.eventHubService.reactiveAgent.subscribe((event) => {
            this.toggleOption('AVAILABLE');
        });

        this.webRTCService.wasAgentRemovedFromDB.subscribe((value) => {
            this.selected = 'UNAVAILABLE';
            this.selectedOption = 'UNAVAILABLE';
            this.webRTCService.isAgentOnline = false;
            this.webRTCService.removeAgentFromFirebase();
            this.store.dispatch(
                setAgentStatus({
                    setAgentStatus: false,
                })
            );

            if (!this.manualDecision && !this.didClientCall)
                setTimeout(() => {
                    this.store.dispatch(
                        showDialog({
                            showDialog: true,
                            title: 'AGENT_INACTIVE',
                            subTitle: 'REACTIVATE_YOUR_SESSION',
                            showCloudFunction: false,
                            fromComponent: 'sticky-button-list',
                        })
                    );
                }, 100);
            else this.manualDecision = false;
        });

        this.webRTCService.didStreamEnd.subscribe((value) => {
            this.didClientCall = false;
            if (this.wasAgentOnlineBeforeCall) this.toggleOption('AVAILABLE');
        });

        this.webRTCService.didClientCall.subscribe((value) => {
            this.accountPanel.close();
            this.didClientCall = true;
        });
    }

    ngOnInit() {
        this.isAgent = AppConfig.isAgent;
        if (this.isAgent) {
            this.webRTCService.subscribeActiveNode();

            const agentState$ = this.store.select(selectAgent);

            agentState$.subscribe((agent) => {
                this.agentEmail = agent.email;
                this.agentStatus = [
                    { value: 'AVAILABLE', viewValue: 'AVAILABLE' },
                    { value: 'UNAVAILABLE', viewValue: 'UNAVAILABLE' },
                    { value: 'LOGOUT', viewValue: 'LOGOUT' },
                    { value: 'name', viewValue: agent.email },
                ];
            });
        }

        const uiState$ = this.store.select(selectUI);

        this.uiSubscription = uiState$.subscribe((uiItem) => {
            this.bgImageNumber = uiItem.images.randomBgImage || 1;
            if (!AppConfig.isAgent) {
                this.showIdleState = uiItem.windows.showIdleState;
            }
            this.isSearchBarOpen = uiItem.windows.searchBarModal;
        });

        const poiState$ = this.store.select(selectPOI);

        this.poiSubscription = poiState$.subscribe((poiItem) => {
            this.isFlightSearch = poiItem.isFlightSearch;
        });

        const dataBusState$ = this.store.select(selectDataBus);

        this.dataBusSubscription = dataBusState$.subscribe((dataBusItem) => {
            this.isRateUSButtonEnabled =
                dataBusItem.surveyConfig.persistentSurvey.enabled &&
                dataBusItem.surveyConfig.persistentSurvey.questions.length > 0;
        });

        WebcamUtil.getAvailableVideoInputs().then(
            (mediaDevices: MediaDeviceInfo[]) => {
                this.checkCameraExist(mediaDevices);
            }
        );
    }

    ngOnDestroy() {
        this.dataBusSubscription.unsubscribe();
        this.poiSubscription.unsubscribe();
        this.uiSubscription.unsubscribe();
    }

    checkCameraExist(mediaDevices) {
        if (mediaDevices.length == 0) this.doesWebcamExist = false;
        else this.doesWebcamExist = true;
    }

    toggleSearchBarModel() {
        this.store.dispatch(toggleStaticInfoModal({ shouldOpen: false }));

        this.store.dispatch(
            toggleSubSideMenu({ shouldOpen: false, selectedCategory: '' })
        ); //To close side menu listing

        this.store.dispatch(
            toggleSideMenuInfo({ shouldOpen: false, animateSideInfo: false })
        );

        this.store.dispatch(toggleSearchBarModel({ shouldOpen: true })); //To open search section
    }

    callAgent() {
        if (!this.showIdleState) {
            if (
                this.webRTCService.busyAgentList.length > 0 &&
                !this.webRTCService.isAgentActive
            )
                this.store.dispatch(
                    showDialog({
                        showDialog: true,
                        title: this.translate.instant('AGENT_BUSY'),
                        subTitle: this.translate.instant('TRY_AGAIN'),
                        showCloudFunction: false,
                    })
                );
            else {
                this.webRTCService.didClientInitiateCall.emit(true);
                this.googleAnalyticService.onCallRing();
                this.webRTCService.startCallTimer();
            }
        }
    }

    onRateUsButtonClick() {
        this.store.dispatch(
            toggleSurveyModal({
                shouldOpen: true,
                activeSurvey: 'persistentSurvey',
            })
        );
    }

    handleClick(method: string) {
        switch (method) {
            case 'help':
                if (!this.showIdleState) this.callAgent();
                break;
            case 'search':
                this.googleAnalyticService.onGlobalSearchClick();
                if (!this.showIdleState) this.toggleSearchBarModel();
                break;
            case 'language':
                if (this.translate.currentLang == 'en')
                    this.translate.use('es');
                else this.translate.use('en');

                break;

            default:
                break;
        }
    }

    toggleOption(option, manual = false) {
        if (this.selectedOption == option) return;
        switch (option) {
            case 'LOGOUT':
                this.webRTCService.removeAgentFromFirebase();
                this.utility.randomizeBgImage(this.bgImageNumber);
                this.webRTCService.isAgentOnline = false;
                this.wasAgentOnlineBeforeCall = false;
                this.authService.signOut();
                if (manual)
                    this.googleAnalyticService.onLogOut(this.agentEmail);
                break;
            case 'AVAILABLE':
                this.selected = 'AVAILABLE';
                this.selectedOption = 'AVAILABLE';

                this.webRTCService.isAgentOnline = true;
                this.wasAgentOnlineBeforeCall = true;

                this.store.dispatch(
                    setAgentStatus({
                        setAgentStatus: true,
                    })
                );
                this.webRTCService.addAgentToFirebase();
                if (manual)
                    this.googleAnalyticService.onAgentSetAvailable(
                        this.agentEmail
                    );

                break;
            case 'UNAVAILABLE':
                this.selected = 'UNAVAILABLE';
                this.selectedOption = 'UNAVAILABLE';
                this.webRTCService.isAgentOnline = false;
                this.wasAgentOnlineBeforeCall = false;

                this.webRTCService.removeAgentFromFirebase();
                this.store.dispatch(
                    setAgentStatus({
                        setAgentStatus: false,
                    })
                );

                this.manualDecision = manual;
                if (manual)
                    this.googleAnalyticService.onAgentSetUnavailable(
                        this.agentEmail
                    );

                break;

            default:
                break;
        }
    }

    openDropDown() {
        this.accountPanel.open();
    }
}
