import { BehaviorSubject, Observable, of, merge, fromEvent } from 'rxjs';
import { AppConfig } from '../../environments/environment';
import { EventEmitter, Injectable, inject } from '@angular/core';
import { ApiService } from './api.service';
import { mapTo } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { setRandomBgImage, flightSearch, showDialog } from '../store/actions';
import { TimeConversionService } from './time-conversion.service';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    public isPoiInfoPlayingSteps = new EventEmitter();
    bgImagePath: string = 'assets/images/login';
    filteredPois = new BehaviorSubject({});
    $online: Observable<boolean>;
    defaultBgImage: number;
    randomBgImage: number;
    pictureNumber: any;
    bgImage: string;

    timeService = inject(TimeConversionService);

    constructor(private store: Store<any>, private api: ApiService) {
        this.$online = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(mapTo(true)),
            fromEvent(window, 'offline').pipe(mapTo(false))
        );
    }

    normalizeFlights(flightRecord) {
        let _flightRecord = flightRecord;
        for (let i = 0; _flightRecord.length > i; i++) {
            let displayTime = this.timeService.formatDate(
                _flightRecord[i].actual
                    ? _flightRecord[i].actual
                    : _flightRecord[i].estimated
                    ? _flightRecord[i].estimated
                    : _flightRecord[i].scheduled,
                'h:mm a'
            );

            _flightRecord[i] = {
                ..._flightRecord[i],
                id: i,
                gate: _flightRecord[i].gate ? _flightRecord[i].gate : '',
                displayTime: displayTime,
                airlineLogo: `${AppConfig.airlineLogo}/${_flightRecord[i].operatingCarrier.airlineCode}.png`,
                marketingCarriers: _flightRecord[i].marketingCarriers
                    ? _flightRecord[i].marketingCarriers
                    : [''],
            };
        }

        return this.sortFlights(_flightRecord, 'T');
    }

    sortFlights(flights, subFilterValue) {
        return flights.sort((flightA, flightB) => {
            let flightAInfo = flightA.operatingCarrier;
            let flightBInfo = flightB.operatingCarrier;

            if (subFilterValue == 'T') {
                if (
                    this.timeService.getUnix(flightA.scheduled) ===
                    this.timeService.getUnix(flightB.scheduled)
                ) {
                    if (
                        parseFloat(flightAInfo.flightNumber) >
                        parseFloat(flightBInfo.flightNumber)
                    )
                        return 1;
                    if (
                        parseFloat(flightAInfo.flightNumber) <
                        parseFloat(flightBInfo.flightNumber)
                    )
                        return -1;
                }

                if (
                    this.timeService.getUnix(flightA.scheduled) >
                    this.timeService.getUnix(flightB.scheduled)
                )
                    return 1;
                if (
                    this.timeService.getUnix(flightA.scheduled) <
                    this.timeService.getUnix(flightB.scheduled)
                )
                    return -1;
            } else if (subFilterValue == 'C') {
                let valueA = flightA.city.toUpperCase();
                let valueB = flightB.city.toUpperCase();
                if (valueA == valueB) {
                    if (
                        parseFloat(flightAInfo.flightNumber) >
                        parseFloat(flightBInfo.flightNumber)
                    )
                        return 1;
                    if (
                        parseFloat(flightAInfo.flightNumber) <
                        parseFloat(flightBInfo.flightNumber)
                    )
                        return -1;
                }
                if (valueA < valueB) {
                    return -1;
                }
                if (valueA > valueB) {
                    return 1;
                }
                return 0;
            } else {
                let valueA = flightA.gate;
                let valueB = flightB.gate;
                if (valueA == valueB) {
                    if (
                        parseFloat(flightAInfo.flightNumber) >
                        parseFloat(flightBInfo.flightNumber)
                    )
                        return 1;
                    if (
                        parseFloat(flightAInfo.flightNumber) <
                        parseFloat(flightBInfo.flightNumber)
                    )
                        return -1;
                }
                if (valueA < valueB) {
                    return -1;
                }
                if (valueA > valueB) {
                    return 1;
                }
                return 0;
            }
        });
    }

    addCategoryColor(setting) {
        let _s = { ...setting };
        _s.categories.forEach((category) => {
            switch (category.name) {
                case 'Dining':
                    category.color = '#8BB348';
                    break;
                case 'Shopping':
                    category.color = '#4E9CD5';
                    break;
                case 'Restrooms':
                case 'Toilets':
                case 'Facilities':
                    category.color = '#F2A424';
                    break;
                case 'Lounges':
                case 'Amenities':
                    category.color = '#D75759';
                    break;
                default:
                    category.color = '';
            }
        });

        return _s;
    }

    getFlightsStatusColor(status: string) {
        let statusColor: string = '';
        switch (status.toUpperCase()) {
            case 'CX':
                statusColor = 'red';
                break;
            case 'SC':
                statusColor = 'green';
                break;
            case 'IA':
                statusColor = 'green';
                break;
            case 'EN':
                statusColor = 'green';
                break;
            case 'DP':
                statusColor = 'green';
                break;
            case 'DL':
                statusColor = 'orange';
                break;
            case 'AR':
                statusColor = 'green';
                break;
            case 'ER':
                statusColor = 'green';
                break;
            case 'LN':
                statusColor = 'green';
                break;
            default:
                statusColor = '';
                break;
        }
        return statusColor;
    }

    getGlobalTimeZone() {
        return this.timeService.getGlobalTimeZone();
    }

    getGlobalDateZone() {
        return this.timeService.getGlobalDateZone();
    }

    randomizeBgImage(imageIndex) {
        this.pictureNumber = this.generateRandomNumber(imageIndex, 4);
        this.bgImage = `${this.bgImagePath}/${this.pictureNumber}.jpg`;

        this.store.dispatch(
            setRandomBgImage({ randomBgImage: this.pictureNumber })
        );
    }

    generateRandomNumber(itemIndex, arrayLength: number) {
        if (arrayLength == 1) return 0;
        let item = null;
        item = Math.floor(Math.random() * arrayLength);

        if (item == itemIndex) {
            for (let i = 1; i <= 5; i++) {
                if (i != item) return i;
            }
        }

        return item;
    }

    imageExists(url, callback) {
        var img = new Image();
        img.onload = () => {
            callback(true);
        };
        img.onerror = () => {
            callback(false);
        };
        img.src = url;
    }

    checkConnection() {
        if (!navigator.onLine)
            return this.store.dispatch(
                showDialog({
                    showDialog: true,
                    title: 'NETWORK_ERROR',
                    subTitle: ``,
                    showCloudFunction: false,
                })
            );
    }

    filterPois(inputValue, allPois, lang) {
        let poisArray = [...allPois];

        const searchedPoi = poisArray.filter((value) => {
            let keywordsLower: Array<string> = [];

            if (value.keywords && value.keywords.length > 0) {
                value.keywords.forEach((element) => {
                    keywordsLower.push(element.toLowerCase());
                });
            }

            if (
                value.name &&
                value.name.toLowerCase().includes(inputValue.toLowerCase())
            )
                return value;
            else if (
                value.shortName &&
                value.name.toLowerCase().includes(inputValue.toLowerCase())
            )
                return value;
            else if (
                keywordsLower &&
                keywordsLower.length > 0 &&
                keywordsLower.includes(inputValue.toLowerCase())
            )
                return value;
            else if (
                value.details[lang] &&
                value.details[lang].shortLocation
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
            )
                return value;
        });

        return searchedPoi;
    }

    filterFlight(
        inputValue,
        isDepartures,
        arrivals,
        departures,
        clearSearch: boolean = false
    ) {
        if (inputValue != '') {
            let poisArray = [];

            if (isDepartures) poisArray = departures;
            else poisArray = arrivals;

            let filterResult = this.filterFlightData(inputValue, poisArray);
            if (filterResult.length > 0) {
                this.store.dispatch(
                    flightSearch({
                        isFlightSearch: true,
                        isDepartureActive: isDepartures,
                        flights: filterResult,
                        searchError: false,
                    })
                );
            } else {
                if (clearSearch) {
                    this.store.dispatch(
                        flightSearch({
                            isFlightSearch: true,
                            isDepartureActive: isDepartures,
                            flights: [''],
                            searchError: true,
                        })
                    );
                } else {
                    if (isDepartures) poisArray = arrivals;
                    else poisArray = departures;

                    let newFilterResult = this.filterFlightData(
                        inputValue,
                        poisArray
                    );

                    if (newFilterResult.length == 0) {
                        this.store.dispatch(
                            flightSearch({
                                isFlightSearch: true,
                                isDepartureActive: isDepartures,
                                flights: [''],
                                searchError: true,
                            })
                        );
                    } else {
                        this.store.dispatch(
                            flightSearch({
                                isFlightSearch: true,
                                isDepartureActive: !isDepartures,
                                flights: newFilterResult,
                                searchError: false,
                            })
                        );
                    }
                }
            }
        }
    }

    filterFlightData(inputValue, poisArray) {
        let inputLowerCase = inputValue.toLowerCase();
        const searchedPoi = poisArray.filter((value) => {
            if (
                value.airlineName &&
                value.airlineName.toLowerCase().includes(inputLowerCase)
            )
                return value;
            else if (
                value.flightNumber &&
                value.flightNumber.includes(inputValue)
            )
                return value;
            else if (
                value.cityName &&
                value.cityName.toLowerCase().includes(inputLowerCase)
            )
                return value;
            else if (
                value.flightNumber &&
                value.flightNumber.toLowerCase().includes(inputLowerCase)
            )
                return value;
        });

        return searchedPoi;
    }
    trackByFunction(_, item) {
        return item;
    }
}
