<div
    class="video-player-container"
    [ngClass]="{
        'height-bigger-width':
            videoType === 'custom' &&
            customPlayer?.nativeElement?.offsetHeight >
                customPlayer?.nativeElement?.offsetWidth
    }"
>
    <div
        class="video-container"
        [ngClass]="{ 'adding-padding-top': videoType !== 'custom' }"
    >
        <youtube-player
            *ngIf="
                (videoType === 'youtube' || videoType === 'youtube-single') &&
                !isEmergencyStateEnabled
            "
            (stateChange)="onPlayerStateChange($event)"
            (error)="onPlayerStateChange($event)"
            (ready)="onPlayerReady($event)"
            [videoId]="videoToPlay"
            [playerVars]="playerVars"
            #youtubePlayer
        ></youtube-player>

        <div
            *ngIf="videoType == 'vimeo' && !isEmergencyStateEnabled"
            #vimeoPlayer
        ></div>

        <video
            #customPlayer
            autoplay
            muted
            *ngIf="videoType == 'custom' && !isEmergencyStateEnabled"
            (ended)="onVideoEnded()"
        >
            <source [src]="videoUrl" [type]="videoFormat" />
            Your browser does not support the video tag.
        </video>

        <div class="featured-video-overlay"></div>
    </div>
</div>
