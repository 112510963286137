/* src/app/components/partner-item/partner-item.component.scss */
.partner-item-container {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}
.partner-item-container .partner-item-icon {
  padding: 0.55em 0.55em;
  display: flex;
}
.partner-item-container .partner-item-icon .icon {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  font-weight: 400;
  height: 1.2em;
  width: 1.2em;
}
.partner-item-container .high-light {
  margin-right: 0.25em;
  font-size: 0.6em;
  font-weight: 400;
}
@media screen and (min-width: 1601px), (min-height: 870px) {
  .partner-item-container .partner-item-icon {
    padding: 1.1em;
  }
  .partner-item-container .partner-item-icon .icon {
    height: 2em;
    width: 2em;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.5);
  }
  .partner-item-container .high-light {
    margin-right: 0.5rem;
    font-size: 1em;
  }
  .partner-item-container-lax .partner-item-icon {
    padding: 1.1em !important;
  }
  .partner-item-container-lax .partner-item-icon .icon {
    height: 1.4em !important;
    width: 1.4em !important;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.5) !important;
  }
  .partner-item-container-lax .high-light {
    margin-right: 0.5rem !important;
    font-size: 0.8em !important;
  }
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .partner-item-container .partner-item-icon {
    padding: 1.1em !important;
  }
  .partner-item-container .partner-item-icon .icon {
    height: 2.4em !important;
    width: 2.4em !important;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.5) !important;
  }
  .partner-item-container .high-light {
    margin-right: 0.5rem !important;
    font-size: 1.4em !important;
  }
  .partner-item-container-lax .partner-item-icon {
    padding: 1.1em !important;
  }
  .partner-item-container-lax .partner-item-icon .icon {
    height: 3.4em !important;
    width: 3.4em !important;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.5) !important;
  }
  .partner-item-container-lax .high-light {
    margin-right: 0.5rem !important;
    font-size: 1.7em !important;
  }
}
/*# sourceMappingURL=partner-item.component-RJUQAIBR.css.map */
