/* src/app/pages/login/login.component.scss */
.login-page-container {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100vh;
}
mat-card {
  padding: 1rem;
}
.login-card-container {
  width: 620px !important;
  height: 380px !important;
}
.image-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.image-logo-container .image-container {
  width: 7em;
}
.image-logo-container .image-container img {
  width: 100%;
}
.alert-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.8;
  background: rgba(226, 6, 6, 0.747);
  border-color: rgba(226, 6, 6, 0.747);
}
.alert-container .alert-message-container {
  margin-right: 2em;
}
.login-loader {
  animation: spin 1s linear infinite;
}
.form-group {
  margin-bottom: 2em;
}
.form-label {
  padding-bottom: calc(0.375em + 1px);
  font-size: 0.95em;
}
.form-control {
  display: block;
  width: 95%;
  padding: 0.375em 0.75em;
  font-size: 0.85em;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.text-center {
  text-align: center;
}
.version-text {
  color: #777777;
  letter-spacing: 1px;
  font-size: 1em;
  margin-top: 0;
}
.submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: black;
  color: white;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0.25em;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  cursor: pointer;
  margin-bottom: 1em;
  width: 90px;
}
.submit-btn:hover {
  background: black;
}
.version-text {
  padding-bottom: 50px;
}
.copy-right-container {
  margin-bottom: 1em;
}
.login-loader {
  width: 0.5em;
  height: 0.5em;
  border: 2px dashed white;
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}
.alert-text {
  color: #dc3545 !important;
  margin-top: 0.25rem !important;
  font-size: 0.9em;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=login.component-XY2LHGM6.css.map */
