import {
  transition,
  sequence,
  animate,
  trigger,
  style,
  state,
} from "@angular/animations";

export const animationConfig = {
  subMenuAnimation: trigger("subMenuAnimation", [
    state(
      "true",
      style({ left: window.innerWidth < 1920 ? "480px" : "25%", zIndex: "3" })
    ),
    state("false", style({ left: "0" })),
    transition("* => *", animate("0.5s")),
  ]),
  subMenuInfoAnimation: trigger("subMenuInfoAnimation", [
    state(
      "false",
      style({
        height: "70%",
        zIndex: 4,
        left: window.innerWidth < 1920 ? "480px" : "25%",
      })
    ),
    state(
      "true",
      style({ left: 0, height: "100%", zIndex: 4, minWidth: "480px" })
    ),
    transition("false => true", [
      sequence([
        animate("0.3s", style({ height: "100%", left: 0, zIndex: 4 })),
      ]),
    ]),
    transition("true => false", [
      sequence([
        animate(
          "0.3s",
          style({
            left: window.innerWidth < 1920 ? "480px" : "25%",
            height: "70%",
            zIndex: 4,
          })
        ),
      ]),
    ]),
  ]),
  flightSearch: trigger("flightSearch", [
    state("true", style({ left: "0", zIndex: "4" })),
    transition("* => *", animate("0.5s")),
  ]),
  searchModelAnimation: trigger("searchModelAnimation", [
    state("true", style({ top: "0", zIndex: "20" })),
    state("false", style({ top: "1080px", zIndex: "3" })),
    transition("* => *", animate("0.5s")),
  ]),
  //basically responsible the slide in and out that happens when we switch categories only !! [DONE]
  slide: trigger("slideInOut", [
    transition(":enter", [
      style({ left: "-500px" }),
      animate("0.5s", style({ left: "0px" })),
    ]),
    transition(":leave", [
      style({ left: "0px" }),
      animate("0.5s", style({ left: "-500px" })),
    ]),
  ]),
  //FADE IN FADE OUT IS WORKING AS INTENDED [DONE]
  fadeInOut: trigger("fadeInOut", [
    transition(":enter", [
      style({ opacity: 0 }),
      animate("0.5s", style({ opacity: 1 })),
    ]),
    transition(":leave", [
      style({ opacity: 1 }),
      animate("0.5s", style({ opacity: 0 })),
    ]),
  ]),
  dimensionsTransition: trigger("dimensionsTransition", [
    transition(":enter", [
      style({ width: "21vw", height: "31vh" }),
      animate("1s", style({ width: "50vw", height: "50vh" })),
    ]),
    transition(":leave", [
      style({ width: "50vw", height: "50vh" }),
      animate("1s", style({ width: "21vw", height: "31vh" })),
    ]),
  ]),
  //This flightSearchAnimation is triggered with a boolean name isFlightSearch
  flightSearchAnimation: [
    trigger("openClose", [
      state(
        "true",
        style({
          left: "-68%",
          top: "-13%",
          "z-index": "3",
          height: "115%",
        })
      ),
      state(
        "false",
        style({ left: "0%", top: "0%", "z-index": "0", height: "100%" })
      ),
      transition("false <=> true", animate("0.25s")),
    ]),
  ],
  flightSearchAnimationLax: [
    trigger("openCloseLax", [
      state(
        "true",
        style({
          left: "-100%",
          top: "0%",
          "z-index": "3",
          height: "115%",
        })
      ),
      state(
        "false",
        style({ left: "0%", top: "0%", "z-index": "0", height: "100%" })
      ),
      transition("false <=> true", animate("0.25s")),
    ]),
  ],
  expansion: [
    trigger("expandCollapse", [
      state(
        "true",
        style({
          height: "*",
          overflow: "hidden",
        })
      ),
      state(
        "false",
        style({
          height: "0px",
          overflow: "hidden",
        })
      ),
      transition("false <=> true", animate("300ms")),
    ]),
  ],
  collapseImage: [
    trigger("expandCollapseImage", [
      state(
        "true",
        style({
          height: "*",
          opacity: "1",
        })
      ),
      state(
        "false",
        style({
          height: "0px",
          opacity: "0",
        })
      ),
      transition("false <=> true", animate("250ms")),
    ]),
  ],
  fadeInOutBgColor: [
    trigger("fadeInOutBgColor", [
      state("true", style({ background: "white" })),
      state("false", style({ background: "transparent" })),
      transition("0 <=> 1", animate("1000ms linear")),
    ]),
  ],
  fadeInOutBgImage: [
    trigger("fadeInOutBgImage", [
      state("true", style({ opacity: 1 })),
      state("false", style({ opacity: 0 })),
      transition("0 <=> 1", animate("1s linear")),
    ]),
  ],
};
