/* src/app/components/video-player/video-player.component.scss */
.video-player-container {
  width: 60%;
  z-index: 5;
  display: block;
}
.video-player-container .video-container {
  position: relative;
  width: 100%;
}
.video-player-container .video-container ::ng-deep iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.video-player-container .video-container .featured-video-overlay {
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  z-index: 50;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
video {
  width: 100%;
  height: 100%;
}
.adding-padding-top {
  padding-top: 56.25%;
}
.height-bigger-width {
  width: unset !important;
  aspect-ratio: unset !important;
}
/*# sourceMappingURL=video-player.component-5B6QAR5S.css.map */
