import dataBusReducer from "./dataBus.reducer";
import flightReducer from "./flight.reducer";
import poiReducer from "./poi.reducer";
import uiReducer from "./ui.reducer";
import animationReducer from "./animation.reducers";
import agentReducer from "./agent.reducer";
export const combinedReducers = {
  dataBus: dataBusReducer,
  flights: flightReducer,
  poi: poiReducer,
  ui: uiReducer,
  ai: animationReducer,
  agent: agentReducer
};
