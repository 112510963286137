/* src/app/components/poi-item/poi-item.component.scss */
.poi-item-container {
  background: white;
  height: 6.5em;
  display: flex;
  width: 100%;
}
.poi-item-container .poi-info-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0%;
}
.poi-item-container .poi-info-container .poi-image-container .image {
  justify-content: center;
  pointer-events: none;
  align-items: center;
  border-radius: 5%;
  width: 3.5em;
  height: 3.5em;
  background-position: contains;
  background-size: cover;
  background-repeat: no-repeat;
}
.poi-item-container .poi-info-container .poi-image-container .image img::before {
  color: black;
  font-size: 1.3em;
}
.poi-item-container .poi-info-container .poi-image-container .imageLAX {
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  background-size: contain;
  pointer-events: none;
  align-items: center;
  position: relative;
  border-radius: 5%;
  height: 6em;
  width: 6em;
}
.poi-item-container .poi-info-container .poi-image-container .imageLAX img::before {
  color: black;
  font-size: 1.3em;
}
.poi-item-container .poi-info-container .poi-image-container .imageLAX .static-icon-container {
  justify-content: center;
  pointer-events: none;
  align-items: center;
  border-radius: 5%;
  width: 4.5em;
  height: 4.5em;
}
.poi-item-container .poi-info-container .poi-image-container .imageLAX .static-icon-container .material-icons {
  font-size: 2.6em;
}
.poi-item-container .poi-info-container .poi-image-container .cover-image {
  background-size: cover;
}
.poi-item-container .poi-info-container .poi-image-container .videoAspectRatio {
  padding-top: 56.25% !important;
  height: 0em !important;
}
.poi-item-container .poi-info-container .poi-image-container .play-icon {
  position: absolute;
  font-size: 38px;
  color: white;
  left: 28%;
  top: 16%;
}
.poi-item-container .poi-info-container .poi-image-container .letter-container {
  box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 3.2em;
  width: 3.2em;
}
.poi-item-container .poi-info-container .poi-image-container .letter-container .letter {
  text-transform: capitalize;
  font-size: 1.3em;
  color: black;
}
.poi-item-container .poi-info-container .poi-image-container .letter-container-lax {
  box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 4.25em;
  width: 4.25em;
}
.poi-item-container .poi-info-container .poi-image-container .letter-container-lax .letter-lax {
  text-transform: capitalize;
  color: black;
  font-size: 1.5em;
}
.poi-item-container .poi-info-container .media-container {
  display: flex;
  min-width: 20%;
  justify-content: center;
  align-items: center;
}
.poi-item-container .poi-info-container .media-container .icon-container {
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.poi-item-container .poi-info-container .emptyFlex {
  width: 2em;
}
.poi-item-container .poi-info-container .letterCircleEmptyFlex {
  width: 1.5em;
}
.poi-item-container .poi-info-container .info-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  min-width: 60%;
}
.poi-item-container .poi-info-container .info-container .poi-title-text {
  transition: font-size 500ms linear;
  padding-bottom: 1%;
  font-size: 0.9em;
  font-weight: 600;
  color: black;
  flex: 1;
  text-transform: capitalize;
}
.poi-item-container .poi-info-container .info-container .poi-title-text-lax {
  transition: font-size 500ms linear;
  padding-bottom: 1%;
  font-size: 0.9em;
  font-weight: 600;
  color: black;
  flex: 1;
  text-transform: capitalize;
}
.poi-item-container .poi-info-container .info-container .poi-subtitle {
  transition: font-size 500ms linear;
  font-size: 0.7em;
  color: black;
  text-transform: capitalize;
}
.poi-item-container .poi-info-container .info-container .poi-subtitle-lax {
  transition: font-size 500ms linear;
  font-size: 0.7em;
  color: black;
  text-transform: capitalize;
}
.poi-item-container .poi-info-container .poi-title-text-nowrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.poi-item-container .poi-info-container .walkTime-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-width: 15%;
}
.poi-item-container .poi-info-container .walkTime-container .walkTime-icon {
  text-align: center !important;
}
.poi-item-container .poi-info-container .walkTime-container .walkTime-icon .material-icons {
  color: black;
  font-size: 1.5em;
  transition: font-size 500ms linear;
}
.poi-item-container .poi-info-container .walkTime-container .walkTime-icon-lax {
  text-align: center !important;
}
.poi-item-container .poi-info-container .walkTime-container .walkTime-icon-lax .material-icons {
  color: #00a6ce;
  font-size: 1.5em;
  transition: font-size 500ms linear;
}
.poi-item-container .poi-info-container .walkTime-container .walkTime-value {
  color: black;
  font-size: 0.8em;
  font-weight: 700;
  transition: font-size 500ms linear;
}
.poi-item-container .poi-info-container .walkTime-container .walkTime-value-lax {
  color: #00a6ce;
  font-size: 0.7em;
  font-weight: 700;
  transition: font-size 500ms linear;
}
.active-poi-item-container {
  background-color: black !important;
}
.active-poi-item-container .letter-container {
  background: white !important;
}
.active-poi-item-container .letter-container-lax {
  background: white !important;
}
.active-poi-item-container .poi-title-text {
  color: white !important;
}
.active-poi-item-container .poi-title-text-lax {
  color: white !important;
}
.active-poi-item-container .poi-subtitle {
  color: white !important;
}
.active-poi-item-container .poi-subtitle-lax {
  color: white !important;
}
.active-poi-item-container .walkTime-container .material-icons {
  color: white !important;
}
.active-poi-item-container .walkTime-container .walkTime-value {
  color: white !important;
}
.active-poi-item-container .walkTime-container .walkTime-value-lax {
  color: white !important;
}
.sub-menu-icon {
  font-size: 2em;
  color: black;
}
.poi-title-text {
  font-size: 1em !important;
  transition: font-size 500ms linear;
}
.poi-title-text-lax {
  font-size: 1.2em !important;
  transition: font-size 500ms linear;
}
.poi-subtitle {
  font-size: 0.8em !important;
  transition: font-size 500ms linear;
}
.poi-subtitle-lax {
  font-size: 1.2em !important;
  transition: font-size 500ms linear;
}
.rating {
  flex: 1;
  color: white;
}
@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .play-icon {
    font-size: 38px !important;
    left: 28% !important;
    top: 19% !important;
  }
  .static-icon-container .material-icons {
    font-size: 5.5em !important;
  }
  .letter-container-lax .letter-lax {
    font-size: 1.5em !important;
  }
}
@media screen and (min-width: 3840px) and (min-height: 2160px) {
  .media-container {
    margin-left: 3em !important;
  }
  .poi-item-container {
    height: 17em !important;
  }
  .emptyFlex {
    width: 2.4em !important;
  }
  .letterCircleEmptyFlex {
    width: 5.1em !important;
  }
  .poi-image-container .image {
    width: 7em !important;
    height: 7em !important;
  }
  .poi-image-container .image img::before {
    color: black;
    font-size: 1.3em;
  }
  .poi-image-container .imageLAX {
    border-radius: 5%;
    width: 15em !important;
    height: 15em !important;
  }
  .poi-image-container .imageLAX img::before {
    color: black;
    font-size: 1.3em;
  }
  .poi-image-container .play-icon {
    font-size: 80px !important;
    left: 33% !important;
    top: 24% !important;
  }
  .poi-image-container .static-icon-container .material-icons {
    font-size: 15em !important;
  }
  .poi-image-container .letter-container {
    height: 7em !important;
    width: 7em !important;
    box-shadow: 0px -1px 15px 1px rgba(0, 0, 0, 0.5) !important;
  }
  .poi-image-container .letter-container .letter {
    font-size: 2.6em !important;
  }
  .poi-image-container .letter-container-lax {
    height: 10em !important;
    width: 10em !important;
    box-shadow: 0px -1px 15px 1px rgba(0, 0, 0, 0.5) !important;
  }
  .poi-image-container .letter-container-lax .letter-lax {
    font-size: 3.5em !important;
  }
  .arrow-left-icon {
    font-size: 5em !important;
    transition: font-size 500ms linear;
  }
  .poi-title-text {
    font-size: 2.2em !important;
    transition: font-size 500ms linear;
  }
  .poi-subtitle {
    font-size: 1.8em !important;
    transition: font-size 500ms linear;
  }
  .poi-title-text-lax {
    font-size: 2.4em !important;
    transition: font-size 500ms linear;
    padding-bottom: 6%;
    padding-left: 1.5em !important;
  }
  .poi-subtitle-lax {
    font-size: 2.3em !important;
    transition: font-size 500ms linear;
    padding-left: 1.5em !important;
  }
  .walkTime-icon .material-icons {
    font-size: 4em !important;
    transition: font-size 500ms linear;
  }
  .walkTime-icon-lax .material-icons {
    font-size: 4em !important;
    transition: font-size 500ms linear;
  }
  .walkTime-value {
    font-size: 1.5em !important;
    transition: font-size 500ms linear;
  }
  .walkTime-value-lax {
    font-size: 1.8em !important;
    transition: font-size 500ms linear;
  }
  .poi-info-container {
    padding: 0% !important;
  }
}
/*# sourceMappingURL=poi-item.component-RFNQTCZU.css.map */
