/* src/app/components/featured-hotel/featured-hotel.component.scss */
.featured-hotel-container {
  position: relative;
  display: flex;
  flex: 1;
}
.featured-hotel-container .image-container {
  flex: 1;
  aspect-ratio: 3/2;
  width: 100%;
  height: auto;
}
.featured-hotel-container .image-container img {
  width: 100%;
}
.featured-hotel-container .overlay {
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  left: 0;
  flex: 1;
  top: 0;
}
.featured-hotel-container .footer-container {
  background: transparent;
  flex-direction: column;
  max-height: 5em;
  display: flex;
  flex: 0.3;
}
.featured-hotel-container .footer-container .title-container {
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 3%;
}
.featured-hotel-container .footer-container .title-container .title {
  flex: 1;
  font-weight: bolder;
  color: white;
  font-weight: bold;
  text-transform: capitalize;
}
.featured-hotel-container .footer-container .rating-container {
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 3%;
  padding-bottom: 3%;
}
.title-container .title {
  font-size: 0.9em;
  transition: font-size 500ms linear;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .title-container .title {
    font-size: 2em !important;
    transition: font-size 500ms linear;
  }
  .footer-container {
    max-height: 10em !important;
  }
}
/*# sourceMappingURL=featured-hotel.component-X6YBO636.css.map */
