<div class="snack-bar-container">
  <div class="snack-bar-message-container">
    <span class="message-container">
      {{ "CONNECTION_LOST_RETRY" | translate }}
    </span>
  </div>

  <div class="snack-bar-action-container">
    <div class="action-container" (click)="onRetryButtonPress()">
      <span class="action"> {{ "RETRY" | translate }} </span>
    </div>
  </div>
</div>
