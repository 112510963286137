<div
    class="flight-item"
    [ngClass]="{
        'active-flight-item-container-height': hasBackArrow,
        'active-flight-item-container-lax': data.id == flightSelected
    }"
    [ngStyle]="{
        'padding-bottom': data.codeShareFlights[0] !== '' ? '0em' : '1em'
    }"
>
    <div
        class="flight-item-container"
        [ngClass]="{
            'active-flight-item-container-lax': data.id == flightSelected
        }"
    >
        <!--back arrow-->
        <div
            class="arrow-icon-container"
            [ngStyle]="{
                'border-bottom':
                    data.codeShareFlights[0] != ''
                        ? '1px solid rgba(199, 199, 199, 0.5)'
                        : '0px solid rgba(199, 199, 199, 0.5)'
            }"
            (click)="close()"
            *ngIf="hasBackArrow"
        >
            <i class="material-icons arrow-left-icon-lax"> arrow_back </i>
        </div>

        <div
            class="flight-icon-lax"
            [ngClass]="{
                'flight-icon-lax-selected': hasBackArrow,
                iconBottomBorder: data.codeShareFlights[0] != ''
            }"
        >
            <div class="icon-container">
                <div
                    class="airLineCaption"
                    [ngClass]="{
                        'active-flight-item-color':
                            data.id == flightSelected && !hasBackArrow,
                        'box-shadow-style': true
                    }"
                >
                    <span class="flightNumber-text">
                        {{ data.flightNumber.substring(0, 2) }}</span
                    >
                </div>
                <img
                    [src]="imgLogo"
                    #imgSection
                    class="icon"
                    onerror="this.style.display= 'none'"
                    [ngClass]="{
                        'box-shadow-style': hasBackArrow ? false : true
                    }"
                />
            </div>
        </div>
        <div
            class="flight-information-lax"
            [ngClass]="{
                'active-flight-information': data.id == flightSelected
            }"
        >
            <div class="airline-info">
                <div class="airline-details">
                    <div class="title">
                        <span>{{ data.cityName }}</span>
                    </div>
                    <div class="sub-title">
                        <span>{{ data.airlineName }}</span>
                    </div>
                </div>
                <div class="gate-details" *ngIf="isGate">
                    <div class="letter-container">
                        <div class="gate-number">
                            <span>{{ data.gate }}</span>
                        </div>
                        <div class="walk-time-container">
                            <div class="walk-time-icon">
                                <i class="material-icons" *ngIf="walkTime">
                                    directions_walk
                                </i>
                            </div>
                            <div class="walk-time-value">
                                <span>{{ walkTime }}</span>
                                <!-- static for now -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="schedule-container">
                <!-- 'background-color':data.codeShareFlights[0] != ''? data.id == flightSelected && !hasBackArrow? '$primary': '#f6f7fb': 'transparent' -->
                <div
                    class="flight-container"
                    class=""
                    [ngClass]="{
                        bordersRTL: data.codeShareFlights[0] != '',
                        'no-codeShareFlights-bg-color':
                            data.codeShareFlights[0] == '',
                        'codeShareFlights-bg-color-with-no-arrow-lax':
                            data.codeShareFlights[0] != '' &&
                            data.id == flightSelected &&
                            !hasBackArrow,
                        'codeShareFlights-bg-color-with-back-arrow-lax':
                            data.codeShareFlights[0] != '' &&
                            data.id == flightSelected &&
                            hasBackArrow
                    }"
                >
                    <!-- [ngStyle]="{
        'background-color':
        data.codeShareFlights[0] != ''
        ? data.id == flightSelected && !hasBackArrow
        ? '$primary'
        : '#f6f7fb'
        : 'transparent'
        }" -->
                    <span class="text">{{ 'FLIGHT' | translate }}</span>
                    <span class="high-light">{{ data.flightNumber }}</span>
                </div>
                <div
                    class="departure-time-container"
                    [ngClass]="{ bottomBorder: data.codeShareFlights[0] != '' }"
                >
                    <span class="text" *ngIf="isDepartureActive">{{
                        'DEPARTURE' | translate
                    }}</span>
                    <span class="text" *ngIf="!isDepartureActive">{{
                        'ARRIVAL' | translate
                    }}</span>
                    <span
                        class="high-light"
                        [ngStyle]="{
                            color: data.timeColor
                        }"
                        >{{ data.timeToShow }}</span
                    >
                </div>
                <div
                    class="status-container"
                    [ngClass]="{ bottomBorder: data.codeShareFlights[0] != '' }"
                >
                    <!-- <span class="text">{{ "STATUS" | translate }}</span> -->
                    <span
                        class="high-light"
                        [ngClass]="{
                            clearStatus: data.statusColor == '',
                            delayedStatus: data.statusColor == 'orange',
                            canceledStatus: data.statusColor == 'red',
                            arrivedStatus: data.statusColor == 'green'
                        }"
                        [ngStyle]="{
                            'line-height': data.statusColor == '' ? 1 : 1.5
                        }"
                        >{{ data.statusToShow | translate }}</span
                    >
                </div>
            </div>
        </div>
    </div>
    <div class="partner-info">
        <app-flight-partner-row
            [partnerData]="data"
            [flightSelected]="flightSelected"
            [hasBackArrow]="hasBackArrow"
        >
        </app-flight-partner-row>
    </div>
</div>
