/* src/app/components/sub-menu-listing/sub-menu-listing.component.scss */
.listing-container {
  flex-direction: column;
  background: white;
  display: flex;
  height: 100%;
}
.listing-container .categories-listing {
  flex-direction: column;
  display: flex;
}
.listing-container .categories-listing .category-listing-title {
  align-items: center;
  padding-left: 1.2em;
  padding-top: 6%;
  display: flex;
  flex: 0.2;
}
.listing-container .categories-listing .category-listing-title .category-title {
  color: #bebcbc;
  font-size: 0.85em;
}
.listing-container .categories-listing .category-listing {
  min-height: 230px;
  overflow: auto;
  flex: 1;
}
.listing-container .categories-listing .line {
  border-bottom: 1px solid #bebcbc;
  margin: 0% 4%;
}
.listing-container .sub-menu-listing-container {
  scrollbar-width: none;
  background-color: white;
  flex-direction: column;
  overflow: auto;
  display: flex;
  flex: 1;
}
.listing-container .sub-menu-listing-container .poi-container-display-none {
  display: none;
}
.listing-container .sub-menu-listing-container .poi-container-display-block {
  margin-top: 1em;
  display: block;
}
.listing-container .sub-menu-listing-container .flight-filter-container {
  flex-direction: column;
  display: flex;
}
.listing-container .sub-menu-listing-container .flight-filter-container .main-filter {
  background: #ebebeb;
  display: flex;
  flex: 1;
}
.listing-container .sub-menu-listing-container .flight-filter-container .main-filter .arrival-container,
.listing-container .sub-menu-listing-container .flight-filter-container .main-filter .departure-container {
  justify-items: flex-start;
  align-items: center;
  padding-left: 3%;
  display: flex;
  flex: 1;
}
.listing-container .sub-menu-listing-container .flight-filter-container .main-filter .arrival-container i,
.listing-container .sub-menu-listing-container .flight-filter-container .main-filter .departure-container i {
  color: black;
  font-size: 1.4em;
}
.listing-container .sub-menu-listing-container .flight-filter-container .main-filter .arrival-container span,
.listing-container .sub-menu-listing-container .flight-filter-container .main-filter .departure-container span {
  padding-left: 3%;
  color: black;
}
.listing-container .sub-menu-listing-container .flight-filter-container .main-filter .main-tab-active {
  background: black;
  font-weight: 500;
}
.listing-container .sub-menu-listing-container .flight-filter-container .main-filter .main-tab-active i {
  color: white !important;
}
.listing-container .sub-menu-listing-container .flight-filter-container .main-filter .main-tab-active span {
  color: white !important;
}
.listing-container .sub-menu-listing-container .flight-filter-container .line-container {
  height: 1px;
  width: 100%;
  padding: 0;
  margin: 0;
}
.listing-container .sub-menu-listing-container .flight-filter-container .line-container .hrStyle {
  border: 0.1px solid;
  color: #ebebeb;
  padding: 0;
  margin: 0;
}
.listing-container .sub-menu-listing-container .flight-filter-container .sub-filter {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: black;
}
.listing-container .sub-menu-listing-container .flight-filter-container .sub-filter .search-content {
  display: flex;
  flex: 1;
  width: 100%;
  height: 70%;
  padding: 0.6em;
}
.listing-container .sub-menu-listing-container .flight-filter-container .sub-filter .search-content .search-button-container {
  display: flex;
  flex: 0.9;
}
.listing-container .sub-menu-listing-container .flight-filter-container .sub-filter .search-content .search-button {
  flex: 1;
  padding-left: 0.5em;
  text-align: start;
  font-size: 1em;
  color: black;
  outline: none;
  margin: 0%;
  border: 0;
  background-color: white;
}
.listing-container .sub-menu-listing-container .flight-filter-container .sub-filter .search-content .search-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.1;
  background-color: white;
}
.listing-container .sub-menu-listing-container .flight-filter-container .sub-filter .search-content .search-icon-container .search-icon {
  color: black;
  font-weight: 100;
}
.listing-container .sub-menu-listing-container .flight-filter-container .sub-filter .active-sub-tab {
  border-radius: 4px;
  background: white;
}
.listing-container .sub-menu-listing-container .flight-filter-container .sub-filter .active-sub-tab span {
  color: black;
}
.listing-container .sub-menu-listing-container .flight-filter-container-height {
  min-height: 18%;
  height: 18%;
}
.listing-container .sub-menu-listing-container .flight-filter-container-thin {
  min-height: 8%;
  height: 8%;
}
.listing-container .sub-menu-listing-container .sub-filter {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: black;
}
.listing-container .sub-menu-listing-container .sub-filter .search-content {
  display: flex;
  flex: 1;
  width: 100%;
  height: 70%;
  padding: 0.6em;
}
.listing-container .sub-menu-listing-container .sub-filter .search-content .search-button-container {
  display: flex;
  flex: 0.9;
}
.listing-container .sub-menu-listing-container .sub-filter .search-content .search-button {
  flex: 1;
  padding-left: 0.5em;
  text-align: start;
  font-size: 1em;
  color: black;
  outline: none;
  margin: 0%;
  border: 0;
  background-color: white;
}
.listing-container .sub-menu-listing-container .sub-filter .search-content .search-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.1;
  background-color: white;
}
.listing-container .sub-menu-listing-container .sub-filter .search-content .search-icon-container .search-icon {
  color: black;
  font-weight: 100;
}
.listing-container .sub-menu-listing-container .sub-filter .active-sub-tab {
  border-radius: 4px;
  background: white;
}
.listing-container .sub-menu-listing-container .sub-filter .active-sub-tab span {
  color: black;
}
.listing-container .flight-filter-container-height {
  min-height: 18%;
  height: 18%;
}
.listing-container .flight-filter-container-thin {
  min-height: 8%;
  height: 8%;
}
.listing-container .search-error-container {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 1.2em;
  display: flex;
  height: 100%;
}
.listing-container .search-error-container .search-value {
  text-align: center;
}
.listing-container .search-error-container .search-icon-container {
  background-color: white;
  align-items: center;
  display: flex;
}
.listing-container .search-error-container .search-icon-container .search-icon {
  font-weight: 100;
  color: black;
  font-size: 2em;
}
.list-header-container {
  background-color: black;
  min-height: 9%;
  display: flex;
  height: 9%;
  z-index: 2;
}
.list-header-container .left-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 0.2;
}
.list-header-container .left-container .material-icons {
  margin-right: 22%;
  cursor: pointer;
  color: white;
}
.list-header-container .middle-container {
  flex: 0.6;
  display: flex;
}
.list-header-container .middle-container .info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.list-header-container .middle-container .info-container .flight-title {
  font-weight: bold;
  font-size: 1em;
  color: white;
  transition: font-size 500ms linear;
}
.list-header-container .middle-container .info-container .flight-subtitle {
  color: white;
  font-size: 0.9em;
  transition: font-size 500ms linear;
}
.list-header-container .right-container {
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-header-container .right-container .icon-container {
  flex: 1;
}
.list-header-container .right-container .icon-container .letter-container {
  width: 3em;
  height: 3em;
  transition: width 500ms linear;
  transition: height 500ms linear;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9cd2e;
  border-radius: 10px;
}
.list-header-container .right-container .icon-container .letter-container span {
  color: white;
  font-size: 1.2em;
}
.list-header-container .right-container .icon-container .icon {
  box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.list-container {
  flex: 1;
}
.list-container .list-item .title-container {
  align-items: center;
  display: flex;
  height: 30px;
}
.list-container .list-item .title-container span {
  padding-left: 10px;
  font-weight: 500;
  color: black;
}
.list-container .list-item .gates-container {
  align-items: center;
  padding-left: 8px;
  flex-wrap: wrap;
  display: flex;
  height: 40px;
}
.list-container .list-item .gates-container .letter-container {
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 3px 3px;
  color: white;
  display: flex;
  height: 25px;
  width: 25px;
}
.list-container .list-item .gates-container .letter-container app-button-icon {
  height: 100%;
  width: 100%;
}
.marine-color {
  background-color: #4089bd;
}
.red-color {
  background-color: #d00607;
}
.yellow-color {
  background-color: #e9cd2e;
}
.marine-color app-button-icon,
.red-color app-button-icon,
.yellow-color app-button-icon {
  height: unset !important;
}
.title-container span {
  transition: font-size 500ms linear;
  font-size: 1.2em;
}
.left-container .material-icons {
  transition: font-size 500ms linear;
  font-size: 3em;
}
.middle-container .flight-title {
  font-size: 1.1em !important;
  transition: font-size 500ms linear;
}
.middle-container .flight-subtitle {
  font-size: 0.9em !important;
  transition: font-size 500ms linear;
}
.right-container .letter-container {
  width: 3em !important;
  height: 3em !important;
  transition: width 500ms linear;
  transition: height 500ms linear;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .sub-menu-listing-container .flight-filter-container .main-filter .arrival-container,
  .sub-menu-listing-container .flight-filter-container .main-filter .departure-container {
    padding-left: 3%;
  }
  .sub-menu-listing-container .flight-filter-container .main-filter .arrival-container i,
  .sub-menu-listing-container .flight-filter-container .main-filter .departure-container i {
    font-size: 4em !important;
  }
  .sub-menu-listing-container .flight-filter-container .main-filter .arrival-container span,
  .sub-menu-listing-container .flight-filter-container .main-filter .departure-container span {
    font-size: 2.2em !important;
  }
  .sub-menu-listing-container .flight-filter-container .main-filter .main-tab-active span {
    font-size: 2.2em !important;
  }
  .sub-menu-listing-container .flight-filter-container .line-container .hrStyle {
    border: 0 solid !important;
    color: #ebebeb;
    padding: 0;
    margin: 0;
  }
  .sub-menu-listing-container .flight-filter-container .sub-filter .search-content {
    display: flex;
    flex: 1;
    width: 100%;
    height: 70%;
    padding: 1.3em !important;
  }
  .sub-menu-listing-container .flight-filter-container .sub-filter .search-content .search-button-container {
    display: flex;
    flex: 0.9;
  }
  .sub-menu-listing-container .flight-filter-container .sub-filter .search-content .search-button {
    padding-left: 1em !important;
    font-size: 1.8em !important;
  }
  .sub-menu-listing-container .flight-filter-container .sub-filter .search-content .search-icon-container .search-icon {
    font-size: 3.5em !important;
  }
  .sub-menu-listing-container .flight-filter-container .sub-filter .active-sub-tab span {
    color: black;
    font-size: 2em !important;
  }
  .sub-menu-listing-container .flight-filter-container-height {
    min-height: 18%;
    height: 18%;
  }
  .sub-menu-listing-container .flight-filter-container-thin {
    min-height: 8%;
    height: 8%;
  }
  .sub-menu-listing-container .search-error-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 1.2em;
    display: flex;
    height: 100%;
  }
  .sub-menu-listing-container .search-error-container .search-value {
    text-align: center;
  }
  .sub-menu-listing-container .search-error-container .search-icon-container {
    background-color: white;
    align-items: center;
    display: flex;
  }
  .sub-menu-listing-container .search-error-container .search-icon-container .search-icon {
    font-weight: 100;
    color: black;
    font-size: 2em;
  }
  .list-header-container {
    background-color: black;
    min-height: 9%;
    display: flex;
    height: 9%;
    z-index: 2;
  }
  .list-header-container .left-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 0.2;
  }
  .list-header-container .left-container .material-icons {
    margin-right: 22%;
    cursor: pointer;
    color: white;
  }
  .list-header-container .middle-container {
    flex: 0.6;
    display: flex;
  }
  .list-header-container .middle-container .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .list-header-container .middle-container .info-container .flight-title {
    font-weight: bold;
    font-size: 1em;
    color: white;
    transition: font-size 500ms linear;
  }
  .list-header-container .middle-container .info-container .flight-subtitle {
    color: white;
    font-size: 0.9em;
    transition: font-size 500ms linear;
  }
  .list-header-container .right-container {
    flex: 0.2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list-header-container .right-container .icon-container {
    flex: 1;
  }
  .list-header-container .right-container .icon-container .letter-container {
    width: 3em;
    height: 3em;
    transition: width 500ms linear;
    transition: height 500ms linear;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9cd2e;
    border-radius: 10px;
  }
  .list-header-container .right-container .icon-container .letter-container span {
    color: white;
    font-size: 1.2em;
  }
  .list-header-container .right-container .icon-container .icon {
    box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }
  .list-container {
    flex: 1;
  }
  .list-container .list-item .title-container {
    align-items: center;
    display: flex;
    height: 30px;
  }
  .list-container .list-item .title-container span {
    padding-left: 10px;
    font-weight: 500;
    color: black;
  }
  .list-container .list-item .gates-container {
    align-items: center;
    padding-left: 8px;
    flex-wrap: wrap;
    display: flex;
    height: 40px;
  }
  .list-container .list-item .gates-container .letter-container {
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 3px 3px;
    color: white;
    display: flex;
    height: 25px;
    width: 25px;
  }
  .list-container .list-item .gates-container .letter-container app-button-icon {
    height: 100%;
    width: 100%;
  }
  app-flight-item {
    min-height: 10em !important;
  }
}
/*# sourceMappingURL=sub-menu-listing.component-RCSNM7RK.css.map */
