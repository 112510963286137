import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import {
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
} from '@angular/cdk/scrolling';
import { EventHubService } from '@app/services/event-hub.service';
import { WebRTCService } from '@app/services/web-rtc.service';
import { UtilityService } from '@app/services/utility.service';
import { MapService } from '@app/services/map.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import {
    selectDataBus,
    selectFlight,
    selectPOI,
    selectUI,
} from '../../store/selectors';
import {
    toggleSearchBarModel,
    toggleAnimationState,
    departuresScrolling,
    toggleSideMenuInfo,
    toggleSubSideMenu,
    arrivalScrolling,
    setSelectedPoi,
    flightSearch,
    flightType,
} from '../../store/actions';

import {
    EventEmitter,
    ElementRef,
    ViewChild,
    Component,
    OnInit,
    Output,
    Input,
} from '@angular/core';
import { AppConfig } from '../../../environments/environment';
import { SortFlightsPipe } from '../../services/pipes/sortFlights.pipe';
import { FLightItemComponent } from '../flight-item/flight-item.component';
import { HotelItemComponent } from '../hotel-item/hotel-item.component';
import { POIComponent } from '../poi-item/poi-item.component';
import { NgIf, NgFor, NgClass, NgStyle, UpperCasePipe } from '@angular/common';

@Component({
    selector: 'app-sub-menu-listing',
    templateUrl: './sub-menu-listing.component.html',
    styleUrls: ['./sub-menu-listing.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgClass,
        NgStyle,
        POIComponent,
        HotelItemComponent,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        FLightItemComponent,
        UpperCasePipe,
        TranslateModule,
        SortFlightsPipe,
    ],
})
export class SubMenuListingComponent implements OnInit {
    @ViewChild('scrollEvent') scrollEvent: ElementRef;
    @ViewChild('flightSectionFilter')
    flightSectionFilter: CdkVirtualScrollViewport;
    @ViewChild('flightSection')
    flightSection: CdkVirtualScrollViewport;

    @Output('onClose')
    onClose = new EventEmitter();
    @Input('selectedCategory')
    selectedCategory: string;

    // concourseData: ConcourseListing = concourseListingDummyData.concourseData;
    // terminalData: TerminalListing = terminalDummyData.data;
    // gateData: GateListing = gateListingDummyData.gateData;

    boardingGateSelected: number = null;
    isDepartureActive: boolean = true;
    sortValue: string = 'cityName';
    departuresFlightItemData = [];
    flightSelected: number = null;
    isSearchBarModalOpen: boolean;
    arrivalsFlightItemData = [];
    virtualScrollBuffer = 1200;
    subTabFilter: string = 'C';
    isFlightSearch: boolean;
    departureScroll: number;
    recentListingData = [];
    arrivalScroll: number;
    poiId: number = null;
    terminalType: string;
    searchError: boolean;
    flightItemData = [];
    searchValue: string;
    recentSelected: any;
    isSubsideMenuInfo;
    poiListingData;
    categoryInfo;
    flights = [];
    poiData;

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private webRTCService: WebRTCService,
        public translate: TranslateService,
        private eventHub: EventHubService,
        private utility: UtilityService,
        private mapService: MapService,
        private store: Store<any>
    ) {
        mapService.resetMap();
        this.webRTCService.toggleSideMenu.subscribe((value) => {
            if (value.categoryInfo.storeName == 'Flights')
                this.selectFlight(value.poiContent);

            this.toggleSideMenuInfo(value.poiContent);
        });
    }

    ngOnInit() {
        this.sortValue = 'cityName';
        this.dataBusSubscribe();
        this.flightSubscribe();
        this.poiSubscribe();
        this.uiSubscribe();
    }

    uiSubscribe() {
        const uiState$ = this.store.select(selectUI);

        uiState$.subscribe((uiItem) => {
            this.arrivalScroll = uiItem.arrivalsScroll || 0;
            this.departureScroll = uiItem.departuresScroll || 0;
            this.isSubsideMenuInfo = uiItem.windows.sideMenuInfo;
            this.isSearchBarModalOpen = uiItem.windows.searchBarModal;

            if (this.selectedCategory.toLowerCase() == 'flights') {
                if (!this.isFlightSearch && !this.isSubsideMenuInfo) {
                    if (this.isDepartureActive) {
                        setTimeout(() => {
                            if (this.flightSection)
                                this.flightSection.scrollToOffset(
                                    this.departureScroll || 0
                                );
                        }, 150);
                    }
                    if (!this.isDepartureActive) {
                        setTimeout(() => {
                            if (this.flightSection)
                                this.flightSection.scrollToOffset(
                                    this.arrivalScroll || 0
                                );
                        }, 150);
                    }
                }
            }
        });
    }

    dataBusSubscribe() {
        const dataBusState$ = this.store.select(selectDataBus);

        dataBusState$.subscribe((dataBusItem) => {
            this.poiData = dataBusItem.pois;
        });
    }

    flightSubscribe() {
        const flightState$ = this.store.select(selectFlight);

        flightState$.subscribe((flightItem) => {
            this.departuresFlightItemData = flightItem.departureFlights;
            this.arrivalsFlightItemData = flightItem.arrivalsFlights;

            if (flightItem.arrivalsFlights && !this.isDepartureActive) {
                this.subTabFilter = 'C';
                this.flightItemData = this.arrivalsFlightItemData;
            }

            if (flightItem.departureFlights && this.isDepartureActive) {
                this.subTabFilter = 'C';
                this.flightItemData = this.departuresFlightItemData;
            }
        });

        this.virtualScrollBuffer = this.flightItemData.length * 2;
    }

    poiSubscribe() {
        const poiState$ = this.store.select(selectPOI);

        poiState$.subscribe((poiItem: any) => {
            this.isDepartureActive = poiItem.isDepartureActive;
            this.categoryInfo = poiItem.categoryInfo;

            if (this.categoryInfo) {
                this.poiListingData = this.poiData[this.categoryInfo.query];
            }

            let target = document.getElementById(poiItem.poiContent.id);
            if (target)
                target.scrollIntoView({
                    block: 'center',
                });

            this.isFlightSearch = poiItem.isFlightSearch;

            if (this.isFlightSearch) {
                if (poiItem.flights.length > 0) {
                    if (poiItem.flights[0] == '') {
                        this.flights = [];
                    } else this.flights = poiItem.flights;
                } else {
                    this.flights = this.isDepartureActive
                        ? [...this.departuresFlightItemData]
                        : [...this.arrivalsFlightItemData];
                }
            } else {
                if (!this.isDepartureActive) {
                    this.subTabFilter = 'cityName';
                    this.flightItemData = this.arrivalsFlightItemData;
                }

                if (this.isDepartureActive) {
                    this.subTabFilter = 'cityName';
                    this.flightItemData = this.departuresFlightItemData;
                }
            }

            this.searchError = poiItem.searchError;

            this.recentSelected = poiItem.recentSelected;

            if (
                this.categoryInfo &&
                this.recentSelected[this.categoryInfo.query]
            ) {
                this.recentListingData = this.checkRecentSelectedDataSet(
                    this.recentSelected[this.categoryInfo.query]
                );
            }
        });
    }

    checkRecentSelectedDataSet(data) {
        let recentSelectedDataSet = [];

        data.forEach((element) => {
            if (element.id && element.walkTime)
                recentSelectedDataSet.push(element);
        });

        return recentSelectedDataSet;
    }

    selectFlight(item) {
        this.flightSelected = item.id;

        let node = this.poiData.Gates.find((node) => node.name == item.gate);

        if (node) {
            this.mapService.goToPoiLocation(node);
            setTimeout(() => {
                this.store.dispatch(
                    setSelectedPoi({
                        instructions: node.instructions,
                    })
                );
            }, 100);
        }
    }

    selectPoi(item) {
        if (item.mapElementId) {
            this.store.dispatch(
                setSelectedPoi({
                    poiContent: item,
                })
            );
            this.mapService.goToPoiLocation(item);

            this.poiId = item.id;
        } else {
            this.store.dispatch(
                setSelectedPoi({
                    poiContent: item,
                    instructions: [],
                })
            );
        }
    }

    selectMainFilter(mainFilterValue: boolean) {
        this.isDepartureActive = mainFilterValue;

        this.setScroll(this.isDepartureActive);

        this.selectSubFilter(this.subTabFilter);
    }

    selectSubFilter(subFilterValue: string) {
        this.store.dispatch(
            flightType({
                isDepartureActive: this.isDepartureActive,
            })
        );

        if (this.isFlightSearch) {
            if (this.flightSectionFilter) {
                this.flightSectionFilter.scrollToIndex(0, 'auto');
            }
            this.eventHub.clearSearchInput.emit(true);
            this.subTabFilter = subFilterValue;

            this.sortValue = 'cityName';
        } else {
            this.subTabFilter = subFilterValue;

            this.flightItemData = this.isDepartureActive
                ? [...this.departuresFlightItemData]
                : [...this.arrivalsFlightItemData];

            this.sortValue = 'cityName';
        }
    }

    toggleSideMenuInfo(item) {
        let searchOpen = false;

        if (this.isSearchBarModalOpen) searchOpen = this.isSearchBarModalOpen;
        switch (this.selectedCategory) {
            case 'Hotels':
            case 'tourism':
                this.store.dispatch(
                    setSelectedPoi({
                        poiContent: item,
                        instructions: [],
                    })
                );
                this.store.dispatch(
                    toggleSideMenuInfo({
                        shouldOpen: true,
                        selectedCategory: this.selectedCategory,
                        animateSideInfo: true,
                    })
                );

                this.store.dispatch(
                    toggleAnimationState({
                        animationState: 'left',
                        fadeInOut: true,
                        isSideInfo: true,
                    })
                );
                break;

            case 'Flights':
                if (item.gate != '') {
                    if (searchOpen) {
                        this.googleAnalyticService.onSearchResultClick(
                            this.categoryInfo.storeName,
                            item
                        );
                        searchOpen = false;
                    }

                    this.store.dispatch(
                        toggleSearchBarModel({ shouldOpen: false })
                    ); //To close the search section
                    this.selectPoi(item);

                    if (this.isFlightSearch)
                        this.setScroll(!this.isDepartureActive, 0);
                    else this.setScroll(!this.isDepartureActive);

                    this.store.dispatch(
                        flightSearch({
                            isFlightSearch: false,
                            isDepartureActive: this.isDepartureActive,
                            flights: [],
                        })
                    );
                    this.store.dispatch(
                        toggleSideMenuInfo({
                            shouldOpen: true,
                            selectedCategory: this.selectedCategory,
                            animateSideInfo: true,
                        })
                    );

                    this.store.dispatch(
                        toggleAnimationState({
                            animationState: 'left',
                            fadeInOut: true,
                            isSideInfo: true,
                        })
                    );
                }

                break;
            default:
                this.selectPoi(item);
                this.store.dispatch(
                    toggleSideMenuInfo({
                        shouldOpen: true,
                        selectedCategory: this.selectedCategory,
                        animateSideInfo: true,
                    })
                );

                this.store.dispatch(
                    toggleAnimationState({
                        animationState: 'left',
                        fadeInOut: true,
                        isSideInfo: true,
                    })
                );
                break;
        }

        this.googleAnalyticService.onPoiSelection(
            this.categoryInfo.storeName,
            item
        );
    }

    onClickSearch() {
        if (!this.categoryInfo)
            this.categoryInfo = {
                icon: 'flight',
                storeName: 'Flights',
                label: 'FLIGHTS',
                dataType: '',
                query: '',
                subMenu: [],
                id: 0,
            };

        let scrollValue = this.flightSection.measureScrollOffset();
        this.googleAnalyticService.onFlightSearchClick(
            this.categoryInfo.storeName
        );
        this.store.dispatch(toggleSearchBarModel({ shouldOpen: true })); //To open search with animation as global search

        this.store.dispatch(
            toggleSubSideMenu({
                shouldOpen: true,
                selectedCategory: 'Flights',
                isFlightSearch: true,
                isDepartureActive: this.isDepartureActive,
                flights: [],
                categoryInfo: this.categoryInfo,
            })
        );
        this.isFlightSearch = true;

        this.setScroll(!this.isDepartureActive, scrollValue);
    }

    setScroll(isDepartureActive, scrollValue = null) {
        if (!this.isFlightSearch || scrollValue != null) {
            if (isDepartureActive) {
                this.store.dispatch(
                    arrivalScrolling({
                        arrivalsScroll:
                            scrollValue != null
                                ? scrollValue
                                : this.flightSection.measureScrollOffset(),
                    })
                );
            } else {
                this.store.dispatch(
                    departuresScrolling({
                        departuresScroll:
                            scrollValue != null
                                ? scrollValue
                                : this.flightSection.measureScrollOffset(),
                    })
                );
            }
        }
    }

    onSubMenuClick(subMenuInfo) {}
}
