/* src/app/components/header/header.component.scss */
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background: #ffffff;
}
.header .left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 100%;
}
.header .left-container .time-container,
.header .left-container .weather-container {
  align-items: center;
  display: flex;
  height: 100%;
  flex: 1;
}
.header .left-container .time-container app-time,
.header .left-container .time-container app-weather,
.header .left-container .weather-container app-time,
.header .left-container .weather-container app-weather {
  height: 100%;
  width: 100%;
}
.header .left-container .time-container {
  justify-content: center;
}
.header .middle-container {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.header .middle-container .logo .app-logo {
  width: 18em;
  margin-top: 1em;
  cursor: pointer;
  filter: none;
}
.header .right-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
}
.header .right-container .language-container,
.header .right-container .search-container,
.header .right-container .help-container {
  align-self: stretch;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  flex: 1;
}
.header .right-container .agent-state-container {
  flex: 1;
  height: 100%;
  display: flex;
  cursor: pointer;
}
.header .right-container .agent-state-container .btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}
.header .right-container .agent-state-container .btn-container .icon-container {
  justify-content: center;
  pointer-events: none;
  align-items: center;
  color: black;
  display: flex;
  height: 30px;
  width: 100%;
  flex: 1;
}
.header .right-container .agent-state-container .btn-container .icon-container .dot-container {
  display: flex;
  z-index: 1;
}
.header .right-container .agent-state-container .btn-container .icon-container .dot-container .material-icons {
  font-size: 4em;
}
.header .right-container .agent-state-container .btn-container .select-container {
  justify-content: center;
  user-select: none;
  display: flex;
  flex: 0.5;
  padding-bottom: 0.2em;
}
.header .right-container .agent-state-container .disable-btn {
  pointer-events: none;
  opacity: 0.4;
}
.header .right-container .agent-state-container .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: red;
}
.header .right-container .agent-state-container ::ng-deep .mat-form-field-underline {
  display: none;
}
.header .right-container .agent-state-container ::ng-deep .mat-select-value {
  color: black !important;
  text-transform: uppercase;
  padding-left: 1em;
  width: unset;
  max-width: unset;
}
.header .right-container .agent-state-container ::ng-deep .mat-select-value-text {
  color: black !important;
  background: transparent !important;
  font-size: 1.2em;
}
.header .right-container .agent-state-container ::ng-deep .mat-option-text {
  color: black !important;
  background: transparent !important;
  font-size: 1.2em;
}
.header .right-container .agent-state-container ::ng-deep .mat-select-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .right-container .agent-state-container ::ng-deep .mat-form-field-wrapper {
  padding: 0 !important;
}
.header .right-container .agent-state-container ::ng-deep .mat-form-field-infix {
  padding: 0 !important;
  border-top: none;
}
.header .right-container .agent-state-container ::ng-deep .mat-select-arrow-wrapper {
  visibility: hidden;
}
.header-idle-state {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
}
.header-idle-state .left-container {
  display: flex;
  flex: 1;
  align-items: center;
}
.header-idle-state .left-container .time-container,
.header-idle-state .left-container .weather-container {
  display: flex;
  align-items: center;
  flex: 1;
}
.header-idle-state .left-container .time-container app-time,
.header-idle-state .left-container .time-container app-weather,
.header-idle-state .left-container .weather-container app-time,
.header-idle-state .left-container .weather-container app-weather {
  height: 100%;
  width: 100%;
}
.header-idle-state .left-container .time-container {
  justify-content: center;
}
.header-idle-state .middle-container {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.header-idle-state .middle-container .logo img {
  width: 12em;
  margin: 1.5em;
  cursor: pointer;
}
.header-idle-state .right-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
}
.header-idle-state .flex-space {
  flex: 1.5;
}
app-button-icon {
  flex: 1;
  height: 100%;
}
.header .app-logo {
  width: 18em !important;
  transition: width 500ms linear;
}
.header .agent-state-container .icon-container .dot-container .material-icons {
  font-size: 2.8em;
  transition: font-size 500ms linear;
}
.header .select-container {
  justify-content: center;
  user-select: none;
  display: flex;
  flex: 0.5;
  padding-bottom: 0 !important;
  padding-top: 0.3em !important;
}
.header ::ng-deep .mat-select-value-text {
  font-size: 1.2em !important;
}
.header ::ng-deep .mat-select-value {
  padding-left: 1em !important;
}
.header ::ng-deep .mat-form-field-infix {
  padding: 0 !important;
}
.header-idle-state .middle-container .logo img {
  width: 12em !important;
  transition: 500ms width linear;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .header .app-logo {
    width: 35em !important;
    transition: width 500ms linear;
  }
  .header .agent-state-container .icon-container .dot-container .material-icons {
    font-size: 2.8em;
    transition: font-size 500ms linear;
  }
  .header .select-container {
    justify-content: center;
    user-select: none;
    display: flex;
    flex: 0.5;
    padding-bottom: 0 !important;
    padding-top: 0.3em !important;
  }
  .header ::ng-deep .mat-select-value-text {
    font-size: 1.2em !important;
  }
  .header ::ng-deep .mat-select-value {
    padding-left: 1em !important;
  }
  .header ::ng-deep .mat-form-field-infix {
    padding: 0 !important;
  }
  .header-idle-state .middle-container .logo img {
    width: 24em !important;
    margin: 3em !important;
  }
}
/*# sourceMappingURL=header.component-2EQAA6BV.css.map */
