import { Injectable, ElementRef, EventEmitter, inject } from '@angular/core';
import { Database, ref, onValue, push, set } from '@angular/fire/database';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GoogleAnalyticsService } from './google-analytics.service';
import { SideMenuService } from './side-menu.service';
import { AppConfig } from 'environments/environment';
import RTCMultiConnection from 'rtcmulticonnection';
import { MapService } from './map.service';
import { TimeConversionService } from './time-conversion.service';

@Injectable({
    providedIn: 'root',
})
export class WebRTCService {
    showRemoteVideo: boolean = false; //
    showLocalVideo: boolean = true; //
    isConnected: boolean = false; //
    isAgentActive: boolean; //
    agentRoomId; //
    connection; //
    agentKey; //
    agent; //
    showCallingState;
    showSnackBar: boolean = false;
    snackBarMessage: string;
    snackBarAction: string;
    alertMsg: ElementRef;
    clientKey;
    globalParticipantId; //??
    globalUserPreferences; //??
    uid;
    clients;

    once = false;
    agentEmail;
    timestamp: number;

    isAgentOnline: boolean = true;
    didAppFinishLoading: boolean = false;
    agentLanguage: Array<string> = [];

    public didClientInitiateCall = new EventEmitter();

    public isConnectionEstablished = new EventEmitter();
    public didStreamEnd = new EventEmitter();
    public didClientCall = new EventEmitter();

    public wasAgentRemovedFromDB = new EventEmitter();
    public toggleSideMenu = new EventEmitter();
    public selectedItem = new EventEmitter();
    activeAgents: any;

    agentShowIncomingCallTime: number = 0;
    callInterval: NodeJS.Timeout;
    callDuration = 0;

    busyAgentList = [];
    showErrorMessage: boolean = false;
    busyAgentKey: string;

    database: Database = inject(Database);
    timeService = inject(TimeConversionService);

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private sideMenuService: SideMenuService,
        private translate: TranslateService,
        private mapService: MapService
    ) {
        translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.checkAgentAvailability();
        });

        this.agentKey = window.localStorage.getItem('agentKey');
    }

    startCallTimer() {
        if (AppConfig.isAgent && this.agentShowIncomingCallTime == 0)
            this.agentShowIncomingCallTime = this.timeService.getUnix();

        this.callDuration = this.timeService.getUnix();
    }

    initializeWebRTC(turnServers) {
        this.connection = new RTCMultiConnection(
            AppConfig.isAgent
                ? `lax-agent-${this.timeService.getValueOf()}`
                : `lax-client-${this.timeService.getValueOf()}`
        );

        this.connection.socketURL =
            'https://lax-rtc-socket-us-central.uc.r.appspot.com/';

        this.connection.socketCustomEvent = 'private-secure-chat';
        this.connection.autoCloseEntireSession = true;

        // Make this room public
        // const publicRoomIdentifier = "publicRoomIdentifier";
        // this.connection.publicRoomIdentifier = publicRoomIdentifier;
        // this.connection.socketMessageEvent = publicRoomIdentifier;

        turnServers.forEach((node) => this.connection.iceServers.push(node));

        // if you want text chat
        this.connection.session['data'] = true;

        this.connection.maxParticipantsAllowed = 2;

        this.onNewParticipant();
        this.onStreamEnded();
        this.onLeave();
        if (!AppConfig.isAgent) {
            this.onReceivingAgentMessage();
            this.onConnectionError();
            this.subscribeAgentBusyNode();
        }
    }

    callAgent() {
        if (!AppConfig.isAgent) {
            this.clientKey = push(
                ref(this.database, `web-rtc/incoming_calls`),
                {
                    kioskInformation: this.mapService.kioskInformation,
                    userId: this.connection.userid,
                    timestamp: this.timeService.getValueOf(),
                    lang: this.translate.currentLang,
                }
            ).key;
        }
    }

    onNewParticipant() {
        this.connection.onNewParticipant = (participantId, userPreferences) => {
            console.log('INSIDE client onNewParticipant ', participantId);
            if (this.callDuration != 0)
                this.googleAnalyticService.onCallPickup(
                    this.timeService.getUnix() - this.callDuration
                );
            // Exist if the room is full
            if (
                this.connection.maxParticipantsAllowed <=
                this.connection.getAllParticipants().length
            ) {
                console.log('Exit request');
                return;
            }
            // Phone rings if there is an available room
            // this.activeRef
            //   .child(this.agentKey)
            //   .set({ userId: this.agentRoomId, ringing: true });
            this.globalParticipantId = participantId;
            this.globalUserPreferences = userPreferences;
            this.connection.acceptParticipationRequest(
                this.globalParticipantId,
                this.globalUserPreferences
            );

            this.isConnectionEstablished.emit(true);
        };
    }

    onConnectionError() {
        this.connection.onerror = (event) => {
            if (event.error.message != 'Transport channel closed') {
                console.log(event, '--------->', 'connection error');
                this.showErrorMessage = true;
            }
        };
    }

    onStreamEnded() {
        this.connection.onstreamended = (event) => {
            //
            if (this.callDuration != 0)
                this.googleAnalyticService.onCallTermination(
                    this.timeService.getUnix() - this.callDuration
                );
            this.agentShowIncomingCallTime = 0;
            this.callDuration = 0;
            //
            var mediaElement = document.getElementById(event.streamid);
            if (mediaElement) {
            }
            console.log('STREAM ENDED: ', event);
            if (AppConfig.isAgent && !this.once) {
                if (this.agentKey) this.removeAgentFromFirebase();
                if (this.busyAgentKey) this.removeBusyAgentFromFirebase();

                this.once = true;
            }

            this.didStreamEnd.emit(true);
        };
    }

    subscribeAgentBusyNode() {
        const nodeRef = ref(this.database, 'web-rtc/busy');
        onValue(nodeRef, (snapshot: any) => {
            if (snapshot.val()) {
                this.busyAgentList = Object.keys(snapshot.val()).map(
                    (i) => snapshot.val()[i]
                );
            } else this.busyAgentList = [];
        });
    }

    onReceivingAgentMessage() {
        this.connection.onmessage = (event) => {
            let componentState = {
                isSubSideMenuInfoOpen: true,
                selectedCategory: event.data.selectedCategory,
                isSubSideMenuOpen: true,
                isStaticInfoOpen: true,
            };

            this.sideMenuService.sideMenuButtonClicked(
                event.data.categoryInfo,
                componentState
            );

            setTimeout(() => {
                this.toggleSideMenu.emit({
                    categoryInfo: event.data.categoryInfo,
                    poiContent: event.data.poiContent,
                });
                if (event.data.selectedItem)
                    this.selectedItem.emit(event.data.selectedItem);
            }, 2000);
        };
    }

    onLeave() {
        this.connection.onleave = (event) => {
            console.log('onleave: ', event);

            // stop all local cameras
            this.connection.attachStreams.forEach(function (localStream) {
                localStream.stop();
            });

            if (event.extra.role == 'agent') {
                console.log('REMOVING AGENT: ', event.userid);
            }

            if (event.extra.role == 'client') {
                console.log('REMOVING client: ', event.userid);
            }
        };
    }

    onActiveAgent() {
        // ** Connection Event Handlers End **

        const nodeRef = ref(this.database, 'web-rtc/active');
        onValue(nodeRef, (snapshot: any) => {
            this.activeAgents = snapshot.val();
            this.checkAgentAvailability();
        });
    }

    checkAgentAvailability() {
        let list = [];

        for (let i in this.activeAgents) {
            if (
                this.activeAgents[i].lang.includes(this.translate.currentLang)
            ) {
                list.push({ ...this.activeAgents[i], firebaseKey: i });
            }
        }

        // Do not display the video chat ui with agent if false
        if (list && list.length > 0) {
            this.isAgentActive = true;
        } else {
            this.isAgentActive = false;
            if (!this.isConnected) this.didStreamEnd.emit(true);
        }
    }

    subscribeActiveNode() {
        const nodeRef = ref(this.database, 'web-rtc/active');
        onValue(nodeRef, (snapshot: any) => {
            setTimeout(() => {
                if (!snapshot.val()) this.wasAgentRemovedFromDB.emit(true);
                else if (!snapshot.val()[this.agentKey])
                    this.wasAgentRemovedFromDB.emit(true);
            }, 500);
        });
    }

    onClientRinging() {
        // ** Connection Event Handlers End **
        const nodeRef = ref(this.database, 'web-rtc/incoming_calls');
        onValue(nodeRef, (snapshot: any) => {
            let list = [];
            for (let i in snapshot.val()) {
                if (this.agentLanguage.includes(snapshot.val()[i].lang)) {
                    list.push({ ...snapshot.val()[i], firebaseKey: i });
                }
            }

            this.clients = list;
            if (this.clients && this.clients.length > 0 && !this.isConnected) {
                this.didClientCall.emit(true);
            } else {
                if (!this.isConnected) this.didStreamEnd.emit(true);
            }
        });
    }

    onAgentNotAnswer() {
        this.googleAnalyticService.onCallNotAnswered(
            this.timeService.getUnix() - this.agentShowIncomingCallTime,
            this.agentEmail
        );

        this.agentShowIncomingCallTime = 0;
    }

    acceptCall() {
        this.connection.sdpConstraints.mandatory = {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: true,
        };

        this.connection.mediaConstraints = {
            video: true,
            audio: true,
        };
        this.connection.addStream({
            audio: true,
            video: true,
        });

        this.connection.extra = {
            roomId: this.agentRoomId,
            role: 'agent',
            joinedAt: new Date().toISOString(),
        };
        console.log(
            'acceptCall() this.connection.acceptParticipationRequest() ',
            this.globalParticipantId,
            this.globalUserPreferences
        );

        this.connection.acceptParticipationRequest(
            this.globalParticipantId,
            this.globalUserPreferences
        );
    }

    addAgentToFirebase() {
        this.timestamp = this.timeService.getValueOf();

        this.agentKey = push(ref(this.database, `web-rtc/active`), {
            email: this.agentEmail,
            timestamp: this.timestamp,
            lang: this.agentLanguage,
        }).key;

        window.localStorage.setItem('agentKey', this.agentKey);
    }

    updateTimeStamp() {
        let milliseconds = this.timeService.getValueOf() - this.timestamp;

        if (milliseconds >= AppConfig.agentMouseEventIdleRefreshRate) {
            this.timestamp = this.timeService.getValueOf();
            set(ref(this.database, `web-rtc/active/${this.agentKey}`), {
                email: this.agentEmail,
                timestamp: this.timestamp,
                lang: this.agentLanguage,
            });
        }
    }

    removeAgentFromFirebase() {
        set(ref(this.database, `web-rtc/active/${this.agentKey}`), null);
    }

    addBusyAgentToFirebase() {
        this.timestamp = this.timeService.getValueOf();

        this.busyAgentKey = push(ref(this.database, `web-rtc/busy`), {
            email: this.agentEmail,
            timestamp: this.timestamp,
            lang: this.agentLanguage,
        }).key;
    }

    removeBusyAgentFromFirebase() {
        set(ref(this.database, `web-rtc/busy/${this.busyAgentKey}`), null);
    }

    searchAgentInDB(ref, options) {
        return new Promise((resolve, reject) => {
            let query = ref
                .orderByChild(options.queryKey)
                .equalTo(options.queryValue)
                .limitToFirst(1);

            query.once('value', (snapshot) => {
                if (snapshot.val()) {
                    resolve({ status: true, data: snapshot.val() });
                } else {
                    resolve({ status: false, data: snapshot.val() });
                }
            });
        });
    }

    removeClient(key) {
        set(ref(this.database, `web-rtc/incoming_calls/${key}`), null);
    }
}
