import { Action, createReducer, on } from '@ngrx/store';
import { UIState } from '../state/ui.state';
import {
    getWeatherInformationSuccess,
    getWeatherInformationFailed,
    toggleAlertWeatherModal,
    toggleSearchBarModel,
    getFlightLaxSuccess,
    toggleSideMenuInfo,
    getFlightLaxFailed,
    getSettingSuccess,
    toggleSelfieModal,
    toggleSubSideMenu,
    getSettingFailed,
    getFlightSuccess,
    getFlightFailed,
    mapInitialized,
    getPOIFailed,
    showDialog,
    resetApp,
    setAgentStatus,
    setRandomBgImage,
    didAppReload,
    arrivalScrolling,
    departuresScrolling,
    showIdleState,
    toggleStaticInfoModal,
    toggleLaxTV,
    getContentSuccess,
    toggleVideoPlayerModal,
    toggleSurveyModal,
} from '../actions';

const initialState: UIState = {
    windows: {
        alertWeatherModal: false,
        sideMenuListing: false,
        animateSideInfo: false,
        searchBarModal: false,
        sideMenuInfo: false,
        selfieModal: false,
        subSideMenu: false,
        agentStatus: true,
        showIdleState: true,
        staticInfoModal: false,
        isLaxTVOpened: false,
        videoPlayerModal: false,
    },
    dialog: {
        version: '',
        showDialog: false,
        subTitle: '',
        title: '',
        showCloudFunction: false,
        fromComponent: '',
    },
    loaders: {
        departureFlights: 'attempt',
        arrivalFlights: 'attempt',
        setting: 'attempt',
        weather: 'attempt',
        map: false,
        pois: 'attempt',
    },

    images: {
        randomBgImage: 1,
    },
    didAppReload: false,
    arrivalsScroll: 0,
    departuresScroll: 0,
    survey: {
        activeSurvey: '',
        showSurvey: false,
    },
};

const uiReducer = createReducer(
    initialState,
    on(getContentSuccess, (state, action) => {
        let _u = { ...state };

        _u.dialog.version = action.version || '';

        return _u;
    }),
    on(didAppReload, (state, action) => {
        let _u = { ...state };

        _u.didAppReload = !_u.didAppReload;

        return _u;
    }),
    on(resetApp, (state, action) => {
        let _u = { ...state };
        _u.windows = {
            alertWeatherModal: false,
            sideMenuListing: false,
            animateSideInfo: false,
            searchBarModal: false,
            sideMenuInfo: false,
            selfieModal: false,
            subSideMenu: false,
            agentStatus: true,
            showIdleState: true,
            staticInfoModal: false,
            isLaxTVOpened: false,
            videoPlayerModal: false,
        };
        _u.dialog.showDialog = false;
        _u.survey.showSurvey = false;

        return _u;
    }),

    on(toggleSelfieModal, (state, action) => {
        let _u = { ...state };

        _u.windows.selfieModal = action.shouldOpen;
        return _u;
    }),
    on(toggleSurveyModal, (state, action) => {
        let _u = { ...state };
        _u.survey.activeSurvey = action.activeSurvey;
        _u.survey.showSurvey = action.shouldOpen;
        return _u;
    }),
    on(toggleSearchBarModel, (state, action) => {
        let _u = { ...state };

        _u.windows.searchBarModal = action.shouldOpen;
        return _u;
    }),
    on(toggleAlertWeatherModal, (state, action) => {
        let _u = { ...state };

        _u.windows.alertWeatherModal = action.shouldOpen;
        return _u;
    }),
    on(getSettingSuccess, (state, action) => {
        let _u = { ...state };
        _u.loaders.setting = 'success';
        return _u;
    }),
    on(getSettingFailed, (state, action) => {
        let _u = { ...state };

        _u.loaders.setting = 'failed';
        return _u;
    }),
    on(showDialog, (state, action) => {
        let _u = { ...state };
        _u.dialog.showCloudFunction = action.showCloudFunction || false;
        _u.dialog.showDialog = action.showDialog;
        _u.dialog.subTitle = action.subTitle;
        _u.dialog.title = action.title;
        _u.dialog.fromComponent = action.fromComponent || '';

        return _u;
    }),
    on(mapInitialized, (state, action) => {
        let _u = { ...state };
        _u.loaders.map = action.mapInitialized;

        return _u;
    }),
    on(getWeatherInformationSuccess, (state, action) => {
        let _u = { ...state };
        _u.loaders.weather = 'success';
        return _u;
    }),
    on(getWeatherInformationFailed, (state, action) => {
        let _u = { ...state };

        _u.loaders.weather = 'failed';
        return _u;
    }),
    on(toggleSideMenuInfo, (state, action) => {
        let _u = { ...state };

        _u.windows.sideMenuInfo = action.shouldOpen;
        _u.windows.animateSideInfo = action.animateSideInfo
            ? action.animateSideInfo
            : false;
        return _u;
    }),
    on(toggleSubSideMenu, (state, action) => {
        let _u = { ...state };
        _u.windows.subSideMenu = action.shouldOpen;
        return _u;
    }),
    on(getFlightSuccess, (state, action) => {
        let _u = { ...state };
        if (action.adi == 'A') {
            _u.loaders.arrivalFlights = 'success';
        } else {
            _u.loaders.departureFlights = 'success';
        }
        return _u;
    }),
    on(getFlightFailed, (state, action) => {
        let _u = { ...state };

        if (action.payload == 'A') {
            _u.loaders.arrivalFlights = 'failed';
        } else {
            _u.loaders.departureFlights = 'failed';
        }
        return _u;
    }),
    on(getFlightLaxSuccess, (state, action) => {
        let _u = { ...state };
        _u.loaders.arrivalFlights = 'success';
        _u.loaders.departureFlights = 'success';
        return _u;
    }),
    on(getFlightLaxFailed, (state, action) => {
        let _u = { ...state };
        _u.loaders.arrivalFlights = 'failed';
        _u.loaders.departureFlights = 'failed';
        return _u;
    }),

    on(getPOIFailed, (state, action) => {
        let _u = { ...state };
        _u.loaders.pois = 'failed';
        return _u;
    }),
    on(setAgentStatus, (state, action) => {
        let _u = { ...state };
        _u.windows.agentStatus = action.setAgentStatus;
        return _u;
    }),
    on(setRandomBgImage, (state, action) => {
        let _u = { ...state };
        _u.images.randomBgImage = action.randomBgImage;
        return _u;
    }),

    on(arrivalScrolling, (state, action) => {
        let _u = { ...state };
        _u.arrivalsScroll = action.arrivalsScroll;
        return _u;
    }),
    on(departuresScrolling, (state, action) => {
        let _u = { ...state };
        _u.departuresScroll = action.departuresScroll;
        return _u;
    }),
    on(showIdleState, (state, action) => {
        let _u = { ...state };
        _u.windows.showIdleState = action.showIdleState;
        return _u;
    }),
    on(toggleStaticInfoModal, (state, action) => {
        let _u = { ...state };
        _u.windows.staticInfoModal = action.shouldOpen;
        return _u;
    }),
    on(toggleLaxTV, (state, action) => {
        let _u = { ...state };
        _u.windows.isLaxTVOpened = action.shouldOpen;
        return _u;
    }),
    on(toggleVideoPlayerModal, (state, action) => {
        let _u = { ...state };
        _u.windows.videoPlayerModal = action.shouldOpen;
        return _u;
    })
);

export default function reducer(state: UIState, action: Action) {
    return uiReducer(state, action);
}
