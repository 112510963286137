import { cloneDeep } from 'lodash-es';
import { AppConfig } from 'environments/environment';
import { selectPOI, selectUI } from '@app/store/selectors';
import { Store } from '@ngrx/store';
import {
    SimpleChanges,
    EventEmitter,
    ViewChildren,
    Component,
    QueryList,
    OnInit,
    Output,
    Input,
    ViewChild,
} from '@angular/core';
import {
    setSelectedPoi,
    toggleSideMenuInfo,
    toggleAnimationState,
} from '@app/store/actions';
import {
    MapInfoWindow,
    MapMarker,
    GoogleMap,
    MapTrafficLayer,
} from '@angular/google-maps';
import { NgIf } from '@angular/common';
import { HotelItemComponent } from '../hotel-item/hotel-item.component';
import { SideMenuService } from '@app/services/side-menu.service';

@Component({
    selector: 'app-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss'],
    standalone: true,
    imports: [
        GoogleMap,
        MapMarker,
        MapInfoWindow,
        MapTrafficLayer,
        NgIf,
        HotelItemComponent,
    ],
})
export class GoogleMapComponent implements OnInit {
    @Output() locationCoordinates = new EventEmitter<string>();
    @ViewChildren(MapMarker) markers: QueryList<MapMarker>;
    @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
    @Input('itemsArray') itemsArray: any;
    @Input('hotelItem') hotelItem;
    @Input('long') long;
    @Input('lat') lat;

    selectedCategory: string;
    poiContent;
    screenInnerWidth: number;
    zoom: number = 16;
    center = {
        lat: parseFloat(AppConfig.airportCoordinates.lat),
        lng: parseFloat(AppConfig.airportCoordinates.long),
    };
    options = {
        mapId: 'LAX_AIRPORT_MAP',
    };
    showTrafficLayer: boolean = false;
    markerItems: any;

    constructor(
        private store: Store<any>,
        private sideMenuService: SideMenuService
    ) {}

    ngOnInit() {
        this.screenInnerWidth = window.innerWidth;
        this.zoom = 16;
        this.markerItems = cloneDeep(this.itemsArray);
        const poiState$ = this.store.select(selectPOI);
        const uiState$ = this.store.select(selectUI);

        poiState$.subscribe((poiItem) => {
            this.hotelItem = null;
            this.selectedCategory = poiItem.selectedCategory;
            if (poiItem.selectedCategory == 'traffic') {
                this.showTrafficLayer = true;
                this.markerItems = [];
            }

            if (this.poiContent !== poiItem.poiContent) {
                this.poiContent = poiItem.poiContent;
            }

            this.zoom = this.screenInnerWidth == 3840 ? 18 : 16;
        });

        uiState$.subscribe((uiItem) => {
            if (uiItem.windows.sideMenuInfo) {
                this.hotelItem = this.poiContent;

                const coordinates = this.poiContent.coordinates?.split(',');
                this.center = {
                    lat: parseFloat(coordinates[0]),
                    lng: parseFloat(coordinates[1]),
                };
                this.zoom = 18;
            } else {
                this.hotelItem = null;
                this.zoom = 16;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        setTimeout(() => {
            if (
                changes.itemsArray?.currentValue &&
                changes.itemsArray?.currentValue.length !==
                    this.markerItems?.length
            ) {
                this.markerItems = cloneDeep(this.itemsArray);
            }

            if (changes.long?.currentValue && changes.lat?.currentValue) {
                const currentMarker = this.markers?.find(
                    (node: any) =>
                        changes.lat?.currentValue === node._position.lat &&
                        changes.long?.currentValue === node._position.lng
                );

                this.infoWindow.open(currentMarker);
            }
        }, 500);
    }

    handleMarkerClick(item) {
        this.center = {
            lat: parseFloat(item.lat),
            lng: parseFloat(item.long),
        };

        const currentMarker = this.markers.find(
            (node: any) =>
                this.center.lat === node._position.lat &&
                this.center.lng === node._position.lng
        );

        this.infoWindow.open(currentMarker);

        // TODO:Add detection for the category to prevent repeating unnecessary actions
        this.store.dispatch(
            setSelectedPoi({
                poiContent: item.element,
                instructions: [],
            })
        );
        this.store.dispatch(
            toggleSideMenuInfo({
                shouldOpen: true,
                selectedCategory: this.selectedCategory,
                animateSideInfo: true,
            })
        );

        this.store.dispatch(
            toggleAnimationState({
                animationState: 'left',
                fadeInOut: true,
                isSideInfo: true,
            })
        );
    }
}
