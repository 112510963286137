export const headerDummyData = {
  searchIcon: {
    icon: "search",
    label: "SEARCH",
    active: true
  },

  helpIcon: {
    icon: "videocam",
    label: "LIVE_HELP",
    active: true
  },

  languageIcon: {
    icon: "language",
    label: "LANGUAGE",
    active: true
  },
  weatherInfo: {
    value: 77,
    unit: "F",
    imageUrl: "assets/icon/partlyCloudy.svg"
  }
};
