<div
    class="sticky-button-list-container"
    [ngStyle]="{
        'z-index': isSearchBarOpen || isFlightSearch ? '-1' : '8',
        'pointer-events': showIdleState ? 'none' : 'unset'
    }"
>
    <div
        class="sticky-btn-container br-l-10px"
        [@fadeInOutBgColor]="
            !showIdleState && !isAgent && isRateUSButtonEnabled
        "
    >
        <app-button-icon
            *ngIf="!isAgent && isRateUSButtonEnabled"
            (click)="onRateUsButtonClick()"
            [buttonInfo]="rateUS"
            [iconSize]="'3em'"
            [textColor]="showIdleState ? 'white' : 'black'"
            [iconColor]="showIdleState ? 'white' : 'black'"
        >
        </app-button-icon>
    </div>

    <div
        class="sticky-btn-container br-l-10px"
        *ngIf="
            (webRTCService.busyAgentList.length > 0 ||
                webRTCService.isAgentActive) &&
            !webRTCService.showErrorMessage &&
            !webRTCService.showCallingState &&
            doesWebcamExist &&
            !isAgent
        "
        [@fadeInOutBgColor]="
            (webRTCService.busyAgentList.length > 0 ||
                webRTCService.isAgentActive) &&
            !webRTCService.showErrorMessage &&
            !webRTCService.showCallingState &&
            doesWebcamExist &&
            !showIdleState &&
            !isAgent
        "
        [ngStyle]="{
            opacity:
                webRTCService.busyAgentList.length > 0 &&
                !webRTCService.isAgentActive &&
                !showIdleState
                    ? '0.7'
                    : '1'
        }"
    >
        <app-button-icon
            (click)="callAgent()"
            [buttonInfo]="helpIcon"
            [iconSize]="'3em'"
            [textColor]="showIdleState ? 'white' : 'black'"
            [iconColor]="showIdleState ? 'white' : 'black'"
        >
        </app-button-icon>
    </div>

    <div
        class="sticky-btn-container br-l-10px"
        *ngFor="let item of buttonsList"
        [@fadeInOutBgColor]="!showIdleState"
    >
        <app-button-icon
            (click)="handleClick(item.name)"
            [buttonInfo]="item.buttonInfo"
            [iconSize]="'3em'"
            [textColor]="showIdleState ? 'white' : 'black'"
            [iconColor]="showIdleState ? 'white' : 'black'"
        >
        </app-button-icon>
    </div>

    <div
        class="agent-state-container ripple"
        [ngClass]="{
            'disable-btn': webRTCService.isConnected || didClientCall
        }"
        (click)="openDropDown()"
        *ngIf="isAgent"
    >
        <div class="btn-container">
            <div class="icon-container">
                <div class="dot-container">
                    <i
                        *ngIf="selected == 'AVAILABLE'"
                        class="material-icons"
                        style="color: green"
                    >
                        check_circle_outline
                    </i>
                    <i
                        *ngIf="selected == 'UNAVAILABLE'"
                        class="material-icons"
                        style="color: orange"
                    >
                        remove_circle_outline
                    </i>
                </div>
            </div>
            <div class="select-container">
                <mat-select
                    [ngStyle]="{ color: showIdleState ? 'white' : 'black' }"
                    [(value)]="selected"
                    style="width: 10em"
                    #accountPanel
                >
                    <mat-option
                        [ngStyle]="{
                            'font-size':
                                status.value == 'name' ? '0.7em' : '1em'
                        }"
                        *ngFor="let status of agentStatus"
                        [value]="status.value"
                        [disabled]="status.value == 'name'"
                        (click)="toggleOption(status.viewValue, true)"
                    >
                        {{ status.viewValue | translate }}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>
</div>
