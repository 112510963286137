import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { NgFor } from "@angular/common";

@Component({
    selector: "app-mask-code",
    templateUrl: "./mask-code.component.html",
    styleUrls: ["./mask-code.component.scss"],
    standalone: true,
    imports: [NgFor]
})
export class MaskCodeComponent implements OnInit {
  @Input("code") code: any;
  @Input("isMasked") isMasked: boolean;
  formattedCode: Array<string>;
  constructor() {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    this.convertStringToArray(this.code.toString(), this.isMasked);
  }

  convertStringToArray(code: string, isMasked: boolean) {
    this.formattedCode = [];
    for (let i = 0; i < code.length; i++) {
      if (!isMasked) {
        if (code.charAt(i)) this.formattedCode.push(code.charAt(i));
      } else {
        if (code.charAt(i)) this.formattedCode.push("*");
      }
    }
  }
}
