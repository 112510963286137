import { Component, OnInit, Input } from '@angular/core';
import { hotelItem } from '../../models/ui/hotel.item.model';
import { RatingComponent } from '../rating/rating.component';

@Component({
    selector: 'app-featured-hotel',
    templateUrl: './featured-hotel.component.html',
    styleUrls: ['./featured-hotel.component.scss'],
    standalone: true,
    imports: [RatingComponent],
})
export class FeaturedHotelComponent implements OnInit {
    @Input('hotelItem') hotelItem: hotelItem;
    ratingColor: string = 'white';
    constructor() {}

    ngOnInit() {}
}
