import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityService } from '../../services/utility.service';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'app-time',
    templateUrl: './time.component.html',
    styleUrls: ['./time.component.scss'],
    standalone: true,
    imports: [NgStyle],
})
export class TimeComponent implements OnInit {
    @Input('timeValueColor') timeValueColor: string;
    @Input('dateValueColor') dateValueColor: string;

    formattedDate: string = this.utility.getGlobalDateZone();
    localTime: string = this.utility.getGlobalTimeZone();
    timeInterval: any;
    constructor(private utility: UtilityService) {}

    timeFromNow: Observable<string>;

    ngOnInit() {
        this.timeInterval = setInterval(() => {
            this.formattedDate = this.utility.getGlobalDateZone();
            this.localTime = this.utility.getGlobalTimeZone();
        }, 1000);
    }
    ngOnDestroy() {
        clearInterval(this.timeInterval);
    }
}
