<div class="idle-state-container" (click)="hideIdleState()">
    <div class="overlay" [@fadeInOut]="showIdleState" *ngIf="showIdleState">
        <div class="header-container">
            <app-header [showHeaderLeftContainer]="false"></app-header>
        </div>

        <div class="body-container">
            @if (isEmergencyStateEnabled) {
            <app-emergency-alert></app-emergency-alert>
            } @else {

            <ng-lottie class="touch-screen" [options]="options" />

            @if (currentPreview?.type==='image') {

            <!-- image -->
            <div class="image-container">
                <img
                    loading="lazy"
                    [src]="getImageURL(currentPreview?.key)"
                    alt=""
                    class="hotel-image"
                />
            </div>

            }@else if (currentPreview){

            <!-- video -->
            <div
                class="video-player-container"
                [ngClass]="{
                    'custom-video-player-container':
                        currentPreview?.url.includes('firebase')
                }"
            >
                @if (currentPreview?.url.includes('firebase')){
                <app-video-player
                    [isLaxTV]="false"
                    [videoUrl]="currentPreview?.url"
                    [videoType]="'custom'"
                    [videoFormat]="currentPreview?.mimeType"
                />}@else if(currentPreview?.url.includes('you')){
                <app-video-player
                    [isLaxTV]="false"
                    [videoUrl]="currentPreview?.url"
                    [videoType]="'youtube-single'"
                />
                }@else {

                <app-video-player
                    [isLaxTV]="false"
                    [videoUrl]="currentPreview?.url"
                    [videoType]="'vimeo'"
                />
                }
            </div>

            }@else {
            <ng-lottie class="touch-screen" [options]="infoOptions" />

            }

            <ng-lottie class="touch-screen" [options]="options" />
            }
        </div>

        <div class="footer">
            <div
                class="interaction-touch-container"
                *ngIf="!isEmergencyStateEnabled"
            >
                <div class="interaction-text-container">
                    <span>
                        {{ 'TOUCH_SCREEN_TO_START' | translate }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
