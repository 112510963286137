import { AppConfig } from '../../../environments/environment';
import { selectDataBus } from '@app/store/selectors';
import { Store } from '@ngrx/store';
import {
    toggleAnimationState,
    toggleSideMenuInfo,
    flightSearch,
} from '@app/store/actions';
import {
    Component,
    OnInit,
    Input,
    SimpleChange,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FlightPartnerRowComponent } from '../flight-partner-row/flight-partner-row.component';
import { NgClass, NgStyle, NgIf } from '@angular/common';
@Component({
    selector: 'app-flight-item',
    templateUrl: './flight-item.component.html',
    styleUrls: ['./flight-item.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgIf,
        FlightPartnerRowComponent,
        TranslateModule,
    ],
})
export class FLightItemComponent implements OnInit {
    @ViewChild('imgSection') imgSection: ElementRef;

    @Input('isDepartureActive') isDepartureActive;
    @Input('flightSelected') flightSelected;
    @Input('hasBackArrow') hasBackArrow;
    @Input('flightItem') data;

    isGate: boolean = false;
    dataBusSubscription;
    imgLogo: string;
    gatesData;
    walkTime;
    constructor(private store: Store<any>) {}

    ngOnInit() {
        this.isGate = false;
        this.isGate = this.data.gate != '';

        const dataBusState$ = this.store.select(selectDataBus);
        this.dataBusSubscription = dataBusState$.subscribe((dataBusItem) => {
            this.gatesData = dataBusItem.pois.Gates;
        });

        this.getWalkTime();

        if (this.hasBackArrow) {
            if (this.data.flightType == 'A') this.isDepartureActive = false;
            else this.isDepartureActive = true;
        }
        this.imgLogo = '';
        this.imgLogo = this.data.airlineLogo;
    }

    ngOnChanges(changes: SimpleChange) {
        this.isGate = false;
        this.isGate = this.data.gate != '';
        if (this.imgSection && !changes.hasOwnProperty('flightSelected')) {
            this.imgLogo = '';
            this.imgSection.nativeElement.style.cssText = "display:'block'";
            this.imgLogo = this.data.airlineLogo;
        }
        this.getWalkTime();
    }

    getWalkTime() {
        if (!this.gatesData) return;

        this.gatesData.find((node) => {
            if (node.gateNumber.includes(this.data.gate))
                this.walkTime = `${node.walkTime} min`;
        });
    }

    close() {
        this.store.dispatch(
            flightSearch({
                isFlightSearch: false,
                isDepartureActive: this.isDepartureActive,
            })
        );

        this.store.dispatch(
            toggleAnimationState({
                animationState: 'right',
                fadeInOut: false,
                isSideInfo: true,
            })
        );

        // this.store.dispatch(
        //   setSelectedPoi({
        //     poiContent: {},
        //     instructions: []
        //   })
        // );

        setTimeout(() => {
            this.store.dispatch(
                toggleAnimationState({
                    animationState: '',
                    fadeInOut: false,
                    isSideInfo: false,
                })
            );
            this.store.dispatch(
                toggleSideMenuInfo({
                    shouldOpen: false,
                    selectedCategory: 'Flights',
                    animateSideInfo: false,
                })
            );
        }, 350);
    }
}
