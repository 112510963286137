/* src/app/components/info-item/info-item.component.scss */
.info-item-container {
  word-break: break-word;
  display: flex;
  padding: 2%;
  padding-left: 0;
  flex: 1;
}
.info-item-container .icon-container {
  flex: 0.1;
  display: flex;
}
.info-item-container .icon-container i {
  color: black;
}
.info-item-container .description-container {
  justify-content: center;
  flex-direction: column;
  color: black;
  display: flex;
  flex: 1;
}
.info-item-container .description-container .info-item-title {
  font-weight: bold;
}
.info-item-container .description-container .info-item-subtitle {
  font-size: 0.8em;
}
.info-item-container .description-container .info-item-subtitle .subtitle {
  display: flex;
  flex-direction: column;
}
.info-item-container .description-container .info-item-subtitle .subtitle .title {
  font-weight: bold;
  color: black;
}
.info-item-container .empty-space {
  flex: 0.1;
}
@media screen and (min-width: 0px), (min-height: 0px) {
  .info-item-title {
    transition: font-size 500ms linear;
    font-size: 1em;
  }
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .icon-container i {
    font-size: 3.5em !important;
    transition: font-size 500ms linear;
  }
  .info-item-title .title {
    transition: font-size 500ms linear;
    font-size: 2.2em !important;
  }
}
/*# sourceMappingURL=info-item.component-ADSK5XC7.css.map */
