import { selectDataBus, selectPOI, selectAgent } from '../..//store/selectors/';
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { VERSION, GIT_REV } from '../../../environments/version';
import { WeatherInfo } from '../../models/ui/weatherInfo.model';
import { UtilityService } from '@app/services/utility.service';
import { ButtonIcon } from '../../models/ui/button.icon.model';
import { WebRTCService } from '../../services/web-rtc.service';
import { AppConfig } from '../../../environments/environment';
import { headerDummyData } from '../../dummyData/header.js';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import Keyboard from 'simple-keyboard';
import { Store } from '@ngrx/store';
import {
    toggleSearchBarModel,
    showDialog,
    toggleSideMenuInfo,
    toggleAnimationState,
    setAgentStatus,
    setSelectedPoi,
} from '../../store/actions';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';
import { ButtonIconComponent } from '../button-icon/button-icon.component';
import { WeatherComponent } from '../weather/weather.component';
import { TimeComponent } from '../time/time.component';
import { NgIf, NgClass, NgFor, NgStyle } from '@angular/common';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TimeComponent,
        WeatherComponent,
        ButtonIconComponent,
        NgClass,
        MatFormField,
        MatSelect,
        NgFor,
        MatOption,
        NgStyle,
    ],
})
export class HeaderComponent implements OnInit {
    @ViewChild('search') searchElement: ElementRef;
    @ViewChild('mySelect') mySelect;

    @Input('showHeaderLeftContainer') showHeaderLeftContainer = true;

    languageIcon: ButtonIcon = headerDummyData.languageIcon;
    searchIcon: ButtonIcon = headerDummyData.searchIcon;
    helpIcon: ButtonIcon = headerDummyData.helpIcon;
    currentYear = new Date().getFullYear();
    selectedOption: string = 'Available';
    selected: string = 'Available';
    weatherInfo: WeatherInfo = {
        shouldShow: false,
        imageUrl: '',
        value: '',
        unit: '',
    };
    isAgent: boolean = false;
    selectedCategory: any;
    keyboard: Keyboard;
    agentStatus: {}[];
    categories: any;

    constructor(
        public webRTCService: WebRTCService,
        private translate: TranslateService,
        private authService: AuthService,
        private utility: UtilityService,
        private store: Store<any>
    ) {}

    ngOnInit() {
        const agentState$ = this.store.select(selectAgent);
        const poiState$ = this.store.select(selectPOI);
        const dataBusState$ = this.store.select(selectDataBus);
        this.isAgent = AppConfig.isAgent;
        agentState$.subscribe((agent) => {
            this.agentStatus = [
                { value: 'Available', viewValue: 'Available' },
                { value: 'Unavailable', viewValue: 'Unavailable' },
                { value: 'Logout', viewValue: 'Logout' },
                { value: 'name', viewValue: agent.email },
            ];
        });

        poiState$.subscribe((poiItem) => {
            this.selectedCategory = poiItem.selectedCategory;
        });

        dataBusState$.subscribe((dataBusItem: any) => {
            if (dataBusItem.weather.currentWeather) {
                this.weatherInfo.unit = dataBusItem.weather.temperatureScale;
                this.weatherInfo.value =
                    dataBusItem.weather.currentWeather.temperature;
                this.weatherInfo.imageUrl = `assets/icon/${dataBusItem.weather.currentWeather.icon}.png`;
                this.utility.imageExists(
                    `assets/icon/${dataBusItem.weather.currentWeather.icon}.png`,
                    (exists) => {
                        this.weatherInfo.shouldShow = exists;
                    }
                );
            }
        });
    }

    toggleSearchBarModel() {
        this.store.dispatch(toggleSearchBarModel({ shouldOpen: true }));
        this.close();
    }

    close() {
        this.store.dispatch(
            toggleAnimationState({
                animationState: 'right',
                fadeInOut: false,
                isSideInfo: true,
            })
        );

        setTimeout(() => {
            this.store.dispatch(
                toggleAnimationState({
                    animationState: '',
                    fadeInOut: false,
                    isSideInfo: false,
                })
            );
            this.store.dispatch(
                toggleSideMenuInfo({
                    shouldOpen: false,
                    selectedCategory: this.selectedCategory,
                    animateSideInfo: false,
                })
            );
            this.store.dispatch(
                setSelectedPoi({
                    poiContent: {},
                    instructions: [],
                })
            );
        }, 350);
    }

    triggerDialog() {
        this.store.dispatch(
            showDialog({
                showDialog: true,
                title: `${AppConfig.pageName} v${VERSION} (${GIT_REV})`,
                subTitle: `© SITA ${this.currentYear}. ${this.translate.instant(
                    'RIGHTS_RESERVED'
                )}`,
                showCloudFunction: true,
            })
        );
    }

    callAgent() {
        this.webRTCService.didClientInitiateCall.emit(true);
    }

    toggleOption(option?) {
        if (this.selectedOption == option) return;
        switch (option) {
            case 'Logout':
                this.webRTCService.removeAgentFromFirebase();
                this.authService.signOut();

                break;
            case 'Available':
                this.selected = 'Available';
                this.selectedOption = 'Available';

                this.store.dispatch(
                    setAgentStatus({
                        setAgentStatus: true,
                    })
                );
                this.webRTCService.addAgentToFirebase();
                break;
            case 'Unavailable':
                this.selected = 'Unavailable';
                this.selectedOption = 'Unavailable';

                this.webRTCService.removeAgentFromFirebase();
                this.store.dispatch(
                    setAgentStatus({
                        setAgentStatus: false,
                    })
                );

                break;
            default:
                break;
        }
    }

    openDropDown() {
        this.mySelect.open();
    }
}
