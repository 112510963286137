import { setCheckIn, setLocusCategories } from '@app/store/actions';
import { GoogleAnalyticsService } from './google-analytics.service';
import { showVenue, getVenue, Path } from '@mappedin/mappedin-js';
import { Injectable, EventEmitter, inject } from '@angular/core';
import { selectDataBus } from '@app/store/selectors';
import { ApiService } from './api.service';
import { cloneDeep } from 'lodash-es';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})
export class MapService {
    //Basically a dynamic service that works for both Visio and Locus Lab Map
    //It call the function correctly according to the map
    //Makes the functionality of the map remain the same even after switch without anything breaking
    public mapInitialized = new EventEmitter();
    public initializationProgress = new EventEmitter();
    kioskInformation;
    overridePois;
    mapView: any;
    venue: any;
    kioskLocation: any;
    kioskTemplate = `
    <div class="kiosk-marker">
      <img src="assets/LLMapIcons/you_are_here.png" style="width:100px" />
    </div>`;

    path: Path | undefined = undefined;
    googleAnalyticService = inject(GoogleAnalyticsService);
    currentTick: number = 100;

    constructor(private store: Store<any>, private api: ApiService) {
        const dataBusState$ = this.store.select(selectDataBus);

        dataBusState$.subscribe((dataBusItem) => {
            this.overridePois = dataBusItem.overridePois;
            if (Object.keys(dataBusItem.kioskNames).length > 0)
                this.kioskInformation = dataBusItem.kioskNames[
                    dataBusItem.computerName
                ]
                    ? dataBusItem.kioskNames[dataBusItem.computerName]
                    : {
                          computerName: 'MSC-01-C1-IK1',
                          poiID: '6490e20a1b7d4018524f9009',
                          kioskLocation: 'L4, Terminal B',
                      };
        });
    }

    async initializeMap(mapElement: any) {
        const venueData: any = {
            clientId: '65caa21429b1935f963f3480',
            clientSecret: 'WsOzO5GcGmlBXZAChXRH42XCRtDyXFxHbt5F7vdIEmdzvY9d',
            venue: 'lawa-lax',
        };
        this.venue = await getVenue(venueData);
        this.mapView = await showVenue(mapElement.nativeElement, this.venue, {
            backgroundColor: '#2c2c2c',
            multiBufferRendering: true,
            outdoorView: {
                enabled: true,
            },
        });
        this.kioskLocation = this.venue.locations
            .find((location) => location.id === '64ecdac330755753d6879f95')
            .nodes.find(
                (element) =>
                    element.id === this.kioskInformation.poiID ||
                    element.id === this.kioskInformation.mappedinID
            );

        // this.mapView.Camera.interactions.set({
        //     zoom: false,
        //     rotationAndTilt: false,
        //     pan: false,
        // });
        this.mapView.FloatingLabels.labelAllLocations({
            appearance: {
                text: {
                    size: 18,
                },
            },
        });
        this.mapInitialized.emit({
            initialized: true,
            poiSet: false,
        });

        // const poisByCategory = {};
        // this.venue.categories.forEach((category) => {
        //     if (category.name && category.locations.length) {
        //         poisByCategory[category.name] = category.locations;
        //     }
        // });
        // console.log(poisByCategory);

        await this.getAllPois();
        setInterval(() => {
            this.getCheckIn();
        }, 900000);

        setInterval(() => {
            this.getAllPois();
        }, 24 * 60 * 60 * 1000);
    }

    async getAllPois() {
        let pois: any = {
            Shopping: [],
            Restrooms: [],
            Gates: [],
            Dining: [],
            CheckIn: [],
            Lounges: [],
            Transport: [],
            Search: [],
        };

        const totalPoiNumber = this.venue.locations.length;

        for (let i = 0; i < this.venue.locations.length; i++) {
            this.initializationProgress.emit({
                currentPoiIndex: i,
                percentage: `${Math.floor((i * 100) / totalPoiNumber)}%`,
                totalPoiNumber,
            });
            if (i === this.currentTick) {
                this.currentTick = this.currentTick + 100;

                await this.wait(1000);
            }
            const element: any = this.venue.locations[i];
            const isRestroom = element.categories.find(
                (node) => node.name === 'Restrooms'
            );
            const isServices = element.categories.find((node) =>
                ['Services'].includes(node.name)
            );

            let obj: any = {
                mapElementId: element.externalId,
                id: element.id,
                name: element.name,
                shortName: element.shortName ? element.shortName : element.name,
                airside: false,
                showLogo: true,
                webUrl: element?.social?.website ? element.social.website : '',
                keywords: element?.tags ? element.tags : [],
                logoUrl: element.logo?.medium ? element.logo?.medium : '',
                imageUrl:
                    element.gallery && element.gallery.length > 0
                        ? element.gallery[0].image.original
                        : element.logo?.medium
                        ? element.logo?.medium
                        : '',
                hoursOfOperation: element.operationHours
                    ? element.operationHours
                    : [],
                telephoneNo: element.phone?.number ? element.phone.number : '',
                details: {
                    en: {
                        shortDescription: element.description
                            ? element.description
                            : '',
                        fullDescription: element.description
                            ? element.description
                            : '',
                        shortLocation:
                            element.nodes.length > 0
                                ? `${element.nodes[0].map.shortName}, ${element.nodes[0].map.mapGroup.shortName}`
                                : '',
                        fullLocation:
                            element.nodes.length > 0
                                ? `${element.nodes[0].map.name}, ${element.nodes[0].map.mapGroup.name}`
                                : '',
                        id: element.poiId,
                        keywordSearch: element.name,
                        languageId: 1,
                    },
                    es: {
                        shortDescription: element.description
                            ? element.description
                            : '',
                        fullDescription: element.description
                            ? element.description
                            : '',
                        shortLocation:
                            element.nodes.length > 0
                                ? `${element.nodes[0].map.shortName}, ${element.nodes[0].map.mapGroup.shortName}`
                                : '',
                        fullLocation:
                            element.nodes.length > 0
                                ? `${element.nodes[0].map.name}, ${element.nodes[0].map.mapGroup.name}`
                                : '',
                        id: element.poiId,
                        keywordSearch: element.name,
                        languageId: 1,
                    },
                },
                nodes: element.nodes,
                polygons: element.polygons,
            };

            //override POI Image
            let overrideData = this.overridePois.find(
                (node) => node.poiID === element.poiId
            );

            if (overrideData) {
                obj.imageUrl = overrideData.Image;
                obj.logoUrl = overrideData.Image;
            }
            //override POI Image

            if (element.name.match('TV')) {
                obj['icon'] = 'assets/icon/live_tv.svg';
            }

            if (!isRestroom && !isServices) {
                const directions = this.getWalkTime(element, false);

                obj = {
                    ...obj,
                    ...directions,
                };

                const accessibleInstructions = this.getWalkTime(element, true);
                obj['accessibleInstructions'] =
                    accessibleInstructions.instructions;
                obj['accessibleDirections'] = accessibleInstructions.directions;

                if (
                    obj.instructions[0].direction === 'No Valid Path Available'
                ) {
                    continue;
                }
            }

            //pushing pois to their categories

            if (element.categories.find((node) => node.name === 'Shops')) {
                pois.Shopping.push({ ...obj, categoryId: 6 });
            }

            if (
                element.categories.find((node) => node.name === 'Food & Drinks')
            ) {
                if (element.nodes.length > 1) {
                    element.nodes.forEach((foodPois) => {
                        const directions = this.getWalkTime(
                            {
                                ...element,
                                externalId: foodPois.externalId,
                                id: foodPois.id,
                            },
                            false
                        );
                        const accessibleInstructions = this.getWalkTime(
                            {
                                ...element,
                                externalId: foodPois.externalId,
                                id: foodPois.id,
                            },
                            true
                        );
                        let _poi = cloneDeep(obj);
                        _poi.mapElementId = foodPois.externalId;
                        _poi.id = foodPois.id;
                        _poi.details.en.shortLocation = `${foodPois.map.shortName}, ${foodPois.map.mapGroup.shortName}`;
                        _poi.details.en.fullLocation = `${foodPois.map.name}, ${foodPois.map.mapGroup.name}`;
                        _poi.details.es.shortLocation = `${foodPois.map.shortName}, ${foodPois.map.mapGroup.shortName}`;
                        _poi.details.es.fullLocation = `${foodPois.map.name}, ${foodPois.map.mapGroup.name}`;
                        _poi.nodes = [foodPois];
                        _poi.polygons = [foodPois.polygon];

                        _poi.instructions = directions.instructions;
                        _poi.directions = directions.directions;
                        _poi.walkTime = directions.walkTime;
                        _poi.accessibleInstructions =
                            accessibleInstructions.instructions;
                        _poi.accessibleDirections =
                            accessibleInstructions.directions;
                        _poi.categoryId = 7;

                        if (
                            _poi.instructions[0].direction !==
                            'No Valid Path Available'
                        ) {
                            pois.Dining.push(_poi);
                        }
                    });
                } else {
                    pois.Dining.push({ ...obj, categoryId: 7 });
                }
            }

            if (element.categories.find((node) => node.name === 'Gates')) {
                obj['gateNumber'] = element.name;
                pois.Gates.push({ ...obj, categoryId: 1 });
            }

            if (element.categories.find((node) => node.name === 'Check-in')) {
                obj['icon'] = 'assets/icon/flight.svg';

                pois.CheckIn.push({ ...obj, categoryId: 9 });
            }

            if (element.categories.find((node) => node.name === 'Lounges')) {
                pois.Lounges.push({ ...obj, categoryId: 5 });
            }

            if (
                element.categories.find((node) =>
                    [
                        'Transportation',
                        'Shared Ride Vans Charter Buses',
                        'Ride Share',
                        'Rental Cars',
                        'LAX-it',
                        'LAX Shuttles',
                        'LAX FlyAway',
                        'Inter Terminal Shuttles',
                        'Hotel & Private Parking Shuttles',
                    ].includes(node.name)
                )
            ) {
                pois.Transport.push({ ...obj, categoryId: 11 });
            }

            if (
                element.categories.find((node) =>
                    [
                        'ATM / Exchange',
                        'Art Program',
                        'Parking',
                        'Security',
                        'Terminals',
                    ].includes(node.name)
                )
            ) {
                pois.Search.push({ ...obj, categoryId: 90 });
            }

            if (isRestroom) {
                element.nodes.forEach((restRoomNode) => {
                    const directions = this.getWalkTime(
                        {
                            ...element,
                            externalId: restRoomNode.externalId,
                            id: restRoomNode.id,
                        },
                        false
                    );
                    const accessibleInstructions = this.getWalkTime(
                        {
                            ...element,
                            externalId: restRoomNode.externalId,
                            id: restRoomNode.id,
                        },
                        true
                    );
                    let _poi = cloneDeep(obj);
                    _poi.mapElementId = restRoomNode.externalId;
                    _poi.id = restRoomNode.id;
                    _poi.details.en.shortLocation = `${restRoomNode.map.shortName}, ${restRoomNode.map.mapGroup.shortName}`;
                    _poi.details.en.fullLocation = `${restRoomNode.map.name}, ${restRoomNode.map.mapGroup.name}`;
                    _poi.details.es.shortLocation = `${restRoomNode.map.shortName}, ${restRoomNode.map.mapGroup.shortName}`;
                    _poi.details.es.fullLocation = `${restRoomNode.map.name}, ${restRoomNode.map.mapGroup.name}`;
                    _poi.nodes = [restRoomNode];
                    _poi.polygons = [restRoomNode.polygon];
                    if (element.name.match('Restroom')) {
                        _poi.icon = 'assets/icon/restroom.svg';
                    }

                    if (
                        element.name.match(' - Women') ||
                        element.name.match(' - Womens')
                    ) {
                        _poi.icon = 'assets/icon/woman-wc.svg';
                    }

                    if (
                        element.name.match(' - Men') ||
                        element.name.match(' - Mens')
                    ) {
                        _poi.icon = 'assets/icon/man-wc.svg';
                    }
                    if (element.name.match(' - Family')) {
                        _poi.icon = 'assets/icon/family-restroom.svg';
                    }

                    _poi.instructions = directions.instructions;
                    _poi.directions = directions.directions;
                    _poi.walkTime = directions.walkTime;
                    _poi.accessibleInstructions =
                        accessibleInstructions.instructions;
                    _poi.accessibleDirections =
                        accessibleInstructions.directions;
                    _poi.categoryId = 3;

                    if (
                        _poi.instructions[0].direction !==
                        'No Valid Path Available'
                    ) {
                        pois.Restrooms.push(_poi);
                    }
                });
            }

            if (isServices) {
                element.nodes.forEach((serviceNode) => {
                    const directions = this.getWalkTime(
                        {
                            ...element,
                            externalId: serviceNode.externalId,
                            id: serviceNode.id,
                        },
                        false
                    );
                    const accessibleInstructions = this.getWalkTime(
                        {
                            ...element,
                            externalId: serviceNode.externalId,
                            id: serviceNode.id,
                        },
                        true
                    );
                    let _poi = cloneDeep(obj);

                    _poi.mapElementId = serviceNode.externalId;
                    _poi.id = serviceNode.id;

                    _poi.details.en.shortLocation = `${serviceNode.map.shortName}, ${serviceNode.map.mapGroup.shortName}`;
                    _poi.details.en.fullLocation = `${serviceNode.map.name}, ${serviceNode.map.mapGroup.name}`;
                    _poi.details.es.shortLocation = `${serviceNode.map.shortName}, ${serviceNode.map.mapGroup.shortName}`;
                    _poi.details.es.fullLocation = `${serviceNode.map.name}, ${serviceNode.map.mapGroup.name}`;
                    _poi.nodes = [serviceNode];
                    _poi.polygons = [serviceNode.polygon];

                    _poi.instructions = directions.instructions;
                    _poi.directions = directions.directions;
                    _poi.walkTime = directions.walkTime;
                    _poi.accessibleInstructions =
                        accessibleInstructions.instructions;
                    _poi.accessibleDirections =
                        accessibleInstructions.directions;
                    _poi.categoryId = 90;

                    if (
                        _poi.instructions[0].direction !==
                        'No Valid Path Available'
                    ) {
                        pois.Search.push(_poi);
                    }
                });
            }
        }

        let poiCounts = 0;
        for (let i in pois) {
            pois[i] = this.sortByWalkTime(pois[i]);
            poiCounts += pois[i].length;
        }

        if (poiCounts !== 0) {
            this.store.dispatch(
                setLocusCategories({
                    payload: pois,
                })
            );
        }

        this.googleAnalyticService.onAllPoisSet(poiCounts);

        this.mapInitialized.emit({
            initialized: true,
            poiSet: true,
        });
    }

    wait(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async getCheckIn() {
        let checkInPois = [];

        const poisByCategory = {};
        this.venue.categories.forEach((category) => {
            if (category.name && category.locations.length) {
                poisByCategory[category.name] = category.locations;
            }
        });
        for (let i = 0; i < poisByCategory['Check-in'].length; i++) {
            const element: any = poisByCategory['Check-in'][i];

            let obj: any = {
                mapElementId: element.externalId,
                id: element.id,
                name: element.name,
                shortName: element.shortName ? element.shortName : element.name,
                airside: false,
                showLogo: true,
                webUrl: element?.social?.website ? element.social.website : '',
                keywords: element?.tags ? element.tags : [],
                logoUrl: element.logo?.medium ? element.logo?.medium : '',
                imageUrl:
                    element.gallery && element.gallery.length > 0
                        ? element.gallery[0].image.original
                        : element.logo?.medium
                        ? element.logo?.medium
                        : '',
                hoursOfOperation: element.operationHours
                    ? element.operationHours
                    : [],
                telephoneNo: element.phone?.number ? element.phone.number : '',
                details: {
                    en: {
                        shortDescription: element.description
                            ? element.description
                            : '',
                        fullDescription: element.description
                            ? element.description
                            : '',
                        shortLocation:
                            element.nodes.length > 0
                                ? `${element.nodes[0].map.shortName}, ${element.nodes[0].map.mapGroup.shortName}`
                                : '',
                        fullLocation:
                            element.nodes.length > 0
                                ? `${element.nodes[0].map.name}, ${element.nodes[0].map.mapGroup.name}`
                                : '',
                        id: element.poiId,
                        keywordSearch: element.name,
                        languageId: 1,
                    },
                    es: {
                        shortDescription: element.description
                            ? element.description
                            : '',
                        fullDescription: element.description
                            ? element.description
                            : '',
                        shortLocation:
                            element.nodes.length > 0
                                ? `${element.nodes[0].map.shortName}, ${element.nodes[0].map.mapGroup.shortName}`
                                : '',
                        fullLocation:
                            element.nodes.length > 0
                                ? `${element.nodes[0].map.name}, ${element.nodes[0].map.mapGroup.name}`
                                : '',
                        id: element.poiId,
                        keywordSearch: element.name,
                        languageId: 1,
                    },
                },
                nodes: element.nodes,
                polygons: element.polygons,
            };

            //override POI Image
            let overrideData = this.overridePois.find(
                (node) => node.poiID === element.poiId
            );

            if (overrideData) {
                obj.imageUrl = overrideData.Image;
                obj.logoUrl = overrideData.Image;
            }
            //override POI Image

            const directions = this.getWalkTime(element, false);

            obj = {
                ...obj,
                ...directions,
            };

            const accessibleInstructions = this.getWalkTime(element, true);
            obj['accessibleInstructions'] = accessibleInstructions.instructions;
            obj['accessibleDirections'] = accessibleInstructions.directions;
            obj['icon'] = 'assets/icon/flight.svg';

            if (obj.instructions[0].direction === 'No Valid Path Available') {
                continue;
            }

            checkInPois.push({ ...obj, categoryId: 9 });
        }

        if (checkInPois.length > 0) {
            this.store.dispatch(
                setCheckIn({
                    payload: this.sortByWalkTime(checkInPois),
                })
            );
        }
        this.googleAnalyticService.onCheckinSet(checkInPois.length);
    }

    async goToKioskLocation() {
        await this.mapView.setMap(this.kioskLocation.map);
        await this.mapView.Camera.focusOn({ nodes: [this.kioskLocation] });
        const coordinate = this.mapView.currentMap.createCoordinate(
            this.kioskLocation.lat, // latitude
            this.kioskLocation.lon // longitude
        );
        await this.mapView.Markers.add(coordinate, this.kioskTemplate);
    }

    async goToPoiLocation(poiData) {
        this.removeMarkers();
        await this.goToKioskLocation();
        const node = poiData.nodes[0];
        if (!node) {
            return;
        }
        await this.mapView.setMap(node.map);
        await this.mapView.Camera.focusOn({ nodes: [node] });
        await this.mapView.setPolygonColor(node.polygon, '#0e7b2f');

        const coordinates = this.mapView.currentMap.createCoordinate(
            poiData.nodes[0].lat, // latitude
            poiData.nodes[0].lon // longitude
        );

        this.mapView.Markers.add(
            coordinates,
            this.getDestinationTemplate(
                poiData.name,
                poiData.details.en.shortLocation
            )
        );
    }

    async showNavigationRoute(
        poiData,
        isFlight = false,
        isAccessibleRoute = false
    ) {
        if (isAccessibleRoute) {
            await this.mapView.Journey.draw(poiData.accessibleDirections, {
                pathOptions: {
                    nearRadius: 0.7,
                    farRadius: 0.7,
                    color: '#00a5ca',
                    animateDrawing: false,
                    flattenPath: true,
                    showPulse: false,
                    displayArrowsOnPath: false,
                },
                departureMarkerTemplate: this.kioskTemplate,
                destinationMarkerTemplate: this.getDestinationTemplate(
                    poiData.name,
                    poiData.details.en.shortLocation
                ),
            });
        } else {
            await this.mapView.Journey.draw(poiData.directions, {
                pathOptions: {
                    nearRadius: 0.7,
                    farRadius: 0.7,
                    color: '#00a5ca',
                    animateDrawing: false,
                    flattenPath: true,
                    showPulse: false,
                    displayArrowsOnPath: false,
                },
                departureMarkerTemplate: this.kioskTemplate,
                destinationMarkerTemplate: this.getDestinationTemplate(
                    poiData.name,
                    poiData.details.en.shortLocation
                ),
            });
        }
    }

    async showMapSteps(poiData, segmentIndex, isAccessible) {
        if (this.path) {
            this.mapView.Paths.remove(this.path);
        }
        let rotation = undefined;

        const directionsNode = isAccessible
            ? 'accessibleDirections'
            : 'directions';

        const nodeFrom =
            poiData[directionsNode].instructions[segmentIndex].node;
        const nodeTo = poiData[directionsNode].instructions[segmentIndex + 1]
            ? poiData[directionsNode].instructions[segmentIndex + 1].node
            : poiData[directionsNode].instructions[segmentIndex].node;
        const segmentDirections = nodeFrom.directionsTo(nodeTo);
        this.path = this.mapView.Paths.add(segmentDirections.path, {
            animateDrawing: true,
            showPulse: true,
            nearRadius: 0.7,
            farRadius: 0.7,
            color: '#08c3ed',
        });
        rotation =
            Math.PI - Math.atan2(nodeTo.y - nodeFrom.y, nodeTo.x - nodeFrom.x);
        // // Switch floors if we need to
        if (this.mapView.currentMap.id !== nodeFrom.map.id) {
            await this.mapView.setMap(nodeFrom.map.id);
        }
        // Focus the camera on the nodes
        await this.mapView.Camera.focusOn(
            {
                nodes: [nodeFrom, nodeTo],
            },
            {
                rotation: rotation,
                duration: 1000,
            }
        );
    }

    getWalkTime(location, isAccessibleRoute) {
        let walkTime = 0;
        let instructions;

        const directions = this.kioskLocation.directionsTo(location, {
            accessible: isAccessibleRoute,
        });

        walkTime = Math.round(directions.distance / 0.94 / 60);

        instructions = this.getNavigationInstructions(directions.instructions);

        // if (location.externalId === 'LOC-IG42ONTV') {
        //     console.log(directions);

        //     directions.instructions;
        // }

        if (walkTime === 0) {
            //means quite close by
            walkTime = 1;
        }

        return { walkTime, instructions, directions };
    }

    resetMap() {
        this.mapView.Paths.removeAll();
        this.mapView.Journey.clear();
        this.removeMarkers();
    }

    async removeMarkers() {
        await this.mapView.Markers.removeAll();
    }

    sortByWalkTime(poiList) {
        return poiList.sort((a, b) => {
            if (a.walkTime > b.walkTime) return 1;
            if (a.walkTime < b.walkTime) return -1;
            return 0;
        });
    }

    getNavigationInstructions(poiInstructions) {
        let normalizedInstructions = [];
        for (let i = 0; poiInstructions.length > i; i++) {
            let walkTime = Math.round(poiInstructions[i].distance / 0.94 / 60);
            let instruction = `${poiInstructions[i].instruction}`;
            // Determine the appropriate string based on walk time
            let walkTimeString;
            if (walkTime < 0.5) {
                walkTimeString = 'Less than a minute';
            } else if (walkTime >= 0.5 && walkTime <= 1.25) {
                walkTimeString = '1 minute';
            } else {
                walkTimeString = `${Math.ceil(walkTime)} minutes`;
            }

            if (i === 0 && poiInstructions[1]) {
                normalizedInstructions.push({
                    direction: `Leave Interactive Kiosk and ${poiInstructions[1].instruction}`,
                    icon: this.getIcon(poiInstructions[1]),
                    walkTimeString: walkTimeString,
                });
            } else if (i === 1) {
                continue;
            } else if (poiInstructions[i].action.type === 'Arrival') {
                normalizedInstructions.push({
                    direction: `Arrive at ${poiInstructions[i].node.locations[0].name}`,
                    icon: this.getIcon(poiInstructions[i]),
                    walkTimeString: walkTimeString,
                });
            } else if (
                (poiInstructions[i].instruction.startsWith('Turn') &&
                    poiInstructions[i].instruction.includes('at')) ||
                (poiInstructions[i].action.type !== 'Arrival' &&
                    poiInstructions[i].action.type !== 'Departure' &&
                    !poiInstructions[i].instruction.startsWith('Turn'))
            ) {
                normalizedInstructions.push({
                    direction: instruction,
                    icon: this.getIcon(poiInstructions[i]),
                    walkTimeString: walkTimeString,
                });
            } else if (
                poiInstructions[i].instruction.startsWith('Turn') &&
                !poiInstructions[i].instruction.includes('at')
            ) {
                const poiLocation = poiInstructions[i].node.locations[0];
                normalizedInstructions.push({
                    direction: poiLocation
                        ? `${poiInstructions[i].instruction} at ${poiLocation.name}`
                        : instruction,
                    icon: this.getIcon(poiInstructions[i]),
                    walkTimeString: walkTimeString,
                });
            }
        }

        return normalizedInstructions;
    }

    getIcon(instruction): string {
        let icon = '';
        if (instruction.action.type === 'Arrival') {
            icon = 'assets/icon/arrival.svg';
        } else if (
            instruction.action.type === 'Turn' &&
            instruction.action.referencePosition === 'At'
        ) {
            icon = 'assets/icon/turn_left.svg';
        } else if (
            instruction.action.type === 'Turn' &&
            instruction.action.bearing === 'Right'
        ) {
            icon = 'assets/icon/turn_right.svg';
        } else if (
            instruction.action.type === 'Turn' &&
            instruction.action.bearing === 'SlightLeft'
        ) {
            icon = 'assets/icon/turn_left.svg';
        } else if (
            instruction.action.type === 'Turn' &&
            instruction.action.bearing === 'SlightRight'
        ) {
            icon = 'assets/icon/turn_right.svg';
        } else if (instruction.action.type === 'TakeVortex') {
            if (instruction.atLocation.type === 'door') {
                icon = 'assets/icon/door.svg';
            } else if (instruction.atLocation.type === 'elevator') {
                icon = 'assets/icon/elevator.svg';
            } else if (instruction.atLocation.type === 'escalator') {
                icon = 'assets/icon/escalator.svg';
            } else if (instruction.atLocation.type === 'shuttle') {
                icon = 'assets/icon/shuttle.svg';
            }
        } else if (instruction.action.type === 'ExitVortex') {
            if (instruction.atLocation.type === 'door') {
                icon = 'assets/icon/door.svg';
            } else if (instruction.atLocation.type === 'elevator') {
                icon = 'assets/icon/elevator.svg';
            } else if (instruction.atLocation.type === 'escalator') {
                icon = 'assets/icon/escalator.svg';
            } else if (instruction.atLocation.type === 'shuttle') {
                icon = 'assets/icon/shuttle.svg';
            }
        }

        return icon;
    }

    getDestinationTemplate(destinationName, location) {
        return `
    <div class="destination-marker">
      <img src="assets/LLMapIcons/destination.png" style="width:100px" />
      <div class="destination-label">
      <span>${destinationName}</span>
      <span style="font-weight: 400;">${location}</span>
      </div>
    </div>`;
    }
}
