import { AppConfig } from '../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { selectUI } from '@app/store/selectors';
import { Store } from '@ngrx/store';
import {
    toggleAnimationState,
    toggleSideMenuInfo,
    toggleStaticInfoModal,
    toggleSubSideMenu,
    setSelectedPoi,
} from '@app/store/actions';
import { TranslateModule } from '@ngx-translate/core';
import { RatingComponent } from '../rating/rating.component';
import { NgIf, NgStyle, NgClass } from '@angular/common';
@Component({
    selector: 'app-back-poi-item',
    templateUrl: './back-poi-item.component.html',
    styleUrls: ['./back-poi-item.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle, NgClass, RatingComponent, TranslateModule],
})
export class BackPoiItemComponent implements OnInit {
    @Input('poiData') data;
    @Input('title') title;
    @Input('subtitle') subtitle;
    @Input('selectedCategory') selectedCategory;
    uiSubscription: any;
    isSideMenuInfo: any;
    isHotel: boolean = false;
    hideWalkTime: boolean = true;
    fontSizeShouldIncrease: boolean = true;
    constructor(private store: Store<any>) {}
    ngOnInit() {
        const uiState$ = this.store.select(selectUI);

        this.uiSubscription = uiState$.subscribe((uiItem) => {
            this.isSideMenuInfo = uiItem.windows.sideMenuInfo;
        });

        if (
            this.selectedCategory == 'Hotels' ||
            this.selectedCategory == 'tourism' ||
            this.selectedCategory == 'LAX_TV' ||
            this.selectedCategory == 'ADA' ||
            this.selectedCategory == 'INFO_TIPS'
        )
            this.hideWalkTime = true;
        else this.hideWalkTime = false;
        if (
            this.selectedCategory == 'LAX_TV' ||
            this.selectedCategory == 'ADA' ||
            this.selectedCategory == 'INFO_TIPS'
        )
            this.fontSizeShouldIncrease = true;
        else this.fontSizeShouldIncrease = false;

        if (
            this.selectedCategory == 'Hotels' ||
            this.selectedCategory == 'tourism'
        )
            this.isHotel = true;
        else this.isHotel = false;
    }

    ngOnDestroy() {
        this.uiSubscription.unsubscribe();
    }

    close() {
        if (
            this.selectedCategory == 'LAX_TV' ||
            this.selectedCategory == 'ADA' ||
            this.selectedCategory == 'INFO_TIPS'
        ) {
            this.store.dispatch(toggleStaticInfoModal({ shouldOpen: false }));

            this.store.dispatch(
                toggleSubSideMenu({
                    shouldOpen: false,
                    selectedCategory: '',
                    categoryInfo: {},
                })
            );
        } else {
            this.store.dispatch(
                toggleAnimationState({
                    animationState: 'right',
                    fadeInOut: false,
                    isSideInfo: true,
                })
            );

            if (
                this.selectedCategory == 'Hotels' ||
                this.selectedCategory == 'tourism'
            ) {
                this.store.dispatch(setSelectedPoi({ poiContent: {} }));
                this.store.dispatch(
                    toggleSideMenuInfo({
                        shouldOpen: false,
                        selectedCategory: this.selectedCategory,
                        animateSideInfo: false,
                    })
                );
            } else {
                setTimeout(() => {
                    this.store.dispatch(
                        toggleAnimationState({
                            animationState: '',
                            fadeInOut: false,
                            isSideInfo: false,
                        })
                    );

                    this.store.dispatch(
                        toggleSideMenuInfo({
                            shouldOpen: false,
                            selectedCategory: this.selectedCategory,
                            animateSideInfo: false,
                        })
                    );
                }, 350);
            }
        }
    }
}
