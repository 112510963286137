/* src/app/components/time/time.component.scss */
.time-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 100px;
  align-items: center;
  justify-content: center;
}
.time-value {
  color: black;
}
.date-value {
  word-break: break-word;
  color: #bebcbc;
  text-align: center;
  font-weight: bold;
}
#time-form {
  text-transform: capitalize;
}
.time-value {
  font-size: 2em;
  transition: font-size 500ms linear;
}
.date-value {
  font-size: 0.9em;
  width: 100%;
  transition: font-size 500ms linear;
}
@media screen and (max-width: 1500px) {
  .date-value {
    font-size: 0.85em;
  }
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .time-value {
    font-size: 4em;
    transition: font-size 500ms linear;
  }
  .date-value {
    font-size: 2.1em;
    transition: font-size 500ms linear;
  }
}
/*# sourceMappingURL=time.component-O67HO437.css.map */
