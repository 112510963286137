/* src/app/components/search-bar/search-bar.component.scss */
.search-bar-container {
  background-color: rgba(25, 25, 25, 0.7);
  height: 100vh;
  width: 100%;
  z-index: 10;
  top: 0;
}
.search-bar-container .search-bar-body {
  overflow: auto;
  height: 54%;
}
.search-bar-container .search-bar-body .list-container {
  background-color: transparent;
  height: 100%;
}
.search-bar-container .search-bar-body .list-container .list-item-container {
  width: calc(33.3333333333% - 0.7rem);
}
.search-bar-container .search-bar-body .list-container .alert-container {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  display: flex;
  height: 50vh;
  width: 100%;
}
.search-bar-container .search-bar-body .list-container .alert-container .icon-container {
  justify-content: center;
  align-items: center;
  display: flex;
}
.search-bar-container .search-bar-body .list-container .alert-container .icon-container .icon .material-icons {
  transition: font-size 500ms linear;
  color: white;
  font-size: 5em;
}
.search-bar-container .search-bar-body .list-container .alert-container .alert-text {
  text-align: center;
  font-size: 2em;
  color: white;
}
.search-bar-container .search-bar-body .list-container .alert-agent-container {
  height: 85vh;
}
.search-bar-container .search-bar-agent-body {
  min-height: 100% !important;
  max-height: 100% !important;
  overflow: auto !important;
}
.search-bar-container .search-bar-footer {
  position: fixed;
  width: 75%;
  bottom: 0%;
  height: 46%;
}
.search-bar-container .search-bar-footer .search-bar-component {
  background-color: white;
  display: flex;
  height: 15%;
  width: 100%;
}
.search-bar-container .search-bar-footer .search-bar-component .left-container {
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 0.05;
}
.search-bar-container .search-bar-footer .search-bar-component .left-container .material-icons {
  font-weight: 100;
  color: black;
}
.search-bar-container .search-bar-footer .search-bar-component .middle-container {
  background-color: white;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.search-bar-container .search-bar-footer .search-bar-component .middle-container .search-bar-filter {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}
.search-bar-container .search-bar-footer .search-bar-component .middle-container .search-bar-filter input {
  border-width: 0px;
  font-weight: 200;
  height: 50%;
  flex: 1;
}
.search-bar-container .search-bar-footer .search-bar-component .middle-container .search-bar-filter input:focus {
  outline-width: 0;
}
.search-bar-container .search-bar-footer .search-bar-component .logo-container {
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex: 0.14;
}
.search-bar-container .search-bar-footer .search-bar-component .logo-container .logo {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  flex: 1;
}
.search-bar-container .search-bar-footer .search-bar-component .right-container {
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 0.1;
}
.search-bar-container .search-bar-footer .search-bar-component .right-container .close-icon-container {
  align-items: center;
  border-radius: 50%;
  display: flex;
  padding: 2%;
}
.search-bar-container .search-bar-footer .search-bar-component .right-container .material-icons {
  color: black;
  font-weight: 100;
}
.search-bar-container .search-bar-footer .simple-keyboard {
  height: 100%;
}
.search-bar-container .search-bar-footer .hg-rows {
  height: 100%;
}
.search-bar-container .search-bar-footer .hg-rows .hg-row {
  height: 16.6666666667%;
}
.content {
  align-items: flex-start;
  justify-content: center;
  display: flex;
}
.content .listing-pois-container {
  flex-wrap: wrap;
  display: flex;
  width: 100vw;
  gap: 1rem;
  padding: 1rem;
  padding-top: 3rem;
  padding-bottom: 0rem;
}
.content .listing-pois-container::after {
  content: "";
  flex: auto;
}
.listing-pois-container-agent {
  width: 100% !important;
}
.simple-keyboard.keyboard-theme {
  background-color: rgba(25, 25, 25, 0.7);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-radius: 0;
}
.simple-keyboard.keyboard-theme .hg-button {
  background: rgba(0, 0, 0, 0.9);
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
  height: 100%;
}
.simple-keyboard.keyboard-theme .hg-button:active {
  background: #1c4995;
  color: white;
}
#root .simple-keyboard.keyboard-theme + .simple-keyboard-preview {
  background: #1c4995;
}
@media screen and (min-width: 0px) and (min-height: 0px) {
  .left-container .material-icons {
    transition: font-size 500ms linear;
    font-size: 3.5em;
  }
  .search-bar-filter input {
    transition: font-size 500ms linear;
    font-size: 1.5em;
  }
  .right-container .material-icons {
    transition: font-size 500ms linear;
    font-size: 3.5em;
  }
  .alert-container .icon-container .icon .material-icons {
    transition: font-size 500ms linear;
    font-size: 8em !important;
  }
  .alert-container .alert-text {
    font-size: 3em !important;
    color: white;
  }
}
@media screen and (min-width: 1601px) and (min-height: 870px) {
  .search-bar-body .list-container .list-item-container {
    margin: 0% 0.5em 0.5em 0.5em !important;
    width: 31.9% !important;
  }
  .search-bar-footer {
    width: calc(100% - 480px) !important;
  }
}
@media screen and (min-width: 1920px) and (min-height: 900px) {
  .search-bar-body .list-container .list-item-container {
    margin: 0% 0% 0% 0% !important;
    width: 32.5% !important;
  }
  .search-bar-footer {
    width: calc(100% - 480px) !important;
  }
}
@media screen and (min-width: 3840px) and (min-height: 2160px) {
  .left-container .material-icons {
    font-size: 7em !important;
    transition: font-size 500ms linear;
  }
  .search-bar-header .search-bar-component {
    height: 15%;
  }
  .search-bar-body .list-container .list-item-container {
    width: 32.9% !important;
    margin: 0% 0% 0% 0% !important;
  }
  .search-bar-filter input {
    font-size: 3em !important;
    transition: font-size 500ms linear;
  }
  .search-bar-filter input::placeholder {
    font-size: 1.2em !important;
  }
  .right-container .material-icons {
    font-size: 7em !important;
    transition: font-size 500ms linear;
  }
  .alert-container .icon-container .icon .material-icons {
    font-size: 8em !important;
    transition: font-size 500ms linear;
  }
  .alert-container .alert-text {
    font-size: 6em !important;
    color: white;
  }
  .simple-keyboard.keyboard-theme {
    height: 100% !important;
  }
  .hg-theme-default .hg-button span {
    font-size: 2.5rem !important;
  }
  .search-bar-footer {
    width: 75% !important;
  }
}
/*# sourceMappingURL=search-bar.component-BVOSWVFN.css.map */
