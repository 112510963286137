import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animationConfig } from '../../angular-animation/animation';
import { AppConfig } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-expandable-item',
    templateUrl: './expandable-item.component.html',
    styleUrls: ['./expandable-item.component.scss'],
    animations: [animationConfig.expansion, animationConfig.fadeInOut],
    standalone: true,
    imports: [NgClass, NgIf, SafeHtmlPipe],
})
export class ExpandableItemComponent implements OnInit {
    @Input('isExpandable') isExpanded;
    @Input('info') info;

    @Output() onClick = new EventEmitter<string>();

    constructor(public translate: TranslateService) {}

    ngOnInit() {}

    onButtonClick() {
        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) this.onClick.emit(this.info);
    }
}
