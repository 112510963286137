import { dataBusState } from '../state/dataBus.state';
import { Action, createReducer, on } from '@ngrx/store';
import {
    getWeatherInformationSuccess,
    setLocusCategories,
    getSettingSuccess,
    getContentSuccess,
    saveSelfieSuccess,
    setComputerName,
    setCheckIn,
} from '../actions';

const initialState: dataBusState = {
    lastUpdated: '',
    categories: {},
    languages: {},
    weather: {},
    idleState: [],
    pois: {
        Transport: [],
        Restrooms: [],
        Shopping: [],
        CheckIn: [],
        Lounges: [],
        Dining: [],
        Gates: [],
        Hotels: [],
        Attractions: [],
    },
    selfie: {
        code: 0,
    },
    overridePois: [],
    staticInfo: [],
    cmsVideos: {
        videos: [],
        featuredYoutubeList: [],
        youtubeList: [],
    },
    emergencyBroadcast: {
        enabled: false,
        title: {
            en: '',
            es: '',
        },
        subtitle: {
            en: '',
            es: '',
        },
    },
    surveyConfig: {
        persistentSurvey: {
            enabled: false,
            introScreen: '',
            questions: [],
            thankYouScreen: '',
        },
        postCallSurvey: {
            enabled: false,
            introScreen: '',
            questions: [],
            thankYouScreen: '',
        },
    },
    turnServers: [],
    kioskNames: {},
    computerName: '',
};

const dataBusReducer = createReducer(
    initialState,
    on(getContentSuccess, (state, action) => {
        const _d = { ...state };
        _d.pois.Hotels = action.hotels;
        _d.pois.Attractions = action.attractions;
        _d.overridePois = action.overridePois;
        _d.staticInfo = action.staticInfo;
        _d.turnServers = action.turnServers;
        _d.cmsVideos.videos = action.cmsVideos.videos;
        _d.cmsVideos.featuredYoutubeList = action.cmsVideos.featuredYoutube;
        _d.cmsVideos.youtubeList = action.cmsVideos.youtube;

        _d.emergencyBroadcast = action.emergencyBroadcast;
        _d.surveyConfig = action.surveyConfig;
        _d.lastUpdated = action.lastUpdated;
        _d.kioskNames = action.kioskNames;
        _d.idleState = action.idleState;

        return _d;
    }),
    on(setComputerName, (state, action) => {
        const _d = { ...state };
        _d.computerName = action.computerName;
        return _d;
    }),

    on(getSettingSuccess, (state, action) => {
        let _d = { ...state };
        _d.categories = action.payload.categories;
        _d.languages = action.payload.languages;
        return _d;
    }),
    on(getWeatherInformationSuccess, (state, action) => {
        let _d = { ...state };
        _d.weather = action.payload;
        return _d;
    }),
    on(setLocusCategories, (state, action) => {
        let _d = { ...state };

        _d.pois = {
            ...action.payload,
            Hotels: _d.pois.Hotels,
            Attractions: _d.pois.Attractions,
        };

        return _d;
    }),
    on(saveSelfieSuccess, (state, action) => {
        let _d = { ...state };
        _d.selfie.code = action.code;

        return _d;
    }),

    on(setCheckIn, (state, action) => {
        let _d = { ...state };
        _d.pois.CheckIn = action.payload;

        return _d;
    })
);

export default function reducer(state: dataBusState, action: Action) {
    return dataBusReducer(state, action);
}
