import { PartnerItemComponent } from '../partner-item/partner-item.component';
import { Component, OnInit, Input } from '@angular/core';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-flight-partner-row',
    templateUrl: './flight-partner-row.component.html',
    styleUrls: ['./flight-partner-row.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, NgFor, PartnerItemComponent, TranslateModule],
})
export class FlightPartnerRowComponent implements OnInit {
    @Input('flightSelected') flightSelected;
    @Input('hasBackArrow') hasBackArrow;
    @Input('partnerData') data;

    widthResolution = 0;

    ngOnInit() {
        this.widthResolution = window.screen.width;
    }
}
