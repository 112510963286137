import { createAction, props } from "@ngrx/store";
import { types } from "./types";

export const toggleAnimationState = createAction(
  types.TOGGLE_ANIMATION_STATE,
  props<{
    animationState: string;
    fadeInOut: boolean;
    isSideInfo: boolean;
  }>()
);
