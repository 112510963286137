/* src/app/components/side-menu-lax/side-menu-lax.component.scss */
.left-side-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  background: #00a5ca;
  min-width: 480px;
  scrollbar-width: none;
  overflow: auto;
  height: 100%;
}
.left-side-menu .airport-info-container {
  height: 30%;
  display: flex;
  flex-direction: column;
}
.left-side-menu .airport-info-container .image-container {
  justify-content: center;
  cursor: pointer;
  display: flex;
  height: 70%;
}
.left-side-menu .airport-info-container .image-container .airport-logo {
  pointer-events: none;
  padding-top: 3rem;
}
.left-side-menu .airport-info-container .local-information {
  height: 15%;
  display: flex;
  justify-content: space-around;
}
.left-side-menu .menu-container {
  height: 70%;
}
.left-side-menu .menu-container .menu {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}
.left-side-menu .menu-container .menu .list {
  height: 100%;
  width: 95%;
  padding-left: 5%;
}
.left-side-menu .menu-container .menu .list .menu-button {
  position: relative;
  background-color: white;
  border-radius: 10px;
  margin: 4.9%;
  margin-left: 0;
  margin-top: 0;
  float: left;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.left-side-menu .menu-container .menu .list .menu-button .icon-container {
  justify-content: center;
  pointer-events: none;
  align-items: center;
  color: black;
  display: flex;
  height: 50%;
  width: 100%;
}
.left-side-menu .menu-container .menu .list .menu-button .icon-container .svg {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(5%) hue-rotate(14deg) brightness(88%) contrast(101%);
}
.left-side-menu .menu-container .menu .list .menu-button .text-container {
  justify-content: center;
  user-select: none;
  display: flex;
  width: 100%;
}
.left-side-menu .menu-container .menu .list .menu-button .text-container .text {
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.left-side-menu .menu-container .menu .list .menu-button::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background-color: transparent;
}
.selected-category {
  background: black !important;
}
.selected-category .icon-container .icon {
  color: #00a6ce !important;
}
.selected-category .icon-container .svg {
  filter: brightness(0) saturate(100%) invert(52%) sepia(67%) saturate(4166%) hue-rotate(160deg) brightness(97%) contrast(101%) !important;
}
.selected-category .text-container .text {
  color: white !important;
}
.hide-menu-button {
  display: none !important;
}
.hide-menu-button-dupe {
  display: none !important;
}
@media screen and (max-width: 3839px), (max-height: 2159px) {
  .airport-logo {
    width: 15em;
    height: 7em;
  }
  .list .menu-button {
    height: 8.1em;
    width: 8.1em;
  }
  .list .menu-button .icon-container .icon {
    font-size: 4em;
  }
  .list .menu-button .icon-container .svg {
    width: 2.3em;
  }
  .list .menu-button .text-container .text {
    font-size: 1em;
  }
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .airport-logo {
    width: 35em;
    height: 16em;
  }
  .list .menu-button {
    height: 16em;
    width: 16em;
  }
  .list .menu-button .icon-container .icon {
    font-size: 8em;
  }
  .list .menu-button .icon-container .svg {
    width: 5em;
  }
  .list .menu-button .text-container .text {
    font-size: 2em;
  }
}
@media screen and (max-height: 1079px) {
  .airport-logo {
    width: 15em;
    height: 7em;
  }
  .list {
    width: 88.5% !important;
  }
  .list .menu-button {
    height: 7.7em !important;
    width: 7.7em !important;
    margin: 4% !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
}
.loader:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  margin: 8px;
  border-radius: 50%;
  border: 6px dashed;
  border-color: white;
  animation: loader 1.2s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=side-menu-lax.component-G5IFXCAN.css.map */
