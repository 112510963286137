/* src/app/components/video-chat/video-chat.component.scss */
.video-container {
  display: flex;
  flex: 1;
}
.video-container .initial-state-container {
  cursor: pointer;
  background: rgba(20, 20, 20, 0.5);
  width: 19vw;
  height: 8vh;
  transition: height 0.2s;
  -webkit-transition: height 0.2s;
  border-bottom-left-radius: 20px;
  display: flex;
  flex: 1;
  min-width: 320px;
  min-height: 65px;
}
.video-container .initial-state-container .initial-content-container {
  display: flex;
  flex: 1;
}
.video-container .initial-state-container .initial-content-container .left-container {
  margin-left: 5%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-container .initial-state-container .initial-content-container .left-container .icon {
  width: 20px;
  height: 35px;
}
.video-container .initial-state-container .initial-content-container .left-container .icon .material-icons {
  font-size: 2.5em;
  transition: font-size 0.2s;
  -webkit-transition: font-size 0.2s;
  color: #ffffff;
}
.video-container .initial-state-container .initial-content-container .right-container {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-container .initial-state-container .initial-content-container .right-container .title {
  font-weight: bold;
  color: white;
}
.video-container .initial-state-container .snack-bar-error-container {
  display: flex;
  flex: 1;
}
.video-container .initial-state-container .snack-bar-error-container app-snack-bar {
  width: 100%;
}
.video-container .agent-receiving-call-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.video-container .agent-receiving-call-container .chatting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 21vw;
  height: 31vh;
  min-height: 15em;
  min-width: 21em;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .header-container {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .header-container .title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .header-container .title-container .title {
  color: white;
  font-size: 1em;
  font-weight: bold;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .header-container .title-container .title {
  color: white;
  font-size: 0.9em;
  font-weight: bold;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .header-container .icon-container {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .header-container .icon-container .icon {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .header-container .icon-container .material-icons {
  cursor: pointer;
  color: white;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .footer-container {
  position: absolute;
  bottom: 0;
  display: flex;
  flex: 1;
  width: 100%;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .footer-container .btn-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .footer-container .btn-container .volume-container {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  display: flex;
  bottom: 3px;
  z-index: 5;
  left: 0;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .footer-container .btn-container .volume-container .icon {
  color: white;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .footer-container .btn-container .hangOut-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  cursor: pointer;
}
.video-container .agent-receiving-call-container .chatting-container .web-rtc-container .footer-container .btn-container .hangOut-button-container .icon {
  color: white;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-container .agent-receiving-call-container .animate-chatting-container {
  width: 50vw;
  min-width: 38em;
  min-height: 35em;
}
.video-container app-web-rtc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.empty-flex {
  flex: 0.1;
}
.disable-button {
  pointer-events: none;
  opacity: 0.5;
}
.web-rtc-content {
  position: relative;
  height: 100%;
  width: 100%;
}
.web-rtc-content video {
  background: black;
}
.web-rtc-content .remoteVideo {
  width: 100%;
  height: 100%;
}
.fit-video-container {
  width: 100%;
  height: 100%;
}
.localVideo {
  border: 1px gray solid;
  width: 25% !important;
  height: 25% !important;
  position: absolute;
  z-index: 5;
  bottom: 0;
  right: 0;
}
.agent-main-container {
  min-height: 22em;
  min-width: 21em;
  background: #3c3c3c;
  display: flex;
  flex-direction: column;
}
.agent-main-container .chatting-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.agent-main-container .chatting-container .video-cam-container {
  background: white;
  border-radius: 50%;
  height: 4em;
  width: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.agent-main-container .chatting-container .video-cam-container .icon .material-icons {
  font-size: 3em;
}
.agent-main-container .chatting-container .title-container .info-container {
  justify-content: center;
  padding-top: 5%;
  display: flex;
}
.agent-main-container .chatting-container .title-container .info-container .title {
  color: white;
  font-size: 0.9em;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
}
.agent-main-container .chatting-container .title-container .info-container .sub-title {
  color: white;
  font-size: 0.8em;
  text-transform: capitalize;
  text-align: center;
}
.agent-main-container .footer-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 0.1;
}
.agent-main-container .footer-container .button-container {
  background: rgb(143, 143, 143);
  border-radius: 3px;
  flex: 0.3;
  height: 1.6em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agent-main-container .footer-container .button-container .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.agent-main-container .footer-container .button-container .icon .material-icons {
  font-size: 1em;
}
.agent-main-container .footer-container .button-container .text-container {
  padding-left: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  font-size: 0.8em;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .agent-receiving-call-container .animate-chatting-container {
    min-height: 50vh !important;
  }
  .agent-receiving-call-container .animate-chatting-container .title {
    font-size: 3em !important;
  }
  .agent-receiving-call-container .hangOut-button-container {
    width: 7em !important;
    height: 7em !important;
  }
  .agent-receiving-call-container .hangOut-button-container .icon {
    font-size: 3.5em !important;
  }
  .title {
    font-size: 2em !important;
  }
  .header-container .icon .material-icons {
    font-size: 3em !important;
  }
}
/*# sourceMappingURL=video-chat.component-MUHBPTXT.css.map */
