import { Injectable } from '@angular/core';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import { AppConfig } from '@environment/environment';
dayjs.extend(utc);
dayjs.extend(timezone);

@Injectable({
    providedIn: 'root',
})
export class TimeConversionService {
    formatDate(
        date: string | number = undefined,
        dateFormat: string = '',
        parseZone: boolean = true,
        toLocal: boolean = false
    ): string {
        // Use the current date if no valid date is provided
        const currentDate = date ? dayjs(date) : dayjs();

        if (currentDate.isValid()) {
            if (parseZone) {
                return dayjs.utc(currentDate).format(dateFormat);
            } else if (toLocal) {
                return dayjs.utc(currentDate).local().format(dateFormat);
            }
            return currentDate.format(dateFormat);
        } else {
            return 'Invalid Date';
        }
    }

    getIsoString(): any {
        return dayjs().toISOString();
    }

    getUnix(date?: any): any {
        return dayjs(date).unix();
    }

    getAfterFlightTime() {
        return dayjs()
            .add(AppConfig.getFlightsAfter, 'minute')
            .tz('America/Los_Angeles')
            .format('MM/DD/YYYY h:mm:ss A');
    }

    getGlobalTimeZone() {
        return dayjs().tz('America/Los_Angeles').format('h:mm a');
    }

    getGlobalDateZone() {
        let formatedDate: string = `${dayjs()
            .tz('America/Los_Angeles')
            .format('ddd')}
     ${dayjs().tz('America/Los_Angeles').format('MMM')} ${dayjs()
            .tz('America/Los_Angeles')
            .format('D')}`;

        return formatedDate;
    }

    getCurrentTime() {
        return dayjs();
    }

    getValueOf() {
        return dayjs().valueOf();
    }
}
