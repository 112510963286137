/* src/app/components/sticky-button-list/sticky-button-list.component.scss */
.sticky-button-list-container {
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  min-width: 8em;
  display: flex;
  height: 55%;
  z-index: 9;
  width: 8%;
  bottom: 0;
  right: 0;
}
.sticky-button-list-container .sticky-btn-container {
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  max-height: 8em;
  display: flex;
  flex: 1;
}
.sticky-button-list-container .sticky-btn-container app-button-icon {
  height: 100%;
}
.sticky-button-list-container .m-b-3 {
  margin-bottom: 3em;
}
.sticky-button-list-container .br-l-10px {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.agent-state-container {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  background-color: white;
  max-height: 8em;
  cursor: pointer;
  display: flex;
  height: 100%;
  flex: 1;
}
.agent-state-container .btn-container {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
  flex: 1;
}
.agent-state-container .btn-container .icon-container {
  justify-content: center;
  pointer-events: none;
  align-items: center;
  color: black;
  display: flex;
  height: 30px;
  width: 100%;
  flex: 1;
}
.agent-state-container .btn-container .icon-container .dot-container {
  display: flex;
  z-index: 1;
}
.agent-state-container .btn-container .icon-container .dot-container .material-icons {
  font-size: 4em;
}
.agent-state-container .btn-container .icon-container .dot-container .svg {
  width: 5em;
}
.agent-state-container .btn-container .select-container {
  justify-content: center;
  padding-bottom: 0.2em;
  user-select: none;
  display: flex;
  flex: 0.5;
  text-align: center;
}
.agent-state-container .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: red;
}
.agent-state-container ::ng-deep .mat-form-field-underline {
  display: none;
}
.agent-state-container ::ng-deep .mat-select-value {
  color: unset;
  text-transform: uppercase;
  padding-left: 1em;
  width: unset;
  max-width: unset;
}
.agent-state-container ::ng-deep .mat-select-value-text {
  background: transparent !important;
  font-size: 1.2em;
}
.agent-state-container ::ng-deep .mat-option-text {
  background: transparent !important;
  color: black !important;
  font-size: 1.2em;
}
.agent-state-container ::ng-deep .mat-select-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
}
.agent-state-container ::ng-deep .mat-form-field-wrapper {
  padding: 0 !important;
}
.agent-state-container ::ng-deep .mat-form-field-infix {
  padding: 0 !important;
  border-top: none;
}
.agent-state-container ::ng-deep .mat-select-arrow-wrapper {
  visibility: hidden;
}
.agent-state-container ::ng-deep .cdk-overlay-pane {
  left: 82%;
  transform: translateX(0px);
}
.disable-btn {
  pointer-events: none;
  opacity: 0.4;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .sticky-btn-container {
    max-height: 16em !important;
  }
  .agent-state-container {
    max-height: 16em !important;
  }
  .dot-container .material-icons {
    font-size: 8em !important;
  }
}
::ng-deep .mat-mdc-select-arrow svg {
  display: none;
}
/*# sourceMappingURL=sticky-button-list.component-GA2XVVMN.css.map */
