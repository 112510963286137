<div
    class="login-page-container"
    [style.background-image]="'url(' + utilityService.bgImage + ')'"
>
    <div class="login-card-container">
        <mat-card>
            <div class="alert-container" *ngIf="authService.showAlert">
                <app-alert-box [alertText]="authService.alertText">
                </app-alert-box>
            </div>

            <div class="image-logo-container">
                <div class="image-container">
                    <img
                        loading="lazy"
                        [src]="'assets/branding/images/login_logo.svg'"
                    />
                </div>
            </div>
            <h6 class="text-center version-text">
                {{ 'PAXASSIST_AGENT_V' | translate }}{{ version }} ({{
                    buildNumber
                }})
            </h6>

            <form [formGroup]="loginForm">
                <div class="form-group">
                    <span class="form-label">
                        {{ 'YOUR_USERNAME' | translate }}</span
                    >
                    <input
                        class="form-control"
                        type="email"
                        required
                        [formControl]="loginForm.controls['email']"
                    />
                    <div
                        *ngIf="
                            loginForm.controls.email.touched &&
                            loginForm.controls.email.errors?.required
                        "
                        class="alert-text"
                    >
                        {{ 'USERNAME_IS_REQUIRED_TO_LOGIN' | translate }}
                    </div>
                    <div
                        *ngIf="
                            loginForm.controls.email.touched &&
                            loginForm.controls.email.errors?.email
                        "
                        class="alert-text"
                    >
                        {{ 'USERNAME_VALIDATION_FORMAT' | translate }}
                    </div>
                </div>

                <div class="form-group">
                    <span class="form-label">
                        {{ 'PASSWORD' | translate }}
                    </span>
                    <input
                        class="form-control"
                        [formControl]="loginForm.controls['password']"
                        type="password"
                        required
                        autocomplete="true"
                    />
                    <div
                        *ngIf="
                            loginForm.controls.password.touched &&
                            loginForm.controls.password.errors?.required
                        "
                        class="alert-text"
                    >
                        {{ 'PASSWORD_IS_REQUIRED_TO_LOGIN' | translate }}
                    </div>
                </div>

                <div class="btn-container">
                    <button
                        type="submit"
                        class="submit-btn"
                        [disabled]="loginForm.invalid"
                        (click)="login()"
                        [ngStyle]="{
                            width: authService.isLoading ? '130px' : '90px'
                        }"
                    >
                        <span *ngIf="!authService.isLoading">
                            {{ 'LOGIN' | translate }}</span
                        >

                        <span *ngIf="authService.isLoading">
                            {{ 'LOGGING_IN' | translate }}</span
                        >

                        <div
                            *ngIf="authService.isLoading"
                            style="margin-left: 0.8em"
                        >
                            <div class="login-loader"></div>
                        </div>
                    </button>
                </div>
            </form>
            <div class="copy-right-container">
                <app-copy-right></app-copy-right>
            </div>
        </mat-card>
    </div>
</div>
