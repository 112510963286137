import { selectUI, selectPOI, selectDataBus } from '../../store/selectors';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MapService } from '@app/services/map.service';
import { Store } from '@ngrx/store';
import { GoogleMapComponent } from '../../components/google-map/google-map.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        GoogleMapComponent,
    ],
})
export class HomeComponent implements OnInit {
    @ViewChild('map', { static: false }) mapEl!: ElementRef<HTMLElement>;
    selectedCategory: string;
    showTrafficMap: boolean;
    mapFullView: boolean;
    dataBusSubscription;
    hideMap: boolean;
    poiSubscription;
    itemsArray: any;
    uiSubscription;
    hotelItem: any;
    long: number;
    lat: number;
    categories;

    constructor(private mapService: MapService, private store: Store<any>) {}

    ngOnInit() {
        this.poiSubscriber();
        this.uiSubscriber();
    }

    ngAfterViewInit() {
        this.mapService.initializeMap(this.mapEl); // [MAP_MIGRATION]
    }

    poiSubscriber() {
        const poiState$ = this.store.select(selectPOI);

        this.poiSubscription = poiState$.subscribe((poiItem: any) => {
            if (poiItem.poiContent.details) {
                this.hotelItem = poiItem.poiContent;

                if (
                    poiItem.selectedCategory == 'Hotels' ||
                    poiItem.selectedCategory == 'tourism'
                ) {
                    this.lat = parseFloat(
                        poiItem.poiContent.coordinates.split(',')[0]
                    );
                    this.long = parseFloat(
                        poiItem.poiContent.coordinates.split(',')[1]
                    );
                }
            }

            if (poiItem.selectedCategory && poiItem.selectedCategory != '') {
                this.mapFullView = false;
                this.selectedCategory = poiItem.selectedCategory;
                this.dataBusSubscriber();
                this.showTrafficMap =
                    poiItem.selectedCategory == 'traffic' ||
                    poiItem.selectedCategory == 'Hotels' ||
                    poiItem.selectedCategory == 'tourism'
                        ? true
                        : false;
            } else {
                this.showTrafficMap = false;
                if (!this.mapFullView) this.hideMap = true;
            }
        });
    }

    uiSubscriber() {
        const uiState$ = this.store.select(selectUI);

        this.uiSubscription = uiState$.subscribe((item) => {
            if (item.windows.sideMenuInfo)
                this.hideMap =
                    this.selectedCategory != 'INFO & TIPS' &&
                    this.selectedCategory != 'tourism' &&
                    this.selectedCategory != 'traffic' &&
                    this.selectedCategory != 'LAX TV' &&
                    this.selectedCategory != 'selfie' &&
                    this.selectedCategory != 'Hotels' &&
                    this.selectedCategory != 'ADA'
                        ? false
                        : true;
            else {
                if (!this.mapFullView) this.hideMap = true;
            }
        });
    }

    dataBusSubscriber() {
        const dataBusState$ = this.store.select(selectDataBus);

        return new Promise((resolve) => {
            this.dataBusSubscription = dataBusState$.subscribe(
                (dataBusItem) => {
                    this.categories = dataBusItem.categories;
                    if (this.selectedCategory == 'Hotels')
                        this.itemsArray = this.formatItemsArray(
                            dataBusItem.pois.Hotels
                        );
                    else if (this.selectedCategory == 'tourism')
                        this.itemsArray = this.formatItemsArray(
                            dataBusItem.pois.Attractions
                        );
                    else this.itemsArray = [];

                    if (Object.keys(dataBusItem.kioskNames).length > 0)
                        resolve([]);
                    else {
                        if (Array.isArray(dataBusItem.categories))
                            resolve(dataBusItem.categories);
                        else resolve([]);
                    }
                }
            );
        });
    }

    ngOnDestroy() {
        if (this.dataBusSubscription) this.dataBusSubscription.unsubscribe();
        this.poiSubscription.unsubscribe();
        this.uiSubscription.unsubscribe();
    }

    formatItemsArray(data) {
        let coordinatesArray = [];
        if (data.length > 0) {
            data.forEach((element) => {
                coordinatesArray.push({
                    element: element,
                    lat: parseFloat(element.coordinates.split(',')[0]),
                    long: parseFloat(element.coordinates.split(',')[1]),
                });
            });
        }

        return coordinatesArray;
    }
}
