/* src/app/components/emergency-alert/emergency-alert.component.scss */
.emergency-message {
  background: #aa0000;
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  z-index: 50;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  flex-direction: column;
  display: flex;
}
.emergency-message .alert-message {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
}
.emergency-message .alert-message .message-title .text {
  font-weight: 900;
  font-size: 2.5em;
  color: white;
}
.emergency-message .alert-message .message-body {
  text-align: center;
  padding-top: 2em;
  width: 60%;
}
.emergency-message .alert-message .message-body .text {
  font-size: 2em;
  color: white;
}
.emergency-message .divider {
  border: 0.5px solid #b34a4a;
  margin: 0em 6em;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .message-title .text {
    font-size: 5.5em !important;
  }
  .message-body .text {
    font-size: 4em !important;
  }
  .divider {
    border: 2px solid #b34a4a !important;
  }
}
/*# sourceMappingURL=emergency-alert.component-7Z524F26.css.map */
