import { PointOfInterest } from '../../models/point.of.interest.model';
import { Component, OnInit, Input } from '@angular/core';
import { FeaturedHotelComponent } from '../featured-hotel/featured-hotel.component';
import { RatingComponent } from '../rating/rating.component';
import { NgIf, NgStyle, NgClass } from '@angular/common';
@Component({
    selector: 'app-hotel-item',
    templateUrl: './hotel-item.component.html',
    styleUrls: ['./hotel-item.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle, NgClass, RatingComponent, FeaturedHotelComponent],
})
export class HotelItemComponent implements OnInit {
    @Input('hideFeaturedHotel') hideFeaturedHotel: boolean;
    @Input('hotelItem') hotelItem: PointOfInterest;
    @Input('forGoogleMap') forGoogleMap;

    constructor() {}

    ngOnInit() {}
}
