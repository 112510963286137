<div class="item-container">
    <div class="header-container expanded-header-lax" (click)="onButtonClick()">
        <div class="icon-container">
            <i class="material-icons arrow-left-icon-lax" *ngIf="!isExpanded">
                add_circle
            </i>
            <i class="material-icons arrow-left-icon-lax" *ngIf="isExpanded">
                remove_circle
            </i>
        </div>
        <div class="title-container">
            <span class="title uppercase">
                {{ info.title[translate.currentLang] }}
            </span>
        </div>
    </div>
    <div
        class="content-container"
        [@expandCollapse]="isExpanded ? true : false"
    >
        <p
            class="description"
            [innerHTML]="info.body[translate.currentLang] | safeHtml"
        ></p>
    </div>
</div>
