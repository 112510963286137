import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { sideMenuDummyData } from '@app/dummyData/sideMenu.js';
import { WebRTCService } from '@app/services/web-rtc.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AppConfig } from 'environments/environment';
import { MapService } from '../../services/map.service';
import { QRCodeModule } from 'angularx-qrcode';
import { NgClass, NgStyle, NgIf } from '@angular/common';

@Component({
    selector: 'app-send-to-client',
    templateUrl: './send-to-client.component.html',
    styleUrls: ['./send-to-client.component.scss'],
    standalone: true,
    imports: [NgClass, NgStyle, NgIf, QRCodeModule, TranslateModule],
})
export class SendToClientComponent implements OnInit {
    @Input('staticInformationData') staticInformationData;
    @Input('selectedItemData') selectedItemData;
    @Input('selectedCategory') selectedCategory;
    @Input('gateInfo') gateInfo;
    @Input('isStaticContent') isStaticContent;
    @Input('categoryInfo') categoryInfo;
    @Input('selectedItem') selectedItem;
    @Input('poiContent') poiContent;
    @Input('isAgent') isAgent;

    selectedStaticParentCategory;
    isLoading: boolean = false;
    isQRCode: boolean = false;
    isLaxTV: boolean = false;
    isSent: boolean = false;
    selectedSubCategory;
    wasInside = false;
    qrWidth: any;
    qrData;

    categoryInformation;

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private translate: TranslateService,
        public webRTCService: WebRTCService,
        private mapService: MapService
    ) {}

    ngOnInit(): void {
        this.isStaticContent = false;
        let encodedString: string = '';

        const kioskID = this.mapService.kioskInformation.mappedinID
            ? this.mapService.kioskInformation.mappedinID
            : this.mapService.kioskInformation.poiID;

        if (!this.isAgent) {
            switch (this.selectedCategory) {
                case 'INFO_TIPS':
                case 'ADA':
                    this.categoryInformation =
                        sideMenuDummyData.categories.find(
                            (node) => node.query == this.selectedCategory
                        );

                    this.selectedStaticParentCategory =
                        this.staticInformationData.find(
                            (node) =>
                                node.parentCategory[
                                    this.translate.currentLang
                                ] ==
                                this.translate.instant(
                                    this.categoryInformation.label
                                )
                        );

                    this.selectedSubCategory =
                        this.selectedStaticParentCategory.subCategories.find(
                            (node) => node.id == this.selectedItem
                        );

                    this.qrData = `${AppConfig.microSiteUrl}viewQR?${this.selectedCategory}|${this.selectedSubCategory.id}`;

                    this.isStaticContent = true;
                    break;

                case 'tourism':
                case 'Hotels':
                    this.qrData = `https://www.google.com/maps/search/?api=1&query=${this.poiContent.details.en.shortLocation}&query_place_id=${this.poiContent.placeID}`;

                    break;

                case 'LAX_TV':
                    this.isLaxTV = true;
                    this.qrData = `${this.poiContent.videoURL}`;
                    break;

                case 'Flights':
                    this.qrData = `https://www.flylax.com/map#/directions?to=${
                        this.gateInfo?.mapElementId
                            ? this.gateInfo.mapElementId
                            : ''
                    }&from=${kioskID}&textDirectionsVisible=true&instruction=0`;

                    break;

                default:
                    this.qrData = `https://www.flylax.com/map#/directions?to=${this.poiContent.mapElementId}&from=${kioskID}&textDirectionsVisible=true&instruction=0`;
                    break;
            }
        } else {
            if (
                this.selectedCategory == 'INFO_TIPS' ||
                this.selectedCategory == 'ADA'
            ) {
                this.selectedStaticParentCategory =
                    this.staticInformationData.find(
                        (node) =>
                            node.parentCategory[this.translate.currentLang] ==
                            this.translate.instant(this.categoryInfo.label)
                    );

                this.selectedSubCategory =
                    this.selectedStaticParentCategory.subCategories.find(
                        (node) => node.id == this.selectedItem
                    );
                this.isStaticContent = true;
            } else {
                if (this.selectedCategory == 'LAX_TV') this.isLaxTV = true;
            }
        }

        if (window.innerWidth == 3840) {
            this.qrWidth = 525;
        } else {
            if (this.isStaticContent) this.qrWidth = 300;
            else this.qrWidth = 275;
        }
    }

    covertHTMLtoText(html) {
        var temp = document.createElement('div');
        temp.innerHTML = html;
        return temp.textContent || temp.innerText || '';
    }

    sendPoiIDToClient() {
        if (this.isAgent) {
            if (this.webRTCService.connection) {
                this.isLoading = true;
                setTimeout(() => {
                    this.isSent = true;
                    this.isLoading = false;
                    setTimeout(() => {
                        this.isSent = false;
                    }, 2000);
                }, 1000);

                this.webRTCService.connection.send({
                    category: this.selectedCategory,
                    categoryInfo: this.categoryInfo,
                    poiContent: this.poiContent,
                    selectedItem: this.selectedItemData,
                });
            }

            this.googleAnalyticService.onSendToClient(
                this.categoryInfo.storeName,
                this.selectedCategory == 'ADA' ||
                    this.selectedCategory == 'INFO_TIPS'
                    ? this.selectedSubCategory.category.en
                    : this.poiContent
            );
        } else {
            this.googleAnalyticService.onSendToMobileClick(
                this.categoryInfo.storeName,
                this.selectedCategory == 'ADA' ||
                    this.selectedCategory == 'INFO_TIPS'
                    ? this.selectedSubCategory.category.en
                    : this.poiContent
            );

            this.isQRCode = true;
        }
    }

    closeQRCode() {
        this.isQRCode = false;
    }

    @HostListener('click')
    clickInside() {
        this.wasInside = true;
    }

    @HostListener('document:click')
    clickOut() {
        if (!this.wasInside) {
            this.isQRCode = false;
        }
        this.wasInside = false;
    }
}
