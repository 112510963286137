/* src/app/components/flight-item/flight-item.component.scss */
.flight-item {
  padding-top: 1em;
  color: black;
  height: 100%;
  width: 100%;
}
.flight-item .flight-item-container {
  display: flex;
  width: 100%;
  height: 100%;
}
.flight-item .flight-item-container .arrow-icon-container {
  border-bottom: 1px solid rgba(199, 199, 199, 0.5);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0.2;
}
.flight-item .flight-item-container .arrow-icon-container .arrow-left-icon {
  font-size: 2.5em;
  color: black;
}
.flight-item .flight-item-container .arrow-icon-container .arrow-left-icon-lax {
  font-size: 2.5em;
  color: white;
}
.flight-item .flight-item-container .flight-icon {
  justify-content: center;
  flex-direction: column;
  padding-left: 0.4em;
  align-items: center;
  position: relative;
  display: flex;
}
.flight-item .flight-item-container .flight-icon .icon-container {
  padding-right: 0.4em;
  position: relative;
  width: 2.2em;
  flex: 1;
}
.flight-item .flight-item-container .flight-icon .icon-container .airLineCaption {
  text-transform: uppercase;
  justify-content: center;
  color: #bebcbc;
  position: absolute;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  font-weight: 500;
  display: flex;
  left: 0%;
  height: 2.1em;
  top: 7%;
  width: 2.2em;
}
.flight-item .flight-item-container .flight-icon .icon-container .icon {
  position: absolute;
  border-radius: 50%;
  margin-top: 10%;
  height: 2.2em;
  width: 2.2em;
}
.flight-item .flight-item-container .flight-icon .icon-container .box-shadow-style {
  box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.5);
}
.flight-item .flight-item-container .flight-icon-lax {
  justify-content: center;
  flex-direction: column;
  padding-left: 0.4em;
  align-items: center;
  position: relative;
  display: flex;
}
.flight-item .flight-item-container .flight-icon-lax .icon-container {
  padding-right: 0.4em;
  position: relative;
  width: 2.2em;
  flex: 1;
}
.flight-item .flight-item-container .flight-icon-lax .icon-container .airLineCaption {
  text-transform: uppercase;
  justify-content: center;
  color: #bebcbc;
  position: absolute;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  font-weight: 500;
  display: flex;
  left: 0%;
  height: 2.1em;
  top: 7%;
  width: 2.2em;
}
.flight-item .flight-item-container .flight-icon-lax .icon-container .icon {
  position: absolute;
  border-radius: 50%;
  margin-top: 10%;
  height: 2.2em;
  width: 2.2em;
}
.flight-item .flight-item-container .flight-icon-lax .icon-container .box-shadow-style {
  box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.5);
}
.flight-item .flight-item-container .flight-icon-lax-selected {
  padding-left: 0em !important;
}
.flight-item .flight-item-container .flight-information {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.flight-item .flight-item-container .flight-information .airline-info {
  display: flex;
  width: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information .airline-info .airline-details {
  justify-content: center;
  flex-direction: column;
  padding-left: 0.4em;
  display: flex;
  height: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information .airline-info .airline-details .title span {
  font-weight: 500;
  color: black;
  font-size: 0.9em;
}
.flight-item .flight-item-container .flight-information .airline-info .airline-details .sub-title {
  line-height: 0.9;
}
.flight-item .flight-item-container .flight-information .airline-info .airline-details .sub-title span {
  font-weight: 400;
  color: black;
  font-size: 0.8em;
}
.flight-item .flight-item-container .flight-information .airline-info .gate-details {
  align-items: center;
  display: flex;
  padding-right: 0.6em;
}
.flight-item .flight-item-container .flight-information .airline-info .gate-details .letter-container {
  background-color: #e9cd2e;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 50px;
  width: 80px;
}
.flight-item .flight-item-container .flight-information .airline-info .gate-details .letter-container .gate-number {
  background-color: #e9cd2e;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  line-height: 1;
  display: flex;
  width: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information .airline-info .gate-details .letter-container .gate-number span {
  font-weight: 500;
  font-size: 1.1em;
  color: white;
}
.flight-item .flight-item-container .flight-information .airline-info .gate-details .letter-container .walk-time-container {
  background-color: #e9cd2e;
  justify-content: center;
  border-radius: 4px;
  line-height: 1;
  display: flex;
  width: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information .airline-info .gate-details .letter-container .walk-time-container .walk-time-icon .material-icons {
  padding-top: 0.15rem;
  font-size: 0.7em;
  color: white;
}
.flight-item .flight-item-container .flight-information .airline-info .gate-details .letter-container .walk-time-container .walk-time-value span {
  font-size: 0.7em;
  color: white;
}
.flight-item .flight-item-container .flight-information .schedule-container {
  justify-content: space-evenly;
  align-items: flex-end;
  padding-top: 2%;
  line-height: 1;
  display: flex;
  width: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information .schedule-container .flight-container {
  flex-direction: column;
  padding-bottom: 0.6em;
  padding-left: 0.4em;
  display: flex;
  width: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information .schedule-container .flight-container .text {
  font-size: 0.6em !important;
  padding-top: 0.3rem;
  font-weight: 400;
  line-height: 1.2;
}
.flight-item .flight-item-container .flight-information .schedule-container .flight-container .high-light {
  font-weight: 500;
  line-height: 1.2;
}
.flight-item .flight-item-container .flight-information .schedule-container .flight-container span {
  font-size: 0.8em;
  color: black;
}
.flight-item .flight-item-container .flight-information .schedule-container .departure-time-container {
  padding-bottom: 0.6em;
  flex-direction: column;
  padding-left: 0.4em;
  display: flex;
  width: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information .schedule-container .departure-time-container .text {
  text-transform: uppercase !important;
  font-size: 0.6em !important;
  padding-top: 0.3rem;
  font-weight: 400;
  line-height: 1.2;
}
.flight-item .flight-item-container .flight-information .schedule-container .departure-time-container .high-light {
  font-weight: 500;
  line-height: 1.2;
}
.flight-item .flight-item-container .flight-information .schedule-container .departure-time-container span {
  font-size: 0.8em;
  color: black;
  border-radius: 2px;
  width: fit-content;
}
.flight-item .flight-item-container .flight-information .schedule-container .status-container {
  padding-bottom: 0.6em;
  flex-direction: column;
  padding-right: 0.6em;
  display: flex;
  width: 75px;
}
.flight-item .flight-item-container .flight-information .schedule-container .status-container .text {
  font-size: 0.6em !important;
  padding-top: 0.3rem;
  font-weight: 400;
  line-height: 1.2;
}
.flight-item .flight-item-container .flight-information .schedule-container .status-container .high-light {
  text-transform: uppercase;
  justify-content: center;
  border-radius: 2px;
  line-height: 1.5;
  font-size: 0.75em;
  font-weight: 500;
  display: flex;
  width: 100%;
}
.flight-item .flight-item-container .flight-information .schedule-container .status-container .delayedStatus {
  background-color: #f7a816;
  color: white;
}
.flight-item .flight-item-container .flight-information .schedule-container .status-container .canceledStatus {
  background-color: #ef0d0d;
  color: white;
}
.flight-item .flight-item-container .flight-information .schedule-container .status-container .arrivedStatus {
  background-color: #00b900;
  color: white;
}
.flight-item .flight-item-container .flight-information .schedule-container .status-container span {
  font-size: 0.6em;
  color: black;
}
.flight-item .flight-item-container .flight-information-lax {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.flight-item .flight-item-container .flight-information-lax .airline-info {
  display: flex;
  width: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information-lax .airline-info .airline-details {
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 0.4em;
  display: flex;
  height: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information-lax .airline-info .airline-details .title span {
  font-weight: 500;
  color: black;
  font-size: 0.9em;
}
.flight-item .flight-item-container .flight-information-lax .airline-info .airline-details .sub-title {
  line-height: 0.9;
}
.flight-item .flight-item-container .flight-information-lax .airline-info .airline-details .sub-title span {
  font-weight: 400;
  color: black;
  font-size: 0.8em;
}
.flight-item .flight-item-container .flight-information-lax .airline-info .gate-details {
  align-items: center;
  display: flex;
  padding-right: 0.6em;
}
.flight-item .flight-item-container .flight-information-lax .airline-info .gate-details .letter-container {
  background-color: #e9cd2e;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 50px;
  width: 80px;
}
.flight-item .flight-item-container .flight-information-lax .airline-info .gate-details .letter-container .gate-number {
  background-color: #e9cd2e;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  line-height: 1;
  display: flex;
  width: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information-lax .airline-info .gate-details .letter-container .gate-number span {
  font-weight: 500;
  font-size: 1.1em;
  color: white;
}
.flight-item .flight-item-container .flight-information-lax .airline-info .gate-details .letter-container .walk-time-container {
  background-color: #e9cd2e;
  justify-content: center;
  border-radius: 4px;
  line-height: 1;
  display: flex;
  width: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information-lax .airline-info .gate-details .letter-container .walk-time-container .walk-time-icon .material-icons {
  font-size: 1em;
  color: white;
}
.flight-item .flight-item-container .flight-information-lax .airline-info .gate-details .letter-container .walk-time-container .walk-time-value span {
  font-size: 0.9em;
  color: white;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container {
  justify-content: space-evenly;
  align-items: flex-end;
  padding-top: 2%;
  line-height: 1;
  display: flex;
  width: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .flight-container {
  flex-direction: column;
  padding-bottom: 0.6em;
  padding-left: 0.4em;
  display: flex;
  width: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .flight-container .text {
  font-size: 0.6em;
  padding-top: 0.3rem;
  font-weight: 400;
  line-height: 1.2;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .flight-container .high-light {
  font-weight: 500;
  line-height: 1.2;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .flight-container span {
  font-size: 0.8em;
  color: black;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .departure-time-container {
  padding-bottom: 0.6em;
  flex-direction: column;
  padding-left: 0.4em;
  display: flex;
  width: 100%;
  flex: 1;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .departure-time-container .text {
  text-transform: uppercase;
  font-size: 0.6em;
  padding-top: 0.3rem;
  font-weight: 400;
  line-height: 1.2;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .departure-time-container .high-light {
  font-weight: 500;
  line-height: 1.2;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .departure-time-container span {
  font-size: 0.8em;
  color: black;
  border-radius: 2px;
  width: fit-content;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .status-container {
  padding-bottom: 0.6em;
  flex-direction: column;
  padding-right: 0.6em;
  display: flex;
  width: 75px;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .status-container .text {
  font-size: 0.6em;
  padding-top: 0.3rem;
  font-weight: 400;
  line-height: 1.2;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .status-container .high-light {
  text-transform: uppercase;
  justify-content: center;
  border-radius: 2px;
  line-height: 1.5;
  font-size: 0.75em;
  font-weight: 500;
  display: flex;
  width: 100%;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .status-container .delayedStatus {
  background-color: #f7a816;
  color: white;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .status-container .canceledStatus {
  background-color: #ef0d0d;
  color: white;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .status-container .arrivedStatus {
  background-color: #00b900;
  color: white;
}
.flight-item .flight-item-container .flight-information-lax .schedule-container .status-container span {
  font-size: 0.6em;
  color: black;
}
.flight-item .flight-item-container .active-flight-information .airline-info .airline-details .title span {
  color: white !important;
}
.flight-item .flight-item-container .active-flight-information .airline-info .airline-details .sub-title span {
  color: white !important;
}
.flight-item .flight-item-container .active-flight-information .schedule-container .flight-container span {
  color: white !important;
}
.flight-item .flight-item-container .active-flight-information .schedule-container .departure-time-container span {
  color: white;
}
.flight-item .flight-item-container .active-flight-information .schedule-container .status-container span {
  color: white !important;
}
.flight-item .partner-info {
  width: 100%;
}
.flight-item .bottomBorder {
  border-bottom: 1px solid rgba(199, 199, 199, 0.5);
}
.flight-item .iconBottomBorder {
  border-bottom: 1px solid rgba(199, 199, 199, 0.5);
}
.flight-item .bordersRTL {
  border-right: 1px solid rgba(199, 199, 199, 0.5);
  border-left: 1px solid rgba(199, 199, 199, 0.5);
  border-top: 1px solid rgba(199, 199, 199, 0.5);
  border-radius: 4px 4px 0px 0px;
  background-color: rgba(199, 199, 199, 0.2);
  padding: 0.7rem;
  display: flex;
  column-gap: 0.2rem;
}
.active-flight-item-container {
  background-color: black;
}
.active-flight-item-container-height {
  height: fit-content;
}
.active-flight-item-color {
  color: white !important;
}
.active-flight-item-container-lax {
  background-color: black;
  color: white;
}
.no-codeShareFlights-bg-color {
  background-color: transparent;
}
.codeShareFlights-bg-color-no-back-arrow {
  background-color: black;
}
.codeShareFlights-bg-color-with-back-arrow {
  background-color: #f6f7fb;
}
.codeShareFlights-bg-color-with-no-arrow-lax {
  background-color: rgb(0, 0, 0) !important;
}
.codeShareFlights-bg-color-with-back-arrow-lax {
  background-color: black !important;
}
.flight-icon .icon {
  height: 3.5em;
  width: 3.5em;
}
.flight-icon-lax {
  padding-left: 1em !important;
}
.flight-icon-lax .icon-container {
  padding-right: 1em !important;
  width: 2.2em !important;
}
.flight-icon-lax .icon-container .airLineCaption {
  font-weight: 500 !important;
  width: 2.2em !important;
}
.flight-icon-lax .icon-container .icon {
  margin-top: 10% !important;
  height: 2.2em !important;
  width: 2.2em !important;
}
.flight-icon-lax .icon-container .box-shadow-style {
  box-shadow: 0px -1px 4px 1px rgba(0, 0, 0, 0.5) !important;
}
.schedule-container .flight-container .text {
  font-size: 0.7em !important;
}
.schedule-container .flight-container span {
  font-size: 0.9em;
}
.schedule-container .departure-time-container .text {
  font-size: 0.7em !important;
}
.schedule-container .departure-time-container span {
  font-size: 0.9em;
}
.schedule-container .status-container .text {
  font-size: 0.7em !important;
}
.schedule-container .status-container span {
  font-size: 0.9em;
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .flight-item {
    padding-top: 1.2em !important;
  }
  .flight-item .flight-item-container .arrow-icon-container .arrow-left-icon {
    font-size: 5em !important;
  }
  .flight-item .flight-item-container .arrow-icon-container .arrow-left-icon-lax {
    font-size: 5em !important;
  }
  .flight-item .flight-item-container .flight-icon {
    padding-left: 0.8em !important;
  }
  .flight-item .flight-item-container .flight-icon .icon-container {
    padding-right: 1.2em !important;
    width: 4.4em !important;
  }
  .flight-item .flight-item-container .flight-icon .icon-container .airLineCaption {
    height: 5.4em !important;
    top: 5%;
    width: 5.4em !important;
  }
  .flight-item .flight-item-container .flight-icon .icon-container .airLineCaption .flightNumber-text {
    font-size: 2em !important;
  }
  .flight-item .flight-item-container .flight-icon .icon-container .icon {
    margin-top: 0% !important;
    height: 5.4em !important;
    width: 5.4em !important;
  }
  .flight-item .flight-item-container .flight-icon .icon-container .box-shadow-style {
    box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.5) !important;
  }
  .flight-item .flight-item-container .flight-icon-lax {
    padding-left: 2.5em !important;
  }
  .flight-item .flight-item-container .flight-icon-lax .icon-container {
    padding-right: 2.5em !important;
    width: 5.4em !important;
  }
  .flight-item .flight-item-container .flight-icon-lax .icon-container .airLineCaption {
    height: 5.4em !important;
    top: 3% !important;
    width: 5.4em !important;
  }
  .flight-item .flight-item-container .flight-icon-lax .icon-container .airLineCaption .flightNumber-text {
    font-size: 2em !important;
  }
  .flight-item .flight-item-container .flight-icon-lax .icon-container .icon {
    margin-top: 5% !important;
    height: 5.4em !important;
    width: 5.4em !important;
  }
  .flight-item .flight-item-container .flight-icon-lax .icon-container .box-shadow-style {
    box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.5) !important;
  }
  .flight-item .flight-item-container .flight-information .airline-info .airline-details {
    padding-left: 0.8em !important;
  }
  .flight-item .flight-item-container .flight-information .airline-info .airline-details .title span {
    font-size: 1.8em !important;
  }
  .flight-item .flight-item-container .flight-information .airline-info .airline-details .sub-title span {
    font-size: 1.6em !important;
  }
  .flight-item .flight-item-container .flight-information .airline-info .gate-details {
    padding-right: 1.2em;
  }
  .flight-item .flight-item-container .flight-information .airline-info .gate-details .letter-container {
    height: 80px !important;
    width: 150px !important;
  }
  .flight-item .flight-item-container .flight-information .airline-info .gate-details .letter-container .gate-number span {
    font-size: 2.2em !important;
  }
  .flight-item .flight-item-container .flight-information .airline-info .gate-details .letter-container .walk-time-container {
    align-items: baseline;
  }
  .flight-item .flight-item-container .flight-information .airline-info .gate-details .letter-container .walk-time-container .walk-time-icon .material-icons {
    padding-top: 0.3rem !important;
    font-size: 1.4em !important;
  }
  .flight-item .flight-item-container .flight-information .airline-info .gate-details .letter-container .walk-time-container .walk-time-value span {
    font-size: 1.4em !important;
  }
  .flight-item .flight-item-container .flight-information .schedule-container {
    padding-top: 4% !important;
  }
  .flight-item .flight-item-container .flight-information .schedule-container .flight-container {
    padding-bottom: 1.2em !important;
    padding-left: 0.8em !important;
  }
  .flight-item .flight-item-container .flight-information .schedule-container .flight-container .text {
    font-size: 1.2em !important;
    padding-top: 0.6rem !important;
  }
  .flight-item .flight-item-container .flight-information .schedule-container .flight-container span {
    font-size: 1.6em !important;
  }
  .flight-item .flight-item-container .flight-information .schedule-container .departure-time-container {
    padding-bottom: 1.2em !important;
    flex-direction: column;
    padding-left: 0.8em !important;
    display: flex;
    width: 100%;
    flex: 1;
  }
  .flight-item .flight-item-container .flight-information .schedule-container .departure-time-container .text {
    text-transform: uppercase !important;
    font-size: 1.2em !important;
    padding-top: 0.6rem !important;
  }
  .flight-item .flight-item-container .flight-information .schedule-container .departure-time-container span {
    font-size: 1.6em !important;
  }
  .flight-item .flight-item-container .flight-information .schedule-container .status-container {
    padding-bottom: 1.2em !important;
    flex-direction: column;
    padding-right: 1.2em !important;
    display: flex;
    width: 150px !important;
  }
  .flight-item .flight-item-container .flight-information .schedule-container .status-container .text {
    font-size: 1.2em !important;
    padding-top: 0.6rem !important;
  }
  .flight-item .flight-item-container .flight-information .schedule-container .status-container .high-light {
    font-size: 1.69em !important;
  }
  .flight-item .flight-item-container .flight-information .schedule-container .status-container span {
    font-size: 1.2em !important;
    color: black;
  }
  .flight-item .flight-item-container .flight-information-lax .airline-info .airline-details {
    padding-left: 0em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .airline-info .airline-details .title span {
    font-size: 2.5em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .airline-info .airline-details .sub-title span {
    font-size: 2em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .airline-info .gate-details {
    padding-right: 2.5em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .airline-info .gate-details .letter-container {
    height: 6em !important;
    width: 10em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .airline-info .gate-details .letter-container .gate-number span {
    font-size: 3em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .airline-info .gate-details .letter-container .walk-time-container {
    align-items: baseline;
  }
  .flight-item .flight-item-container .flight-information-lax .airline-info .gate-details .letter-container .walk-time-container .walk-time-icon .material-icons {
    padding-top: 0.3rem !important;
    font-size: 2em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .airline-info .gate-details .letter-container .walk-time-container .walk-time-value span {
    font-size: 2em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .schedule-container {
    padding-top: 4% !important;
  }
  .flight-item .flight-item-container .flight-information-lax .schedule-container .flight-container {
    padding-bottom: 1.2em !important;
    padding-left: 0em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .schedule-container .flight-container .text {
    font-size: 1.7em !important;
    padding-top: 0.6rem !important;
  }
  .flight-item .flight-item-container .flight-information-lax .schedule-container .flight-container span {
    font-size: 1.7em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .schedule-container .departure-time-container {
    padding-bottom: 1.2em !important;
    flex-direction: column;
    padding-left: 0.8em !important;
    display: flex;
    width: 100%;
    flex: 1;
  }
  .flight-item .flight-item-container .flight-information-lax .schedule-container .departure-time-container .text {
    text-transform: uppercase !important;
    font-size: 1.7em !important;
    padding-top: 0.6rem !important;
  }
  .flight-item .flight-item-container .flight-information-lax .schedule-container .departure-time-container span {
    font-size: 1.7em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .schedule-container .status-container {
    padding-bottom: 1.2em !important;
    flex-direction: column;
    padding-right: 2.2em !important;
    display: flex;
    width: 10em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .schedule-container .status-container .text {
    font-size: 1.7em !important;
    padding-top: 0.6rem !important;
  }
  .flight-item .flight-item-container .flight-information-lax .schedule-container .status-container .high-light {
    font-size: 1.7em !important;
  }
  .flight-item .flight-item-container .flight-information-lax .schedule-container .status-container span {
    font-size: 1.7em !important;
    color: black;
  }
  .flight-item .bottomBorder {
    border-bottom: 1px solid rgba(199, 199, 199, 0.5);
  }
  .flight-item .iconBottomBorder {
    border-bottom: 1px solid rgba(199, 199, 199, 0.5);
  }
  .flight-item .bordersRTL {
    border-right: 1px solid rgba(199, 199, 199, 0.5);
    border-left: 1px solid rgba(199, 199, 199, 0.5);
    border-top: 1px solid rgba(199, 199, 199, 0.5);
    border-radius: 4px 4px 0px 0px;
    font-size: 2rem;
    padding: 1rem;
  }
}
/*# sourceMappingURL=flight-item.component-DJZB5OVS.css.map */
