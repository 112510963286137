<div class="listing-container" *ngIf="selectedCategory != 'Search'">
    <div
        class="categories-listing"
        *ngIf="categoryInfo && categoryInfo.subMenu.length > 0"
    >
        <div class="category-listing-title">
            <span class="category-title">{{
                'CATEGORIES' | translate | uppercase
            }}</span>
        </div>
        <div class="category-listing">
            <div
                *ngFor="let item of categoryInfo.subMenu; let isEnd = last"
                [ngClass]="{
                    'poi-container-display-none': item.walkTime == -1,
                    'poi-container-display-block': item.walkTime != -1
                }"
                (click)="onSubMenuClick(item)"
                [ngStyle]="{
                    'margin-bottom':
                        isEnd == true && item.walkTime != -1 ? '1em' : '0em'
                }"
            >
                <app-poi
                    *ngIf="item.walkTime != -1"
                    [isSubMenu]="true"
                    [poiData]="{
                        name: translate.instant(item.label),
                        icon: item.icon
                    }"
                ></app-poi>
            </div>
        </div>
        <div class="line"></div>
        <div class="category-listing-title">
            <span class="category-title">{{ 'ALL' | translate }}</span>
        </div>
    </div>
    <!-- todo make it a component -->

    <!-- Gates -->
    <div
        class="sub-menu-listing-container"
        *ngIf="selectedCategory === 'Gates'"
    >
        <div
            [ngClass]="{
                'poi-container-display-none': item.walkTime == -1,
                'poi-container-display-block': item.walkTime != -1
            }"
            *ngFor="let item of poiListingData; let isEnd = last"
            [ngStyle]="{
                'margin-bottom':
                    isEnd == true && item.walkTime != -1 ? '1em' : '0em'
            }"
            (click)="toggleSideMenuInfo(item)"
        >
            <app-poi
                *ngIf="item.walkTime != -1"
                [id]="item.id"
                [poiData]="item"
            ></app-poi>
        </div>
    </div>

    <!-- Hotel -->
    <div
        class="sub-menu-listing-container"
        *ngIf="selectedCategory === 'Hotels'"
    >
        <!-- <app-recent-selected
      *ngIf="recentListingData && recentListingData.length > 0"
      [usingHotelComponent]="true"
      [recentListingData]="recentListingData"
      (toggleSideMenuInfo)="toggleSideMenuInfo($event)"
    ></app-recent-selected> -->
        <div
            class="poi-container-display-block"
            *ngFor="
                let hotelItem of poiListingData;
                let isEnd = last;
                let isFirst = first
            "
            [ngStyle]="{
                'margin-bottom':
                    isEnd == true && hotelItem.isFeatured == false
                        ? '1em'
                        : '0em',
                'margin-top':
                    isFirst == true && hotelItem.isFeatured == true
                        ? '0em'
                        : '1em'
            }"
            (click)="toggleSideMenuInfo(hotelItem)"
        >
            <app-hotel-item
                [hotelItem]="hotelItem"
                [hideFeaturedHotel]="false"
            ></app-hotel-item>
        </div>
    </div>

    <!-- tourism -->
    <div
        class="sub-menu-listing-container"
        *ngIf="selectedCategory === 'tourism'"
    >
        <!-- <app-recent-selected
      *ngIf="recentListingData && recentListingData.length > 0"
      [usingHotelComponent]="true"
      [recentListingData]="recentListingData"
      (toggleSideMenuInfo)="toggleSideMenuInfo($event)"
    >
    </app-recent-selected> -->

        <div
            class="poi-container-display-block"
            *ngFor="
                let tourism of poiListingData;
                let isEnd = last;
                let isFirst = first
            "
            [ngStyle]="{
                'margin-bottom':
                    isEnd == true && tourism.isFeatured == false
                        ? '1em'
                        : '0em',
                'margin-top':
                    isFirst == true && tourism.isFeatured == true
                        ? '0em'
                        : '1em'
            }"
            (click)="toggleSideMenuInfo(tourism)"
        >
            <app-hotel-item
                [hotelItem]="tourism"
                [hideFeaturedHotel]="false"
            ></app-hotel-item>
        </div>
    </div>

    <!-- todo make it a component -->
    <!-- Flights -->
    <div
        class="sub-menu-listing-container"
        *ngIf="selectedCategory === 'Flights'"
    >
        <div
            class="flight-filter-container"
            [ngClass]="{
                'flight-filter-container-height': !isFlightSearch,
                'flight-filter-container-thin': isFlightSearch
            }"
        >
            <div class="main-filter">
                <div
                    class="departure-container"
                    [ngClass]="{ 'main-tab-active': isDepartureActive }"
                    (click)="selectMainFilter(true)"
                >
                    <i class="material-icons"> flight_takeoff </i>
                    <span>{{ 'DEPARTURES' | translate | uppercase }}</span>
                </div>

                <div
                    class="arrival-container"
                    [ngClass]="{ 'main-tab-active': !isDepartureActive }"
                    (click)="selectMainFilter(false)"
                >
                    <i class="material-icons"> flight_land </i>
                    <span>{{ 'ARRIVALS' | translate | uppercase }}</span>
                </div>
            </div>

            <!--Line-->
            <div class="line-container">
                <hr class="hrStyle" />
            </div>

            <div
                class="sub-filter"
                (click)="onClickSearch()"
                [ngStyle]="{ display: isFlightSearch ? 'none' : 'flex' }"
            >
                <div class="search-content">
                    <div class="search-button-container">
                        <button class="search-button">
                            {{ 'SEARCH' | translate }}
                        </button>
                    </div>

                    <div class="search-icon-container">
                        <i class="material-icons search-icon"> search </i>
                    </div>
                </div>
            </div>
        </div>

        <!-- maxBufferPx="1200" -->
        <cdk-virtual-scroll-viewport
            #flightSection
            *ngIf="!isFlightSearch"
            style="height: 100%"
            itemSize="100"
            [minBufferPx]="virtualScrollBuffer"
            [maxBufferPx]="virtualScrollBuffer"
        >
            <app-flight-item
                *cdkVirtualFor="
                    let item of flightItemData | sortFlights : sortValue
                "
                [isDepartureActive]="isDepartureActive"
                [flightSelected]="flightSelected"
                [flightItem]="item"
                style="min-height: 5em"
                (click)="selectFlight(item); toggleSideMenuInfo(item)"
            >
                <!-- toggleSideMenuInfo(item) -->
            </app-flight-item>
        </cdk-virtual-scroll-viewport>

        <cdk-virtual-scroll-viewport
            #flightSectionFilter
            *ngIf="isFlightSearch && !searchError"
            style="height: 100%"
            itemSize="100"
            [minBufferPx]="virtualScrollBuffer"
            [maxBufferPx]="virtualScrollBuffer"
        >
            <app-flight-item
                *cdkVirtualFor="let item of flights | sortFlights : sortValue"
                [isDepartureActive]="isDepartureActive"
                [flightSelected]="flightSelected"
                [flightItem]="item"
                style="min-height: 5em"
                (click)="selectFlight(item); toggleSideMenuInfo(item)"
            >
            </app-flight-item>
        </cdk-virtual-scroll-viewport>

        <div class="search-error-container" *ngIf="searchError">
            <div class="search-icon-container">
                <i class="material-icons search-icon"> search </i>
            </div>
            <div class="search-value">
                {{ 'SEARCH_NO_RESULT' | translate }}
            </div>
        </div>
    </div>

    <!-- Check-In -->
    <div
        class="sub-menu-listing-container"
        *ngIf="selectedCategory === 'Check-In'"
    >
        <!-- <app-recent-selected
      *ngIf="recentListingData && recentListingData.length > 0"
      [usingHotelComponent]="false"
      [recentListingData]="recentListingData"
      (toggleSideMenuInfo)="toggleSideMenuInfo($event)"
    >
    </app-recent-selected> -->
        <div
            [ngClass]="{
                'poi-container-display-none': item.walkTime == -1,
                'poi-container-display-block': item.walkTime != -1
            }"
            *ngFor="let item of poiListingData; let isEnd = last"
            [ngStyle]="{
                'margin-bottom':
                    isEnd == true && item.walkTime != -1 ? '1em' : '0em'
            }"
            (click)="toggleSideMenuInfo(item)"
        >
            <app-poi [id]="item.id" [poiData]="item"></app-poi>
        </div>
    </div>

    <!-- Restrooms -->
    <div
        class="sub-menu-listing-container"
        *ngIf="selectedCategory === 'Restrooms'"
    >
        <div
            [ngClass]="{
                'poi-container-display-none': item.walkTime == -1,
                'poi-container-display-block': item.walkTime != -1
            }"
            *ngFor="let item of poiListingData; let isEnd = last"
            [ngStyle]="{
                'margin-bottom':
                    isEnd == true && item.walkTime != -1 ? '1em' : '0em'
            }"
            (click)="toggleSideMenuInfo(item)"
        >
            <app-poi [id]="item.id" [poiData]="item"></app-poi>
        </div>
    </div>

    <!-- Relax -->
    <div
        class="sub-menu-listing-container"
        *ngIf="selectedCategory === 'Relax'"
    >
        <!-- <app-recent-selected
      *ngIf="recentListingData && recentListingData.length > 0"
      [usingHotelComponent]="false"
      [recentListingData]="recentListingData"
      (toggleSideMenuInfo)="toggleSideMenuInfo($event)"
    >
    </app-recent-selected> -->

        <div
            [ngClass]="{
                'poi-container-display-none': item.walkTime == -1,
                'poi-container-display-block': item.walkTime != -1
            }"
            *ngFor="let item of poiListingData; let isEnd = last"
            [ngStyle]="{
                'margin-bottom':
                    isEnd == true && item.walkTime != -1 ? '1em' : '0em'
            }"
            (click)="toggleSideMenuInfo(item)"
        >
            <app-poi [id]="item.id" [poiData]="item"></app-poi>
        </div>
    </div>

    <!-- Shopping -->
    <div
        class="sub-menu-listing-container"
        *ngIf="selectedCategory === 'Shopping'"
    >
        <!-- <app-recent-selected
      *ngIf="recentListingData && recentListingData.length > 0"
      [usingHotelComponent]="false"
      [recentListingData]="recentListingData"
      (toggleSideMenuInfo)="toggleSideMenuInfo($event)"
    >
    </app-recent-selected> -->

        <div
            [ngClass]="{
                'poi-container-display-none': item.walkTime == -1,
                'poi-container-display-block': item.walkTime != -1
            }"
            *ngFor="let item of poiListingData; let isEnd = last"
            [ngStyle]="{
                'margin-bottom':
                    isEnd == true && item.walkTime != -1 ? '1em' : '0em'
            }"
            (click)="toggleSideMenuInfo(item)"
        >
            <app-poi [id]="item.id" [poiData]="item"></app-poi>
        </div>
    </div>

    <!-- Transport -->
    <div
        class="sub-menu-listing-container"
        *ngIf="selectedCategory === 'Transport'"
    >
        <!-- <app-recent-selected
      *ngIf="recentListingData && recentListingData.length > 0"
      [usingHotelComponent]="false"
      [recentListingData]="recentListingData"
      (toggleSideMenuInfo)="toggleSideMenuInfo($event)"
    >
    </app-recent-selected> -->

        <div
            [ngClass]="{
                'poi-container-display-none': item.walkTime == -1,
                'poi-container-display-block': item.walkTime != -1
            }"
            *ngFor="let item of poiListingData; let isEnd = last"
            [ngStyle]="{
                'margin-bottom':
                    isEnd == true && item.walkTime != -1 ? '1em' : '0em'
            }"
            (click)="toggleSideMenuInfo(item)"
        >
            <app-poi [id]="item.id" [poiData]="item"></app-poi>
        </div>
    </div>

    <!-- Dining -->
    <div
        class="sub-menu-listing-container"
        *ngIf="selectedCategory === 'Dining'"
    >
        <!-- <app-recent-selected
      *ngIf="recentListingData && recentListingData.length > 0"
      [usingHotelComponent]="false"
      [recentListingData]="recentListingData"
      (toggleSideMenuInfo)="toggleSideMenuInfo($event)"
    >
    </app-recent-selected> -->

        <div
            [ngClass]="{
                'poi-container-display-none': item.walkTime == -1,
                'poi-container-display-block': item.walkTime != -1
            }"
            *ngFor="let item of poiListingData; let isEnd = last"
            [ngStyle]="{
                'margin-bottom':
                    isEnd == true && item.walkTime != -1 ? '1em' : '0em'
            }"
            (click)="toggleSideMenuInfo(item)"
        >
            <app-poi [id]="item.id" [poiData]="item"></app-poi>
        </div>
    </div>
</div>
