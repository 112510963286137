import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "app-alert-box",
    templateUrl: "./alert-box.component.html",
    styleUrls: ["./alert-box.component.scss"],
    standalone: true,
})
export class AlertBoxComponent implements OnInit {
  @Input("alertText") alertText: any;

  constructor() {}

  ngOnInit() {}
}
