import { createAction, props } from '@ngrx/store';
import { types } from './types';

export const toggleSelfieModal = createAction(
    types.TOGGLE_SELFIE,
    props<{ shouldOpen: boolean }>()
);

export const toggleSurveyModal = createAction(
    types.TOGGLE_SURVEY,
    props<{ shouldOpen: boolean; activeSurvey: string }>()
);

export const toggleSideMenuInfo = createAction(
    types.SIDE_MENU_INFO,
    props<{
        shouldOpen: boolean;
        selectedCategory?: string;
        animateSideInfo?: boolean;
        setAnimationState?: string;
    }>()
);

export const toggleLaxTV = createAction(
    types.TOGGLE_LAX_TV,
    props<{ shouldOpen: boolean }>()
);

export const toggleSearchBarModel = createAction(
    types.TOGGLE_SEARCH_BAR,
    props<{ shouldOpen: boolean }>()
);

export const toggleAlertWeatherModal = createAction(
    types.TOGGLE_WEATHER_MODAL,
    props<{ shouldOpen: boolean }>()
);

export const showDialog = createAction(
    types.TOGGLE_DIALOG,
    props<{
        showDialog: boolean;
        title: string;
        subTitle: string;
        showCloudFunction?: boolean;
        fromComponent?: string;
    }>()
);

export const mapInitialized = createAction(
    types.MAP_INTIALIZED,
    props<{ mapInitialized: boolean }>()
);

export const toggleStaticInfoModal = createAction(
    types.TOGGLE_STATIC_INFO,
    props<{ shouldOpen: boolean }>()
);

export const toggleSubSideMenu = createAction(
    types.TOGGLE_SUB_SIDE_MENU,
    props<{
        shouldOpen: boolean;
        animateSideInfoState?: string;
        isDepartureActive?: boolean;
        selectedCategory?: string;
        isFlightSearch?: boolean;
        searchError?: boolean;
        categoryInfo?: {};
        flights?: {}[];
    }>()
);

export const flightSearch = createAction(
    types.FLIGHT_SEARCH,
    props<{
        isFlightSearch: boolean;
        isDepartureActive?: boolean;
        flights?: {}[];
        searchError?: boolean;
    }>()
);

export const flightType = createAction(
    types.FLIGHT_TYPE,
    props<{
        isDepartureActive: boolean;
    }>()
);

export const arrivalScrolling = createAction(
    types.ARRIVAL_SCROLL,
    props<{
        arrivalsScroll: number;
    }>()
);

export const departuresScrolling = createAction(
    types.DEPARTURE_SCROLL,
    props<{
        departuresScroll: number;
    }>()
);

export const setAgentStatus = createAction(
    types.SET_AGENT_STATUS,
    props<{
        setAgentStatus: boolean;
    }>()
);
export const setRandomBgImage = createAction(
    types.SET_RANDOM_BG_IMAGE,
    props<{
        randomBgImage: number;
    }>()
);

export const resetApp = createAction(types.RESET_APP);

export const didAppReload = createAction(types.TOGGLE_DID_APP_RESET);

export const showIdleState = createAction(
    types.SHOW_IDLE_STATE,
    props<{ showIdleState: boolean }>()
);

export const toggleVideoPlayerModal = createAction(
    types.TOGGLE_VIDEO_PLAYER_MODAL,
    props<{ shouldOpen: boolean }>()
);
