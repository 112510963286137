import { enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';

import { AppConfig } from './environments/environment';
import { AppComponent } from './app/app.component';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import { MetaReducer, ActionReducer, provideStore } from '@ngrx/store';
import { effects } from './app/store/effects';
import { provideEffects } from '@ngrx/effects';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    withInterceptorsFromDi,
    provideHttpClient,
    HttpClient,
} from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { combinedReducers } from './app/store/reducers';
import { YOUTUBE_PLAYER_CONFIG } from '@angular/youtube-player';

import {
    PreloadAllModules,
    provideRouter,
    withPreloading,
} from '@angular/router';
import { routes } from '@app/routing/app-routing.module';

//Firebase
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import {
    getAnalytics,
    provideAnalytics,
    ScreenTrackingService,
    UserTrackingService,
} from '@angular/fire/analytics';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web/build/player/lottie_light';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const reducer: any = combinedReducers;

export function localStorageSyncReducer(
    reducer: ActionReducer<any>
): ActionReducer<any> {
    return localStorageSync({
        keys: [{ ui: ['images', 'didAppReload'] }, 'agent'],
        rehydrate: true,
    })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

if (AppConfig.production) {
    enableProdMode();
    if (window) window.console.log = function () {};
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
            })
        ),
        provideRouter(routes, withPreloading(PreloadAllModules)),
        provideStore(reducer, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        provideEffects(effects),
        // provideStoreDevtools({
        //     maxAge: 25,
        //     logOnly: !isDevMode,
        // }),
        {
            provide: YOUTUBE_PLAYER_CONFIG,
            useValue: {
                loadApi: true,
                disablePlaceholder: true,
            },
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideFirebaseApp(() => initializeApp(AppConfig.firebase)),
        provideAuth(() => getAuth()),
        provideAnalytics(() => getAnalytics()),
        ScreenTrackingService,
        UserTrackingService,
        provideDatabase(() => getDatabase()),
        providePerformance(() => getPerformance()),
        { provide: FIREBASE_OPTIONS, useValue: AppConfig.firebase },
        provideLottieOptions({
            player: () => player,
        }),
    ],
}).catch((err) => console.error(err));
